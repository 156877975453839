export default {
  setBookingsOrders(state: any, payload: any) {
    if (payload.action === "replace") {
      state.bookingOrders = payload.data;
    }

    if (payload.action === "unshift") {
      const existingOrders: any = state.bookingOrders
        ? state.bookingOrders
        : [];

      if (existingOrders.length > 0) {
        for (let i = 0; i < existingOrders.length; i++) {
          if (existingOrders[i]["order_id"] === payload.data["order_id"]) {
            existingOrders.splice(i, 1);
            existingOrders.unshift(payload.data);
            state.bookingOrders = existingOrders;

            break;
          }
        }
      }

      if (existingOrders.length === 0) {
        state.bookingOrders.unshift(payload.data);
      }

      state.bookingOrders.unshift(payload.data);
    }
  },
  setBookingsRequests(state: any, payload: any) {
    if (payload.action === "replace") {
      state.bookingRequests = payload.data;
    }

    if (payload.action === "unshift") {
      const existingRequests: any = state.bookingRequests
        ? state.bookingRequests
        : [];

      if (existingRequests.length > 0) {
        for (let i = 0; i < existingRequests.length; i++) {
          if (existingRequests[i]["order_id"] === payload.data["order_id"]) {
            existingRequests.splice(i, 1);
            existingRequests.unshift(payload.data);
            state.bookingRequests = existingRequests;

            break;
          }
        }
      }

      if (existingRequests.length === 0) {
        state.bookingRequests = payload.data;
      }
    }
  },
};
