<template>
  <ion-header
    :translucent="true"
    mode="ios"
    class="ion-no-border"
    v-if="!isGuestReviewer"
  >
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="cancelModal" v-if="reqStatus === 'ready'">
          <ion-icon :icon="arrowBack"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title> {{ reviewTitle }} </ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div
      class="container p-0"
      :class="isWebBigScreen ? (isGuestReviewer ? 'mt-2' : 'mt-4') : ''"
    >
      <div
        :class="isWebBigScreen ? 'box shadow-sm rounded' : ''"
        v-if="reqFormStatus"
      >
        <create-appointment-booking-summary
          :bookingInfoType="bookingInfoType"
          :bookingInfo="bookingInfo"
          :isPastEvent="isPastEvent"
          :isGuestReviewer="isGuestReviewer"
        >
        </create-appointment-booking-summary>
        <ion-list>
          <ion-item
            lines="full"
            class="pt-3"
            v-if="filterStatus === 'ongoing' && compResponseOptions.length > 0"
          >
            <ion-label>
              <h3><strong>Session is in progress</strong></h3>
            </ion-label>
            <span
              class="material-symbols-rounded pl-1 sidebar-icon-normal"
              slot="end"
            >
              perm_contact_calendar
            </span>
            <span
              class="material-symbols-rounded pl-1 sidebar-icon-normal"
              slot="end"
            >
              cast
            </span>
          </ion-item>
          <ion-item
            lines="full"
            class="pt-3"
            v-if="allowReview && compResponseOptions.length > 0"
          >
            <ion-label>
              <h3><strong>Response</strong></h3>
            </ion-label>
            <ion-select
              placeholder="Select response"
              @ionChange="changeBookingResponse($event.target.value)"
              :value="bookingResponse"
              interface="popover"
              slot="end"
            >
              <ion-select-option
                lines="none"
                v-for="(opt, index) in compResponseOptions"
                :key="opt.alias"
                :value="index"
              >
                {{ opt.alias }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item
            lines="full"
            class="pt-3"
            :counter="true"
            v-if="
              filterStatus === 'pending' &&
              bookingInfoType === 'order' &&
              bookingResponse >= 0 &&
              compShowConfirm
            "
          >
            <ion-textarea
              v-model="meetingLink"
              placeholder="Enter meeting link here. ( If additional details like passcode is required, share in the space below )"
              :maxlength="500"
              :auto-grow="false"
            >
            </ion-textarea>
          </ion-item>

          <ion-item
            lines="full"
            class="pt-3"
            :counter="true"
            v-if="bookingResponse >= 0 && compShowConfirm"
          >
            <ion-textarea
              v-model="clientFeedback"
              placeholder="Additional details or feedback"
              :maxlength="300"
              :auto-grow="true"
            >
            </ion-textarea>
          </ion-item>
          <ion-item lines="none" class="py-3">{{ reqFeedback }}</ion-item>
          <ion-spinner
            name="circles"
            class="mb-3 ml-2"
            v-if="reqStatus === 'ongoing'"
          ></ion-spinner>
          <ion-button
            @click="updateBookingRequest"
            class="mt-2 mb-3 px-2"
            expand="block"
            v-if="
              bookingResponse >= 0 && reqStatus === 'ready' && compShowConfirm
            "
          >
            {{ compSubmitTitle }}
          </ion-button>
        </ion-list>
      </div>
    </div>

    <!--  -->
    <div class="container text-center" v-if="reqStatus === 'successful'">
      <div class="row pt-4">
        <div :class="!isWebBigScreen ? 'col-12' : 'col-4'"></div>
        <div :class="!isWebBigScreen ? 'col-12' : 'col-4'">
          <div class="text-center">
            <img
              class="img-fluid"
              src="../../../images/main/event_accepted.svg"
              v-if="finalResponse === 'accepted'"
              alt=""
            />
            <img
              class="img-fluid"
              src="../../../images/main/event_rejected.svg"
              v-else
              alt=""
            />
            <h5 class="font-weight-bold mt-2">Request {{ finalResponse }}</h5>
            <ion-note class="ml-3 m4-3 mb-3 pt-2 pb-3">
              {{ compSubmitMsg }}
            </ion-note>
            <div class="ml-3 mb-3 py-2" v-if="isGuestReviewer">
              Get more out of rendez with your own account. Manage your
              appointments with providers more efficiently, or become a service
              provider yourself. Creating an account takes 30 seconds. Setting
              up a provider account takes less than 2 minutes.
            </div>
            <ion-button
              @click="confirmModal"
              class="mt-3 mb-5"
              fill="outline"
              expand="block"
              v-if="!isGuestReviewer"
            >
              Continue
            </ion-button>

            <ion-button
              @click="goToLink('feed')"
              class="my-2"
              fill="outline"
              expand="block"
              v-if="isGuestReviewer"
            >
              Continue exploring rendez
            </ion-button>

            <ion-button
              @click="goToLink('sign_up')"
              class="mb-5"
              fill="solid"
              expand="block"
              v-if="isGuestReviewer"
            >
              Join the rendez community
            </ion-button>
          </div>
        </div>
        <div :class="!isWebBigScreen ? 'col-12' : 'col-4'"></div>
      </div>
    </div>
  </ion-content>

  <ion-modal :keep-contents-mounted="true">
    <ion-datetime
      id="startTime"
      presentation="time"
      v-model="currStartTime"
      :show-default-buttons="true"
    ></ion-datetime>
  </ion-modal>

  <ion-modal :keep-contents-mounted="true">
    <ion-datetime
      id="endTime"
      presentation="time"
      v-model="currEndTime"
      :show-default-buttons="true"
    ></ion-datetime>
  </ion-modal>
</template>

<script lang="ts">
import {
  IonTitle,
  IonIcon,
  IonItem,
  IonList,
  IonLabel,
  IonContent,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonButton,
  IonDatetime,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonNote,
  IonSpinner,
  modalController,
} from "@ionic/vue";
import { add, arrowBack, copyOutline, optionsOutline } from "ionicons/icons";
import { defineComponent, ref, computed, inject } from "vue";
import storeKeeper from "@/hooks/RendezStorage";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { CapacitorHttp } from "@capacitor/core";
import urls from "@/hooks/urlConfig";
import timeKeeper from "@/hooks/Timer";
import coreSockets from "@/hooks/sockEvents";

import CreateAppointmentBookingSummary from "@/components/forms/appointment/CreateAppointmentBookingSummary.vue";

export default defineComponent({
  components: {
    IonTitle,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonContent,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonButton,
    IonDatetime,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonModal,
    IonNote,
    IonSpinner,
    CreateAppointmentBookingSummary,
  },
  props: {
    bookingInfoType: {
      required: true,
    },
    bookingInfo: {
      required: true,
      type: Object,
      default: () => {
        [];
      },
    },
    filterStatus: {
      required: true,
    },
    reviewTitle: {
      required: true,
    },
    allowReview: {
      required: true,
    },
    actionType: {
      required: true,
    },
    isRequestRating: {
      type: Boolean,
      required: true,
      default: false,
    },
    isPastEvent: {
      required: true,
      default: false,
    },
    isGuestReviewer: {
      default: false,
    },
  },
  setup(props) {
    const sk = storeKeeper();

    const tk = timeKeeper();

    //get the urls
    const coreUrl = urls.coreUrl;

    // get the global route object
    const router = useRouter();

    // get the global vuex store objects
    const vueStore = useStore();

    // real time alerts
    const sock = coreSockets();

    const currStartTime = ref("2022-01-01T12:00");
    const currEndTime = ref("2022-01-01T13:00");

    const isWebBigScreen = ref(inject("isWebBigScreen"));

    const compReviewerName = computed(function () {
      let reviewerName = "";
      if (props.isGuestReviewer) {
        reviewerName = props.bookingInfo["guest_name"];
      } else {
        const sessionData = vueStore.getters["auth/getSessionData"];
        reviewerName =
          props.bookingInfoType === "order"
            ? sessionData["username"]
            : sessionData["name"];
      }

      return reviewerName;
    });

    function cancelModal() {
      return modalController.dismiss(null, "cancel");
    }
    // bookingInfo.value["username"]
    async function confirmModal() {
      const data = {
        type: "review",
        selected_status:
          compResponseOptions.value[bookingResponse.value]["value"],
      };

      return modalController.dismiss(data, "confirm");
    }

    const bookingInfoType: any = ref(props.bookingInfoType);
    const bookingInfo: any = ref(props.bookingInfo);
    const bookingResponse = ref(-1);

    const clientFeedback = ref("");

    const finalResponse = ref("");

    const filterStatus: any = ref(props.filterStatus);

    const serviceList = ref(JSON.parse(props.bookingInfo["services"]));

    const meetingLink = ref(
      filterStatus.value !== "pending"
        ? bookingInfo.value["delivery_location"]
        : ""
    );

    const requestResponseOptions = [
      {
        value: "cancelled",
        alias: "Cancel",
        feedback:
          "Thank you for reaching out, unfortunately I'm unavailable to meet you at this time",
        submitMsg:
          "You have successfully rejected this request and the service provider has been informed.",
      },
    ];

    const orderResponsePendingOptions = [
      {
        value: "accepted",
        alias: "Accept",
        feedback: "Thank you for reaching out, looking forward to meeting you",
        submitMsg:
          "You have successfully accepted this request and the client has been informed.The appointment is now official, if you wish to cancel this appointment,  go to active rendez.",
      },
      {
        value: "rejected",
        alias: "Reject",
        feedback:
          "Thank you for reaching out, unfortunately I'm unavailable to meet you at this time",
        submitMsg:
          "You have successfully rejected this request and the client has been informed.",
      },
    ];

    const orderResponseAcceptOptions = [
      {
        value: "ongoing",
        alias: "Start session",
        feedback: "Session succssfully started.",
        submitMsg:
          "You have successfully commenced with this appointment and the client has been informed.",
      },
      {
        value: "cancelled",
        alias: "Cancel",
        feedback:
          "I was looking forward to meeting you, unfortunately I have to cancel at this time",
        submitMsg:
          "You have successfully rejected this request and the client has been informed.",
      },
    ];

    const orderResponseOngoingOptions = [
      {
        value: "completed",
        alias: "Complete",
        feedback:
          "Thank you for reaching out, hope you had a productive session",
        submitMsg:
          "You have successfully completed this request and the client has been informed.",
      },
      {
        value: "cancelled",
        alias: "Cancel",
        feedback:
          "I was looking forward to meeting you, unfortunately I have to cancel at this time",
        submitMsg:
          "You have successfully rejected this request and the client has been informed.",
      },
    ];

    const compResponseOptions = computed(function () {
      let responseOptions: any = [];

      // determine the response options for when the request is pending
      if (filterStatus.value === "pending") {
        if (bookingInfoType.value === "request") {
          responseOptions = requestResponseOptions;
        }

        if (bookingInfoType.value === "order") {
          responseOptions = orderResponsePendingOptions;
        }
      }

      // determine the response options for when the request is accepted

      if (filterStatus.value === "accepted") {
        if (bookingInfoType.value === "request") {
          responseOptions = requestResponseOptions;
        }

        if (bookingInfoType.value === "order") {
          responseOptions = orderResponseAcceptOptions;
        }
      }

      // determine the response options for when the request is ongoing

      if (filterStatus.value === "ongoing") {
        if (bookingInfoType.value === "request") {
          responseOptions = requestResponseOptions;
        }

        if (bookingInfoType.value === "order") {
          responseOptions = orderResponseOngoingOptions;
        }
      }

      return responseOptions;
    });

    const compShowConfirm = computed(function () {
      let showConfirm = true;
      const currFinalResponse = finalResponse.value;
      const currDate = new Date();
      const existingDate = new Date(bookingInfo.value["provider_start_time"]);

      if (
        currDate < existingDate &&
        filterStatus.value === "ongoing" &&
        currFinalResponse === "completed"
      ) {
        showConfirm = false;
      }

      return showConfirm;
    });

    const compSubmitTitle = computed(function () {
      const responseIndex = bookingResponse.value;

      return responseIndex >= 0
        ? `${compResponseOptions.value[responseIndex]["alias"]} request`
        : "";
    });

    const compSubmitMsg = computed(function () {
      const responseIndex = bookingResponse.value;

      return responseIndex >= 0
        ? compResponseOptions.value[responseIndex]["submitMsg"]
        : "";
    });

    function changeBookingResponse(index: any) {
      const responseIndex = parseInt(index);

      bookingResponse.value = responseIndex;

      finalResponse.value = compResponseOptions.value[responseIndex]["value"];
      if (!compShowConfirm.value) {
        reqFeedback.value =
          "You can't complete an appointment before the agreed start time.";
        return;
      }

      reqFeedback.value = "";
      showSubmitButton.value = responseIndex >= 0 ? true : false;
    }

    const reqFeedback = ref("");
    const reqFormStatus = ref(true);
    const reqStatus = ref("ready");
    const showSubmitButton = ref(false);

    const showServices = ref(false);

    const updateBookingRequest = async () => {
      reqStatus.value = "ongoing";

      let finalName = "";

      if (bookingInfoType.value === "order") {
        finalName =
          bookingInfo.value["user_category"] === "active"
            ? bookingInfo.value["name"]
            : bookingInfo.value["guest_name"];
      }

      if (bookingInfoType.value === "request") {
        finalName = bookingInfo.value["username"];
      }

      const responseIndex = bookingResponse.value;

      if (clientFeedback.value.length === 0) {
        clientFeedback.value =
          responseIndex >= 0
            ? compResponseOptions.value[responseIndex]["feedback"]
            : "";
      }

      const tokenId = await sk.getStoreIon("access_token");
      const reqHeader: any = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tokenId,
      };

      if (!tokenId) {
        delete reqHeader["Authorization"];
      }

      let finalRemoteLocation = bookingInfo.value["delivery_location"]
        ? bookingInfo.value["delivery_location"]
        : "";

      if (bookingInfo.value["delivery_mode"] === "remote") {
        if (filterStatus.value === "pending") {
          finalRemoteLocation = meetingLink.value;
        } else {
          finalRemoteLocation = finalRemoteLocation
            ? finalRemoteLocation
            : "remote_location";
        }
      }

      const options = {
        url: coreUrl.backendUrl + "update_appointment_order_request",
        headers: reqHeader,
        data: {
          iv_order_id: bookingInfo.value["order_id"],
          iv_booking_info_type: bookingInfoType.value,
          iv_name: finalName,
          iv_reviewer_name: compReviewerName.value,
          iv_email:
            bookingInfo.value["user_category"] === "active"
              ? bookingInfo.value["email"]
              : bookingInfo.value["guest_email"],
          iv_response:
            compResponseOptions.value[bookingResponse.value]["value"],
          iv_feedback: clientFeedback.value,
          iv_delivery_location: finalRemoteLocation,
          iv_service_list: JSON.parse(bookingInfo.value["services"]),
          iv_session_list: JSON.parse(bookingInfo.value["session_time"]),
          iv_total_cost: bookingInfo.value["total_cost"],
          iv_total_duration: bookingInfo.value["total_duration"],
          iv_total_currency: bookingInfo.value["total_currency"],
        },
      };

      console.log(options);

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.push("home");
      }

      if (response.data.status) {
        reqStatus.value = "successful";
        reqFeedback.value = response.data.message.main_msg;
        reqFormStatus.value = false;
        showSubmitButton.value = false;
        // Emit real time notification

        const new_booking_alert = {
          notification_data: response.data.data.notification_data,
          booking_data: {
            ...bookingInfo.value,
            ...response.data.data.booking_data,
          },
          additional_update: response.data.data.additional_update,
        };
        sock.socket.emit("set_notification", new_booking_alert);

        if (
          compResponseOptions.value[bookingResponse.value]["value"] ===
            "ongoing" &&
          bookingInfo.value["delivery_mode"] === "remote"
        ) {
          let finalLink = "https://meet.google.com";
          finalLink = bookingInfo.value["delivery_location"]
            ? bookingInfo.value["delivery_location"]
            : finalLink;
          window.open(finalLink, "_blank");
          confirmModal();
        }
      } else {
        reqFeedback.value = response.data.message.main_msg;
        reqStatus.value = "ready";
      }
    };

    const goToLink = (link: any) => {
      window.location.href = link;
    };

    return {
      add,
      arrowBack,
      copyOutline,
      optionsOutline,
      tk,
      cancelModal,
      confirmModal,
      currStartTime,
      currEndTime,
      clientFeedback,
      meetingLink,
      bookingResponse,
      serviceList,
      changeBookingResponse,
      compResponseOptions,
      compSubmitTitle,
      compSubmitMsg,
      compShowConfirm,
      reqStatus,
      reqFeedback,
      reqFormStatus,
      showServices,
      isWebBigScreen,
      finalResponse,
      updateBookingRequest,
      goToLink,
    };
  },
});
</script>

<style scoped>
ion-icon {
  color: #7e00e9;
}
</style>
