<template>
  <span @click="logoutUser">Logout</span>
</template>

<script lang="ts">
import { CapacitorHttp } from "@capacitor/core";
import { toastController } from "@ionic/vue";
import { defineComponent, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import storeKeeper from "@/hooks/RendezStorage";
import urls from "@/hooks/urlConfig";
import coreSockets from "@/hooks/sockEvents";

export default defineComponent({
  name: "compAuthSignOut",
  setup() {
    /*
      VueType:TTemplate, its the header of the app
      Responsibiliy: its responsible for the top navigations, sign out feature, socket realtime notification feature
      links: Standard non-sign in links when logged out, and signed in links when logged in
      Active:  Always
      Funtions: refreshHome, openPostContent, runSearch, logOut, getUserAlerts
      Use Session data : This page needs session data
      External libraries :  urlConfig , RendezStorage
      Components: FeedDataList => This components populates the feed of provider's posting, its used here in the home page to show a section of the feeds
      */

    // Initiate the local storage
    const sk = storeKeeper();

    // get the url config that holds all the URL basepaths
    const coreUrl = urls.coreUrl;

    // get the global route object for url routing
    const router = useRouter();

    // initiate the sockets config for socket functions
    const sock = coreSockets();

    // get the global vuex store objects, for temp persistent storage
    const vueStore = useStore();

    // get session data
    const compSessionData = computed(function () {
      const sessionData = vueStore.getters["auth/getSessionData"];
      return sessionData;
    });

    //get user owner id data if user is active
    const ownerId = compSessionData.value
      ? compSessionData.value["ownerId"]
      : "none";

    // feedback toast controller
    async function feedbackToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 2500,
        position: "bottom",
      });

      await toast.present();
    }

    // function for logging out user
    async function logoutUser() {
      // Get your assigned token id, that will validate the api execution
      const tokenId = await sk.getStoreIon("access_token");

      // The function that will perform the actual action
      const signOut = async () => {
        // set the data required to access the backend
        const options = {
          url: coreUrl.backendUrl + "sign_out",
          headers: { Authorization: "Bearer " + tokenId },
        };

        // sending the http request to the server
        const response = await CapacitorHttp.request({
          ...options,
          method: "GET",
        });

        // if backend execution is successful
        if (response.data.status) {
          // Reset the vuex session data to the default
          await vueStore.dispatch("auth/executeSetDefaultSessionData");
          await vueStore.dispatch("account/executeSetDefaultAccountData");
          await vueStore.dispatch("account/executeDefaultSetAlertsList");

          // exit the socket room , so as to end this channel
          sock.socket.emit("leave_client_room", ownerId);

          // Disconnect this instance from the server
          sock.socket.on("disconnect", () => {
            // console.log(sock.socket.id); // undefined
          });

          // first delete all data in the ionic storage db
          await sk.delAllStoreIon();

          // router.replace("/home");
          // redirect user to the homepage
          window.location.href = "/home";
        }

        // if user is not alloed to access this resource in the backend
        if (response.status === 401 || response.status === 422) {
          // delete all data in the ionic storage db
          await sk.delAllStoreIon();

          // redirect to home
          router.push("home");
        }
      };

      // execute the logout function in a promise block to prevent the server from crashing

      signOut()
        .then()
        .catch((err) => {
          err ? err : "Temporary server issue";
          feedbackToast(err);
        });
    }

    return {
      logoutUser,
    };
  },
});
</script>

<style scoped>
.icon-md {
  font-size: 24px;
}

.active-link {
  font-weight: 900;
  text-decoration: underline;
}
</style>
