import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-606cafd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 2,
  class: "mt-3 pt-3 col-6 mx-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_utility_image_upload = _resolveComponent("utility-image-upload")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.imageBase)
      ? (_openBlock(), _createBlock(_component_ion_header, {
          key: 0,
          translucent: true,
          mode: "ios",
          class: "ion-no-border"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_buttons, { slot: "start" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, { onClick: _ctx.cancelModal }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(" Save avatar ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_buttons, { slot: "end" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, { onClick: _ctx.clearImage }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.cameraReverse }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.imageBase)
      ? (_openBlock(), _createBlock(_component_utility_image_upload, {
          key: 1,
          ImgHeight: 300,
          ImgWidth: 300,
          canvasSize: "small-sqr-cropper ",
          onEmitUpdateImageUpload: _ctx.updateImageUpload,
          onEmitCloseImageUpload: _ctx.cancelModal
        }, null, 8, ["onEmitUpdateImageUpload", "onEmitCloseImageUpload"]))
      : _createCommentVNode("", true),
    (_ctx.imageBase)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ion_icon, {
            icon: _ctx.closeCircle,
            color: "light",
            onClick: _ctx.clearImage,
            size: "large",
            class: "clear-image cursor-pointer"
          }, null, 8, ["icon", "onClick"]),
          _createVNode(_component_ion_img, {
            src: _ctx.imageBase,
            style: {"width":"100%"},
            class: "rounded"
          }, null, 8, ["src"]),
          (_ctx.reqStatus)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                onClick: _ctx.createNewAvatar,
                class: "mb-4 mt-4",
                expand: "block",
                color: "primary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Update Avatar")
                ]),
                _: 1
              }, 8, ["onClick"]))
            : (_openBlock(), _createBlock(_component_ion_spinner, {
                key: 1,
                name: "circles",
                class: "mb-3 ml-2"
              }))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}