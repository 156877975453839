import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_create_auth_update_username = _resolveComponent("create-auth-update-username")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      (_ctx.actionType === 'settings')
        ? (_openBlock(), _createBlock(_component_ion_header, {
            key: 0,
            translucent: true,
            mode: "ios",
            class: "ion-no-border"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, { slot: "start" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, { onClick: _ctx.cancelModal }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.arrowBack }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Update brand/business name")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_buttons, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, { onClick: _ctx.cancelModal }, {
                        default: _withCtx(() => [
                          _createTextVNode("Done")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_create_auth_update_username, { actionType: _ctx.actionType }, null, 8, ["actionType"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}