/*
Category : Session hooks
Description : Contains the list of various errors to be used site wide
*/
import { reactive } from "vue";
import { io } from "socket.io-client";
import urls from "@/hooks/urlConfig";
import storeKeeper from "@/hooks/RendezStorage";

//get the urls
const coreUrl = urls.coreUrl;

const socket = io(coreUrl.socketUrl);

// get the global vuex store objects
const sk = storeKeeper();

const sockState = reactive({
  active: false,
});

async function getSessionData() {
  const sessionData: any = JSON.parse(await sk.getStoreIon("session_data"));

  if (sessionData) {
    const ownerId: any = sessionData["ownerId"];
    socket.emit("join_client_room", ownerId);
  }
}

socket.on("connect", function () {
  sockState.active = true;
  getSessionData();
});

// Example of a GET request
const createMessage = async () => {
  return true;
};

export default function coreSockets() {
  return {
    socket,
    sockState,
    createMessage,
  };
}
