<template>
  <ion-header
    :translucent="true"
    mode="ios"
    class="ion-no-border"
    v-if="!isGuestReviewer"
  >
    <ion-toolbar class="py-2">
      <ion-buttons slot="start">
        <ion-button @click="cancelModal" v-if="reqStatus === 'ready'">
          <ion-icon :icon="arrowBack"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title class="px-3">
        {{ reviewTitle }}
        <div v-if="trustStatus" class="trusted-provider">
          ( <i class="fa fa-handshake-o fa-fw"></i> this is one of your trusted
          providers )
        </div>
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div
      class="container p-0"
      :class="isWebBigScreen ? (isGuestReviewer ? 'mt-2' : 'mt-4') : ''"
    >
      <div
        :class="isWebBigScreen ? 'box shadow-sm rounded' : ''"
        v-if="reqFormStatus"
      >
        <create-appointment-booking-summary
          :bookingInfoType="bookingInfoType"
          :bookingInfo="bookingInfo"
          :isPastEvent="isPastEvent"
          :isGuestReviewer="isGuestReviewer"
        >
        </create-appointment-booking-summary>
        <ion-list v-if="reviewRatingInfo">
          <ion-item lines="full" class="pt-3" v-if="reviewRatingInfo">
            <ion-label>
              <h3>
                <strong>{{ compRatingLevel }}</strong>
              </h3>
            </ion-label>
            <i
              class="fa fa-star pl-1"
              v-for="star in fv.rating.val"
              :key="star"
            ></i>
          </ion-item>
        </ion-list>
        <ion-list v-if="!reviewRatingInfo">
          <ion-item lines="full" class="pt-3" v-if="reviewRatingInfo">
            <ion-label>
              <h3>
                <strong>{{ compRatingLevel }}</strong>
              </h3>
            </ion-label>
            <i
              class="fa fa-star pl-1"
              v-for="star in fv.rating.val"
              :key="star"
            ></i>
          </ion-item>

          <ion-item lines="full" class="pt-3" v-if="!isRequestRating">
            <ion-label>
              <h3>
                <strong>Rate your session</strong>
              </h3>
            </ion-label>
            <i
              class="fa fa-star pl-1"
              v-for="star in fv.rating.val"
              :key="star"
            ></i>
            <ion-select
              placeholder="Select rating"
              class="pl-2"
              @ionChange="changeBookingRating($event.target.value)"
              :value="fv.rating.val"
              interface="popover"
              slot="end"
              v-if="reqStatus === 'ready'"
            >
              <ion-select-option
                lines="none"
                v-for="opt in ratingOptions"
                :key="opt.alias"
                :value="opt.value"
              >
                {{ opt.alias }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item
            lines="full"
            class="pt-3"
            v-if="
              fv.rating.val >= 3 &&
              !trustStatus &&
              !isRequestRating &&
              !isGuestReviewer
            "
          >
            <ion-label>
              <h3><strong>Add to favourites / trusted providers </strong></h3>
            </ion-label>
            <ion-select
              placeholder="Select "
              class="pl-2"
              @ionChange="fv.trusted.val = $event.target.value"
              :value="fv.trusted.val"
              interface="popover"
              slot="end"
            >
              <ion-select-option
                lines="none"
                v-for="trust in trustedOptions"
                :key="trust.alias"
                :value="trust.value"
              >
                {{ trust.alias }}
              </ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item
            lines="full"
            class="pt-3"
            :counter="true"
            v-if="fv.rating.val >= 0 || isRequestRating"
          >
            <ion-textarea
              v-model="fv.feedback.val"
              :placeholder="
                isRequestRating
                  ? 'Enter any additional info for ' + bookingInfo['name']
                  : 'Enter feedback for ' + bookingInfo['name']
              "
              :maxlength="300"
              :auto-grow="true"
            >
            </ion-textarea>
          </ion-item>

          <ion-note class="ml-3">{{ reqFeedback }}</ion-note>
          <ion-spinner
            name="circles"
            class="mb-3 ml-2"
            v-if="reqStatus === 'processing'"
          ></ion-spinner>
          <ion-button
            @click="updateBookingRating"
            class="mt-2 mb-3 px-2"
            expand="block"
            v-if="reqStatus === 'ready' && !isRequestRating"
          >
            Rate service
          </ion-button>

          <ion-button
            @click="requestBookingRating"
            class="mt-2 mb-3 px-2"
            expand="block"
            v-if="reqStatus === 'ready' && isRequestRating"
          >
            Request rating
          </ion-button>
        </ion-list>
      </div>

      <!--  -->
      <div class="container text-center" v-if="reqStatus === 'complete'">
        <div class="row pt-4">
          <div :class="!isWebBigScreen ? 'col-12' : 'col-4'"></div>
          <div :class="!isWebBigScreen ? 'col-12' : 'col-4'">
            <div class="text-center">
              <img
                class="img-fluid"
                src="../../../images/main/rating.svg"
                alt=""
              />
              <h5 class="font-weight-bold mt-2">Feedback provided</h5>
              <ion-note class="ml-3 mb-3 py-2">
                Thank you for taking the time to provide feedback.
                <span v-if="fv.trusted.val === 'yes'">
                  {{ bookingInfo["name"] }} has been added to your trusted
                  provider circle
                </span>
              </ion-note>
              <div class="ml-3 mb-3 py-2" v-if="isGuestReviewer">
                Get more out of rendez with your own account. Manage your
                appointments with providers more efficiently, or become a
                service provider yourself. Creating an account takes 30 seconds.
                Setting up a provider account takes less than 2 minutes.
              </div>
              <ion-button
                @click="confirmModal"
                class="mt-3 mb-5"
                fill="outline"
                expand="block"
                v-if="!isGuestReviewer"
              >
                Continue
              </ion-button>

              <ion-button
                @click="goToLink('feed')"
                class="my-2"
                fill="outline"
                expand="block"
                v-if="isGuestReviewer"
              >
                Continue exploring rendez
              </ion-button>

              <ion-button
                @click="goToLink('sign_up')"
                class="mb-5"
                fill="solid"
                expand="block"
                v-if="isGuestReviewer"
              >
                Join the rendez community
              </ion-button>
            </div>
          </div>
          <div :class="!isWebBigScreen ? 'col-12' : 'col-4'"></div>
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script lang="ts">
import {
  IonTitle,
  IonIcon,
  IonItem,
  IonList,
  IonLabel,
  IonContent,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonNote,
  IonSpinner,
  modalController,
} from "@ionic/vue";
import { star, arrowBack } from "ionicons/icons";
import { defineComponent, ref, computed, reactive, inject } from "vue";
import storeKeeper from "@/hooks/RendezStorage";
import { useRouter } from "vue-router";
import { CapacitorHttp } from "@capacitor/core";
import urls from "@/hooks/urlConfig";
import timeKeeper from "@/hooks/Timer";
import coreSockets from "@/hooks/sockEvents";

import CreateAppointmentBookingSummary from "@/components/forms/appointment/CreateAppointmentBookingSummary.vue";

export default defineComponent({
  components: {
    IonTitle,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonContent,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonNote,
    IonSpinner,
    CreateAppointmentBookingSummary,
  },
  props: {
    bookingInfoType: {
      required: true,
    },
    bookingInfo: {
      required: true,
      type: Object,
      default: () => {
        [];
      },
    },
    filterStatus: {
      required: true,
    },
    reviewTitle: {
      required: true,
    },
    allowReview: {
      required: true,
    },
    actionType: {
      required: true,
    },
    isRequestRating: {
      type: Boolean,
      required: true,
      default: false,
    },
    isPastEvent: {
      required: true,
      default: false,
    },
    isGuestReviewer: {
      default: false,
    },
    reviewRatingInfo: {
      default: false,
    },
  },
  setup(props) {
    const sk = storeKeeper();

    const tk = timeKeeper();

    //get the urls
    const coreUrl = urls.coreUrl;

    // get the global route object
    const router = useRouter();

    // real time alerts
    const sock = coreSockets();

    const isWebBigScreen = ref(inject("isWebBigScreen"));

    function cancelModal() {
      return modalController.dismiss(null, "cancel");
    }

    async function confirmModal() {
      const data = {
        type: props.isRequestRating ? "request_rating" : "rating",
        selected_rating: fv.rating.val,
        feedback: fv.feedback.val,
      };

      reqStatus.value = "ready";

      return modalController.dismiss(data, "confirm");
    }

    const bookingInfo: any = ref(props.bookingInfo);

    // const filterStatus: any = ref(props.filterStatus)
    // const bookingInfoType: any= ref(props.bookingInfoType)

    const ratingOptions = [
      { value: 5, alias: "Excellent" },
      { value: 4, alias: "Very good" },
      { value: 3, alias: "Satisfactory" },
      { value: 2, alias: "Poor" },
      { value: 1, alias: "Terrible" },
    ];

    const trustedOptions = [
      { value: "yes", alias: "Yes" },
      { value: "no", alias: "No" },
    ];

    const compRatingLevel = computed(function () {
      const reviewInfo = props.reviewRatingInfo;
      let finalRating = "";

      if (reviewInfo) {
        const currentReview = ratingOptions.filter(
          (item) => item.value === reviewInfo["rating"]
        );
        finalRating = `Session was rated ${currentReview[0][
          "alias"
        ].toLowerCase()}`;
      }

      return finalRating;
    });

    const trustStatus: any = ref(false);

    // Example of a GET request
    const getTrustStatus = async () => {
      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url:
          coreUrl.backendUrl +
          `get_trust_status/${bookingInfo.value["provider_id"]}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenId,
        },
      };

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "GET",
      });
      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.push("home");
      }

      if (response.data.status) {
        trustStatus.value = response.data.data.trust_status;
      }
    };

    if (!props.isGuestReviewer) {
      getTrustStatus();
    }

    const fv: any = reactive({
      rating: {
        status: false,
        val: props.reviewRatingInfo ? props.reviewRatingInfo["rating"] : 0,
        msg: false,
        validate: ["required", "numeric"],
      },
      trusted: {
        status: false,
        val: trustStatus.value ? "no" : "",
        msg: false,
        validate: ["required"],
      },
      feedback: {
        status: false,
        val: props.isRequestRating
          ? "Requesting your kind support to provide review and ratings for our session"
          : "",
        msg: false,
        validate: [
          "required",
          { option: "textMin", val: 8 },
          { option: "textMax", val: 500 },
        ],
      },
    });

    function changeBookingRating(rating: any) {
      fv.rating.val = parseInt(rating);

      if (fv.rating.val >= 0) {
        reqStatus.value = "ready";
      }
    }

    const reqFeedback = ref("");
    const reqFormStatus = ref(true);
    const reqStatus = ref("ready");

    const updateBookingRating = async () => {
      reqStatus.value = "processing";

      const tokenId = await sk.getStoreIon("access_token");
      const reqHeader: any = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tokenId,
      };

      if (!tokenId) {
        delete reqHeader["Authorization"];
      }

      const options = {
        url: coreUrl.backendUrl + "create_appointment_rating",
        headers: reqHeader,
        data: {
          iv_order_id: bookingInfo.value["order_id"],
          iv_provider_id: bookingInfo.value["provider_id"],
          iv_email: bookingInfo.value["email"],
          iv_rating: fv.rating.val,
          iv_feedback: fv.feedback.val,
          iv_trusted: fv.trusted.val ? fv.trusted.val : "no",
        },
      };

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.replace("home");
      }

      if (response.data.status) {
        reqStatus.value = "complete";
        reqFeedback.value = response.data.message.main_msg;
        reqFormStatus.value = false;

        const new_booking_alert = {
          notification_data: response.data.data.notification_data,
          booking_data: {
            ...bookingInfo.value,
            ...response.data.data.booking_data,
          },
          additional_update: response.data.data.additional_update,
        };
        sock.socket.emit("set_notification", new_booking_alert);
      } else {
        reqFeedback.value = response.data.message.main_msg;
        reqStatus.value = "ready";
      }
    };

    const requestBookingRating = async () => {
      reqStatus.value = "processing";

      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url: coreUrl.backendUrl + "create_appointment_request_rating",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenId,
        },
        data: {
          iv_user_category: bookingInfo.value["user_category"],
          iv_owner_id: bookingInfo.value["owner_id"],
          iv_order_id: bookingInfo.value["order_id"],
          iv_email:
            bookingInfo.value["user_category"] === "active"
              ? bookingInfo.value["email"]
              : bookingInfo.value["guest_email"],
          iv_name:
            bookingInfo.value["user_category"] === "active"
              ? bookingInfo.value["name"]
              : bookingInfo.value["guest_name"],
          iv_feedback: fv.feedback.val,
        },
      };

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      if (response.status === 401 || response.status === 422) {
        console.log(response);
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.replace("home");
      }

      if (response.data.status) {
        reqStatus.value = "complete";
        reqFeedback.value = response.data.message.main_msg;
        reqFormStatus.value = false;

        const new_booking_alert = {
          notification_data: response.data.data.notification_data,
          booking_data: false,
          additional_update: response.data.data.additional_update,
        };
        sock.socket.emit("set_notification", new_booking_alert);
      } else {
        reqFeedback.value = response.data.message.main_msg;
        reqStatus.value = "ready";
      }
    };

    const goToLink = (link: any) => {
      window.location.href = link;
    };

    return {
      star,
      arrowBack,
      tk,
      cancelModal,
      confirmModal,
      fv,
      changeBookingRating,
      compRatingLevel,
      trustStatus,
      ratingOptions,
      trustedOptions,
      reqStatus,
      reqFeedback,
      reqFormStatus,
      isWebBigScreen,
      updateBookingRating,
      requestBookingRating,
      goToLink,
    };
  },
});
</script>

<style scoped>
/* ion-title {
    font-weight: normal;
    font-size: 16px;
 } */

.trusted-provider {
  font-weight: normal;
  font-size: 11px;
}

ion-icon {
  color: #7e00e9;
}
</style>
