export default {
  setAlertsList(state: any, payload: any) {
    if (payload.action === "replace") {
      state.alertsList = payload.data;
    }

    if (payload.action === "unshift") {
      state.alertsList.unshift(payload.data);
    }
  },
  setDefaultAlertsList(state: any) {
    state.alertsList = [];
  },
};
