export default {
  executeSetFavInterests(context, payload) {
    context.commit("setFavInterests", payload.data);
    context.commit("setLoadFavState", payload.loadFav);
  },
  executeSetFavBookmarks(context, payload) {
    context.commit("setFavBookmarks", payload.data);
    context.commit("setLoadFavState", payload.loadFav);
  },
  executeSetFavBlocks(context, payload) {
    context.commit("setFavBlocks", payload.data);
    context.commit("setLoadFavState", payload.loadFav);
  },
};
