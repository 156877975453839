<template>
  <div class="main-page best-selling">
    <div class="view_slider recommended pt-2">
      <div class="container rendez-wide">
        <div class="row">
          <div
            :class="isWebBigScreen ? compPageInfo : 'p-0 col-md-6 col-sm-12'"
            v-for="(feed, index) in feedData"
            :key="feed + index"
          >
            <a href="#" v-if="feed.media_name === 'none'">
              <img class="img-fluid" src="../../images/list/v1.png" />
            </a>
            <a href="#" v-else>
              <img class="img-fluid" :src="imgBaseFeedUrl + feed.media_name" />
            </a>
            <div class="inner-slider">
              <div class="inner-wrapper">
                <div class="d-flex align-items-center">
                  <span class="seller-image">
                    <img
                      class="img-fluid"
                      :src="
                        imgBaseAvatarUrl +
                        'tr:di-default_avatar.png/' +
                        feed.avatar
                      "
                      alt=""
                    />
                  </span>
                  <span class="seller-name">
                    <a href="#">{{ feed.name }}</a>
                    <span class="level hint--top level-one-seller">
                      {{ feed.expertise }}
                    </span>
                  </span>
                </div>
                <h3 style="min-height: 80px; max-height: 80px">
                  <!-- {{ feed['post_brief'] }} -->
                  {{ feed["post_brief"].substring(0, 190) }}
                  {{ feed["post_brief"].length <= 190 ? "" : "... more" }}
                </h3>
                <div class="content-info">
                  <div class="rating-wrapper">
                    <!-- <span class="gig-rating text-body-2">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" width="15" height="15">
                                         <path fill="currentColor"
                                            d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
                                         </path>
                                      </svg>
                                      5.0 |
                                      <span>(5)</span>
                                   </span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, inject } from "vue";
import { CapacitorHttp } from "@capacitor/core";
import urls from "@/hooks/urlConfig";
import storeKeeper from "@/hooks/RendezStorage";
import { menu } from "ionicons/icons";

export default defineComponent({
  name: "AccountPosts",
  props: {
    ownerId: {
      type: String,
      required: true,
    },
    postSize: {
      type: String,
      required: true,
    },
    canvasType: {
      type: String,
      default: "page",
    },
  },
  setup(props) {
    //get the urls
    const coreUrl = urls.coreUrl;

    const sk = storeKeeper();

    const imgBaseFeedUrl = coreUrl.imgFeedHost;
    const imgBaseAvatarUrl = ref(coreUrl.imgAvatarHost);

    const isWebBigScreen = ref(inject("isWebBigScreen"));

    const feedData: any = ref([]);

    // Example of a GET request
    const getProfilePosts = async () => {
      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url: coreUrl.backendUrl + "get_profile_posts/" + props.ownerId,
        headers: {
          Authorization: "Bearer " + tokenId,
          "Content-Type": "application/json",
        },
      };

      // const response: HttpResponse = await CapacitorHttp.get(options);

      const response = await CapacitorHttp.request({
        ...options,
        method: "GET",
      });

      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        // await sk.delAllStoreIon()
        //
        // router.push("home");
      }

      if (response.data.status) {
        feedData.value = response.data.data;
      }
    };

    getProfilePosts();

    const compPageInfo = computed(function () {
      return props.canvasType === "page" ? "p-1 col-4" : "p-1 col-3";
    });

    return {
      isWebBigScreen,
      imgBaseFeedUrl,
      imgBaseAvatarUrl,
      feedData,
      menu,
      compPageInfo,
    };
  },
});
</script>

<style scoped></style>
