import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vueStore from "./vue_store";

import { IonicVue, getPlatforms, toastController } from "@ionic/vue";
import { Capacitor } from "@capacitor/core";
// import OneSignalVuePlugin from "@onesignal/onesignal-vue3";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Main components */
import MainTab from "@/components/main/MainTab.vue";
import MainHeader from "@/components/main/MainHeader.vue";
// import MainMobileHeaderMenu from "@/components/main/MainMobileHeaderMenu.vue";
import MainFooter from "@/components/main/MainFooter.vue";

const platformList = getPlatforms();

const isWebMobile = platformList.includes("mobileweb") ? true : false;
const isWebDesktop = platformList.includes("desktop") ? true : false;
const isWebBigScreen = window.screen.width > 550 ? true : false;
const isNativePlatform = Capacitor.isNativePlatform() ? true : false;
const isMobile = platformList.includes("mobile") ? true : false;

const app = createApp(App).use(IonicVue).use(router).use(vueStore);

// feedback toast controller
async function feedbackToast(msg) {
  const toast = await toastController.create({
    message: msg,
    duration: 2500,
    position: "bottom",
  });

  await toast.present();
}

app.provide("isWebMobile", isWebMobile);
app.provide("isWebDesktop", isWebDesktop);
app.provide("isWebBigScreen", isWebBigScreen);
app.provide("isMobile", isMobile);
app.provide("isNativePlatform", isNativePlatform);
app.provide("feedbackToast", feedbackToast);

app.component("main-tab", MainTab);
app.component("main-header", MainHeader);
app.component("main-footer", MainFooter);
// app.component("main-mobile-header-menu", MainMobileHeaderMenu);

router.isReady().then(() => {
  // app.use(OneSignalVuePlugin, { appId: "53762809-39ec-4e19-9cea-724e96a7607f"}).mount("#app");
  app.mount("#app");
});
