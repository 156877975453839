<template>
  <ion-header :translucent="true" mode="ios" class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="cancelModal">
          <ion-icon :icon="closeCircle" color="dark"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title class="text-left pl-5">Create post</ion-title>
      <ion-buttons slot="end" v-if="isServiceProvider">
        <ion-button color="dark" @click="openPostOptions('preferences')">
          <ion-icon :icon="chevronBack"></ion-icon> Prev
        </ion-button>
        <ion-button color="dark" @click="openPostOptions('preferences')">
          Next <ion-icon :icon="chevronForward"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content class="ion-padding" :fullscreen="true" mode="ios">
    <ion-textarea
      v-model.trim="postBrief"
      placeholder="Enter the summary content for your post "
      :maxlength="500"
      :auto-grow="true"
      v-if="isServiceProvider"
    >
    </ion-textarea>
    <div v-else>
      <p>
        You can only post content if you become a service provider. It only
        takes a few seconds.
      </p>
      <router-link
        @click="cancelModal"
        to="/onboard_username"
        class="btn-lrg-standard btn-contact-me js-contact-me js-open-popup-join"
      >
        Become a service provider.
      </router-link>
    </div>

    <div v-if="imageBase" class="">
      <ion-icon
        :icon="closeCircle"
        color="light"
        @click="clearImage"
        size="large"
        class="clear-image cursor-pointer"
      ></ion-icon>
      <ion-img :src="imageBase" style="width: 100%" class="rounded"></ion-img>
    </div>

    <div class="mt-2">
      <ion-chip
        class="p-2 my-2 mr-1"
        v-for="(tag, index) in reqPostOptions['postTags']"
        :key="tag + index"
        color="primary"
      >
        <ion-label>{{ tag }}</ion-label>
        <ion-icon size="small" :icon="close"></ion-icon>
      </ion-chip>
    </div>
  </ion-content>

  <ion-footer class="ion-no-border" v-if="isServiceProvider">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="openPostOptions('preferences')">
          <ion-icon :icon="options"></ion-icon>
        </ion-button>
        <ion-button @click="openImageUpload()">
          <ion-icon :icon="imageOutline"></ion-icon>
        </ion-button>
        <ion-button @click="openPostOptions('tags')">
          <ion-icon :icon="pricetagsOutline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="end">
        <ion-button fill="solid" @click="createNewFeedPost" v-if="reqStatus">
          Post content
        </ion-button>
        <ion-spinner name="circles" class="mb-3 ml-2" v-else></ion-spinner>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>

  <ion-modal :is-open="showImageModal">
    <utility-image-upload
      :ImgHeight="420"
      :ImgWidth="520"
      @emit-update-image-upload="updateImageUpload"
      @emit-close-image-upload="closeImageUpload"
    >
    </utility-image-upload>
  </ion-modal>
</template>

<script lang="ts">
import {
  toastController,
  IonModal,
  IonHeader,
  IonFooter,
  IonToolbar,
  IonTextarea,
  IonSpinner,
  modalController,
  IonButton,
  IonButtons,
  IonIcon,
  IonChip,
  IonImg,
  IonTitle,
  IonContent,
} from "@ionic/vue";
import {
  arrowBack,
  closeCircle,
  close,
  ellipsisVertical,
  megaphoneOutline,
  imageOutline,
  options,
  pricetagsOutline,
  chevronForward,
  chevronBack,
} from "ionicons/icons";
import { defineComponent, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import storeKeeper from "@/hooks/RendezStorage";
import { CapacitorHttp } from "@capacitor/core";
import urls from "@/hooks/urlConfig";
import validators from "@/hooks/validation";
import CreateFeedPostOptions from "@/components/forms/feed/CreateFeedPostOptions.vue";
import UtilityImageUpload from "@/components/utility/UtilityImageUpload.vue";

export default defineComponent({
  name: "CreateFeedPostContent",
  components: {
    IonModal,
    IonHeader,
    IonFooter,
    IonToolbar,
    IonTextarea,
    IonSpinner,
    IonButton,
    IonButtons,
    IonIcon,
    IonChip,
    IonImg,
    IonTitle,
    IonContent,
    UtilityImageUpload,
  },
  setup() {
    const sk = storeKeeper();

    // get the global route object
    const router = useRouter();

    //get the urls
    const coreUrl = urls.coreUrl;

    // get the global vuex store objects
    const vueStore = useStore();

    // get the validators
    const validate = validators();

    const isServiceProvider = computed(function () {
      const isProvider = vueStore.getters["auth/getSessionData"][
        "serviceProvider"
      ]
        ? true
        : false;
      return isProvider;
    });

    const imageBase: any = ref(false);

    function clearImage() {
      imageBase.value = false;
    }

    function updateImageUpload(imageSource: string) {
      imageBase.value = imageSource;
      showImageModal.value = !showImageModal.value;
    }

    async function deletePostOptionData() {
      await sk.delStoreIon("post_option_data");
    }

    deletePostOptionData();

    const postOptionList = {
      preferences: {
        title: "Post preferences",
        action: "preferences",
      },
      tags: {
        title: "Add tags",
        action: "tags",
      },
    };

    const new_date = new Date();

    const reqPostOptions: any = ref({
      postType: "now",
      postSchedule: new_date.toISOString(),
      postAudience: "all",
      postMature: "no",
      postTags: [],
    });

    async function openPostOptions(currOption: string) {
      const modal = await modalController.create({
        component: CreateFeedPostOptions,
        breakpoints: [0, 0.8, 1],
        initialBreakpoint: 0.8,
        handle: true,
        componentProps: {
          currentProviderServices: currentProviderServices.value,
          reqPostOptions: postOptionList[currOption],
        },
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();
      //
      if (role === "confirm") {
        reqPostOptions.value = data;
      }
    }

    const showImageModal = ref(false);

    // Image upload modal
    async function openImageUpload() {
      showImageModal.value = !showImageModal.value;
    }

    // Image upload modal
    async function closeImageUpload() {
      showImageModal.value = !showImageModal.value;
    }

    const name = ref("");

    function cancelModal() {
      return modalController.dismiss(null, "cancel");
    }

    function confirmModal() {
      return modalController.dismiss(name.value, "confirm");
    }

    // get session data
    const compSessionData = computed(function () {
      const sessionData = vueStore.getters["auth/getSessionData"];
      return sessionData;
    });

    const currentProviderServices: any = ref([]);

    const getAccountProviderServices = async () => {
      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url:
          coreUrl.backendUrl +
          "get_account_provider_services?provider_id=" +
          compSessionData.value["ownerId"],
        headers: { Authorization: "Bearer " + tokenId },
      };

      // const response: HttpResponse = await CapacitorHttp.get(options);

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "GET",
      });

      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.push("home");
      }

      if (response.data.status) {
        const services_data = response.data.data.account_services
          ? response.data.data.account_services
          : [];
        currentProviderServices.value = services_data;
      }
    };

    getAccountProviderServices()
      .then()
      .catch((err) => {
        const response = err
          ? "Temporary server issue"
          : "Temporary server issue";
        feedbackToast(response);
      });

    const reqFeedback = ref("");
    const reqStatus = ref(true);
    const reqFormStatus = ref(true);

    const postBrief = ref("");

    async function feedbackToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 2500,
        position: "bottom",
      });

      await toast.present();
    }

    // Example of a POST request. Note: data
    // can be passed as a raw JS Object (must be JSON serializable)
    const createNewFeedPost = async () => {
      reqStatus.value = false;

      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url: coreUrl.backendUrl + "create_new_feed_content",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenId,
        },
        data: {
          iv_post_brief: postBrief.value,
          iv_post_active: reqPostOptions.value["postType"],
          iv_post_date: reqPostOptions.value["postSchedule"],
          iv_access: reqPostOptions.value["postAudience"],
          iv_mature_content: reqPostOptions.value["postMature"],
          iv_tags: reqPostOptions.value["postTags"],
          iv_media_type: "image",
          iv_media_base: imageBase.value ? imageBase.value : "none",
        },
      };

      // const response: HttpResponse = await CapacitorHttp.post(options);

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      if (response.data.status) {
        reqFeedback.value = response.data.message.main_msg;

        feedbackToast(reqFeedback.value);

        reqStatus.value = true;

        const newPostData = response.data.data;
        const isNewPost = newPostData["post_brief"] ? true : false;

        if (isNewPost) {
          console.log(newPostData);

          const payload = { data: newPostData, action: "unshift" };

          vueStore.dispatch("feed/executeSetFeedData", payload);
        }

        return modalController.dismiss(null, "cancel");
      } else if (response.status === 401) {
        await sk.delAllStoreIon();
        // await sk.delVueX()

        window.location.href = "/home";
      } else {
        // const iv_msg: any = response.data.message.iv_msg;
        reqFeedback.value = response.data.message.main_msg;

        feedbackToast(reqFeedback.value);

        reqStatus.value = true;
      }
    };

    return {
      arrowBack,
      closeCircle,
      close,
      ellipsisVertical,
      megaphoneOutline,
      imageOutline,
      options,
      chevronForward,
      chevronBack,
      pricetagsOutline,
      isServiceProvider,
      reqFeedback,
      postBrief,
      reqPostOptions,
      createNewFeedPost,
      validate,
      reqStatus,
      reqFormStatus,
      openPostOptions,
      openImageUpload,
      closeImageUpload,
      showImageModal,
      cancelModal,
      confirmModal,
      updateImageUpload,
      imageBase,
      clearImage,
    };
  },
});
</script>

<style scoped>
ion-img::part(image) {
  border-radius: 6px;
}

ion-list {
  --background: #fff;
}

ion-item {
  --background: #fff;
  --color: #111;
  --padding: 0px;

  /* --border-style: dashed; */
  /* --border-width: 2px; */

  /* --border-radius: 20px;

   --ripple-color: purple;

   --detail-icon-color: white;
   --detail-icon-opacity: 1;
   --detail-icon-font-size: 20px; */
}

.clear-image {
  float: right;
  margin-bottom: -33px;
  position: relative;
  z-index: 9999;
}
</style>
