export default {
  setAvataSrc(state: any, payload: any) {
    state.avatarSrc = payload;
  },
  setLoadAccountState(state: any, payload: any) {
    state.loadAccountState = payload;
  },
  setAccountData(state: any, payload: any) {
    state.accountData = payload;
  },
  setAccountServices(state: any, payload: any) {
    if (Array.isArray(payload)) {
      state.accountServices = payload;
    } else {
      state.accountServices.push(payload);
    }
  },
  setDefaultAccountData(state: any) {
    state.accountData = state.defaultAccountData;
    state.accountServices = state.defaultAccountServices;
  },
  setAccountRatingSummary(state: any, payload: any) {
    state.accountRatingSummary = payload;
  },
};
