<template>
    <ion-page>


            <ion-content>


               <!-- get started -->
                  <div class="get-started text-center" >
                     <div class="content " >
                        <img src="../images/main/rendez_logo.png" width="200" class="pb-3" />
                        <h4>Designed for work, built for fun</h4>
                        <img src="../images/main/home_bg.svg" class="mt-5 pt-5" />
                        <p class="pt-3">
                            Rendez gives you access to amazing service providers,
                            coupled with scheduling capabilities to maximise your time and productivity.
                        </p>
                     </div>
                  </div>
               <!-- get started -->

            </ion-content>

            <ion-footer class="ion-no-border p-2">
                <ion-button class=" mt-2" expand="block" color="primary" @click="goToLink('feed')" fill="outline">Explore service providers</ion-button>
                <ion-button class=" mt-2" expand="block" color="primary" @click="goToLink('sign_up')" >Join the rendez community</ion-button>
            </ion-footer>

</ion-page>


</template>

<script lang="ts">
import {  IonPage, IonContent, IonButton, IonFooter } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonFooter
  },
  setup(){


    const goToLink = (link: any) => {

      window.location.href=link
    }
    return {
      goToLink
    }
  }


});
</script>

<style scoped>

.get-started {
  /* background: url(../images/avatar-bg.jpg); */
  background: #f5f5f5;
    /* background-size: cover;
    text-align: center;
    padding: 60px 0; */
  /* Full height */
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

ion-footer{
    background: #f5f5f5;
}

</style>
