<template>
    <ion-page>
                <ion-content>


                       <div class="container text-center pt-5 mt-5"  >
                           <div class="container text-center mt-5" >
                               <div class="row ">
                                   <div :class="isMobile?'col-3':'col-4' " ></div>
                                    <div :class="isMobile?'col-6':'col-4' " >
                                          <img class="img-fluid" src="../images/main/error_page.svg"  alt='' />
                                   </div>
                                   <div :class="isMobile?'col-3':'col-4' " ></div>
                               </div>

                               <div class="text-center ">
                                  <h5 class="font-weight-bold mt-3">This page does exist. </h5>
                                  <router-link to="/feed"> Back to site </router-link>
                              </div>

                          </div>
                      </div>

               </ion-content>

      </ion-page>




</template>

<script lang="ts">
import {
    IonPage,
    IonContent
 } from '@ionic/vue';
import { defineComponent,ref,inject } from 'vue';

export default defineComponent({
  name: 'NotFoundPage',
  components: {
      IonPage,
      IonContent
  },
  setup(){

      const isMobile = ref(inject("isMobile"))


      return {
          isMobile,
      }
  }


});
</script>

<style scoped>

.homepage-search-block{
    background: #f5f5f5;
}

</style>
