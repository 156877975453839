<template>
  <div class="">
    <div class="rounded bg-white mb-1">
      <div
        class="review-section border-bottom p-3 d-flex align-items-center justify-content-between mb-0"
        :class="isWebBigScreen ? 'mt-4 ' : 'mt-3'"
      >
        <h6 class="m-0">
          Average rating:
          <small>
            <!-- <span class="star-rating-s15"></span> -->
            <span>
              <span class="total-rating" data-impression-collected="true">{{
                ratingSummary.rating ? ratingSummary.rating : "n/a"
              }}</span></span
            ><span
              ><div class="" data-impression-collected="true">
                {{
                  !ratingSummary.rating
                    ? " no ratings yet"
                    : ` from ${
                        ratingSummary.reviews > 1
                          ? ratingSummary.reviews + " reviews"
                          : ratingSummary.reviews + " review"
                      } `
                }}
              </div>
            </span>
          </small>
        </h6>
        <select
          class="custom-select custom-select-sm border-0 shadow-sm ml-2"
          v-model="activeRatingsGroup"
        >
          <option value="0">All ratings</option>
          <option
            v-for="group in ratingsGrouping"
            :key="group.alias"
            :value="group.value"
          >
            {{ group.alias }}
            ....................{{ group.value === 1 ? ".." : "" }} ({{
              group.occurence
            }})
          </option>
        </select>
      </div>
      <div class="p-3">
        <div class="review-list">
          <ul v-if="compRatingsData.length > 0">
            <li v-for="(rate, index) in compRatingsData" :key="rate + index">
              <div class="d-flex">
                <div class="left">
                  <span>
                    <img
                      :src="
                        imgBaseAvatarUrl +
                        'tr:di-default_avatar.png/' +
                        rate.avatar
                      "
                      class="profile-pict-img img-fluid"
                      alt=""
                    />
                  </span>
                </div>
                <div class="right">
                  <h4>
                    {{ rate.rating }}
                    <!-- content -->
                    <span class="gig-rating text-body-2">
                      <i
                        class="fa fa-star pl-1 primary-color"
                        v-for="star in parseInt(rate.rating)"
                        :key="star"
                      >
                      </i>
                    </span>
                  </h4>
                  <div class="country d-flex align-items-center">
                    <span>
                      <div class="country-name font-accent">
                        by {{ rate.name }}
                      </div>
                    </span>
                  </div>
                  <div class="review-description">
                    <p>
                      {{ rate.feedback }}
                    </p>
                  </div>
                  <span class="publish py-3 d-inline-block w-100">
                    Published
                    {{ tk.fromNow(rate.created_date) }} ago
                  </span>
                </div>
              </div>
            </li>
          </ul>
          <div v-else>
            {{ compRatingsError }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed, inject } from "vue";
import { useRouter } from "vue-router";
import storeKeeper from "@/hooks/RendezStorage";
import timeKeeper from "@/hooks/Timer";
import { CapacitorHttp } from "@capacitor/core";
import urls from "@/hooks/urlConfig";

export default defineComponent({
  name: "AccountRatings",
  props: {
    ownerId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const sk = storeKeeper();

    const tk = timeKeeper();

    //get the urls
    const coreUrl = urls.coreUrl;

    const imgBaseAvatarUrl = ref(coreUrl.imgAvatarHost);

    const isWebBigScreen = ref(inject("isWebBigScreen"));

    // get the global route object
    const router = useRouter();

    const ratingData: any = ref([]);
    const ratingSummary: any = ref({ reviews: false, rating: false });
    const ratingSet: any = ref(false);

    // Example of a GET request
    const getRatingsData = async () => {
      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url: coreUrl.backendUrl + `get_ratings_info/${props.ownerId}`,
        headers: { Authorization: "Bearer " + tokenId },
      };

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "GET",
      });

      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.push("home");
      }

      if (response.data.status) {
        ratingData.value = response.data.data.ratings_data;
        ratingSummary.value = response.data.data.rating_summary;
        ratingSet.value = true;
      }
    };

    getRatingsData();

    const activeRatingsGroup: any = ref(0);

    const ratingsGrouping: any = ref([
      { value: 5, occurence: 0, alias: "5 stars" },
      { value: 4, occurence: 0, alias: "4 stars" },
      { value: 3, occurence: 0, alias: "3 stars" },
      { value: 2, occurence: 0, alias: "2 stars" },
      { value: 1, occurence: 0, alias: "1 star" },
    ]);

    const compRatingsData = computed(function () {
      let currentRatingsData = ratingData.value;
      const currentRatingsGroup = parseInt(activeRatingsGroup.value);

      if (ratingSet.value) {
        if (currentRatingsGroup > 0) {
          currentRatingsData = ratingData.value.filter(
            (item) => parseInt(item.rating) === currentRatingsGroup
          );
        }
      }

      return currentRatingsData;
    });

    const compRatingsError = computed(function () {
      const currentRatingsGroup = parseInt(activeRatingsGroup.value);
      let ratingsMessage = "";

      if (currentRatingsGroup > 0) {
        ratingsMessage =
          currentRatingsGroup > 1
            ? `You currently have no ${currentRatingsGroup} stars ratings`
            : "You currently have no 1 star ratings";
      } else {
        ratingsMessage = " You currently have no ratings from any clients. ";
      }

      return ratingsMessage;
    });

    watch(
      () => ratingData.value,
      (currentValue, _oldValue) => {
        currentValue.forEach(function (item, _) {
          ratingsGrouping.value[5 - item.rating]["occurence"] += 1;
        });
      }
    );

    return {
      isWebBigScreen,
      compRatingsData,
      compRatingsError,
      ratingSummary,
      activeRatingsGroup,
      ratingsGrouping,
      tk,
      imgBaseAvatarUrl,
    };
  },
});
</script>

<style scoped>
.total-rating {
  font-size: 16px;
  font-weight: bold;
}
</style>
