<template>
  <ion-page>
    <ion-header :translucent="true" mode="ios" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="cancelModal">
            <ion-icon :icon="arrowBack"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title> {{ postType.replace("service_", "") }} service </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="cancelModal">Done</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <!-- <main-header pageTitle="Feed"></main-header> -->

    <ion-content>
      <create-account-update-services
        :postType="postType"
        :serviceId="serviceId"
        v-if="postType === 'service_create' || postType === 'service_update'"
      >
      </create-account-update-services>

      <create-account-archive-delete-services
        :postType="postType"
        :serviceId="serviceId"
        v-if="
          postType === 'service_restore' ||
          postType === 'service_archive' ||
          postType === 'service_delete'
        "
      >
      </create-account-archive-delete-services>
    </ion-content>

    <!-- <main-tab></main-tab> -->
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonContent,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonHeader,
  IonButton,
  IonButtons,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";

import { arrowBack } from "ionicons/icons";

import CreateAccountUpdateServices from "@/components/forms/account/CreateAccountUpdateServices.vue";
import CreateAccountArchiveDeleteServices from "@/components/forms/account/CreateAccountArchiveDeleteServices.vue";

export default defineComponent({
  name: "AccountUpdateProfileInfo",
  components: {
    IonPage,
    IonContent,
    IonTitle,
    IonIcon,
    IonToolbar,
    IonHeader,
    IonButton,
    IonButtons,
    CreateAccountUpdateServices,
    CreateAccountArchiveDeleteServices,
  },
  props: {
    postType: {
      required: true,
      default: "create",
    },
    serviceId: {
      required: true,
      default: 0,
    },
  },
  setup() {
    function cancelModal() {
      return modalController.dismiss(null, "cancel");
    }

    return {
      cancelModal,
      arrowBack,
    };
  },
});
</script>

<style scoped>
.homepage-search-block {
  background: #f5f5f5;
}
</style>
