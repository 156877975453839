/*
Category : Utility hooks
Description : Contains the list of various validation functions to be used site
              wide for validating input
*/
export default function validators(){

    // check for required data
    function isRequired(str: string){

        // check if value is empty
        if(String(str).trim() === ''){
            return false;
        }

        return true;
    }
    // end check for required data

    // check for required data array
    function isRequiredArray(arr: any){

        // check if array provided is empty
        if(arr.length < 1){
            return false;
        }

        return true;
    }
    // end check for required data array

    // check for alphabets and numbers only
    function isAlpha(str: string) {
      let code: any, i: any, len: any;

      for (i = 0, len = str.length; i < len; i++) {
        code = str.charCodeAt(i);
        if (!(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123)) { // lower alpha (a-z)
          return false;
        }
      }
      return true;
    }

    // check for alphabets and numbers only
    function isAlphaNumeric(str: string) {
      let code: any, i: any, len: any;

      for (i = 0, len = str.length; i < len; i++) {
        code = str.charCodeAt(i);
        if (!(code > 47 && code < 58) && // numeric (0-9)
            !(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123)) { // lower alpha (a-z)
          return false;
        }
      }
      return true;
    }

    // end check for alphabets and numbers only

    // check for safe inputs :alphabets, numbers, dashes and underscores
    function isSafeInput(str: string){

        if(/[^a-zA-Z0-9_-]/.test(str)){
            return false;
        }

        return true;
    }
    // end check for safe inputs :alphabets, numbers, dashes and underscores


    // check for safe inputs :alphabets, numbers, dashes, underscores and spaces
    function isSafeSpaceInput(str: string){

        if(/[^a-zA-Z0-9_-\s]/.test(str)){
            return false;
        }

        return true;
    }
    // end check for safe inputs :alphabets, numbers, dashes, underscores and spaces


    // check for safe inputs :alphabets, numbers, dashes, underscores and spaces
    function isSafeSpaceComma(str: string){

        if(/[^a-zA-Z0-9_-\s,]/.test(str)){
            return false;
        }

        return true;
    }
    // end check for safe inputs :alphabets, numbers, dashes, underscores and spaces



    // check for valid numbers
    function isNumeric(num: any) {
      let code: any, i: any, len: any;

      for (i = 0, len = num.length; i < len; i++) {
        code = num.charCodeAt(i);
        if (!(code > 47 && code < 58)) // numeric (0-9)
        {
          return false;
        }
      }
      return true;
    }
    // end check for valid numbers


    // check for valid email
    function isEmail(email: string)
    {
        const re = /^\S+@\S+\.\S+$/;
        return re.test(email);
    }
    // end check for valid email

    // check for valid email
    function isWebsite(website: string)
    {
        website=website.replace("https://","")
        website=website.replace("http://","")
        // check if value is empty
        const re: any = /^\S+\.\S+\.\S+$/;
        const sub_re: any = /^\S+\.\S+$/;
        return String(website).trim() === ''?true:re.test(website)?true:sub_re.test(website);
    }
    // end check for valid email


    // check for string length between minimum and maximum value
    function isLengthRange(str: string, min: number, max: number)
    {
        if(str.length < min || str.length > max){
            return false
        }else{
            return true
        }
    }
    // end check for string length between minimum and maximum value


    // check for minimum string length
    function isTextMin(str: string, min: number)
    {

        if(str.length < min){
            return false
        }else{
            return true
        }
    }
    // end check for minimum string length


    // check for maximum string length
    function isTextMax(str: string, max: number)
    {
        if(str.length > max){
            return false
        }else{
            return true
        }
    }
    // end check for maximum string length


    // check to see if valid object
    function isObject(obj: any)
    {
        return obj != null && obj.constructor.name === "Object"
    }
    // end check to see if valid object



    // collate all the validation options in an object, with corressponding error messages
    // each validation option has a validator option to be called for the actual validation
    // and an error message incase validation fails
    const validationOptions: any = {
        required:{validator:isRequired,errorMsg:"This Field cannot be empty"},
        requiredArray:{validator:isRequiredArray,errorMsg:"At least one data is required"},
        alpha:{validator:isAlpha,errorMsg:"This Field can only contain alphabets"},
        alphaNumeric:{validator:isAlphaNumeric,errorMsg:"This Field can only contain alphabets & numbers"},
        numeric:{validator:isNumeric,errorMsg:"This Field can only contain numbers"},
        safeInput:{validator:isSafeInput,errorMsg:"This Field can only contain alphabets, numbers, underscore or dashes"},
        safeSpaceInput:{validator:isSafeSpaceInput,errorMsg:"This Field can only contain alphabets, numbers, underscore, dashes or spaces"},
        safeSpaceComma:{validator:isSafeSpaceComma,errorMsg:"This Field can only contain alphabets, numbers, underscore, dashes,commas or spaces"},
        email:{validator:isEmail,errorMsg:"This Field can only contain valid emails. e.g name@domain.com"},
        website:{validator:isWebsite,errorMsg:"This Field can only contain valid websites. e.g www.domain.com or domain.com"},
        textMin:{validator:isTextMin,errorMsg:"The minimum length required for this field is "},
        textMax:{validator:isTextMax,errorMsg:"The maximum length required for this field is "},
        lengthRange:{validator:isLengthRange,errorMsg:"The text length required for must be between "}
    }

    // end collation of all the validation options

    /* The validateFormData function that will be used for each validation, it takes 3 args
     * The form value (fv) : carrying all the meta data for the form being evaluated
     * The field : to identify the specific form field being validated
     * checkSubmit :  to know if we will be checking the submit button, default is false
    */
    function validateFormData(fv: any,field: any,checkSubmit: any = true){

        const value = fv[field]["val"]; // get the actual value
        const validators = fv[field]["validate"]; // get the validator to be tested
        const reqFeedback = runValidation(value, validators); // call the run validation
        fv[field]["status"] = reqFeedback.status; // update the status of the form being tested
        fv[field]["msg"] = reqFeedback.error; // update the message of the form being tested

        // validate to see if we will show the submit button and reset feedback meta data
        if(checkSubmit){

            // call the checkInputValidity to see if all the form value was properly validated
            checkInputValidity(fv)

        }

    }

    /* The actual function to run each validation, it takes two values
     * value: the actual value to be tested
     * validateRequest : the validator to be tested
    */
    function runValidation(value: any, validateRequest: any){

        const result: any = []; // empty array to hold all the test results
        const error: any = []; // empty array to hold all the error feedback
        const reqFeedback: any = {status:false, error:false}; // initiate reqFeedback to hold the feedback


        // loop through all the validators and test the value provided
        for(let i = 0; i < validateRequest.length; i++){


            let newValidateRequest: any = false; // variable for current validator being tested
            let newValidateOptionVal: any = false;  // variable for current validator limit (where applicable)

            // check if the current validator is a string or object

            if(isObject(validateRequest[i])){

                // if object , get the object, and fetch the validator and limit from the object

                const newValidateObj: any = validateRequest[i]; // fetch object
                newValidateRequest = newValidateObj["option"]; // fetch validator from object
                newValidateOptionVal = newValidateObj["val"]; // fetch limit from object

            }else{

                // if string, fetch the string and use it as a validator right away

                newValidateRequest = validateRequest[i]; // fetch validator stright from string
                newValidateOptionVal = false; // limit is not required
            }


            // get current validator from list of validationOptions
            if(validationOptions[newValidateRequest]){

                // check to see if validator requires a limit, if yes, call validator and pass value and limit
                // else call validator, and pass just the value , the result of the test is passed to newResult

                const newResult: any = newValidateOptionVal?
                                validationOptions[newValidateRequest]["validator"](value,newValidateOptionVal):
                                validationOptions[newValidateRequest]["validator"](value);

                // fetch the error message for the current validator , and create a final error string
                // to be determined by the prescence or abscence of a limit
                const newError: any = validationOptions[newValidateRequest]["errorMsg"];
                const finalError: any = `${newError} ${newValidateOptionVal?newValidateOptionVal:""}`;

                // pass the newResult feedback into the existing result array
                result.push(newResult);

                // check if newResult passed, if yes skip this step,
                // else add the error message to the error array
                if(!newResult){
                    error.push(finalError);
                }


            }
        }

        // check to see if all results are true or not, and save in final result
        const finalResult: any = result.every(Boolean);

        // save final result in feedback status
        reqFeedback["status"] = finalResult;

        // save first error in feedback error if true and false if nothing
        reqFeedback["error"] = finalResult?false:error[0];

        // return feedback
        return reqFeedback;
    }


    // check all the fv values excluding feedback and submit,
    // then show or hide submit button depending on outcome
    //  Also reset all feedback meta data
    function checkInputValidity(fv: any){

        // create empty validate result array
        const validateResult: any = [];

        // loop through all the form value status, to see if all validation passed
        for(const key in fv){
            if(key == "reqFeedback" || key == "showSubmit"){
                continue;
            }else{
                validateResult.push(fv[key]["status"] )
            }
        }

        // show or hide submit button
        fv["showSubmit"]["status"] = validateResult.every(Boolean);


        // reset feedback meta data
        // fv.reqFeedback.status = false;
        // fv.reqFeedback.msg = false;
        // fv.reqFeedback.val = "";
    }

    // return just the validateFormData function
    return {
        isRequired,
        isAlpha,
        isAlphaNumeric,
        isSafeSpaceInput,
        isSafeSpaceComma,
        checkInputValidity,
        validateFormData
    }

}
