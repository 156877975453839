<template>
    <ion-page>
            <ion-header :translucent="true" mode="ios" class="ion-no-border" v-if="actionType === 'settings' " >
                  <ion-toolbar>
                    <ion-buttons slot="start">
                      <ion-button @click="cancelModal">
                        <ion-icon :icon="arrowBack" ></ion-icon>
                      </ion-button>
                    </ion-buttons>
                    <ion-title> Update profile info</ion-title>
                    <ion-buttons slot="end" >
                      <ion-button @click="cancelModal">Done</ion-button>
                    </ion-buttons>
                  </ion-toolbar>
            </ion-header>

        <!-- <main-header pageTitle="Feed"></main-header> -->

            <ion-content>
                <create-account-update-profile-info :actionType="actionType">
                </create-account-update-profile-info>
            </ion-content>

        <!-- <main-tab></main-tab> -->
     </ion-page>
</template>

<script lang="ts">

import {  IonPage,IonContent,IonTitle,IonIcon,IonToolbar,IonHeader,IonButton,IonButtons,modalController } from '@ionic/vue';
import { defineComponent } from 'vue';

import { arrowBack  } from 'ionicons/icons';

import CreateAccountUpdateProfileInfo from '@/components/forms/account/CreateAccountUpdateProfileInfo.vue'


export default defineComponent({
  name: 'AccountUpdateProfileInfo',
  components: {
    IonPage,
    IonContent,
    IonTitle,
    IonIcon,
    IonToolbar,
    IonHeader,
    IonButton,
    IonButtons,
    CreateAccountUpdateProfileInfo
   },
   props:{
       actionType:{
           required:true,
           default:"settings"
       }
   },
   setup(){
       function cancelModal() {
         return modalController.dismiss(null, 'cancel');
       }

       return {
           cancelModal,
           arrowBack
       }
   }


});
</script>

<style scoped>


.homepage-search-block{
    background: #f5f5f5;
}

</style>
