<template>
  <ion-page>
    <ion-header
      :translucent="true"
      mode="ios"
      class="ion-no-border"
      v-if="isSubMenu"
    >
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="toggleSubMenu">
            <ion-icon :icon="arrowBack"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title class="text-left pl-5">{{ activeSubMenu }} menu</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list v-if="!isSubMenu">
        <ion-item
          button
          :detail="true"
          lines="full"
          @click="redirectLink('/bookings')"
        >
          <span
            class="material-symbols-rounded link-icon pointer-cursor text-secondary"
          >
            page_info
          </span>
          <ion-label class="pl-3">
            <h3>Pending requests</h3>
          </ion-label>
        </ion-item>

        <ion-item
          button
          :detail="true"
          @click="redirectLink('/appointment_settings')"
          lines="full"
        >
          <span
            class="material-symbols-rounded link-icon pointer-cursor text-secondary"
          >
            settings_account_box
          </span>
          <ion-label class="pl-3">
            <h3>Appointment settings</h3>
          </ion-label>
        </ion-item>

        <ion-item
          button
          :detail="true"
          lines="full"
          @click="redirectLink('/my_services')"
        >
          <span
            class="material-symbols-rounded link-icon pointer-cursor text-secondary"
          >
            inventory
          </span>
          <ion-label class="pl-3">
            <h3>My services</h3>
          </ion-label>
        </ion-item>

        <ion-item
          button
          :detail="true"
          lines="full"
          @click="redirectLink('/my_ratings')"
        >
          <span
            class="material-symbols-rounded link-icon pointer-cursor text-secondary"
          >
            ballot
          </span>
          <ion-label class="pl-3">
            <h3>My ratings</h3>
          </ion-label>
        </ion-item>

        <ion-item
          button
          :detail="true"
          lines="full"
          @click="redirectLink('/my_posts')"
        >
          <span
            class="material-symbols-rounded link-icon pointer-cursor text-secondary"
          >
            perm_media
          </span>
          <ion-label class="pl-3">
            <h3>My posts</h3>
          </ion-label>
        </ion-item>

        <ion-item
          button
          :detail="true"
          lines="full"
          @click="redirectLink('/my_circle')"
        >
          <span
            class="material-symbols-rounded link-icon pointer-cursor text-secondary"
          >
            user_attributes
          </span>
          <ion-label class="pl-3">
            <h3>My circle</h3>
          </ion-label>
        </ion-item>

        <ion-item
          button
          :detail="true"
          lines="full"
          @click="redirectLink('/my_trusted_brands')"
        >
          <span
            class="material-symbols-rounded link-icon pointer-cursor text-secondary"
          >
            diversity_1
          </span>
          <ion-label class="pl-3">
            <h3>My trusted brands</h3>
          </ion-label>
        </ion-item>

        <ion-item
          button
          :detail="true"
          @click="redirectLink('/my_bookmarks')"
          lines="full"
        >
          <span
            class="material-symbols-rounded link-icon pointer-cursor text-secondary"
          >
            bookmarks
          </span>
          <ion-label class="pl-3">
            <h3>My bookmarks</h3>
          </ion-label>
        </ion-item>

        <ion-item
          button
          :detail="true"
          @click="redirectLink('/my_settings')"
          lines="full"
        >
          <span
            class="material-symbols-rounded link-icon pointer-cursor text-secondary"
          >
            tune
          </span>
          <ion-label class="pl-3">
            <h3>All settings</h3>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonIcon,
  IonItem,
  IonList,
  IonLabel,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonPage,
  IonContent,
  IonTitle,
  modalController,
} from "@ionic/vue";
import {
  menu,
  arrowBack,
  cartOutline,
  basketOutline,
  cogOutline,
  settingsOutline,
  optionsOutline,
  heartOutline,
  thumbsUp,
  bookmarkOutline,
  bookOutline,
} from "ionicons/icons";
import { defineComponent, ref } from "vue";

export default defineComponent({
  components: {
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonPage,
    IonContent,
    IonTitle,
  },
  setup() {
    const isSubMenu = ref(false);
    const activeSubMenu = ref("");

    function toggleSubMenu() {
      isSubMenu.value = !isSubMenu.value;
    }

    function showSubMenu(menu) {
      activeSubMenu.value = menu;
      isSubMenu.value = true;
    }

    const navRequestOptions = [
      {
        reviewType: "pending_requests",
        filterStatus: "pending",
        alias: "Pending requests",
      },
      {
        reviewType: "accepted_requests",
        filterStatus: "accepted",
        alias: "Accepted requests",
      },
      {
        reviewType: "completed_requests",
        filterStatus: "completed",
        alias: "Completed requests",
      },
      {
        reviewType: "overdue_requests",
        filterStatus: "overdue",
        alias: "Overdue requests",
      },
      {
        reviewType: "rejected_requests",
        filterStatus: "rejected",
        alias: "Rejected requests",
      },
      {
        reviewType: "cancelled_requests",
        filterStatus: "cancelled",
        alias: "Cancelled requests",
      },
    ];

    const navOrderOptions = [
      {
        reviewType: "pending_orders",
        filterStatus: "pending",
        alias: "Pending orders",
      },
      {
        reviewType: "accepted_orders",
        filterStatus: "accepted",
        alias: "Accepted orders",
      },
      {
        reviewType: "completed_orders",
        filterStatus: "completed",
        alias: "Completed orders",
      },
      {
        reviewType: "overdue_orders",
        filterStatus: "overdue",
        alias: "Overdue orders",
      },
      {
        reviewType: "rejected_orders",
        filterStatus: "rejected",
        alias: "Rejected orders",
      },
      {
        reviewType: "cancelled_orders",
        filterStatus: "cancelled",
        alias: "Cancelled orders",
      },
    ];

    function redirectLink(link: string) {
      return modalController.dismiss(link, "confirm");
    }

    return {
      menu,
      arrowBack,
      cartOutline,
      basketOutline,
      cogOutline,
      settingsOutline,
      optionsOutline,
      heartOutline,
      thumbsUp,
      bookmarkOutline,
      bookOutline,
      isSubMenu,
      activeSubMenu,
      toggleSubMenu,
      showSubMenu,
      navRequestOptions,
      navOrderOptions,
      redirectLink,
    };
  },
});
</script>

<style scoped>
ion-icon {
  color: #000;
}

.link-icon {
  font-size: 16px;
}
</style>
