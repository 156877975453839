export default {
  setFavInterests(state: any, payload: any) {
    if (payload.storage === "replace") {
      state.favInterests = payload.info;
    }

    if (payload.storage === "append") {
      state.favInterests.push(payload.info);
    }
  },
  setFavBookmarks(state: any, payload: any) {
    if (payload.storage === "replace") {
      state.favBookmarks = payload.info;
    }

    if (payload.storage === "append") {
      state.favBookmarks.push(payload.info);
    }
  },
  setFavBlocks(state: any, payload: any) {
    if (payload.storage === "replace") {
      state.favBlocks = payload.info;
    }

    if (payload.storage === "append") {
      state.favBlocks.push(payload.info);
    }
  },
  setLoadFavState(state: any, payload: any) {
    state.loadFavState = payload;
  },
};
