<template>
  <ion-page>
    <ion-header :translucent="true" mode="ios" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="cancelModal">
            <ion-icon :icon="arrowBack"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title> Update your policy & perks list </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="cancelModal">Done</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <!-- <main-header pageTitle="Feed"></main-header> -->

    <ion-content>
      <create-account-update-policy-perks
        :clearPolicyPerksList="clearPolicyPerksList"
        :samplePolicyPerksList="samplePolicyPerksList"
        @emit-toggle-footer-visibily="toggleFooterVisibily"
      >
      </create-account-update-policy-perks>
    </ion-content>

    <ion-footer v-if="showFooter">
      <ion-toolbar>
        <ion-button
          slot="start"
          fill="clear"
          @click="activateSamplePolicyPerksList"
        >
          <span class="material-symbols-rounded sidebar-icon-large"
            >model_training</span
          >
          Generate sample</ion-button
        >
        <ion-button slot="end" fill="clear" @click="updateClearPolicyPerksList">
          Delete all
          <span class="material-symbols-rounded sidebar-icon-large"
            >delete_forever</span
          >
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonContent,
  IonTitle,
  IonIcon,
  IonToolbar,
  IonHeader,
  IonFooter,
  IonButton,
  IonButtons,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";

import { arrowBack } from "ionicons/icons";

import CreateAccountUpdatePolicyPerks from "@/components/forms/account/CreateAccountUpdatePolicyPerks.vue";

export default defineComponent({
  name: "AccountUpdateProfileInfo",
  components: {
    IonPage,
    IonContent,
    IonTitle,
    IonIcon,
    IonToolbar,
    IonHeader,
    IonFooter,
    IonButton,
    IonButtons,
    CreateAccountUpdatePolicyPerks,
  },
  setup() {
    function cancelModal() {
      return modalController.dismiss(null, "cancel");
    }

    const savedPolicyPerksList = [
      "1 free 1 30mins consultations for every 5 appointments",
      "Payment must be made before booking is accepted",
      "5% bonus for referrals that lead to successful appointments",
      "Loyalty bonus: 5% discount on services after 10 successful appointments",
      "Timely response to client inquiries and concerns",
      "Maintain confidentiality of client information and appointments",
      "Non-disclosure agreements (NDAs) available upon request",
      "Negotiable payment terms and flexible payment plans",
      "Re-occuring bookings are accepted",
    ];

    const clearPolicyPerksList = ref(false);
    const samplePolicyPerksList: any = ref([]);

    function updateClearPolicyPerksList() {
      clearPolicyPerksList.value = true;
      samplePolicyPerksList.value = [];
      clearPolicyPerksList.value = false;
    }

    function activateSamplePolicyPerksList() {
      samplePolicyPerksList.value = savedPolicyPerksList;
    }

    const showFooter = ref(true);

    const toggleFooterVisibily = () => {
      showFooter.value = !showFooter.value;
    };

    return {
      cancelModal,
      arrowBack,
      clearPolicyPerksList,
      samplePolicyPerksList,
      showFooter,
      toggleFooterVisibily,
      updateClearPolicyPerksList,
      activateSamplePolicyPerksList,
    };
  },
});
</script>

<style scoped>
.homepage-search-block {
  background: #f5f5f5;
}
</style>
