<template>
  <ion-header class="ion-no-border" v-if="showHeader">
    <ion-toolbar>
      <ion-title class="pl-2" slot="start">Manage circle action</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="closeModal()">
          <span
            class="material-symbols-rounded sidebar-icon-normal pointer-cursor"
            >cancel
          </span>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <div class="mx-3 my-3" v-if="!showHeader">
    <ion-button
      @click="confirmInterestAction('remove_interest')"
      fill="solid"
      expand="block"
      style="font-size: 12px"
      v-if="compIsProviderAnInterest && reqStatus === 'ready'"
    >
      remove from circle
    </ion-button>
    <ion-button
      @click="confirmInterestAction('add_interest')"
      fill="outline"
      expand="block"
      style="font-size: 12px"
      v-if="
        !compIsProviderAnInterest && reqStatus === 'ready' && isAuthenticated
      "
    >
      add to circle
    </ion-button>
    <ion-spinner name="bubbles" v-if="reqStatus === 'processing'"></ion-spinner>
  </div>
  <ion-content v-if="showHeader">
    <div class="mx-3 my-3">
      <ion-button
        @click="confirmInterestAction('remove_interest')"
        fill="solid"
        expand="block"
        style="font-size: 12px"
        v-if="compIsProviderAnInterest && reqStatus === 'ready'"
      >
        remove from circle
      </ion-button>
      <ion-button
        @click="confirmInterestAction('add_interest')"
        fill="outline"
        expand="block"
        style="font-size: 12px"
        v-if="
          !compIsProviderAnInterest && reqStatus === 'ready' && isAuthenticated
        "
      >
        add to circle
      </ion-button>
      <ion-spinner
        name="bubbles"
        v-if="reqStatus === 'processing'"
      ></ion-spinner>
    </div>
  </ion-content>
  <ion-modal
    :is-open="isOpen"
    :backdropDismiss="true"
    :breakpoints="[0.2, 0.1]"
    :initialBreakpoint="0.2"
  >
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-title class="pl-2">Confirm your decision</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="toggleOpen()">
            <span
              class="material-symbols-rounded sidebar-icon-normal pointer-cursor"
              >cancel
            </span>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-button
        @click="addToInterest"
        fill="solid"
        class="p-2 my-3"
        v-if="interestAction === 'add_interest'"
      >
        <span
          class="material-symbols-rounded sidebar-icon-normal pointer-cursor"
          >check
        </span>
        Yes
      </ion-button>

      <ion-button
        @click="removeFromInterest"
        fill="solid"
        class="p-2 my-3"
        v-if="interestAction === 'remove_interest'"
      >
        <span
          class="material-symbols-rounded sidebar-icon-normal pointer-cursor px-2"
          >check
        </span>
        Yes
      </ion-button>

      <ion-button @click="toggleOpen()" fill="outline" class="p-2 my-3">
        <span
          class="material-symbols-rounded sidebar-icon-normal pointer-cursor px-2"
          >close
        </span>
        No
      </ion-button>
    </ion-content>
  </ion-modal>
</template>

<script lang="ts">
import {
  IonButtons,
  IonButton,
  IonSpinner,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  toastController,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref, computed } from "vue";
import { arrowBack } from "ionicons/icons";
import urls from "@/hooks/urlConfig";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import storeKeeper from "@/hooks/RendezStorage";
import { CapacitorHttp } from "@capacitor/core";
import coreSockets from "@/hooks/sockEvents";

export default defineComponent({
  name: "compFavCircleActions",
  components: {
    IonButtons,
    IonButton,
    IonSpinner,
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
  },
  props: {
    currentProvider: {
      required: true,
      default: { owner_id: "none", delivery_mode: "remote" },
    },
    showHeader: {
      default: false,
    },
  },
  setup(props) {
    const sk = storeKeeper();

    //get the urls
    const coreUrl = urls.coreUrl;

    // get the global vuex store objects
    const vueStore = useStore();

    // real time alerts
    const sock = coreSockets();

    const router = useRouter();

    const isAuthenticated = ref(vueStore.getters["auth/getSessionData"]);

    const compGetInterestData = computed(function () {
      const interests = vueStore.getters["fav/getFavInterests"];
      return interests;
    });

    const compIsProviderAnInterest = computed(function () {
      const interestList = compGetInterestData.value.filter(
        (item) => item.provider_id === props.currentProvider["owner_id"]
      );

      return interestList.length > 0 ? true : false;
    });

    const compIsProviderTrusted = computed(function () {
      const interestList = compGetInterestData.value.filter(
        (item) =>
          item.provider_id === props.currentProvider["owner_id"] &&
          item.trusted === true
      );

      return interestList.length > 0 ? true : false;
    });

    const providerId = ref(props.currentProvider["owner_id"]);

    const reqStatus = ref("ready");

    const addToInterest = async () => {
      reqStatus.value = "processing";

      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url: coreUrl.backendUrl + "add_new_interest",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenId,
        },
        data: {
          iv_provider_id: providerId.value,
        },
      };

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.replace("home");
      }

      if (response.data.status) {
        reqStatus.value = "ready";
        const interest_payload = {
          loadFav: true,
          data: { storage: "append", info: response.data.data.interest_info },
        };
        await vueStore.dispatch("fav/executeSetFavInterests", interest_payload);
        feedbackToast("Provider was successfully added to your interests");

        const new_interest_alert = {
          notification_data: response.data.data.notification_data,
          interest_data: response.data.data.interest_info,
          additional_update: response.data.data.additional_update,
        };

        sock.socket.emit("set_notification", new_interest_alert);

        isOpen.value = false;

        if (props.showHeader) {
          closeModal();
        }
      } else {
        reqStatus.value = "ready";
      }
    };

    const removeFromInterest = async () => {
      reqStatus.value = "processing";

      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url: coreUrl.backendUrl + "remove_existing_interest",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenId,
        },
        data: {
          iv_provider_id: providerId.value,
        },
      };

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.replace("home");
      }

      if (response.data.status) {
        const existingInterests = compGetInterestData.value;

        for (let i = 0; i <= existingInterests.length; i++) {
          if (existingInterests[i]["provider_id"] === providerId.value) {
            existingInterests.splice(i, 1);

            const interest_payload = {
              loadFav: true,
              data: { storage: "replace", info: existingInterests },
            };
            await vueStore.dispatch(
              "fav/executeSetFavInterests",
              interest_payload
            );

            isOpen.value = false;

            if (props.showHeader) {
              closeModal();
            }

            break;
          }
        }

        reqStatus.value = "ready";

        feedbackToast("Provider was successfully remove from your interests");
      } else {
        reqStatus.value = "ready";
      }
    };

    // feedback toast controller
    async function feedbackToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 2500,
        position: "bottom",
      });

      await toast.present();
    }

    const isOpen = ref(false);

    function toggleOpen() {
      isOpen.value = !isOpen.value;
    }

    const interestAction = ref("");

    function confirmInterestAction(action) {
      isOpen.value = true;
      interestAction.value = action;
    }

    async function closeModal() {
      return modalController.dismiss("endform", "close");
    }

    return {
      isAuthenticated,
      interestAction,
      confirmInterestAction,
      arrowBack,
      compIsProviderAnInterest,
      compIsProviderTrusted,
      addToInterest,
      removeFromInterest,
      isOpen,
      toggleOpen,
      reqStatus,
      closeModal,
    };
  },
});
</script>

<style scoped>
/* ion-modal::part(content) {
       display: flex;
      flex-direction: column;
      height: 100%;
  } */

ion-action-sheet.my-custom-class {
  --background: #eee;
  --backdrop-opacity: 0.6;
  --button-background-selected: #e97223;
  --button-color: #333;
  --color: #000;
  /* role: "destructive" button iOS styling override */
  --ion-color-danger: #000000;
}

ion-toolbar {
  --background: #fff;
}

ion-datetime {
  --background: #f5f5f5;
}
</style>
