<template>
  <ion-header
    collapse="fade"
    :class="cleanTitle === 'Home' ? 'ion-no-border' : ''"
    :transluscent="true"
  >
    <!-- Navigation -->
    <nav
      class="navbar navbar-expand-lg navbar-light bg-white osahan-nav-mid px-0 border-top"
    >
      <div
        class="container"
        :class="
          isWebBigScreen && cleanTitle === 'Home'
            ? 'rendez-mid-wide'
            : 'rendez-wide'
        "
      >
        <button
          class="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <!-- <span class="navbar-toggler-icon"></span> -->
          <a
            class="navbar-brand mb-2"
            href="JavaScript:void(0)"
            v-if="compIsAuthenticated"
          >
            <img
              src="../../images/main/logo.png"
              width="35"
              alt=""
              @click="refreshHome"
            />
          </a>
          <a
            class="navbar-brand pr-3"
            href="JavaScript:void(0)"
            v-if="!compIsAuthenticated"
          >
            <img
              src="../../images/main/rendez_logo.png"
              width="120"
              alt=""
              @click="refreshHome"
            />
          </a>
        </button>

        <IonButtons
          slot="start"
          class="pl-0"
          v-if="!isWebBigScreen && compIsAuthenticated"
        >
          <ion-title>{{ cleanTitle === "Home" ? "" : "" }}</ion-title>
          <ion-title>{{ cleanTitle === "Home" ? "" : cleanTitle }}</ion-title>
        </IonButtons>

        <div class="collapse navbar-collapse py-1" id="navbarResponsive">
          <a
            class="navbar-brand d-none d-lg-block d-xl-block d-md-block"
            href="JavaScript:void(0)"
            v-if="compIsAuthenticated"
          >
            <img
              src="../../images/main/logo.png"
              width="33"
              alt=""
              @click="refreshHome"
            />
          </a>
          <a
            class="navbar-brand d-none d-lg-block d-xl-block d-md-block"
            href="JavaScript:void(0)"
            v-if="!compIsAuthenticated"
          >
            <img
              src="../../images/main/rendez_logo.png"
              width="150"
              alt=""
              @click="refreshHome"
              v-if="cleanTitle === 'Home'"
            />
            <img
              src="../../images/main/logo.png"
              width="33"
              alt=""
              @click="refreshHome"
              v-else
            />
          </a>

          <!-- Topbar Search -->
          <form
            class="d-none d-lg-block d-xl-block d-md-block form-inline my-1 my-md-0 mw-100 navbar-search"
            v-if="isWebBigScreen && pageTitle !== 'home'"
          >
            <router-link to="/search">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control bg-white small"
                  placeholder="Find services and service providers"
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
                <div class="input-group-append">
                  <button class="btn btn-success" type="button">
                    <i class="fa fa-search fa-sm"></i>
                  </button>
                </div>
              </div>
            </router-link>
          </form>
          <ul class="navbar-nav mx-auto">
            <li class="nav-item" v-if="!compIsAuthenticated">
              <router-link
                class="nav-link mt-1"
                :class="urlPath === 'home' ? 'active-link' : ''"
                to="/home"
                >Home</router-link
              >
            </li>

            <li class="nav-item">
              <router-link
                class="nav-link mt-1"
                :class="urlPath === 'feed' ? 'active-link' : ''"
                to="/feed"
                >Feed</router-link
              >
            </li>

            <li class="nav-item mt-1">
              <router-link
                class="nav-link"
                :class="urlPath === 'calendar' ? 'active-link' : ''"
                to="/schedule_manager/pending_requests"
                v-if="compIsAuthenticated"
                >Pending requests
              </router-link>
            </li>

            <li class="nav-item mt-1" v-if="compIsAuthenticated">
              <router-link
                class="nav-link"
                :class="urlPath === 'bookings' ? 'active-link' : ''"
                to="/schedule_manager/upcoming_schedule"
                >Upcoming schedule
              </router-link>
            </li>

            <li class="nav-item mt-1" v-if="compIsServiceProvider">
              <router-link
                class="nav-link"
                to="/schedule_manager/appointment_booking_settings"
                >Appointment settings
              </router-link>
            </li>

            <li class="nav-item" v-if="false">
              <router-link class="nav-link" to="#">Guide </router-link>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle mt-1"
                :class="
                  urlPath === 'user_request' || urlPath === 'privacy_policy'
                    ? 'active-link'
                    : ''
                "
                href="#"
                id="navbarDropdownPortfolio"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Inside Rendez
              </a>
              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdownPortfolio"
              >
                <a class="dropdown-item" href="#" v-if="false">About rendez</a>
                <a class="dropdown-item" href="#" v-if="false">Careers</a>
                <a class="dropdown-item" href="#" v-if="false"
                  >Help & support</a
                >
                <router-link
                  class="nav-link"
                  :class="urlPath === 'user_request' ? 'active-link' : ''"
                  to="/user_request"
                  v-if="compCanAccess"
                  >User request</router-link
                >
                <router-link
                  class="nav-link"
                  :class="urlPath === 'privacy_policy' ? 'active-link' : ''"
                  to="/privacy_policy"
                  >Privacy policy</router-link
                >
              </div>
            </li>
          </ul>
        </div>
        <ul class="navbar-nav ml-auto">
          <!-- <li class="nav-item" v-if="false">
                    <a class="nav-link" href="#">
                        <i class="fa fa-fw fa-envelope"></i>
                        <span>Messages</span>
                    </a>
                 </li> -->
          <li
            class="nav-item dropdown no-arrow no-caret mr-2 dropdown-notifications d-sm-none"
          >
            <!-- <a class="btn btn-icon btn-transparent-dark dropdown-toggle" href="/search" id="searchDropdown" > -->
            <router-link
              class="btn btn-icon btn-transparent-dark dropdown-toggle"
              to="/search"
            >
              <i class="fa fa-search fa-fw icon-md text-secondary"></i>
            </router-link>
          </li>
          <li
            class="nav-item dropdown no-arrow no-caret mr-2 dropdown-notifications d-none d-lg-block d-xl-block d-md-block"
            @click="openPostContent"
            v-if="compCanAccess"
          >
            <a
              class="btn btn-icon btn-transparent-dark dropdown-toggle"
              href="javascript:void(0)"
              id="searchDropdown"
            >
              <i class="fa fa-plus fa-fw icon-md text-secondary"></i>
            </a>
          </li>
          <li
            class="nav-item dropdown no-arrow no-caret mr-2 dropdown-notifications"
            :class="urlPath === 'alerts' ? 'active-link' : ''"
            v-if="compIsAuthenticated"
          >
            <router-link
              class="btn btn-icon btn-transparent-dark dropdown-toggle"
              to="/alerts"
            >
              <i class="fa fa-bell fa-fw icon-md text-secondary"></i>
            </router-link>
            <ion-badge
              v-if="compAlertsList.length > 0"
              style="padding: 5px; margin-left: -20px; border-radius: 50%"
            >
              {{ compAlertsList.length }}
            </ion-badge>
          </li>
          <li
            class="nav-item dropdown no-arrow no-caret mr-2 dropdown-notifications d-sm-none"
            v-if="!compIsAuthenticated"
          >
            <router-link
              class="btn btn-icon btn-transparent-dark dropdown-toggle"
              to="/sign_in"
            >
              <i class="fa fa-lock fa-fw icon-md text-secondary"></i>
            </router-link>
          </li>

          <li
            class="nav-item dropdown no-arrow no-caret dropdown-notifications d-sm-none"
            v-if="!compIsAuthenticated"
          >
            <router-link
              class="btn btn-icon btn-transparent-dark dropdown-toggle"
              to="/sign_up"
            >
              <i class="fa fa-power-off fa-fw icon-md text-secondary"></i>
            </router-link>
          </li>

          <li
            class="nav-item dropdown no-arrow no-caret dropdown-user"
            v-if="compCanAccess"
          >
            <a
              class="btn btn-icon btn-transparent-dark dropdown-toggle"
              id="navbarDropdownUserImage"
              href="javascript:void(0);"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img class="img-fluid" :src="compAvatarSrc" />
            </a>
            <div
              class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
              aria-labelledby="navbarDropdownUserImage"
            >
              <h6 class="dropdown-header d-flex align-items-center">
                <div class="dropdown-user-details">
                  <div class="dropdown-user-details-name">{{ compName }}</div>
                  <div class="dropdown-user-details-email">
                    {{ compUsername }}
                  </div>
                </div>
              </h6>
              <div class="dropdown-divider"></div>
              <router-link class="dropdown-item" to="/my_account">
                <span><i class="fa fa-user fa-fw pr-2"></i>My account</span>
                <span class="float-right"></span>
              </router-link>
              <router-link class="dropdown-item" to="/my_circle">
                <span><i class="fa fa-user-plus fa-fw pr-2"></i>Interest</span>
                <span class="float-right">{{
                  compInterestCircleTotal.length
                }}</span>
              </router-link>
              <router-link class="dropdown-item" to="/my_trusted_brands">
                <span
                  ><i class="fa fa-thumbs-up fa-fw pr-2"></i>Trusted
                  providers</span
                >
                <span class="float-right">{{
                  compTrustedProviderTotal.length
                }}</span>
              </router-link>
              <router-link class="dropdown-item" to="/my_bookmarks">
                <span><i class="fa fa-bookmark fa-fw pr-2"></i>Bookmarks</span>
                <span class="float-right">{{ compBookmarkTotal.length }}</span>
              </router-link>
              <a class="dropdown-item" href="javascript:void(0)" v-if="false">
                <span
                  ><i class="fa fa-pie-chart fa-fw pr-2"></i>Impact
                  <strong> ( coming soon )</strong></span
                >
                <span class="float-right"></span>
              </a>
              <router-link class="dropdown-item" to="/my_settings">
                <span><i class="fa fa-gear fa-fw pr-2"></i>Settings</span>
                <span class="float-right"></span>
              </router-link>
              <a class="dropdown-item" href="javascript:void(0)">
                <span
                  ><i class="fa fa-lock fa-fw pr-2"></i>
                  <comp-auth-sign-out></comp-auth-sign-out>
                </span>
                <span class="float-right"></span>
              </a>
            </div>
          </li>
          <li
            class="nav-item mt-1 font-weight-bold d-none d-lg-block d-xl-block d-md-block"
            v-if="!compIsAuthenticated"
          >
            <router-link class="nav-link" to="/sign_in">
              <i class="fa fa-fw fa-lock"></i>
              <span>Sign in</span>
            </router-link>
          </li>
          <!-- <li class="nav-item mt-1" v-if="compCanAccess">
            <a
              class="nav-link"
              href="JavaScript:void(0)"
              @click="openPostContent"
            >
              <i class="fa fa-fw fa-lock"></i>
              <span>Post a service</span>
            </a>
          </li> -->
          <li
            class="nav-item mt-1 font-weight-bold d-none d-lg-block d-xl-block d-md-block"
            v-if="!compIsAuthenticated"
          >
            <router-link class="nav-link" to="/sign_up">
              <i class="fa fa-fw fa-power-off"></i>
              <span>Get started</span>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </ion-header>
  <!-- Navigation -->
</template>

<script lang="ts">
import { CapacitorHttp } from "@capacitor/core";
import {
  IonHeader,
  IonButtons,
  IonTitle,
  IonBadge,
  toastController,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref, computed, inject } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import storeKeeper from "@/hooks/RendezStorage";
import urls from "@/hooks/urlConfig";
import coreSockets from "@/hooks/sockEvents";
import CreateFeedPostContent from "@/components/forms/feed/CreateFeedPostContent.vue";
import compAuthSignOut from "@/views/auth/compAuthSignOut.vue";

import { home, search, addCircle } from "ionicons/icons";

export default defineComponent({
  name: "MainHeader",
  props: {
    pageTitle: {
      type: String,
      default: "Home",
    },
    urlPath: {
      type: String,
      default: "Home",
    },
  },
  components: {
    IonHeader,
    IonButtons,
    IonTitle,
    IonBadge,
    compAuthSignOut,
  },
  setup(props) {
    /*
    VueType:TTemplate, its the header of the app
    Responsibiliy: its responsible for the top navigations, sign out feature, socket realtime notification feature
    links: Standard non-sign in links when logged out, and signed in links when logged in
    Active:  Always
    Funtions: refreshHome, openPostContent, runSearch, logOut, getUserAlerts
    Use Session data : This page needs session data
    External libraries :  urlConfig , RendezStorage
    Components: FeedDataList => This components populates the feed of provider's posting, its used here in the home page to show a section of the feeds
    */

    // Initiate the local storage
    const sk = storeKeeper();

    // get the url config that holds all the URL basepaths
    const coreUrl = urls.coreUrl;

    // fetch the image url basepath for avatars
    const imgBaseAvatarUrl = ref(coreUrl.imgAvatarHost);

    // get the global route object for url routing
    const router = useRouter();

    // initiate the sockets config for socket functions
    const sock = coreSockets();

    // get the global vuex store objects, for temp persistent storage
    const vueStore = useStore();

    // Get the title of the page from the props( basically the url ), and clean it up for use
    const cleanTitle = computed(function () {
      const title = props.pageTitle;

      const firstLetter = title.charAt(0);

      const firstLetterCap = firstLetter.toUpperCase();

      const remainingLetters = title.slice(1);

      return firstLetterCap + remainingLetters;
    });

    // get the mobile platform status using the inject function
    const isMobile = ref(inject("isMobile"));

    // get the screen status using the inject function
    const isWebBigScreen = ref(inject("isWebBigScreen"));

    // get session data
    const compSessionData = computed(function () {
      const sessionData = vueStore.getters["auth/getSessionData"];
      return sessionData;
    });

    //get user owner id data if user is active
    const ownerId = compSessionData.value
      ? compSessionData.value["ownerId"]
      : "none";

    // check if user is logged in
    const compIsAuthenticated = computed(function () {
      const authenticate = compSessionData.value
        ? compSessionData.value["isAuthenticated"]
        : false;
      return authenticate;
    });

    // check if user is a service provider
    const compIsServiceProvider = computed(function () {
      const authenticate = compSessionData.value
        ? compSessionData.value["serviceProvider"]
        : false;
      return authenticate;
    });

    // get user's shortened name
    const compName = computed(function () {
      const sessionData = compSessionData.value;
      const name = sessionData["name"];
      return name.substring(0, 22);
    });

    // get user's formatted username if provider
    const compUsername = computed(function () {
      const sessionData = compSessionData.value;
      return sessionData["username"] && sessionData["serviceProvider"]
        ? "@" + sessionData["username"]
        : "";
    });

    // get user remote avatar link
    const compAvatarSrc = computed(function () {
      const imageBaseSrc = vueStore.getters["account/getAvatarSrc"];
      const avatar = compSessionData.value["avatar"];
      return imageBaseSrc
        ? imageBaseSrc
        : imgBaseAvatarUrl.value +
            "tr:di-default_avatar.png/" +
            avatar +
            "?" +
            new Date().getTime();
    });

    // check if user can see the post content button
    const compCanAccess = computed(function () {
      if (compSessionData.value) {
        return compSessionData.value["isAuthenticated"] ? true : false;
      } else {
        return false;
      }
    });

    // Calculate the number of people in your interest circle
    const compInterestCircleTotal = computed(function () {
      const interests = vueStore.getters["fav/getFavInterests"];
      return interests;
    });

    // Calculate the number of trusted providers you keep
    const compTrustedProviderTotal = computed(function () {
      const trustedList = compInterestCircleTotal.value.filter(
        (item) => item.trusted === true
      );

      return trustedList;
    });

    // Get your total number of bookmarks
    const compBookmarkTotal = computed(function () {
      const bookmarks = vueStore.getters["fav/getFavBookmarks"];
      return bookmarks;
    });

    // function for sending the link back to home or feed, depending on log in status
    function refreshHome() {
      const link = compIsAuthenticated.value ? "/feed" : "/home";
      router.push(link);
    }

    // function for opeening the post content modal from the header, another exists for footer views
    async function openPostContent() {
      const modal = await modalController.create({
        component: CreateFeedPostContent,
      });
      modal.present();

      // const { data, role } = await modal.onWillDismiss();
      //
      // if (role === 'confirm') {
      //   console.log(`Hello, ${data}!`);
      // }
    }

    // function for routing clicks
    function runSearch() {
      router.push("/search");
    }

    // feedback toast controller
    async function feedbackToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 2500,
        position: "bottom",
      });

      await toast.present();
    }

    // function for logging out user
    async function logoutUser() {
      // Get your assigned token id, that will validate the api execution
      const tokenId = await sk.getStoreIon("access_token");

      // The function that will perform the actual action
      const signOut = async () => {
        // set the data required to access the backend
        const options = {
          url: coreUrl.backendUrl + "sign_out",
          headers: { Authorization: "Bearer " + tokenId },
        };

        // sending the http request to the server
        const response = await CapacitorHttp.request({
          ...options,
          method: "GET",
        });

        // if backend execution is successful
        if (response.data.status) {
          // first delete all data in the ionic storage db
          await sk.delAllStoreIon();

          // Reset the vuex session data to the default
          await vueStore.dispatch("auth/executeSetDefaultSessionData");
          await vueStore.dispatch("account/executeSetDefaultAccountData");
          await vueStore.dispatch("account/executeDefaultSetAlertsList");

          // exit the socket room , so as to end this channel
          sock.socket.emit("leave_client_room", ownerId);

          // Disconnect this instance from the server
          sock.socket.on("disconnect", () => {
            // console.log(sock.socket.id); // undefined
          });

          // router.replace("/home");
          // redirect user to the homepage
          window.location.href = "/home";
        }

        // if user is not alloed to access this resource in the backend
        if (response.status === 401 || response.status === 422) {
          // delete all data in the ionic storage db
          await sk.delAllStoreIon();

          // redirect to home
          router.push("home");
        }
      };

      // execute the logout function in a promise block to prevent the server from crashing

      signOut()
        .then()
        .catch((err) => {
          err ? err : "Temporary server issue";
          feedbackToast(err);
        });
    }

    // trigger Alerts

    // Set a compute function to get the latest alert from vueX, this way any component where this DB is updated
    // the header component gets the latest data.
    // This particular component needs only the active alerts so as to let the user no how many unread alerts exists
    const compAlertsList = computed(function () {
      const alertsData = vueStore.getters["alerts/getAlertsList"];

      const activeAlerts = alertsData.filter(
        (item) => item.status === "active"
      );

      return activeAlerts;
    });

    // end trigger alerts

    return {
      search,
      home,
      cleanTitle,
      isWebBigScreen,
      compIsAuthenticated,
      compIsServiceProvider,
      compName,
      compUsername,
      compInterestCircleTotal,
      compTrustedProviderTotal,
      compBookmarkTotal,
      isMobile,
      refreshHome,
      logoutUser,
      addCircle,
      compCanAccess,
      openPostContent,
      runSearch,
      compAvatarSrc,
      compAlertsList,
    };
  },
});
</script>

<style scoped>
.icon-md {
  font-size: 24px;
}

.active-link {
  font-weight: 900;
  text-decoration: underline;
}
</style>
