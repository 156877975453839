export default function constDataList(){

    const categoryList = [
        {id:"wellness", value:"wellness", alias:"Wellness & Personal grooming", active:false},
        {id:"ict", value:"ict", alias:"Information Communication Technology", active:false},
        {id:"events", value:"events", alias:"Events", active:false},
        {id:"health_care", value:"health_care", alias:"Health care", active:false},
        {id:"animal_care", value:"animal_care", alias:"Animal care", active:false},
        {id:"counselling", value:"counselling", alias:"Theraphy & Counselling", active:false},
        {id:"food_catering", value:"food_catering", alias:"Food & catering", active:false},
        {id:"sports_fitness", value:"sports_fitness", alias:"Sports & Fitness", active:false},
        {id:"hospitality", value:"hospitality", alias:"Hospitality", active:false},
        {id:"entertainment", value:"entertainment", alias:"Entertainment", active:false},
        {id:"financial_services", value:"financial_services", alias:"Financial services", active:false},
        {id:"creative_arts", value:"creative_arts", alias:"Creative & Digital arts", active:false},
        {id:"knowledge_and_skills_training", value:"knowledge_and_skills_training", alias:"Knowledge & Skills training", active:false},
        {id:"legal", value:"legal", alias:"Legal services", active:false},
        {id:"research", value:"research", alias:"Research services", active:false},
        {id:"real_estate", value:"real_estate", alias:"Real estate services", active:false},
        {id:"others", value:"others", alias:"Others", active:false}
    ]


    return {
        categoryList
    }
}
