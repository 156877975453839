<template>
  <ion-list class="pt-0">
    <ion-item lines="none" v-if="!linkHeader">
      <img
        alt="This is your avatar"
        width="45"
        :src="compAvatarSrc"
        class="round-img cursor-pointer"
        @click="openAvatarUpload"
      />

      <span
        class="material-symbols-rounded action-avatar-icon pointer-cursor pt-1"
        @click="openAvatarUpload"
      >
        add_a_photo
      </span>

      <ion-label class="pl-4">
        <div class="font-weight-bold">
          <div class="text-truncate">
            {{ compUsername }}

            <span
              class="material-symbols-rounded action-user-icon mx-1 pointer-cursor"
              @click="openUsernameForm"
            >
              border_color
            </span>
          </div>
          <div class="small">
            {{ compAccountDataInfo.expertise }}
          </div>
        </div>
      </ion-label>
    </ion-item>
    <ion-item v-if="linkHeader">
      <ion-label
        ><h1>{{ linkHeader }}</h1></ion-label
      >
    </ion-item>
    <ion-item
      lines="none"
      v-for="(link, index) in linkList"
      :key="link.route + index"
      class="pointer-cursor"
      :class="link.active ? 'py-2 font-weight-bold' : 'py-2'"
    >
      <span class="material-symbols-rounded pl-1" :class="iconSize">
        {{ link.icon }}
      </span>

      <ion-label class="pl-4"
        ><div class="text-truncate sidebar-link">
          <router-link :to="link.route" class="text-secondary">
            {{ link.alias }}
          </router-link>
        </div></ion-label
      >
    </ion-item>
  </ion-list>

  <span
    class="btn-secondary btn btn-white btn-custom-order mt-2 ml-4"
    @click="OpenWebShare"
    v-if="showSharePage"
  >
    <span class="material-symbols-rounded action-user-icon mx-1 pointer-cursor">
      share
    </span>
    Share your page
  </span>
  <ion-modal :is-open="showAvatarModal">
    <utility-avatar-upload></utility-avatar-upload>
  </ion-modal>
</template>

<script lang="ts">
import {
  IonItem,
  IonList,
  IonLabel,
  IonModal,
  modalController,
} from "@ionic/vue";
import { defineComponent, inject, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import urls from "@/hooks/urlConfig";
import UtilityAvatarUpload from "@/components/utility/UtilityAvatarUpload.vue";
import UtilityWebShare from "@/components/utility/UtilityWebShare.vue";
import AuthUpdateUsername from "@/components/auth/AuthUpdateUsername.vue";

export default defineComponent({
  name: "NewAccountProviderLeftSidebar",
  components: {
    IonItem,
    IonList,
    IonLabel,
    IonModal,
    UtilityAvatarUpload,
  },
  props: {
    linkHeader: {
      type: String,
      default: "My account",
    },
    linkList: {
      type: Object,
      required: true,
      default: () => ({
        link: "profile",
        alias: "Profile",
      }),
    },
    iconSize: {
      type: String,
      required: true,
      default: "sidebar-icon-normal",
    },
    showSharePage: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    //get the urls
    const coreUrl = urls.coreUrl;

    // get the global route object for url routing
    const router = useRouter();

    const imgBaseAvatarUrl = ref(coreUrl.imgAvatarHost);

    // get the global vuex store objects
    const vueStore = useStore();

    const currentTab = ref("profile");

    function switchTab(ev: any) {
      currentTab.value = ev.detail.value;
    }

    const isMobile = ref(inject("isMobile"));

    const compAccountDataInfo = computed(function () {
      return vueStore.getters["account/getAccountData"];
    });

    const compAuthSessionData = computed(function () {
      const sessionData = vueStore.getters["auth/getSessionData"];
      return sessionData;
    });

    const compUsername = computed(function () {
      const sessionData = compAuthSessionData.value;
      return sessionData ? sessionData["username"] : "";
    });

    const compAccountDataServices = computed(function () {
      return vueStore.getters["account/getAccountServices"];
    });

    const avatarName = ref(vueStore.getters["auth/getSessionData"]["avatar"]);

    const compAvatarSrc = computed(function () {
      const imageSrc = vueStore.getters["account/getAvatarSrc"];

      return imageSrc
        ? imageSrc
        : imgBaseAvatarUrl.value +
            "tr:di-default_avatar.png/" +
            avatarName.value +
            "?" +
            new Date().getTime();
    });

    async function openUsernameForm() {
      const modal = await modalController.create({
        component: AuthUpdateUsername,
        mode: "ios",
        cssClass: "",
        componentProps: {
          actionType: "settings",
        },
      });
      modal.present();

      await modal.onWillDismiss();
      // const { data, role } = await modal.onWillDismiss();

      // if(role === "confirm"){
      //     isSetAppointmentInfo.value = "updated"
      //     appointmentInfo.value = data
      // }
    }

    const showAvatarModal = ref(false);

    // Image upload modal
    async function openAvatarUpload() {
      showAvatarModal.value = !showAvatarModal.value;
    }

    // function for routing clicks
    const routeAction = (route: any) => {
      router.push(route);
    };

    const shareOptions = {
      title: "",
      text: `Hello there, I am inviting you to visit my page(${
        compUsername.value ? "@" + compUsername.value : ""
      }) on Rendez to schedule appointments with me.`,
      url: "https://web.rendezapp.com/",
      dialogTitle: "Share your page with your community",
    };

    const shareExtraOptions = {
      hashtags: ["Rendez", "RendezApp", compUsername.value],
    };

    async function OpenWebShare() {
      const modal = await modalController.create({
        component: UtilityWebShare,
        componentProps: {
          shareOptions: shareOptions,
          shareExtraOptions: shareExtraOptions,
        },
        handle: true,
        mode: "ios",
      });
      modal.present();
    }

    return {
      close,
      switchTab,
      currentTab,
      isMobile,
      compAccountDataInfo,
      compAuthSessionData,
      compUsername,
      compAccountDataServices,
      showAvatarModal,
      openAvatarUpload,
      routeAction,
      openUsernameForm,
      OpenWebShare,
      imgBaseAvatarUrl,
      compAvatarSrc,
    };
  },
});
</script>

<style scoped>
.sidebar-icon-normal {
  font-size: 32px;
}

.sidebar-icon-small {
  font-size: 20px;
}

.sidebar-link {
  font-size: 16px;
}

.action-avatar-icon {
  font-size: 24px;
  margin-left: -32px;
  color: #f5f5f5;
}

.action-user-icon {
  font-size: 14px;
}

.round-img {
  border-radius: 50%;
}

.item-complex .item-content,
.item {
  background-color: #f7f7f7;
}

ion-item-background {
  background-color: rgba(0, 0, 0, 0);
}
</style>
