<template>
  <ion-page>
    <ion-header :translucent="true" mode="ios" class="ion-no-border">
      <ion-toolbar>
        <ion-title class="text-left pl-1">{{
          shareOptions.dialogTitle
        }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="toggleSubMenu">
            <ion-icon :icon="closeCircleOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="m-2 p-2 row">
        <!-- twitter share -->
        <div class="col-3 mt-3">
          <s-twitter
            :window-features="windowFeatures"
            :share-options="shareTwitterOptions"
            :use-native-behavior="useNativeBehavior"
          >
            <!-- your icon component -->
            <img src="../../images/brand/x_black.png" width="32" alt="" />
          </s-twitter>
        </div>

        <!-- facebook share -->
        <div class="col-3 mt-3">
          <s-facebook
            :window-features="windowFeatures"
            :share-options="shareFacebookOptions"
            :use-native-behavior="useNativeBehavior"
          >
            <img src="../../images/brand/facebook.png" width="32" alt="" />
          </s-facebook>
        </div>

        <!-- whatsapp share -->
        <div class="col-3 mt-3">
          <s-whats-app
            :window-features="windowFeatures"
            :share-options="shareWhatsAppOptions"
            :use-native-behavior="useNativeBehavior"
          >
            <img src="../../images/brand/whatsapp.png" width="32" alt="" />
          </s-whats-app>
        </div>

        <!-- Gmail share -->
        <div class="col-3 mt-3">
          <s-gmail
            :window-features="windowFeatures"
            :share-options="shareGmailOptions"
            :use-native-behavior="useNativeBehavior"
          >
            <!-- your icon component -->
            <img src="../../images/brand/gmail.png" width="32" alt="" />
          </s-gmail>
        </div>

        <!-- LinkedIn share -->
        <div class="col-3 mt-3">
          <s-linked-in
            :window-features="windowFeatures"
            :share-options="shareLinkedInOptions"
            :use-native-behavior="useNativeBehavior"
          >
            <!-- your icon component -->
            <img src="../../images/brand/linkedin.png" width="32" alt="" />
          </s-linked-in>
        </div>

        <!-- Telegram share  -->
        <div class="col-3 mt-3">
          <s-telegram
            :window-features="windowFeatures"
            :share-options="shareTelegramOptions"
            :use-native-behavior="useNativeBehavior"
          >
            <!-- your icon component -->
            <img src="../../images/brand/telegram.png" width="32" alt="" />
          </s-telegram>
        </div>

        <!-- Email share -->
        <div class="col-3 mt-3">
          <s-email
            :window-features="windowFeatures"
            :share-options="shareEmailOptions"
            :use-native-behavior="useNativeBehavior"
          >
            <!-- your icon component -->
            <img src="../../images/brand/mail.png" width="32" alt="" />
          </s-email>
        </div>

        <!-- SMS share -->
        <div class="col-3 mt-3">
          <s-sms
            :window-features="windowFeatures"
            :share-options="shareSmsOptions"
            :use-native-behavior="useNativeBehavior"
          >
            <!-- your icon component -->
            <img src="../../images/brand/sms.png" width="32" alt="" />
          </s-sms>
        </div>

        <!-- Tumblr share -->
        <div class="col-3 mt-3">
          <s-tumblr
            :window-features="windowFeatures"
            :share-options="shareTumblrOptions"
            :use-native-behavior="useNativeBehavior"
          >
            <!-- your icon component -->
            <img src="../../images/brand/tumblr_grey.png" width="32" alt="" />
          </s-tumblr>
        </div>

        <div class="col-3 mt-3">
          <!-- pinterest -->
          <s-pinterest
            :window-features="windowFeatures"
            :share-options="sharePinterestOptions"
            :use-native-behavior="useNativeBehavior"
          >
            <!-- your icon component -->
            <img src="../../images/brand/pinterest.png" width="32" alt="" />
          </s-pinterest>
        </div>

        <div class="col-3 mt-3">
          <!-- Yammer -->
          <s-yammer
            :window-features="windowFeatures"
            :share-options="shareYammerOptions"
            :use-native-behavior="useNativeBehavior"
          >
            <!-- your icon component -->
            <img src="../../images/brand/yammer.png" width="32" alt="" />
          </s-yammer>
        </div>

        <div class="col-3 mt-3">
          <!-- reddit -->
          <s-reddit
            :window-features="windowFeatures"
            :share-options="shareRedditOptions"
            :use-native-behavior="useNativeBehavior"
          >
            <!-- your icon component -->
            <img src="../../images/brand/reddit.png" width="32" alt="" />
          </s-reddit>
        </div>

        <div class="col-3 mt-3">
          <s-microsoft-teams
            :window-features="windowFeatures"
            :share-options="shareMicrosoftTeamsOptions"
            :use-native-behavior="useNativeBehavior"
          >
            <!-- your icon component -->
            <img src="../../images/brand/teams.png" width="32" alt="" />
          </s-microsoft-teams>
        </div>

        <!-- mastodon -->
        <div class="col-3 mt-3">
          <s-mastodon
            :window-features="windowFeatures"
            :share-options="shareMastodonOptions"
            :use-native-behavior="useNativeBehavior"
          >
            <!-- your icon component -->
            <img src="../../images/brand/mastodon.png" width="32" alt="" />
          </s-mastodon>
        </div>

        <div class="col-3 mt-3">
          <s-flip-board
            :window-features="windowFeatures"
            :share-options="shareFlipBoardOptions"
            :use-native-behavior="useNativeBehavior"
          >
            <!-- your icon component -->
            <img src="../../images/brand/flipboard.png" width="32" alt="" />
          </s-flip-board>
        </div>

        <div class="col-3 mt-3">
          <s-trello
            :window-features="windowFeatures"
            :share-options="shareTrelloOptions"
            :use-native-behavior="useNativeBehavior"
          >
            <!-- your icon component -->
            <img src="../../images/brand/trello.png" width="32" alt="" />
          </s-trello>
        </div>

        <!-- text nessage -->
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonIcon,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonPage,
  IonContent,
  IonTitle,
  modalController,
} from "@ionic/vue";
import { closeCircleOutline } from "ionicons/icons";
import { defineComponent, ref } from "vue";
import {
  STwitter,
  SFacebook,
  SWhatsApp,
  SGmail,
  SLinkedIn,
  STelegram,
  SEmail,
  SSms,
  STumblr,
  SPinterest,
  SYammer,
  SReddit,
  SMicrosoftTeams,
  SMastodon,
  SFlipBoard,
  STrello,
} from "vue-socials";

export default defineComponent({
  components: {
    IonIcon,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonPage,
    IonContent,
    IonTitle,
    STwitter,
    SFacebook,
    SWhatsApp,
    SGmail,
    SLinkedIn,
    STelegram,
    SEmail,
    SSms,
    STumblr,
    SPinterest,
    SYammer,
    SReddit,
    SMicrosoftTeams,
    SMastodon,
    SFlipBoard,
    STrello,
  },
  props: {
    shareOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          title: "",
          text: "",
        };
      },
    },
    shareExtraOptions: {
      type: Object,
      required: true,
      default: function () {
        return {
          hashtags: [],
        };
      },
    },
  },
  setup(props) {
    const isSubMenu = ref(false);

    function toggleSubMenu() {
      isSubMenu.value = !isSubMenu.value;
      return modalController.dismiss(true, "confirm");
    }

    const windowFeatures = ref({});
    const useNativeBehavior = ref(false);
    const shareTwitterOptions = ref({
      url: props.shareOptions.url,
      text: props.shareOptions.text,
      hashtags: props.shareExtraOptions.hashtags,
      via: "rendezapp",
    });

    const shareFacebookOptions = ref({
      url: props.shareOptions.url,
      quote: props.shareOptions.text,
      hashtag: props.shareExtraOptions.hashtags,
    });

    const shareWhatsAppOptions = ref({
      number: "1(999)999-99-99",
      text: props.shareOptions.text,
    });

    const shareGmailOptions = ref({
      to: "",
      su: props.shareOptions.title,
      cc: "",
      bcc: "",
      body: props.shareOptions.text,
    });

    const shareLinkedInOptions = ref({
      url: props.shareOptions.url,
    });

    const shareTelegramOptions = ref({
      url: props.shareOptions.url,
      text: props.shareOptions.text,
    });

    const shareEmailOptions = ref({
      url: props.shareOptions.url,
      mail: "",
      cc: [],
      bcc: [],
      subject: props.shareOptions.title,
      body: props.shareOptions.text,
    });

    const shareSmsOptions = ref({
      number: "",
      body: props.shareOptions.text,
    });

    const shareTumblrOptions = ref({
      canonicalUrl: props.shareOptions.url,
      title: props.shareOptions.title,
      caption: props.shareOptions.title,
      tags: props.shareExtraOptions.hashtags,
    });

    const sharePinterestOptions = ref({
      url: props.shareOptions.url,
      media: "",
      description: props.shareOptions.text,
    });

    const shareYammerOptions = ref({
      url: props.shareOptions.url,
      text: props.shareOptions.text,
      groupId: "",
    });

    const shareRedditOptions = ref({
      url: props.shareOptions.url,
      title: props.shareOptions.title,
      text: props.shareOptions.text,
      selftext: true,
    });

    const shareMicrosoftTeamsOptions = ref({
      url: props.shareOptions.url,
      preview: true,
      text: props.shareOptions.text,
      title: props.shareOptions.title,
      selftext: true,
      instruction: "",
    });

    const shareMastodonOptions = ref({
      domain: "https://mas.to",
      url: props.shareOptions.url,
      text: props.shareOptions.text,
    });

    const shareFlipBoardOptions = ref({
      url: props.shareOptions.url,
      title: props.shareOptions.title,
    });

    const shareTrelloOptions = ref({
      url: props.shareOptions.url,
      name: "",
      description: props.shareOptions.text,
    });

    return {
      closeCircleOutline,
      isSubMenu,
      toggleSubMenu,
      shareTwitterOptions,
      shareFacebookOptions,
      shareWhatsAppOptions,
      shareGmailOptions,
      shareLinkedInOptions,
      shareTelegramOptions,
      shareEmailOptions,
      shareSmsOptions,
      shareTumblrOptions,
      sharePinterestOptions,
      shareYammerOptions,
      shareRedditOptions,
      shareMicrosoftTeamsOptions,
      shareMastodonOptions,
      shareFlipBoardOptions,
      shareTrelloOptions,
      windowFeatures,
      useNativeBehavior,
    };
  },
});
</script>

<style scoped>
ion-icon {
  color: #000;
}
</style>
