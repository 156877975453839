<template>
  <div class="container">
    <div class="row justify-content-center align-items-center mt-1">
      <div class="mx-auto col-12">
        <div class="osahan-login mt-2 py-4" v-if="reqFormStatus">
          <div class="form-group">
            <ion-label class="mb-1">
              <b>Service</b>
              <ion-note
                ><span v-if="fv.service.msg">
                  | {{ fv.service.msg }}</span
                ></ion-note
              >
            </ion-label>
            <div class="position-relative icon-form-control">
              <i class="position-absolute fa fa-gift fa-fw"> </i>
              <input
                type="text"
                class="form-control"
                :class="fv.service.msg ? 'form-control-error' : ''"
                required
                v-model.trim="fv.service.val"
                disabled
              />
            </div>
          </div>

          <div class="form-group">
            <ion-label class="mb-1">
              <b>Duration</b>
              <ion-note>
                <span v-if="fv.duration.msg">
                  |
                  {{
                    fv.duration.msg === "This field is required."
                      ? "The hour or min field is required"
                      : fv.duration.msg
                  }}
                </span>
              </ion-note>
            </ion-label>
            <div class="row">
              <div class="position-relative icon-form-control col-8 mb-0 pr-1">
                <i class="position-absolute fa fa-clock-o fa-fw"></i>
                <select
                  id="inputState"
                  class="form-control"
                  disabled
                  :class="fv.duration.msg ? 'form-control-error' : ''"
                  v-model.trim="timeHour"
                >
                  <option value="0">00 hrs</option>
                  <option v-for="hour in 23" :key="hour" :value="hour">
                    {{ hour > 1 ? hour + " hrs" : hour + " hr" }}
                  </option>
                </select>
              </div>
              <div class="form-group col-4 mb-0 pl-0">
                <select
                  id="inputState1"
                  class="form-control"
                  disabled
                  :class="fv.duration.msg ? 'form-control-error' : ''"
                  v-model.trim="timeMin"
                >
                  <option value="0">00 mins</option>
                  <option v-for="min in minList" :key="min" :value="min">
                    {{ min }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="form-group">
            <ion-label class="mb-1">
              <b>Cost</b>
              <ion-note>
                <span v-if="fv.cost.msg || fv.currency.msg">
                  | {{ fv.cost.msg }}
                </span>
              </ion-note>
            </ion-label>
            <div class="row">
              <div class="position-relative icon-form-control col-8 mb-0 pr-1">
                <i class="position-absolute fa fa-money fa-fw"></i>
                <input
                  type="text"
                  class="form-control"
                  :class="fv.cost.msg ? 'form-control-error' : ''"
                  required
                  v-model.trim="fv.cost.val"
                  disabled
                />
              </div>
              <div class="form-group col-4 mb-0 pl-0">
                <input
                  type="text"
                  class="form-control"
                  disabled
                  :class="fv.currency.msg ? 'form-control-error' : ''"
                  required
                  :value="compCleanCurrency"
                />
              </div>
            </div>
          </div>

          <div class="form-group">
            <ion-label class="mb-1">
              <b>Is fee negotiable?</b>
              <ion-note
                ><span v-if="fv.negotiable.msg">
                  | {{ fv.negotiable.msg }}</span
                ></ion-note
              >
            </ion-label>
            <div class="position-relative icon-form-control">
              <i class="position-absolute fa fa-handshake-o fa-fw"> </i>
              <select
                class="form-control"
                :class="fv.negotiable.msg ? 'form-control-error' : ''"
                required
                v-model.trim="fv.negotiable.val"
                disabled
              >
                <option value="">Select option</option>
                <option
                  v-for="(final, index) in finalFee"
                  :key="final + index"
                  :value="final"
                >
                  {{ final }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <ion-label>
              <b> Brief business summary </b>
            </ion-label>
            <div class="position-relative">
              <textarea
                type="text"
                disabled
                class="form-control"
                :class="fv.brief.msg ? 'form-control-error' : ''"
                rows="8"
                required
                v-model.trim="fv.brief.val"
              ></textarea>
            </div>
          </div>

          <ion-note>{{ reqFeedback }}</ion-note>
          <div v-if="reqStatus === 'ready'">
            <button
              class="btn btn-block text-uppercase"
              :class="
                postType === 'service_archive' || postType === 'service_restore'
                  ? 'btn-secondary'
                  : 'btn-danger'
              "
              @click="runDeleteAccountService"
              type="button"
            >
              {{
                postType === "service_restore" || postType === "service_archive"
                  ? postType === "service_restore"
                    ? "Restore service"
                    : "Archive service"
                  : "Delete service"
              }}
            </button>
          </div>
          <ion-spinner
            name="circles"
            class="mb-3 ml-2"
            v-if="reqStatus === 'processing'"
          ></ion-spinner>
        </div>

        <div class="container" v-else>
          <img
            class="img-fluid"
            src="../../../images/main/profile.svg"
            alt=""
          />
          <div class="text-center pt-2">
            <h5 class="font-weight-bold mt-3">
              Basic info successfully updated !!!
            </h5>
            <p class="text-muted">
              Well done, you are all set to start accepting clients.
            </p>

            <p class="text-muted">
              <b> Tip: </b> You can boost your chances of landing clients , by
              updating your skill sets and list of services
            </p>
            <ion-button
              @click="routeBasicInfo"
              class="mt-4"
              expand="block"
              color="primary"
              fill="outline"
              >Got to dashboard</ion-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { IonNote, IonLabel, IonSpinner, IonButton } from "@ionic/vue";
import { defineComponent, reactive, ref, inject, computed } from "vue";
import { useStore } from "vuex";
import storeKeeper from "@/hooks/RendezStorage";
import { CapacitorHttp } from "@capacitor/core";
import urls from "@/hooks/urlConfig";

export default defineComponent({
  name: "CreateAccountUpdateServices",
  components: {
    IonNote,
    IonLabel,
    IonSpinner,
    IonButton,
  },
  props: {
    postType: {
      required: true,
      default: "service_archive",
    },
    serviceId: {
      required: true,
      default: 0,
    },
  },
  setup(props) {
    const sk = storeKeeper();

    //get the urls
    const coreUrl = urls.coreUrl;

    // get feedback toast function
    const feedbackToast: any = inject("feedbackToast");

    // get the global vuex store objects
    const vueStore = useStore();

    const compAllAccountDataServices = computed(function () {
      const allAcountServices = vueStore.getters["account/getAccountServices"];
      return allAcountServices;
    });

    const compCurrentService = computed(function () {
      const currentService = compAllAccountDataServices.value.filter((item) => {
        return item.service_id === props.serviceId;
      });

      return currentService.length > 0 ? currentService[0] : [];
    });

    // reference form variables validation in main doc
    const fv: any = reactive({
      service: {
        status: false,
        val: compCurrentService.value["service"]
          ? compCurrentService.value["service"]
          : "",
        msg: false,
        validate: [
          "required",
          { option: "textMin", val: 3 },
          { option: "textMax", val: 150 },
        ],
      },
      duration: {
        status: false,
        val: compCurrentService.value["duration"]
          ? compCurrentService.value["duration"]
          : 0,
        msg: false,
        validate: ["required", "numeric"],
      },
      cost: {
        status: false,
        val: compCurrentService.value["cost"]
          ? compCurrentService.value["cost"]
          : "",
        msg: false,
        validate: ["required", "numeric"],
      },
      currency: {
        status: false,
        val: compCurrentService.value["currency"]
          ? compCurrentService.value["currency"]
          : "",
        msg: false,
        validate: ["required"],
      },
      negotiable: {
        status: false,
        val: compCurrentService.value["negotiable"]
          ? compCurrentService.value["negotiable"]
          : "",
        msg: false,
        validate: ["required"],
      },
      brief: {
        status: false,
        val: compCurrentService.value["brief"]
          ? compCurrentService.value["brief"]
          : "",
        msg: false,
        validate: [
          { option: "textMin", val: 0 },
          { option: "textMax", val: 500 },
        ],
      },
      showSubmit: { status: false, val: true },
    });

    const compOfficialCurrency = computed(function () {
      const accountData = vueStore.getters["account/getAccountData"];
      return accountData["currency"];
    });

    const compCleanCurrency = computed(function () {
      const currency = compOfficialCurrency.value;
      return currency.split("-")[0];
    });

    function formatCost(action) {
      let newCost: any = "";

      if (fv.cost.val) {
        if (action === "toString") {
          newCost = parseInt(fv.cost.val).toLocaleString();
        } else {
          newCost =
            typeof fv.cost.val === "string"
              ? parseInt(fv.cost.val.replace(/,/g, ""))
              : fv.cost.val.toLocaleString();
        }
      }

      fv.cost.val = newCost;
    }

    formatCost("toString");

    const finalFee = ["non-negotiable", "negotiable"];

    const timeHour: any = ref(Math.floor(fv.duration.val / 60));
    const timeMin: any = ref(fv.duration.val % 60);

    const minList = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

    const reqFeedback = ref("");
    const reqStatus = ref("ready");
    const reqFormStatus = ref(true);

    // Example of a POST request. Note: data
    // can be passed as a raw JS Object (must be JSON serializable)
    const deleteAccountService = async () => {
      const service_id = compCurrentService.value["service_id"]
        ? compCurrentService.value["service_id"]
        : "";

      reqStatus.value = "processing";

      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url: coreUrl.backendUrl + "archive_delete_account_services",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenId,
        },
        data: {
          iv_service_id: service_id,
          iv_request_type: props.postType,
        },
      };

      // const response: HttpResponse = await CapacitorHttp.post(options);

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      if (response.data.status) {
        reqFeedback.value = response.data.message.main_msg;

        let updateAccountService: any = "";
        let serviceIndex: any = -1;

        updateAccountService = compAllAccountDataServices.value;

        for (let i = 0; i < updateAccountService.length; i++) {
          if (
            updateAccountService[i]["service_id"] ===
            compCurrentService.value["service_id"]
          ) {
            serviceIndex = i;
            break;
          }
        }

        if (props.postType === "service_restore") {
          updateAccountService[serviceIndex]["status"] = "active";
        }

        if (props.postType === "service_archive") {
          updateAccountService[serviceIndex]["status"] = "archived";
        }

        if (props.postType === "service_delete") {
          updateAccountService.splice(serviceIndex, 1);
        }

        const payload = { loadAccount: true, services: updateAccountService };
        vueStore.dispatch("account/executeSetAccountServices", payload);

        reqStatus.value = "complete";
      } else if (response.status === 401) {
        await sk.delAllStoreIon();
        // await sk.delVueX()

        window.location.href = "/home";
      } else {
        reqFeedback.value = response.data.message.main_msg;
        reqStatus.value = "ready";
      }
    };

    const runDeleteAccountService = async () => {
      deleteAccountService()
        .then()
        .catch((err) => {
          const response = err
            ? "Temporary server issue"
            : "Temporary server issue";
          feedbackToast(response);
        });
    };

    return {
      compCurrentService,
      compCleanCurrency,
      formatCost,
      finalFee,
      timeHour,
      timeMin,
      minList,
      reqFeedback,
      runDeleteAccountService,
      fv,
      reqStatus,
      reqFormStatus,
    };
  },
});
</script>

<style scoped>
.form-control-error {
  border-color: #eb445a;
}

.no-bottom-radius {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.no-top-radius {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

ion-list {
  --background: #fff;
}

ion-item {
  --background: #fff;
  --color: #111;
  --padding: 0px;

  /* --border-style: dashed; */
  /* --border-width: 2px; */

  /* --border-radius: 20px;

   --ripple-color: purple;

   --detail-icon-color: white;
   --detail-icon-opacity: 1;
   --detail-icon-font-size: 20px; */
}

.homepage-search-block {
  background: #f5f5f5;
}
</style>
