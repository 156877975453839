export default {
  getAuthToken(state) {
    return {
      token: state.sessionData.accessToken,
      expiry: state.sessionData.accessTokenExpiry,
    };
  },
  getOwnerId(state) {
    return state.sessionData.ownerId;
  },
  getSessionData(state) {
    return state.sessionData;
  },
  getLoadAuthState(state) {
    return state.loadAuthState;
  },
};
