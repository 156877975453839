<template>
  <div class="main-page best-selling">
    <ion-refresher
      slot="fixed"
      @ionRefresh="handleRefresh($event)"
      class="mt-5"
    >
      <ion-refresher-content
        :pulling-icon="chevronDownCircleOutline"
        pulling-text="Pull to refresh"
        refreshing-spinner="circles"
        refreshing-text="Refreshing..."
      >
      </ion-refresher-content>
    </ion-refresher>
    <div class="view_slider recommended pt-1">
      <div
        class="container"
        :class="
          isWebBigScreen && feedPage === 'Home'
            ? 'rendez-mid-wide'
            : 'rendez-wide'
        "
      >
        <div class="row">
          <div
            class="col-md-3"
            :class="isWebBigScreen ? 'px-2 mb-3' : 'p-0'"
            v-for="(feed, index) in feedDataList"
            :key="feed + index"
          >
            <a href="#" v-if="feed.media_name === 'none'">
              <img class="img-fluid" src="../../images/list/v1.png" />
            </a>
            <a href="#" v-else>
              <img class="img-fluid" :src="imgBaseFeedUrl + feed.media_name" />
            </a>
            <div class="inner-slider">
              <div class="inner-wrapper">
                <div class="d-flex align-items-center">
                  <span class="seller-image">
                    <img
                      class="img-fluid"
                      :src="
                        imgBaseAvatarUrl +
                        'tr:di-default_avatar.png/' +
                        feed.avatar
                      "
                      alt=""
                    />
                  </span>
                  <span class="seller-name">
                    <router-link
                      :to="
                        feed.owner_id !== ownerId
                          ? '/p/' + feed.username
                          : 'account'
                      "
                    >
                      {{ feed.username }}
                    </router-link>
                    <span class="level hint--top level-one-seller">
                      {{ feed.expertise }}
                    </span>
                  </span>
                </div>
                <h3 style="min-height: 80px; max-height: 80px">
                  <!-- {{ feed['post_brief'] }} -->
                  {{ feed["post_brief"].substring(0, 190) }}
                  {{ feed["post_brief"].length <= 190 ? "" : "... more" }}
                </h3>
                <div class="content-info">
                  <div class="rating-wrapper">
                    <span class="gig-rating text-body-2" style="color: #333">
                      <i class="fa fa-star pr-1"></i>
                      {{ feed.rating_all_avg }}
                      <span
                        >( from {{ feed.rating_client_size }} reviews )</span
                      >
                    </span>
                  </div>
                </div>
                <div class="footer">
                  <div>
                    {{ checkBookmark(feed.post_id, index) }}
                    <ion-icon
                      :icon="bookmarks"
                      class="mr-3 pointer-cursor"
                      v-if="feedDataList[index]['status'] === 'bookmarked'"
                      @click="removeFromBookmark(feed.post_id, index)"
                    >
                    </ion-icon>
                    <ion-icon
                      :icon="bookmarksOutline"
                      class="mr-3 pointer-cursor"
                      v-if="feedDataList[index]['status'] !== 'bookmarked'"
                      @click="addToBookmark(feed.post_id, index)"
                    >
                    </ion-icon>
                    <ion-icon
                      class="mr-3 pointer-cursor"
                      :icon="shareSocialOutline"
                      @click="shareContent"
                    >
                    </ion-icon>
                    <ion-icon
                      class="pointer-cursor"
                      :icon="flagOutline"
                      v-if="feed.owner_id !== ownerId"
                      @click="openFeedActionModal(index, 'report')"
                    >
                    </ion-icon>
                  </div>
                  <div
                    class="price pointer-cursor"
                    @click="
                      feed.owner_id !== ownerId
                        ? openFeedActionModal(index, 'book')
                        : openFeedActionModal(index, 'delete')
                    "
                  >
                    {{
                      feed.owner_id !== ownerId
                        ? "Book appointment"
                        : "Delete post"
                    }}
                  </div>
                  <!-- <div class="price pointer-cursor"  @click="goToSignin" v-else>
                                             Book appointment
                                          </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, inject, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import storeKeeper from "@/hooks/RendezStorage";
import { CapacitorHttp } from "@capacitor/core";
import urls from "@/hooks/urlConfig";
import { Share } from "@capacitor/share";

import {
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  toastController,
  modalController,
} from "@ionic/vue";
import {
  shareSocialOutline,
  flagOutline,
  bookmarks,
  bookmarksOutline,
  chevronDownCircleOutline,
} from "ionicons/icons";
import FeedBooking from "@/components/feed/FeedBooking.vue";
import FeedDeletePost from "@/components/feed/FeedDeletePost.vue";
import FeedSharePost from "@/components/feed/FeedSharePost.vue";
import ReportBlockAccount from "@/components/report/ReportBlockAccount.vue";

export default defineComponent({
  name: "FeedPage",
  components: {
    IonIcon,
    IonRefresher,
    IonRefresherContent,
  },
  props: {
    feedLimit: {
      required: true,
      type: Number,
      default: 0,
    },
    feedType: {
      required: true,
      type: String,
      default: "all",
    },
    feedPage: {
      required: true,
      type: String,
      default: "Home",
    },
    accountId: {
      required: true,
      type: String,
      default: "none",
    },
  },
  setup(props) {
    const sk = storeKeeper();

    //get the urls
    const coreUrl = urls.coreUrl;

    const imgBaseFeedUrl = coreUrl.imgFeedHost;
    const imgBaseAvatarUrl = ref(coreUrl.imgAvatarHost);

    const isWebBigScreen = ref(inject("isWebBigScreen"));

    // get the global route object
    const router = useRouter();

    // get the global vuex store objects
    const vueStore = useStore();

    const modalList = {
      book: FeedBooking,
      report: ReportBlockAccount,
      delete: FeedDeletePost,
      share: FeedSharePost,
    };

    async function openFeedActionModal(index: any, popup: any) {
      const modal = await modalController.create({
        component: modalList[popup],
        mode: "ios",
        cssClass: !isWebBigScreen.value
          ? ""
          : popup === "delete" || popup === "share"
          ? ""
          : "large-xlg",
        componentProps: {
          currentProvider: compFeedData.value[index],
        },
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if (
        (data === "archive" || data === "delete") &&
        role === "confirm" &&
        popup === "delete"
      ) {
        const currFeedData = compFeedData.value;
        currFeedData.splice(index, 1);

        const payload = { data: currFeedData, action: "replace" };

        vueStore.dispatch("feed/executeSetFeedData", payload);
      }
    }

    // Example of a GET request
    const getFeedPosts = async () => {
      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url: coreUrl.backendUrl + "get_feed_posts",
        headers: {
          Authorization: "Bearer " + tokenId,
          "Content-Type": "application/json",
        },
      };

      // const response: HttpResponse = await CapacitorHttp.get(options);

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "GET",
      });

      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.push("home");
      }

      if (response.data.status) {
        const payload = { data: response.data.data, action: "replace" };

        vueStore.dispatch("feed/executeSetFeedData", payload);
      }
    };

    getFeedPosts()
      .then()
      .catch((err) => {
        err ? "Temporary server issue" : "Temporary server issue";
        feedbackToast(err);
      });

    const ownerId: any = ref(null);

    const sessionData: any = ref(null);

    const getSessionData = async () => {
      const tempData = await sk.getStoreIon("session_data");

      sessionData.value = tempData ? JSON.parse(tempData) : false;

      ownerId.value = tempData ? JSON.parse(tempData)["ownerId"] : false;
    };

    getSessionData();

    const compCanAccess = computed(function () {
      if (sessionData.value) {
        return sessionData.value["isAuthenticated"] ? true : false;
      } else {
        return false;
      }
    });

    function goToSignin() {
      window.location.href = "/sign_in";
    }

    const compFeedData = computed(function () {
      const feedData = vueStore.getters["feed/getFeedData"];

      return feedData;
    });

    const feedDataList: any = ref([]);

    watch(
      () => compFeedData.value,
      (currentValue, _oldValue) => {
        let limit = props.feedLimit;

        if (limit > 0) {
          const dataLength = currentValue.length;
          const excess = dataLength % 4;
          limit =
            excess > 0 && dataLength < limit ? dataLength - excess : limit;
        }

        feedDataList.value =
          limit === 0 ? currentValue : currentValue.slice(0, limit);
      }
    );

    const compGetBookmarkData = computed(function () {
      const bookmarks = vueStore.getters["fav/getFavBookmarks"];
      return bookmarks;
    });

    function checkBookmark(post_id: string, index: number) {
      const bookmarkList = compGetBookmarkData.value.filter(
        (item) => item["bookmark_value"] === post_id
      );

      if (bookmarkList.length > 0) {
        feedDataList.value[index]["status"] = "bookmarked";
      }
    }

    const reqStatus = ref("ready");

    // add to bookmark
    const addToBookmark = async (post_id: string, index: number) => {
      reqStatus.value = "processing";

      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url: coreUrl.backendUrl + "add_new_bookmark",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenId,
        },
        data: {
          iv_bookmark_value: post_id,
          iv_bookmark_type: "post",
        },
      };

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.replace("home");
      }

      if (response.data.status) {
        feedDataList.value[index]["status"] = "bookmarked";
        const bookmark_payload = {
          loadFav: true,
          data: { storage: "append", info: response.data.data.bookmark_info },
        };
        await vueStore.dispatch("fav/executeSetFavBookmarks", bookmark_payload);
        feedbackToast("Post was successfully added to your bookmarks");
        reqStatus.value = "ready";
      } else {
        reqStatus.value = "ready";
      }
    };

    // remove post id from bookmarks
    const removeFromBookmark = async (post_id: string, index: number) => {
      reqStatus.value = "processing";

      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url: coreUrl.backendUrl + "remove_existing_bookmark",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenId,
        },
        data: {
          iv_bookmark_value: post_id,
          iv_bookmark_type: "post",
        },
      };

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.replace("home");
      }

      if (response.data.status) {
        const existingBookmarks = compGetBookmarkData.value;

        for (let i = 0; i <= existingBookmarks.length; i++) {
          if (existingBookmarks[i]["bookmark_value"] === post_id) {
            feedDataList.value[index]["status"] = "active";
            existingBookmarks.splice(i, 1);

            const bookmark_payload = {
              loadFav: true,
              data: { storage: "replace", info: existingBookmarks },
            };
            await vueStore.dispatch(
              "fav/executeSetFavBookmarks",
              bookmark_payload
            );

            break;
          }
        }

        reqStatus.value = "ready";

        feedbackToast("Bookmark was successfully remove from your post");
      } else {
        reqStatus.value = "ready";
      }
    };

    // feedback toast controller
    async function feedbackToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 2500,
        position: "bottom",
      });

      await toast.present();
    }

    const handleRefresh = (event) => {
      setTimeout(() => {
        getFeedPosts();
        event.target.complete();
      }, 2000);
    };

    async function shareContent() {
      const isShareContent = await Share.canShare();

      if (isShareContent.value) {
        await Share.share({
          title: "See cool stuff",
          text: "Really awesome thing you need to see right now",
          url: "https://web.rendezapp.com/",
          dialogTitle: "Share with buddies",
        });
      }
    }

    return {
      chevronDownCircleOutline,
      handleRefresh,
      shareContent,
      imgBaseFeedUrl,
      imgBaseAvatarUrl,
      isWebBigScreen,
      addToBookmark,
      removeFromBookmark,
      checkBookmark,
      compFeedData,
      feedDataList,
      shareSocialOutline,
      flagOutline,
      bookmarks,
      bookmarksOutline,
      openFeedActionModal,
      ownerId,
      compCanAccess,
      goToSignin,
    };
  },
});
</script>

<style scoped>
ion-icon {
  font-size: 16px;
}

ion-segment-button {
  --background: #f5f5f5;
  --indicator-color: #666;
  --border-width: 0px 0px 0px 0px;
}

ion-segment {
  --background: #f5f5f5;
  --border-width: 0px 0px 0px 0px;
}

.seller-profile {
  border-width: 0px;
  margin: 0px;
  padding: 0px;
  box-shadow: 0;
}
</style>
