<template>
  <div class="container">
    <div
      class="row justify-content-center align-items-center"
      :class="actionType === 'onboarding' ? 'vh-100' : 'mt-1'"
    >
      <div
        class="mx-auto"
        :class="actionType === 'onboarding' ? 'col-4' : 'col-12'"
      >
        <div
          class="text-center osahan-login pt-4"
          v-if="actionType === 'onboarding'"
        >
          <a href="/home"><img src="../../../images/main/logo.png" alt="" /></a>
          <h5 class="font-weight-bold mt-3" v-if="reqFormStatus">
            Create basic information
          </h5>
          <p class="text-muted mt-2">Step <b>2</b> of <b>2</b></p>
        </div>

        <div class="osahan-login mt-4 py-4" v-if="reqFormStatus">
          <div class="form-group mb-2">
            <ion-label class="mb-1">
              <b>Languages</b>
              <ion-note
                ><span v-if="fv.languages.msg">
                  | {{ fv.languages.msg }}</span
                ></ion-note
              >
            </ion-label>
            <div class="position-relative icon-form-control mt-2">
              <ion-chip
                class="p-2 mt-1 mr-1"
                v-for="(lang, index) in serviceLanguages"
                :key="lang.value + index"
                @click="removeLanguage(index)"
                :color="lang.active ? 'primary' : 'light'"
              >
                <ion-label>{{ lang.alias }}</ion-label>
                <ion-icon
                  :icon="lang.active ? closeCircle : addCircle"
                ></ion-icon>
              </ion-chip>
            </div>
          </div>

          <div class="form-group my-2 pt-4">
            <ion-label class="mb-1">
              <b>Technical skills</b>
              <ion-note
                ><span v-if="fv.skills.msg">
                  | {{ fv.skills.msg }}</span
                ></ion-note
              >
            </ion-label>
            <div class="position-relative icon-form-control mt-2">
              <i class="position-absolute fa fa-phone fa-fw"> </i>
              <input
                type="text"
                class="form-control"
                required
                placeholder=" Seperate new skill with comma or the enter button "
                v-model.trim="newSkill"
                @keyup="addSkillByComma"
                @keyup.enter="addSkillByClick"
              />
            </div>

            <ion-chip
              class="p-2 mt-3 mr-1"
              v-for="(skill, index) in skillList"
              :key="skill + index"
              @click="removeSkill(index)"
              color="primary"
            >
              <ion-label>{{ skill }}</ion-label>
              <ion-icon :icon="closeCircle"></ion-icon>
            </ion-chip>
          </div>

          <ion-note>{{ reqFeedback }}</ion-note>
          <div v-if="reqStatus === 'ready'">
            <button
              class="btn btn-success btn-block text-uppercase"
              @click="runUpdateCredentials"
              type="button"
            >
              Save skills update
            </button>
          </div>
          <ion-spinner
            name="circles"
            class="mb-3 ml-2"
            v-if="reqStatus === 'processing'"
          ></ion-spinner>
        </div>

        <div class="container" v-else>
          <img
            class="img-fluid"
            src="../../../images/main/profile.svg"
            alt=""
          />
          <div class="text-center pt-2">
            <h5 class="font-weight-bold mt-3">
              Basic info successfully updated !!!
            </h5>
            <p class="text-muted">
              Well done, you are all set to start accepting clients.
            </p>

            <ion-button
              @click="routeBasicInfo"
              class="mt-4"
              expand="block"
              color="primary"
              fill="outline"
              >Got to dashboard</ion-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  IonNote,
  IonIcon,
  IonChip,
  IonLabel,
  IonSpinner,
  IonButton,
} from "@ionic/vue";
import { closeCircle, addCircle } from "ionicons/icons";
import { defineComponent, reactive, ref, inject, computed } from "vue";
import { useStore } from "vuex";
import storeKeeper from "@/hooks/RendezStorage";
import { CapacitorHttp } from "@capacitor/core";
import urls from "@/hooks/urlConfig";
import validators from "@/hooks/validation";

export default defineComponent({
  name: "CreateOnboardServiceProvider",
  components: {
    IonNote,
    IonIcon,
    IonChip,
    IonLabel,
    IonSpinner,
    IonButton,
  },
  props: {
    actionType: {
      required: true,
      default: "settings",
    },
  },
  setup() {
    const sk = storeKeeper();

    //get the urls
    const coreUrl = urls.coreUrl;

    // get the global vuex store objects
    const vueStore = useStore();

    // get the validators
    const validate = validators();

    // get feedback toast function
    const feedbackToast: any = inject("feedbackToast");

    const accountDataInfo = ref(vueStore.getters["account/getAccountData"]);

    console.log(accountDataInfo.value);

    // const compCheckAction = computed(function(){
    //     const accountData = accountDataInfo.value
    //     return accountData && props.actionType == 'settings' ? true:false
    // })

    const newSkill = ref("");
    const skillList: any = ref(
      accountDataInfo.value && accountDataInfo.value["skills"]
        ? JSON.parse(accountDataInfo.value["skills"])
        : []
    );

    function addSkillByClick() {
      if (newSkill.value) {
        skillList.value.push(newSkill.value);
        newSkill.value = "";
      }
    }

    function addSkillByComma() {
      if (newSkill.value.search(",") > -1) {
        const tempTag = newSkill.value;
        newSkill.value = "";
        skillList.value.push(tempTag.replace(",", ""));
      }
    }

    function removeSkill(index) {
      skillList.value.splice(index, 1);
    }

    const serviceLanguages = ref([
      {
        value: "english",
        alias: "English",
        active: accountDataInfo.value["languages"]
          ? JSON.parse(accountDataInfo.value["languages"]).includes("english")
          : false,
      },
      {
        value: "french",
        alias: "French",
        active: accountDataInfo.value["languages"]
          ? JSON.parse(accountDataInfo.value["languages"]).includes("french")
          : false,
      },
      {
        value: "spanish",
        alias: "Spanish",
        active: accountDataInfo.value["languages"]
          ? JSON.parse(accountDataInfo.value["languages"]).includes("spanish")
          : false,
      },
      {
        value: "arabic",
        alias: "Arabic",
        active: accountDataInfo.value["languages"]
          ? JSON.parse(accountDataInfo.value["languages"]).includes("arabic")
          : false,
      },
    ]);

    const compServiceLanguages = computed(function () {
      return serviceLanguages.value.filter((item) => item.active === false);
    });

    const compSelectedServiceLanguages = computed(function () {
      return serviceLanguages.value.filter((item) => item.active === true);
    });

    const languageIndex: any = ref(false);
    function addLanguage() {
      if (languageIndex.value) {
        const currentIndex = parseInt(languageIndex.value);
        serviceLanguages.value[currentIndex]["active"] =
          !serviceLanguages.value[currentIndex]["active"];
      }
    }

    function removeLanguage(index: any) {
      serviceLanguages.value[index]["active"] =
        !serviceLanguages.value[index]["active"];
    }

    // reference form variables validation in main doc
    const fv: any = reactive({
      languages: {
        status: false,
        val: accountDataInfo.value ? accountDataInfo.value["languages"] : "",
        msg: false,
        validate: ["required"],
      },
      skills: {
        status: false,
        val: accountDataInfo.value ? accountDataInfo.value["skills"] : [],
        msg: false,
        validate: ["required"],
      },
      showSubmit: { status: false, val: true },
    });

    const reqFeedback = ref("");
    const reqStatus = ref("ready");
    const reqFormStatus = ref(true);

    const sessionData: any = ref(null);

    async function getCurrentId() {
      const newSessionData = await sk.getStoreIon("session_data");
      sessionData.value = await JSON.parse(newSessionData);
    }

    getCurrentId();

    // Example of a POST request. Note: data
    // can be passed as a raw JS Object (must be JSON serializable)
    const updateCredentials = async () => {
      const tokenId = await sk.getStoreIon("access_token");

      reqStatus.value = "processing";

      const newLanguages: any = [];

      await serviceLanguages.value.filter(function (item) {
        if (item.active) {
          newLanguages.push(item.value);
        }
        return false;
      });

      fv.languages.val = await [...new Set(newLanguages)];
      fv.skills.val = await [...new Set(skillList.value)];

      const options = {
        url: coreUrl.backendUrl + "update_account_skills",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenId,
        },
        data: {
          iv_languages: fv.languages.val,
          iv_skills: fv.skills.val,
        },
      };

      // const response: HttpResponse = await CapacitorHttp.post(options);

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      async function runStorage() {
        try {
          accountDataInfo.value["languages"] = JSON.stringify(fv.languages.val);
          accountDataInfo.value["skills"] = JSON.stringify(fv.skills.val);

          // save data in vue x for faster state management
          const payload = { loadAuth: true, data: accountDataInfo.value };
          await vueStore.dispatch("account/executeSetAccountData", payload);
        } catch (e) {
          console.log(e);
        }
      }

      if (response.data.status) {
        reqFeedback.value = response.data.message.main_msg;
        reqStatus.value = "complete";
        runStorage();
      } else if (response.status === 401) {
        // const payload = { loadAuth:false, data:response.data.data.sessionData}
        // await vueStore.dispatch("auth/executeSetSessionData", payload);

        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        window.location.href = "/signin";
      } else {
        const iv_msg: any = response.data.message.iv_msg;
        reqFeedback.value = response.data.message.main_msg;

        if (iv_msg) {
          for (const key in iv_msg) {
            if (fv[key]) {
              fv[key]["status"] = false;
              fv[key]["msg"] = iv_msg[key];
            }
          }
        }

        reqStatus.value = "ready";
      }
    };

    const runUpdateCredentials = async () => {
      updateCredentials()
        .then()
        .catch((err) => {
          const response = err
            ? "Temporary server issue"
            : "Temporary server issue";
          feedbackToast(response);
        });
    };

    return {
      addCircle,
      closeCircle,
      reqFeedback,
      runUpdateCredentials,
      validate,
      fv,
      reqStatus,
      reqFormStatus,
      compServiceLanguages,
      compSelectedServiceLanguages,
      serviceLanguages,
      languageIndex,
      addLanguage,
      removeLanguage,
      newSkill,
      skillList,
      addSkillByClick,
      addSkillByComma,
      removeSkill,
    };
  },
});
</script>

<style scoped>
ion-list {
  --background: #fff;
}

ion-item {
  --background: #fff;
  --color: #111;
  --padding: 0px;

  /* --border-style: dashed; */
  /* --border-width: 2px; */

  /* --border-radius: 20px;

   --ripple-color: purple;

   --detail-icon-color: white;
   --detail-icon-opacity: 1;
   --detail-icon-font-size: 20px; */
}

.homepage-search-block {
  background: #f5f5f5;
}
</style>
