<template>
  <div class="container">
    <div class="row justify-content-center align-items-center mt-1">
      <div class="mx-auto col-12">
        <div class="osahan-login mt-2" v-if="reqFormStatus">
          <div class="form-group my-2 pt-4">
            <ion-label class="mb-1">
              <b>Policies or perk</b>
              <ion-note
                ><span v-if="fv.policy_perks.msg">
                  | {{ fv.policy_perks.msg }}</span
                ></ion-note
              >
            </ion-label>
            <div class="position-relative icon-form-control mt-2">
              <i
                class="position-absolute fa fa-plus fa-fw"
                @click="addperkByClick"
              >
              </i>
              <input
                type="text"
                class="form-control"
                required
                placeholder=" Enter a new perk and hit enter the enter button "
                v-model.trim="newperk"
                @keyup.enter="addperkByClick"
              />
            </div>

            <ion-list
              :inset="true"
              color="light"
              class="mx-0 my-2 px-0"
              lines="full"
            >
              <ion-item
                class="pt-1"
                v-for="(perk, index) in compPerksList"
                :key="perk + index"
              >
                <ion-label>{{ perk }}</ion-label>
                <ion-icon
                  slot="end"
                  :icon="closeCircle"
                  size="small"
                  @click="removeperk(index)"
                  v-if="reqStatus === 'ready'"
                ></ion-icon>
              </ion-item>
            </ion-list>
          </div>

          <ion-note class="mt-2">{{ reqFeedback }}</ion-note>
          <div v-if="reqStatus === 'ready'">
            <button
              class="btn btn-success btn-block text-uppercase mb-4"
              @click="runUpdatePolicyPerks"
              type="button"
            >
              Update policy / perks
            </button>
          </div>
          <ion-spinner
            name="circles"
            class="mb-3 ml-2"
            v-if="reqStatus === 'processing'"
          ></ion-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  IonNote,
  IonIcon,
  IonLabel,
  IonSpinner,
  IonItem,
  IonList,
} from "@ionic/vue";
import { closeCircle, addCircle } from "ionicons/icons";
import { defineComponent, reactive, ref, inject, computed, watch } from "vue";
import { useStore } from "vuex";
import storeKeeper from "@/hooks/RendezStorage";
import { CapacitorHttp } from "@capacitor/core";
import urls from "@/hooks/urlConfig";
import validators from "@/hooks/validation";

export default defineComponent({
  name: "CreateAccountUpdateRules",
  components: {
    IonNote,
    IonIcon,
    IonLabel,
    IonSpinner,
    IonItem,
    IonList,
  },
  props: {
    samplePolicyPerksList: {
      required: true,
      default: [],
    },
  },
  emits: ["emitToggleFooterVisibily"],
  setup(props, { emit }) {
    const sk = storeKeeper();

    //get the urls
    const coreUrl = urls.coreUrl;

    // get the global vuex store objects
    const vueStore = useStore();

    // get the validators
    const validate = validators();

    // get feedback toast function
    const feedbackToast: any = inject("feedbackToast");

    const accountDataInfo = ref(vueStore.getters["account/getAccountData"]);

    // const compCheckAction = computed(function(){
    //     const accountData = accountDataInfo.value
    //     return accountData && props.actionType == 'settings' ? true:false
    // })

    const newperk = ref("");
    const perkList: any = ref(
      accountDataInfo.value && accountDataInfo.value["policy_perks"]
        ? JSON.parse(accountDataInfo.value["policy_perks"])
        : []
    );

    const compPerksList = computed(function () {
      const finalList = perkList.value;
      const sampleList = props.samplePolicyPerksList;
      return finalList.concat(sampleList);
    });

    watch(
      () => props.samplePolicyPerksList,
      async (newValue, _oldValue) => {
        if (newValue.length === 0) {
          perkList.value = [];
        }

        if (newValue.length > 0) {
          perkList.value = newValue;
        }
      }
    );

    function addperkByClick() {
      if (newperk.value) {
        perkList.value.unshift(newperk.value);
        newperk.value = "";
      }
    }

    function removeperk(index) {
      perkList.value.splice(index, 1);
    }

    // reference form variables validation in main doc
    const fv: any = reactive({
      policy_perks: {
        status: false,
        val: accountDataInfo.value ? accountDataInfo.value["policy_perks"] : [],
        msg: false,
        validate: ["required"],
      },
      showSubmit: { status: false, val: true },
    });

    const reqFeedback = ref("");
    const reqStatus = ref("ready");
    const reqFormStatus = ref(true);

    const sessionData: any = ref(null);

    async function getCurrentId() {
      const newSessionData = await sk.getStoreIon("session_data");
      sessionData.value = await JSON.parse(newSessionData);
    }

    getCurrentId();

    // Example of a POST request. Note: data
    // can be passed as a raw JS Object (must be JSON serializable)
    const updatePolicyPerks = async () => {
      const tokenId = await sk.getStoreIon("access_token");

      reqStatus.value = "processing";

      fv.policy_perks.val = await [...new Set(perkList.value)];

      const options = {
        url: coreUrl.backendUrl + "update_account_policy_perks",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenId,
        },
        data: {
          iv_policy_perks: fv.policy_perks.val,
        },
      };

      // const response: HttpResponse = await CapacitorHttp.post(options);

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      async function runStorage() {
        try {
          accountDataInfo.value["policy_perks"] = JSON.stringify(
            fv.policy_perks.val
          );

          // save data in vue x for faster state management
          const payload = { loadAuth: true, data: accountDataInfo.value };
          await vueStore.dispatch("account/executeSetAccountData", payload);
          console.log(accountDataInfo.value);
        } catch (e) {
          console.log(e);
        }
      }

      if (response.data.status) {
        reqFeedback.value = response.data.message.main_msg;
        reqStatus.value = "complete";
        runStorage();
        emit("emitToggleFooterVisibily");
      } else if (response.status === 401) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        window.location.href = "/signin";
      } else {
        const iv_msg: any = response.data.message.iv_msg;
        reqFeedback.value = response.data.message.main_msg;

        if (iv_msg) {
          for (const key in iv_msg) {
            if (fv[key]) {
              fv[key]["status"] = false;
              fv[key]["msg"] = iv_msg[key];
            }
          }
        }

        reqStatus.value = "ready";
      }
    };

    const runUpdatePolicyPerks = async () => {
      updatePolicyPerks()
        .then()
        .catch((err) => {
          const response = err
            ? "Temporary server issue"
            : "Temporary server issue";
          feedbackToast(response);
        });
    };

    return {
      addCircle,
      closeCircle,
      reqFeedback,
      runUpdatePolicyPerks,
      validate,
      fv,
      reqStatus,
      reqFormStatus,
      newperk,
      compPerksList,
      addperkByClick,
      removeperk,
    };
  },
});
</script>

<style scoped>
ion-item {
  --border-color: #ccc;
}
.homepage-search-block {
  background: #f5f5f5;
}

.item-complex .item-content,
.item {
  background-color: #f1f1f1;
}

ion-item-background {
  background-color: rgba(0, 0, 0, 0);
}
</style>
