<template>
  <ion-app>
    <main-header
      :pageTitle="urlPath.replace('_', ' ')"
      :urlPath="urlPath"
      v-if="isActiveHeaderNav && isWebBigScreen"
    ></main-header>
    <div class="containers page-bg-beige">
      <div class="main-page second pb-5">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-3 left pt-3 mt-3 pr-0 pl-0 sticky-left d-none d-lg-block d-xl-block d-md-block"
              v-if="
                isWebBigScreen && compIsServiceProvider && isActiveSidebarNav
              "
            >
              <account-provider-account-profile-desktop-menu
                linkHeader=""
                :linkList="linkList"
                iconSize="sidebar-icon-normal"
              ></account-provider-account-profile-desktop-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ion-router-outlet :key="compUrlPath" :animated="true" />
    <ion-modal
      :is-open="
        isOpen &&
        compBookingList['alertSize'] > 0 &&
        urlPath !== 'schedule_manager' &&
        urlPath !== 'search'
      "
      :backdropDismiss="false"
      :breakpoints="[0.8, 1]"
      :initialBreakpoint="1"
    >
      <ion-header class="ion-no-border">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button
              @click="toggleOpen()"
              v-if="compBookingList['activeAlert'] === 'general'"
            >
              <span
                class="material-symbols-rounded sidebar-icon-normal pointer-cursor"
                >close
              </span>
            </ion-button>
          </ion-buttons>
          <ion-title
            :class="compBookingList['activeAlert'] === 'general' ? '' : 'pl-4'"
          >
            {{ compBookingList["alertTitle"] }}
          </ion-title>
        </ion-toolbar>
        <ion-toolbar v-if="compBookingList['activeAlert'] === 'general'">
          <ion-segment
            :value="compCurrentTab"
            mode="md"
            @ionChange="switchTab($event)"
          >
            <ion-segment-button value="pending_requests" mode="md">
              <ion-label color="dark">
                Requests

                <ion-badge
                  color="dark"
                  class="p-2 tab-white-text"
                  mode="ios"
                  v-if="
                    compBookingList['alertOptions']['pending_requests']['data']
                      .length > 0
                  "
                >
                  {{
                    compBookingList["alertOptions"]["pending_requests"]["data"]
                      .length
                  }}
                </ion-badge>
              </ion-label>
            </ion-segment-button>
            <ion-segment-button value="overdue_appointments" mode="md">
              <ion-label color="dark">
                Overdue
                <ion-badge
                  class="p-2 tab-white-text"
                  mode="ios"
                  color="dark"
                  v-if="
                    compBookingList['alertOptions']['overdue_appointments'][
                      'data'
                    ].length > 0
                  "
                >
                  {{
                    compBookingList["alertOptions"]["overdue_appointments"][
                      "data"
                    ].length
                  }}
                </ion-badge>
              </ion-label>
            </ion-segment-button>
            <ion-segment-button value="pending_review" mode="md">
              <ion-label color="dark">
                Rating
                <ion-badge
                  class="p-2 tab-white-text"
                  mode="ios"
                  color="dark"
                  v-if="
                    compBookingList['alertOptions']['pending_review']['data']
                      .length > 0
                  "
                >
                  {{
                    compBookingList["alertOptions"]["pending_review"]["data"]
                      .length
                  }}
                </ion-badge>
              </ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-no-padding">
        <comp-appointment-manage-requests
          appointmentInfo="ongoing_appointments"
          filterAppointmentInfo="ongoing"
          bookingInfoTab="order_and_request"
          :scheduleSearchValue="''"
          viewType="modal"
          v-if="compCurrentTab === 'ongoing_appointments'"
        ></comp-appointment-manage-requests>

        <comp-appointment-manage-requests
          appointmentInfo="pending_requests"
          filterAppointmentInfo="pending"
          bookingInfoTab="order_and_request"
          :scheduleSearchValue="''"
          viewType="modal"
          v-if="compCurrentTab === 'pending_requests'"
        ></comp-appointment-manage-requests>

        <comp-appointment-manage-requests
          appointmentInfo="overdue_appointments"
          filterAppointmentInfo="accepted"
          bookingInfoTab="order_and_request"
          :scheduleSearchValue="''"
          viewType="modal"
          v-if="compCurrentTab === 'overdue_appointments'"
        ></comp-appointment-manage-requests>

        <comp-appointment-manage-requests
          appointmentInfo="pending_review"
          filterAppointmentInfo="completed"
          bookingInfoTab="request"
          :scheduleSearchValue="''"
          viewType="modal"
          v-if="compCurrentTab === 'pending_review'"
        ></comp-appointment-manage-requests>
      </ion-content>
      <ion-footer
        class="ion-no-border"
        v-if="compCurrentTab === 'ongoing_appointments'"
      >
        <ion-toolbar>
          <ion-label class="pl-3">
            This pop-up will remain active until the ongoing session is
            over*</ion-label
          >
        </ion-toolbar>
      </ion-footer>
    </ion-modal>
  </ion-app>
</template>

<script lang="ts">
import {
  IonApp,
  IonRouterOutlet,
  IonHeader,
  IonFooter,
  IonToolbar,
  IonContent,
  IonTitle,
  IonLabel,
  IonButtons,
  IonButton,
  IonModal,
  IonSegment,
  IonSegmentButton,
  IonBadge,
} from "@ionic/vue";
import { defineComponent, ref, computed, watch, inject, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import storeKeeper from "@/hooks/RendezStorage";
import coreSockets from "@/hooks/sockEvents";
import { CapacitorHttp } from "@capacitor/core";
import urls from "@/hooks/urlConfig";
import AccountProviderAccountProfileDesktopMenu from "@/components/account/AccountProviderAccountProfileDesktopMenu.vue";
import CompAppointmentManageRequests from "@/views/appointment/CompAppointmentManageRequests.vue";

import { PushNotifications } from "@capacitor/push-notifications";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonHeader,
    IonFooter,
    IonToolbar,
    IonContent,
    IonTitle,
    IonLabel,
    IonButtons,
    IonButton,
    IonModal,
    IonSegment,
    IonSegmentButton,
    IonBadge,
    AccountProviderAccountProfileDesktopMenu,
    CompAppointmentManageRequests,
  },
  setup() {
    const route = ref(useRoute());

    // get the global vuex store objects
    const vueStore = useStore();

    //get the urls
    const coreUrl = urls.coreUrl;

    // initiate the sockets config for socket functions
    const sock = coreSockets();

    // Initiate the local storage
    const sk = storeKeeper();

    const isWebBigScreen = ref(inject("isWebBigScreen"));
    const isNativePlatform = ref(inject("isNativePlatform"));

    // get feedback toast function
    const feedbackToast: any = inject("feedbackToast");

    const compSessionData = computed(function () {
      const sessionData = vueStore.getters["auth/getSessionData"];
      return sessionData;
    });

    const compIsServiceProvider = computed(function () {
      const authenticate = compSessionData.value
        ? compSessionData.value["serviceProvider"]
        : false;
      return authenticate;
    });

    const registerNotifications = async () => {
      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === "prompt") {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== "granted") {
        throw new Error("User denied permissions!");
      }

      await PushNotifications.register();
    };

    const addListeners = async () => {
      await PushNotifications.addListener("registration", (token) => {
        sk.setStoreIon("fcm_token", token.value);
      });

      await PushNotifications.addListener("registrationError", (err) => {
        console.error("Registration error: ", err.error);
      });

      await PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => {
          console.log("Push notification received: ", notification);
        }
      );

      await PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification) => {
          console.log(
            "Push notification action performed",
            notification.actionId,
            notification.inputValue
          );
        }
      );
    };

    onMounted(() => {
      // Check if user is logged in and entitled to alerts before loading the function
      if (isNativePlatform.value) {
        registerNotifications();
        addListeners();
      }
    });

    const noHeaderPaths = ref([
      "sign_up",
      "sign_in",
      "forgot_password",
      "onboard_username",
      "onboard_service_provider",
      "forgot_password",
      "reset_password",
      "confirm_account",
      "search",
      "mobile_home",
      "delete_account",
      "my_account",
      "my_profile",
      "p",
      "guest_client",
    ]);

    const showSidebar = ref([
      "my_account",
      "my_services",
      "my_ratings",
      "my_posts",
      "my_circle",
      "my_trusted_brands",
      "my_bookmarks",
      "my_settings",
    ]);

    const urlPath = ref(route.value.path.replace("/", "").split("/")[0]);

    const isActiveSidebarNav = ref(
      showSidebar.value.includes(urlPath.value) ? true : false
    );

    const isActiveHeaderNav = ref(
      noHeaderPaths.value.includes(urlPath.value) ? false : true
    );

    isActiveHeaderNav.value =
      isWebBigScreen.value && ["my_account"].includes(urlPath.value)
        ? true
        : isActiveHeaderNav.value;

    watch(
      () => route.value.path.replace("/", "").split("/")[0],
      (currentValue, oldValue) => {
        isActiveSidebarNav.value = showSidebar.value.includes(currentValue)
          ? true
          : false;

        isActiveHeaderNav.value = noHeaderPaths.value.includes(currentValue)
          ? false
          : true;

        isActiveHeaderNav.value =
          isWebBigScreen.value && ["my_account"].includes(currentValue)
            ? true
            : isActiveHeaderNav.value;

        urlPath.value = currentValue !== oldValue ? currentValue : oldValue;

        if (currentValue !== oldValue) {
          for (let i = 0; i < linkList.value.length; i++) {
            if (linkList.value[i]["route"] === currentValue) {
              linkList.value[i]["active"] = true;
            } else {
              linkList.value[i]["active"] = false;
            }
          }
        }
      }
    );

    const compUrlPath = computed(function () {
      const newRoute = route.value;
      return newRoute["fullPath"];
    });

    //get user owner id data if user is active
    const ownerId = compSessionData.value
      ? compSessionData.value["ownerId"]
      : "none";

    // Plug in a socket function to listen for new updates to be recieved across platforms
    /** This socket function focuses on cross-platform email verification update
     */
    sock.socket.on("get_confirm_account_verification", async function (data) {
      const sessionData = vueStore.getters["auth/getSessionData"];

      if (sessionData["isAuthenticated"]) {
        // save data in vue x for faster state management if user is logged in
        const payload = {
          loadAuth: true,
          data: data,
        };
        await vueStore.dispatch("auth/executeSetSessionData", payload);
        // save data in the ionic storage db
        await sk.setStoreIon("session_data", JSON.stringify(data));

        // emit this event to the users channel to impact any active platform
        sock.socket.emit(
          "set_confirm_account_verification",
          sessionData["ownerId"]
        );
      }
    });

    // Plug in a socket function to listen for new notifications
    /** This socket function focuses on notifications , but it fetches accompanying order related data to
     *  allow cer4tain actions to be taken from the notifications page
     */
    sock.socket.on("get_notification", function (data) {
      // Double check if this notifications are for this user, by checking that the id matches the notification_reciever_id
      // of the firsr(or only notification sent via the socket server)
      if (data.notification_data[0].notification_receiver_id === ownerId) {
        // loop through the notifcation data , and save to the vueX db
        let i = 0;

        while (i < data.notification_data.length) {
          const payload_alert = {
            data: data.notification_data[i] ? data.notification_data[i] : [],
            action: "unshift",
          };
          vueStore.dispatch("alerts/executeSetAlertsList", payload_alert);
          i++;
        }
        // end notification update loop

        // check if booking data was sent for update
        const bookingData = data.booking_data ? data.booking_data : false;

        // if booking data exists
        if (data.additional_update.includes("booking") && bookingData) {
          const payload_order = {
            data: bookingData,
            action: "unshift",
          };

          const payload_request = {
            data: bookingData,
            action: "unshift",
          };

          // update the alert vueX DB where receiver is a provider
          if (bookingData.provider_id === ownerId) {
            vueStore.dispatch(
              "bookings/executeSetBookingOrders",
              payload_order
            );
          }

          // update the alert vueX DB where receiver is an owner
          if (bookingData.owner_id === ownerId) {
            vueStore.dispatch(
              "bookings/executeSetBookingRequests",
              payload_request
            );
          }
        }
      }
    });

    const linkList = ref([
      {
        route: "my_account",
        alias: "Account profile",
        icon: "user_attributes",
        active: urlPath.value === "my_account" ? true : false,
      },
      {
        route: "my_services",
        alias: "Services",
        icon: "inventory",
        active: urlPath.value === "my_services" ? true : false,
      },
      {
        route: "my_ratings",
        alias: "Ratings",
        icon: "ballot",
        active: urlPath.value === "my_ratings" ? true : false,
      },
      {
        route: "my_posts",
        alias: "My posts",
        icon: "perm_media",
        active: urlPath.value === "my_posts" ? true : false,
      },
      {
        route: "my_circle",
        alias: "Circle",
        icon: "group_add",
        active: urlPath.value === "my_circle" ? true : false,
      },
      {
        route: "my_trusted_brands",
        alias: "Trusted brands",
        icon: "diversity_1",
        active: urlPath.value === "my_trusted_brands" ? true : false,
      },
      {
        route: "my_bookmarks",
        alias: "Bookmarks",
        icon: "bookmarks",
        active: urlPath.value === "my_bookmarks" ? true : false,
      },
      {
        route: "my_settings",
        alias: "Settings & preferences",
        icon: "manage_accounts",
        active: urlPath.value === "my_settings" ? true : false,
      },
    ]);

    // The function for getting user alerts
    const getUserAlerts = async () => {
      // Get your assigned token id, that will validate the api execution
      const tokenId = await sk.getStoreIon("access_token");

      // set the data required to access the backene
      const options = {
        url: coreUrl.backendUrl + "get_user_alerts",
        headers: {
          Authorization: "Bearer " + tokenId,
          "Content-Type": "application/json",
        },
      };

      // sending the http request to the server
      const response = await CapacitorHttp.request({
        ...options,
        method: "GET",
      });

      // check if we were able to fetch the required data from the server
      // data required  include alert data, booking order and request data
      if (
        response.data.status &&
        response.data.data.alerts_list &&
        response.data.data.bookings_list.order &&
        response.data.data.bookings_list.request
      ) {
        // Set the payload to update necessary VueX db, to update all the components that need the data fetched

        // Update alert VueX storage
        const payload_alert = {
          data: response.data.data.alerts_list
            ? response.data.data.alerts_list
            : [],
          action: "replace",
        };

        // Update order VueX storage
        const payload_order = {
          data: response.data.data.bookings_list.order
            ? response.data.data.bookings_list.order
            : [],
          action: "replace",
        };

        // Update request VueX storage
        const payload_request = {
          data: response.data.data.bookings_list.request
            ? response.data.data.bookings_list.request
            : [],
          action: "replace",
        };

        // Execute the VueX dispatch
        vueStore.dispatch("alerts/executeSetAlertsList", payload_alert);
        vueStore.dispatch("bookings/executeSetBookingOrders", payload_order);
        vueStore.dispatch(
          "bookings/executeSetBookingRequests",
          payload_request
        );
      }
    };

    // check if user can see the post content button
    const compCanAccess = computed(function () {
      if (compSessionData.value) {
        return compSessionData.value["isAuthenticated"] ? true : false;
      } else {
        return false;
      }
    });

    // The onmounted native vie function, waits for all vue components to be mounted t
    // then executes the getuser alerts function in a promise block
    onMounted(() => {
      // Check if user is logged in and entitled to alerts before loading the function
      if (compCanAccess.value) {
        getUserAlerts()
          .then()
          .catch((err) => {
            err ? "Temporary server issue" : "Temporary server issue";
            feedbackToast(err);
          });
      }
    });

    const compOrderList = computed(function () {
      const bookingOrders = vueStore.getters["bookings/getBookingOrders"];

      return bookingOrders;
    });

    const compRequestList = computed(function () {
      const bookingRequests = vueStore.getters["bookings/getBookingRequests"];

      return bookingRequests;
    });

    const compBookingList: any = computed(function () {
      let bookingLists: any = [];
      const orderLists: any = compOrderList.value;
      const requestLists: any = compRequestList.value;

      bookingLists = orderLists.concat(requestLists);

      const alertOptions = {
        ongoing_appointments: {
          status: "ongoing",
          data: [],
        },
        pending_requests: {
          status: "pending",
          data: [],
        },
        overdue_appointments: {
          status: "accepted",
          data: [],
        },
        pending_review: {
          status: "completed",
          data: [],
        },
      };

      alertOptions["ongoing_appointments"]["data"] = bookingLists
        ? bookingLists.filter((item) =>
            item["request_status"] === "ongoing" ? true : false
          )
        : [];

      alertOptions["pending_requests"]["data"] = orderLists
        ? orderLists.filter((item) =>
            item["request_status"] === "pending" ? true : false
          )
        : [];

      alertOptions["overdue_appointments"]["data"] = bookingLists
        ? bookingLists.filter(function (item) {
            const currDate = new Date();
            const existingDate = new Date(item["provider_start_time"]);
            if (
              item["request_status"] === "accepted" &&
              currDate > existingDate
            ) {
              return true;
            }
            return false;
          })
        : [];

      alertOptions["pending_review"]["data"] = bookingLists
        ? bookingLists.filter((item) =>
            item["request_status"] === "completed" &&
            item["complete_status"] !== "completed"
              ? true
              : false
          )
        : [];

      const appointmentAlertPayload = {
        activeAlert:
          alertOptions["ongoing_appointments"]["data"].length > 0
            ? "ongoing"
            : "general",
        alertTitle:
          alertOptions["ongoing_appointments"]["data"].length > 0
            ? "Ongoing sessions"
            : "Appointment notifications",
        alertSize:
          alertOptions["ongoing_appointments"]["data"].length +
          alertOptions["pending_requests"]["data"].length +
          alertOptions["overdue_appointments"]["data"].length +
          alertOptions["pending_review"]["data"].length,
        alertOptions: alertOptions,
      };

      return appointmentAlertPayload;
    });

    watch(
      () => compBookingList.value,
      async (currentValue, _oldValue) => {
        if (currentValue["alertSize"] > 0) {
          isOpen.value = true;
        }

        if (currentValue["activeAlert"] === "ongoing") {
          currentTab.value = "";
        }
      }
    );

    const currentTab = ref("");

    const compCurrentTab = computed(function () {
      let activeTab = currentTab.value;
      const currentBookingList = compBookingList.value;

      if (!activeTab) {
        activeTab =
          currentBookingList["activeAlert"] === "ongoing"
            ? "ongoing_appointments"
            : "pending_requests";
      }
      return activeTab;
    });

    function switchTab(ev: any) {
      currentTab.value = "";
      currentTab.value = ev.detail.value;
    }

    const isOpen = ref(true);

    function toggleOpen() {
      isOpen.value = !isOpen.value;
    }

    return {
      compUrlPath,
      urlPath,
      linkList,
      isActiveHeaderNav,
      isWebBigScreen,
      compIsServiceProvider,
      isActiveSidebarNav,
      isOpen,
      toggleOpen,
      compOrderList,
      compRequestList,
      compBookingList,
      switchTab,
      compCurrentTab,
    };
  },
});
</script>

<style>
.page-bg-beige {
  background-color: #f5f7f7;
  min-height: 100vh;
}

.rendez-wide {
  max-width: 99%;
}

.rendez-mid-wide {
  max-width: 92%;
}

.text-tiny {
  font-size: 9px;
}

.text-small {
  font-size: 12px;
}

.text-italics {
  font-style: italic;
}

.pointer-cursor {
  cursor: pointer;
}

.cap-first-letter {
  text-transform: capitalize;
}

#center-spinner {
  text-align: center;
  position: relative;
  left: 0;
  right: 0;
  top: 50%;
}

.center-spinner {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
}

.primary-color {
  color: #7e00e9;
}

.form-control:focus {
  border-color: #7e00e9;
}

ion-button {
  text-transform: none;
}

.small-details {
  color: #999;
}

.large-text-caption {
  font-size: 58px;
}

.mlarge-text-caption {
  font-size: 50px;
}

ion-radio {
  --size: 18px;
  --background-checked: #7e00e9;
}

ion-radio::part(container) {
  border-radius: 36px;
  border: 2px solid #7e00e9;
}

ion-checkbox::part(container) {
  /* border-radius: 5px; */
  border: 2px solid #7e00e9;
}

.sticky-left {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  left: 0;
  z-index: 51; /* Stay on top */
  overflow-y: scroll; /* Disable horizontal scroll */
  overflow-x: hidden;
  scrollbar-width: none;
  padding-bottom: 50px;
}

ion-label {
  text-transform: none;
}

.summary-text-icon {
  font-size: 15px;
  color: #555;
  vertical-align: bottom;
  margin-bottom: 3px;
}

.tab-white-text {
  color: #fff;
}

.white-text {
  color: #fff;
}

.black-text {
  color: #000;
}

.fc-daygrid-dot-event {
  color: #333333;
}

.fullscreen-content {
  min-height: 100vh;
}

.header-icon {
  font-size: 18px;
}

/* .fc-event-time, .fc-event-title {
padding: 0 1px;
white-space: normal;
} */
</style>
