<template>
  <ion-page class="mt-5">
    <ion-content>
      <!-- Begin Page Content -->
      <section class="py-5 pt-5 mt-3 homepage-search-block position-relative">
        <div
          class="container"
          :class="isWebBigScreen ? 'rendez-mid-wide' : 'rendez-wide'"
        >
          <div class="row py-lg-5">
            <div class="col-lg-6 col-sm-12">
              <div class="homepage-search-title">
                <span
                  class="mb-3 text-shadow text-gray-900"
                  :class="
                    isWebBigScreen
                      ? 'large-text-caption'
                      : 'mlarge-text-caption'
                  "
                >
                  Rendez is the platform for efficiency and productivity
                </span>
                <h5
                  class="mb-5 text-shadow text-gray-800"
                  :class="
                    isWebBigScreen
                      ? 'font-weight-normal '
                      : 'font-weight-light mb-'
                  "
                >
                  Rendez gives you access to amazing service providers, coupled
                  with scheduling capabilities to maximise your time and
                  productivity.
                </h5>
              </div>
              <div class="homepage-search-form">
                <div class="form-row">
                  <div class="col-sm-12 col-lg-6 form-group">
                    <button
                      type="button"
                      class="btn btn-secondary btn-block btn-lg btn-gradient shadow-sm"
                      @click="goToLink('sign_in')"
                    >
                      <i class="fa fa-lock"></i> Sign in
                    </button>
                  </div>
                  <div class="col-sm-12 col-lg-6 form-group">
                    <button
                      type="button"
                      class="btn btn-outline-success btn-block btn-lg btn-gradient shadow-sm"
                      @click="goToLink('sign_up')"
                    >
                      Get started <i class="fa fa-play"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-lg-6 pr-0 mr-0 d-none d-lg-block d-xl-block d-md-block"
            >
              <img class="img-fluid" src="../images/main/home_bg.svg" alt="" />
            </div>
          </div>
        </div>
      </section>

      <ion-segment
        :value="currentTab"
        class="mt-5"
        mode="md"
        @ionChange="switchTab($event)"
      >
        <ion-segment-button value="providers" mode="md">
          <ion-label color="dark">Featured providers</ion-label>
        </ion-segment-button>
        <ion-segment-button value="rendez_brief">
          <ion-label color="dark">Rendez in a nutshell</ion-label>
        </ion-segment-button>
      </ion-segment>

      <hr />

      <!--       recent -->
      <div
        class="main-page best-selling"
        :class="currentTab === 'providers' ? '' : 'd-none'"
      >
        <feed-data-list
          :feedLimit="20"
          feedType="all"
          feedPage="Home"
          accountId="none"
        ></feed-data-list>
      </div>

      <div
        class="main-page best-selling"
        :class="currentTab === 'rendez_brief' ? '' : 'd-none'"
      >
        <!--       services-->
        <!--    about section -->
        <section class="bg-white">
          <div class="container mb-3" :class="isWebBigScreen ? 'py-5' : ''">
            <div class="about-section">
              <div class="row align-items-center">
                <div class="col-md-6">
                  <img
                    src="../images/main/rating.svg"
                    class="video-img w-50"
                    :class="isWebBigScreen ? '' : 'd-none'"
                  />
                  <img
                    src="../images/main/event_accepted.svg"
                    class="video-img w-50"
                    :class="isWebBigScreen ? '' : 'd-none'"
                  />
                  <img
                    src="../images/main/booking_slots_custom.svg"
                    class="video-img"
                    :class="isWebBigScreen ? 'w-100' : ''"
                  />
                </div>
                <div class="col-md-6">
                  <h2>Rendez is for everybody (literally )</h2>
                  <span class="about-summary">
                    With Rendez, anyone can monetize their skills, share
                    knowledge, and connect with others. Join the Rendez
                    community today and experience the power of seamless
                    scheduling!
                  </span>

                  <ul class="mt-4 pt-3">
                    <li>
                      <span
                        ><i class="fa fa-check-circle about-check"></i> For
                        Individuals
                      </span>
                      Whether you're a service provider with a skill to share or
                      a client seeking convenience, Rendez is for you! As a
                      service provider, you don't need a business to get
                      started. Share your expertise, manage bookings, and grow
                      your client base - all from one platform. Offer services
                      for free or charge a fee, Rendez simplifies your
                      scheduling needs. For individual clients, easily find and
                      book sessions with your favorite service providers, from
                      fitness classes to consulting sessions.
                    </li>
                    <li>
                      <span
                        ><i class="fa fa-check-circle about-check"></i> Small
                        and medium scale businesses
                      </span>
                      Efficiently manage your team's schedules and client
                      appointments with Rendez. Simplify your operations and
                      improve customer satisfaction with our intuitive booking
                      system. Enterprise Organizations
                    </li>
                    <li>
                      <span
                        ><i class="fa fa-check-circle about-check"></i>
                        Enterprise organizations</span
                      >
                      Rendez scales to meet the needs of large businesses.
                      Centralize your scheduling, manage multiple locations, and
                      teams with ease. Enhance your customer experience and
                      boost productivity.
                    </li>
                    <li>
                      <span
                        ><i class="fa fa-check-circle about-check"></i> For
                        Everybody
                      </span>
                      No account? No problem! Access a vast network of service
                      providers and book sessions without creating an account.
                      Enjoy the flexibility and convenience of Rendez, even as a
                      guest user.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--    about section -->

        <!--    about section -->
        <div class="market-wrapper mt-5 pt-5 mb-5 pb-5 bg-white">
          <div class="container">
            <h2 class="text-center">Businesses/Services using Rendez</h2>
            <ul class="categories-list mb-0">
              <li>
                <a href="javascript:void(0)">
                  <i class="material-icons icon-img primary-color">hot_tub</i
                  >Grooming & selfcare
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <i class="material-icons icon-img primary-color"
                    >contact_phone</i
                  >Private Consulting
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <i class="material-icons icon-img primary-color"
                    >record_voice_overs</i
                  >Freelancers
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <i class="material-icons icon-img primary-color"
                    >video_camera_front</i
                  >Content creators
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <i class="material-icons icon-img primary-color">event</i
                  >Event planners</a
                >
              </li>
              <li>
                <a href="javascript:void(0)">
                  <i class="material-icons icon-img primary-color"
                    >supervised_user_circle</i
                  >One on one tutorials
                </a>
              </li>
              <li>
                <a href="javascript:void(0)">
                  <i class="material-icons icon-img primary-color"
                    >diversity_3</i
                  >Co-operate businesses</a
                >
              </li>
              <li>
                <a href="javascript:void(0)">
                  <i class="material-icons icon-img primary-color">styler</i
                  >Fashion & Lifestyle
                </a>
              </li>
            </ul>
          </div>
        </div>

        <!--    about section -->
        <div
          class="container"
          style="background: #f5f5f5"
          :class="isWebBigScreen ? 'w-70  pt-3 mt-5 rendez-mid-wide' : 'pt-1 '"
        >
          <div class="about-section py-2">
            <div class="row align-items-center">
              <div class="col-md-6">
                <h2>How Rendez saves you time and makes you money</h2>
                <ul>
                  <li>
                    <span
                      ><i class="fa fa-check-circle about-check"></i>Becomne a
                      service provider</span
                    >
                    Join our network of trusted professionals and instantly gain
                    exposure to a vast customer base. Expand your reach and grow
                    your business with Rendez.
                  </li>
                  <li>
                    <span
                      ><i class="fa fa-check-circle about-check"></i> List your
                      service </span
                    >Showcase your expertise and offerings to potential clients.
                    Easily create and manage your services, including
                    descriptions, pricing, and duration.
                  </li>
                  <li>
                    <span
                      ><i class="fa fa-check-circle about-check"></i> Share your
                      availability </span
                    >S et your schedule and let clients book appointments at
                    their convenience. Receive real-time notifications and stay
                    organized with our intuitive calendar system.
                  </li>
                  <li>
                    <span
                      ><i class="fa fa-check-circle about-check"></i> Accelerate
                      efficiency and productivity
                    </span>
                    Focus on delivering exceptional services while Rendez
                    handles the scheduling. Reduce administrative tasks,
                    no-shows, and last-minute cancellations, and watch your
                    productivity soar. By joining Rendez, you'll be able to save
                    time, increase revenue, and improve customer satisfaction.
                    Sign up now and start growing your business today!
                  </li>
                </ul>
              </div>
              <div class="col-md-6">
                <img
                  src="../images/main/overdue.svg"
                  class="video-img w-100 d-none d-lg-block d-xl-block d-md-block"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- get started -->
      <div>
        <div class="get-started">
          <div class="content">
            <h2>Ready access to amazing service providers</h2>
            <p>
              Rendez gives you access to amazing service providers, coupled with
              scheduling capabilities to maximise your time and productivity.
            </p>
            <ion-button
              class="mr-3 mt-2"
              color="primary"
              fill="outline"
              @click="goToLink('feed')"
              >Explore service providers</ion-button
            >
            <ion-button
              class="mr-3 mt-2"
              color="primary"
              @click="goToLink('sign_up')"
              >Join the Rendez community</ion-button
            >
          </div>
        </div>
      </div>
      <!-- get started -->

      <!--       footer -->
      <main-footer></main-footer>
      <!--       footer-->
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonContent,
  IonButton,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from "@ionic/vue";
import { defineComponent, ref, inject } from "vue";
import urls from "@/hooks/urlConfig";
import { useRouter } from "vue-router";
import FeedDataList from "@/components/feed/FeedDataList.vue";

export default defineComponent({
  name: "HomePage",
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    FeedDataList,
  },
  setup() {
    /* 
    VueType:Page
    Responsibiliy: The first page the user encounters,introduction to Rendez a brief info about Rendez, and some featured providers
    links: Standard non-sign in links
    Active:  Only when a user haven't signed in
    Funtions: goToLink, switchTab
    Use Session data : This page does not need session data as all session required data are fetched via components
    External libraries :  urlConfig
    Components: FeedDataList => This components populates the feed of provider's posting, its used here in the home page to show a section of the feeds
    */

    // get the url config that holds all the URL basepaths
    const coreUrl = urls.coreUrl;

    // fetch the image url basepath for feeds
    const imgBaseUrl = coreUrl.imgFeedHost;

    // get the global route object for url routing
    const router = useRouter();

    // get the screen status using the inject function
    const isWebBigScreen = ref(inject("isWebBigScreen"));

    // function for routing clicks
    function goToLink(link) {
      router.push(link);
    }

    // variable for switching between tabs
    const currentTab = ref("providers");

    // function for switching between tabs
    function switchTab(ev: any) {
      currentTab.value = ev.detail.value;
    }

    // final return to hold setup values
    return {
      imgBaseUrl,
      isWebBigScreen,
      goToLink,
      currentTab,
      switchTab,
    };
  },
});
</script>

<style scoped>
.get-started {
  background: url(../images/get-started.jpg) center top no-repeat;
  background-size: cover;
  text-align: center;
  padding: 60px 0;
}

.homepage-search-block {
  background: #f5f5f5;
}

ion-segment-button {
  --border-style: solid;
  --border-color: #999;
  --border-width: 0px 0px 2px 0px;
}

.segment-button-checked {
  --border-color: #7e00e9;
  --border-width: 0px 0px 0px 0px;
}

.about-check {
  position: absolute;
  margin: 3px 0 0 0px;
  left: 0;
  width: 17px;
  color: #7e00e9;
}

.about-summary {
  line-height: 24px;
  padding-bottom: 23px;
  font-size: 15px;
  color: #62646a;
  position: relative;
}

.icon-img {
  display: block;
  width: 48px;
  font-size: 45px;
  margin: 0 auto 15px;
}
</style>
