/*
Category : Utility hooks
Description : Contains the list of various errors to be used site wide
*/
import { format } from "date-fns-tz";

export default function timeKeeper() {
  function cleanTime(hr: any, min: any) {
    let mainHr = 0;
    let mainMin: any = 0;
    let mainTime = hr > 12 ? "pm" : "am";

    if (hr === 12) {
      mainHr = 12;
      mainTime = min == 0 ? "noon" : "pm";
    } else {
      mainHr = hr > 12 ? hr - +12 : hr;
    }

    mainMin = min < 10 ? "0" + min : min;

    return mainHr + ":" + mainMin + " " + mainTime;
  }

  function getTimeAliasUnrange(startAppt: any) {
    const startHr = new Date(startAppt).getHours();
    const startMin = new Date(startAppt).getMinutes();

    const startTime = cleanTime(startHr, startMin);

    return startTime;
  }

  function getTimeAlias(startAppt: any, endAppt: any) {
    const startHr = new Date(startAppt).getHours();
    const startMin = new Date(startAppt).getMinutes();
    const endHr = new Date(endAppt).getHours();
    const endMin = new Date(endAppt).getMinutes();

    const startTime = cleanTime(startHr, startMin);
    const endTime = cleanTime(endHr, endMin);

    return startTime + " - " + endTime;
  }

  function fromNow(time) {
    const newTime = new Date(time);
    const currTime = new Date();

    let diff = (currTime.getTime() - newTime.getTime()) / 1000;
    diff /= 60;
    const total_minutes = Math.abs(Math.round(diff));

    let main_time = "seconds";
    let other_time = "none";
    let final_time = "";
    let main_time_quota = 0;
    let other_time_quota = 1;

    // let timeOption = total_minutes <= 59?"minutes":main_time
    // timeOption = total_minutes <= 59?"minutes":main_time

    if (total_minutes > 2 && total_minutes <= 59) {
      final_time = total_minutes + "minutes ago";
    } else if (total_minutes >= 60 && total_minutes <= 1439) {
      main_time = total_minutes <= 119 ? "hour" : "hours";
      other_time = total_minutes == 61 ? "minute" : "minutes";
      main_time_quota = 60;
      final_time = formatFromNow(
        total_minutes,
        main_time,
        other_time,
        main_time_quota,
        other_time_quota
      );
    } else if (total_minutes >= 1440 && total_minutes <= 10079) {
      main_time = total_minutes <= 2879 ? "day" : "days";
      other_time = total_minutes == 1441 ? "hour" : "hours";
      main_time_quota = 1440;
      other_time_quota = 60;
      final_time = formatFromNow(
        total_minutes,
        main_time,
        other_time,
        main_time_quota,
        other_time_quota
      );
    } else if (total_minutes >= 10080 && total_minutes <= 43799) {
      main_time = total_minutes <= 20159 ? "week" : "weeks";
      other_time = total_minutes == 10081 ? "day" : "days";
      main_time_quota = 10080;
      other_time_quota = 1440;
      final_time = formatFromNow(
        total_minutes,
        main_time,
        other_time,
        main_time_quota,
        other_time_quota
      );
    } else if (total_minutes >= 43800 && total_minutes <= 525599) {
      main_time = total_minutes <= 87599 ? "month" : "months";
      other_time = total_minutes == 43801 ? "week" : "weeks";
      main_time_quota = 43800;
      other_time_quota = 10080;
      final_time = formatFromNow(
        total_minutes,
        main_time,
        other_time,
        main_time_quota,
        other_time_quota
      );
    } else if (total_minutes >= 525600) {
      main_time = total_minutes <= 1051199 ? "year" : "years";
      other_time = total_minutes == 525601 ? "month" : "months";
      main_time_quota = 525600;
      other_time_quota = 43800;
      final_time = formatFromNow(
        total_minutes,
        main_time,
        other_time,
        main_time_quota,
        other_time_quota
      );
    } else {
      final_time = "a few seconds ago";
    }

    return final_time;
  }

  function formatFromNow(
    total_minutes,
    main_time,
    other_time,
    main_time_quota,
    other_time_quota
  ) {
    const main_time_val = Math.floor(total_minutes / main_time_quota);
    const other_time_val = Math.floor(
      (total_minutes % main_time_quota) / other_time_quota
    );

    return other_time_val === 0
      ? `${main_time_val} ${main_time} ago`
      : `${main_time_val} ${main_time}  ${other_time_val} ${other_time} ago`;
  }

  function fromNowShort(time) {
    const newTime = new Date(time);
    const currTime = new Date();

    let diff = (currTime.getTime() - newTime.getTime()) / 1000;
    diff /= 60;
    const total_minutes = Math.abs(Math.round(diff));

    let main_time = "secs";
    let final_time = "";
    let main_time_quota = 0;

    // let timeOption = total_minutes <= 59?"minutes":main_time
    // timeOption = total_minutes <= 59?"minutes":main_time

    if (total_minutes > 2 && total_minutes <= 59) {
      final_time = total_minutes + " mns";
    } else if (total_minutes >= 60 && total_minutes <= 1439) {
      main_time = total_minutes <= 119 ? "hr" : "hrs";
      main_time_quota = 60;
      final_time = formatFromNowShort(
        total_minutes,
        main_time,
        main_time_quota
      );
    } else if (total_minutes >= 1440 && total_minutes <= 10079) {
      main_time = total_minutes <= 2879 ? "d" : "ds";
      main_time_quota = 1440;
      final_time = formatFromNowShort(
        total_minutes,
        main_time,
        main_time_quota
      );
    } else if (total_minutes >= 10080 && total_minutes <= 43799) {
      main_time = total_minutes <= 20159 ? "wk" : "wks";
      main_time_quota = 10080;
      final_time = formatFromNowShort(
        total_minutes,
        main_time,
        main_time_quota
      );
    } else if (total_minutes >= 43800 && total_minutes <= 525599) {
      main_time = total_minutes <= 87599 ? "mth" : "mths";
      main_time_quota = 43800;
      final_time = formatFromNowShort(
        total_minutes,
        main_time,
        main_time_quota
      );
    } else if (total_minutes >= 525600) {
      main_time = total_minutes <= 1051199 ? "yr" : "yrs";
      main_time_quota = 525600;
      final_time = formatFromNowShort(
        total_minutes,
        main_time,
        main_time_quota
      );
    } else {
      final_time = "40 secs";
    }

    return final_time;
  }

  function formatFromNowShort(total_minutes, main_time, main_time_quota) {
    const main_time_val = Math.floor(total_minutes / main_time_quota);

    return `${main_time_val} ${main_time}`;
  }

  function formatMinToHr(total_minutes) {
    let final_time = "";

    if (total_minutes < 60) {
      final_time = total_minutes + " mins";
    } else {
      const hr_time_val = Math.floor(total_minutes / 60);
      const min_time_val = total_minutes % 60;

      final_time =
        min_time_val === 0
          ? `${hr_time_val} ${hr_time_val > 1 ? "hrs" : "hr"}`
          : `${hr_time_val} ${hr_time_val > 1 ? "hrs" : "hr"} ${min_time_val} ${
              min_time_val > 1 ? "mins" : "min"
            }`;
    }

    return final_time;
  }

  function formatCleanFullDate(currDate) {
    return format(new Date(currDate), "dd, MMM yyyy");
  }

  function formatTimeOnly(currDate) {
    const date = new Date(currDate);
    const hours = date.getUTCHours();
    const dayPeriod = hours / 12 >= 1 ? " pm" : " am";
    const time = format(date, "HH:mm");
    return time + dayPeriod;
  }

  function nowCompare(currTime, type) {
    const newTime = new Date(currTime).getTime();
    const nowTime = new Date().getTime();

    console.log(currTime);
    console.log(new Date(currTime));

    let status = false;

    if (type === "before") {
      status = nowTime <= newTime ? true : false;
    }

    if (type === "after") {
      status = nowTime >= newTime ? true : false;
    }

    return status;
  }

  function timeHourMin(time: any) {
    let finalTime = time < 60 ? `${time} mins` : time === 60 ? `1 hr` : "";

    const hour = Math.floor(time / 60);
    const min = time % 60;
    const hourTime = hour > 1 ? `${hour} hrs ` : `${hour} hr `;
    const minTime = min === 0 ? "" : `${min} mins`;
    finalTime = finalTime ? finalTime : hourTime + minTime;

    return finalTime;
  }

  return {
    getTimeAliasUnrange,
    getTimeAlias,
    fromNow,
    fromNowShort,
    formatMinToHr,
    formatCleanFullDate,
    formatTimeOnly,
    nowCompare,
    timeHourMin,
  };
}
