import { createRouter, createWebHistory } from "@ionic/vue-router";
import storeKeeper from "@/hooks/RendezStorage";
import vueStore from "@/vue_store/index";
import urls from "@/hooks/urlConfig";
import { Capacitor } from "@capacitor/core";
import { CapacitorHttp } from "@capacitor/core";

import { RouteRecordRaw } from "vue-router";
import HomePage from "../views/HomePage.vue";
import MobileHomePage from "../views/MobileHomePage.vue";
import NotFoundPage from "../views/NotFoundPage.vue";

const sk = storeKeeper();

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: Capacitor.isNativePlatform() ? "/mobile_home" : "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Capacitor.isNativePlatform() ? MobileHomePage : HomePage,
    meta: { requiresUnauth: true },
  },
  {
    path: "/mobile_home",
    name: "MobileHomePage",
    component: () => import("../views/MobileHomePage.vue"),
    meta: { requiresUnauth: true },
  },
  {
    path: "/p/:providerUsername",
    name: "AccountProviderAccountBookingPage",
    props: true,
    component: () =>
      import("../views/account/AccountProviderAccountBookingPage.vue"),
  },
  {
    path: "/my_account",
    name: "AccountProviderAccountPage",
    component: () => import("../views/account/AccountProviderAccountPage.vue"),
    meta: { requiresAuth: true, requiresServiceProvider: true },
  },
  {
    path: "/my_profile",
    name: "AccountClientProfilePage",
    component: () => import("../views/account/AccountClientProfilePage.vue"),
    meta: { requiresAuth: true, requiresNotServiceProvider: true },
  },
  {
    path: "/my_services",
    name: "AccountProviderServicesPage",
    component: () => import("../views/account/AccountProviderServicesPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/my_ratings",
    name: "AccountProviderRatingsPage",
    component: () => import("../views/account/AccountProviderRatingsPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/my_posts",
    name: "AccountProviderPostsPage",
    component: () => import("../views/account/AccountProviderPostsPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/alerts",
    name: "Alerts",
    component: () => import("../views/AlertsPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/schedule_manager/:appointmentInfo",
    name: "BookingsPage",
    props: true,
    component: () => import("../views/BookingsPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/guest_client/schedule_manager/:appointmentInfo/:orderId",
    name: "GuestClient",
    props: true,
    component: () => import("../views/guest/GuestClient.vue"),
    meta: { requiresUnauth: true },
  },
  {
    path: "/my_bookmarks",
    name: "FavProviderBookmarksPage",
    component: () => import("../views/FavProviderBookmarksPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/my_circle",
    name: "FavProviderCirclePage",
    component: () => import("../views/FavProviderCirclePage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/my_trusted_brands",
    name: "FavProviderTrustedBrandsPage",
    component: () => import("../views/FavProviderTrustedBrandsPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/search/SearchPage.vue"),
  },
  {
    path: "/feed",
    name: "Feed",
    component: () => import("../views/FeedPage.vue"),
  },
  {
    path: "/message",
    name: "Message",
    component: () => import("../views/MessagePage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/sign_in",
    name: "AuthSignInPage",
    component: () => import("../views/auth/AuthSignInPage.vue"),
    meta: { requiresUnauth: true },
  },
  {
    path: "/sign_up",
    name: "AuthSignUpPage",
    component: () => import("../views/auth/AuthSignUpPage.vue"),
    meta: { requiresUnauth: true },
  },
  {
    path: "/confirm_account/:tokenId",
    name: "AuthConfirmAccount",
    props: true,
    component: () => import("../views/auth/AuthConfirmAccount.vue"),
  },
  {
    path: "/forgot_password",
    name: "AuthForgotPassword",
    component: () => import("../views/auth/AuthForgotPassword.vue"),
    meta: { requiresUnauth: true },
  },
  {
    path: "/reset_password/:tokenId",
    name: "AuthResetPassword",
    props: true,
    component: () => import("../views/auth/AuthResetPassword.vue"),
    meta: { requiresUnauth: true },
  },
  {
    path: "/change_password",
    name: "AuthChangePassword",
    component: () => import("../views/auth/AuthChangePassword.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/onboard_username",
    name: "OnboardUsernamePage",
    component: () => import("../views/onboard/OnboardUsernamePage.vue"),
    meta: { requiresAuth: true, requiresNoOnboardUsername: true },
  },
  {
    path: "/onboard_service_provider",
    name: "OnboardServiceProvider",
    component: () => import("../views/onboard/OnboardServiceProviderPage.vue"),
    meta: { requiresAuth: true, requiresNoOnboardProfile: true },
  },
  {
    path: "/my_settings",
    name: "AccountSettingsPage",
    component: () => import("../views/AccountSettingsPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/delete_account",
    name: "AccountDeletePage",
    component: () => import("../views/AccountDeletePage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/starter_preferences",
    name: "SettingsStarterPreferences",
    component: () => import("../views/settings/SettingsStarterPreferences.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/appointment_booking_settings",
    name: "AppointmentSettingsBookingPage",
    component: () => import("../views/AppointmentSettingsBookingPage.vue"),
    meta: { requiresAuth: true, requiresServiceProvider: true },
  },
  {
    path: "/appointment_finance_settings",
    name: "AppointmentSettingsFinancePage",
    component: () => import("../views/AppointmentSettingsFinancePage.vue"),
    meta: { requiresAuth: true, requiresServiceProvider: true },
  },
  {
    path: "/user_request",
    name: "ReportUserRequest",
    component: () => import("../views/ReportUserRequest.vue"),
  },
  {
    path: "/privacy_policy",
    name: "PrivacyPolicyPage",
    component: () => import("../views/PrivacyPolicyPage.vue"),
  },
  { path: "/:notFound(.*)", component: NotFoundPage },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// permissions: {"req_appointments": "denied", "service_provider": "denied"}

router.beforeResolve(async (to, _, next) => {
  let canAccess: any = false;
  let isOnboardUsername: any = false;
  let isOnboardProfile: any = false;
  let isServiceProvider: any = false;

  if (vueStore.getters["auth/getLoadAuthState"]) {
    const sessionData = vueStore.getters["auth/getSessionData"];

    if (sessionData) {
      canAccess = sessionData.isAuthenticated;
      isOnboardUsername = sessionData.onboardUsername ? true : false;
      isOnboardProfile = sessionData.onboardProfile ? true : false;
      isServiceProvider = sessionData.serviceProvider ? true : false;
    }

    // const freshState = await getGlobalAuthState();
    // if (freshState) {
    //   canAccess = freshState["isAuthenticated"];
    //   isOnboardUsername = freshState["onboardUsername"] ? true : false;
    //   isOnboardProfile = freshState["onboardProfile"] ? true : false;
    //   isServiceProvider = freshState["serviceProvider"] ? true : false;
    // }

    if (to.meta.requiresAuth && !canAccess) {
      next("/sign_in");
    } else if (
      to.meta.requiresAuth &&
      canAccess &&
      to.meta.requiresServiceProvider &&
      !isServiceProvider
    ) {
      next("/my_profile");
    } else if (
      to.meta.requiresAuth &&
      canAccess &&
      to.meta.requiresNotServiceProvider &&
      isServiceProvider
    ) {
      next("/my_account");
    } else if (
      to.meta.requiresAuth &&
      canAccess &&
      to.meta.requiresNoOnboardUsername &&
      isOnboardUsername
    ) {
      next("/onboard_service_provider");
    } else if (
      to.meta.requiresAuth &&
      canAccess &&
      to.meta.requiresNoOnboardProfile &&
      isOnboardProfile
    ) {
      next("/my_account");
    } else if (to.meta.requiresUnauth && canAccess) {
      if (to.name === "GuestClient") {
        next("/schedule_manager/pending_review");
      } else {
        next("/my_account");
      }
    } else {
      next();
    }
  } else {
    try {
      const freshState = await getGlobalAuthState();

      if (freshState) {
        canAccess = freshState["isAuthenticated"];
        isOnboardUsername = freshState["onboardUsername"] ? true : false;
        isOnboardProfile = freshState["onboardProfile"] ? true : false;
        isServiceProvider = freshState["serviceProvider"] ? true : false;
      }
    } catch (_e) {
      canAccess = false;
    }

    if (to.meta.requiresAuth && !canAccess) {
      next("/sign_in");
    } else if (
      to.meta.requiresAuth &&
      canAccess &&
      to.meta.requiresServiceProvider &&
      !isServiceProvider
    ) {
      next("/my_profile");
    } else if (
      to.meta.requiresAuth &&
      canAccess &&
      to.meta.requiresNotServiceProvider &&
      isServiceProvider
    ) {
      next("/my_account");
    } else if (
      to.meta.requiresAuth &&
      canAccess &&
      to.meta.requiresNoOnboardUsername &&
      isOnboardUsername
    ) {
      next("/onboard_service_provider");
    } else if (
      to.meta.requiresAuth &&
      canAccess &&
      to.meta.requiresNoOnboardProfile &&
      isOnboardProfile
    ) {
      next("/my_account");
    } else if (to.meta.requiresUnauth && canAccess) {
      if (to.name === "GuestClient") {
        next("/schedule_manager/pending_review");
      } else {
        next("/my_account");
      }
    } else {
      next();
    }
  }
});

router.afterEach(async (_to, _from) => {
  let canAccess = false;
  const freshState = await getGlobalAuthState();
  if (freshState) {
    canAccess = freshState["isAuthenticated"];
  }

  if (!canAccess) {
    // Reset the vuex session data to the default
    await vueStore.dispatch("auth/executeSetDefaultSessionData");
  }
});

async function getGlobalAuthState() {
  let session_data = await sk.getStoreIon("session_data");
  session_data = JSON.parse(session_data);

  const payload = { loadAuth: true, data: session_data };
  await vueStore.dispatch("auth/executeSetSessionData", payload);

  return session_data;
}

async function checkActiveLogin() {
  //get the urls
  const coreUrl = urls.coreUrl;

  const tokenId = await sk.getStoreIon("access_token");

  if (tokenId) {
    const options = {
      url: coreUrl.backendUrl + "check_active_login",
      headers: { Authorization: "Bearer " + tokenId },
    };

    // or...
    const response = await CapacitorHttp.request({ ...options, method: "GET" });

    if (response.status == 422) {
      await sk.delAllStoreIon();
      await sk.setStoreIon("isLoginExpiry", "yes");
      window.location.href = "sign_in";
    }

    if (response.status) {
      const data = response.data["refreshed_access_token"];
      if (data["access_token"]) {
        await sk.setStoreIon("access_token", data["access_token"]);
        await sk.setStoreIon(
          "access_token_expiry",
          data["access_token_expiry"]
        );
      }
    }
  }
}

function runCheckActiveLogin() {
  checkActiveLogin()
    .then()
    .catch((err) => {
      console.log(err);
    });
}

setInterval(runCheckActiveLogin, 86400000);
// check for core site data : interests , bookmarks

async function getUserFavData() {
  //get the urls
  const coreUrl = urls.coreUrl;

  const tokenId = await sk.getStoreIon("access_token");

  if (tokenId) {
    const options = {
      url: coreUrl.backendUrl + "get_user_fav_data",
      headers: { Authorization: "Bearer " + tokenId },
    };

    // or.....
    const response = await CapacitorHttp.request({ ...options, method: "GET" });

    if (response.data.status) {
      const interest_info = response.data.data.interest_info;
      const interest_payload = {
        loadFav: true,
        data: { storage: "replace", info: interest_info },
      };
      await vueStore.dispatch("fav/executeSetFavInterests", interest_payload);

      const bookmark_info = response.data.data.bookmark_info;
      const bookmark_payload = {
        loadFav: true,
        data: { storage: "replace", info: bookmark_info },
      };
      await vueStore.dispatch("fav/executeSetFavBookmarks", bookmark_payload);

      const blocks_info = response.data.data.blocks_info;
      const blocks_payload = {
        loadFav: true,
        data: { storage: "replace", info: blocks_info },
      };
      await vueStore.dispatch("fav/executeSetFavBlocks", blocks_payload);
    }
  }
}

getUserFavData()
  .then()
  .catch((err) => {
    console.log(err);
  });

export default router;
