<template>


    <ion-fab style="bottom:15%;right:12%" slot="fixed" horizontal="end" vertical="bottom" >
            <ion-fab-button @click="openPostContent">
              <ion-icon :icon="add"></ion-icon>
            </ion-fab-button>
    </ion-fab>


</template>

<script lang="ts">
import { IonFab, IonFabButton, IonIcon, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { add } from 'ionicons/icons';
import CreateFeedPostContent from '@/components/forms/feed/CreateFeedPostContent.vue'

export default defineComponent({
  name: 'MainTab',
  components: {
      IonFab,
      IonFabButton,
      IonIcon
  },
  setup(){


      async function openPostContent() {
        const modal = await modalController.create({
          component: CreateFeedPostContent
        });
        modal.present();

        // const { data, role } = await modal.onWillDismiss();
        //
        // if (role === 'confirm') {
        //   console.log(`Hello, ${data}!`);
        // }
      }


    return {
      add,
      openPostContent
    }


  }


});
</script>

<style scoped>

ion-modal::part(content) {
     display: flex;
    flex-direction: column;
    height: 100%;
}

  ion-action-sheet.my-custom-class {
    --background: #eee;
    --backdrop-opacity: 0.6;
    --button-background-selected: #e97223;
    --button-color: #333;
    --color: #000;
    /* role: "destructive" button iOS styling override */
    --ion-color-danger: #000000;
  }

  ion-toolbar {
  --background: #fff;
  }
</style>
