export default {
  setLoadAuthState(state: any, payload: any) {
    state.loadAuthState = payload;
  },
  setSessionData(state: any, payload: any) {
    state.sessionData = payload;
  },
  setDefaultSessionData(state: any) {
    state.sessionData = state.defaultSessionData;
  },
};
