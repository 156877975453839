<template>
  <ion-header :translucent="true" mode="ios" class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="closeImageUpload">
          <ion-icon :icon="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title> Upload Image </ion-title>
      <ion-buttons slot="end">
        <ion-button @click="confirmImageUpload">Done</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <div class="container">
    <input
      type="file"
      accept="image/*"
      @change="onFileChange"
      id="fileForm"
      hidden
    />
    <keep-alive>
      <cropper
        class="mx-auto mt-4 pt-4"
        :class="canvasSize"
        for="fileForm"
        ref="cropper"
        :src="currentImg"
        :canvas="{ height: parseInt(ImgHeight), width: parseInt(ImgWidth) }"
        :stencil-props="{
          handlers: {},
          movable: true,
          scalable: false,
        }"
        :stencil-size="{
          height: parseInt(ImgHeight),
          width: parseInt(ImgWidth),
        }"
        @change="uploadImage"
        v-if="!succesfulUpload && !previewImage"
      >
        Click here or the <i class="bi bi-upload"></i> icon to upload a picture.
      </cropper>
    </keep-alive>
    <div v-if="jpegImgUrl && !succesfulUpload && previewImage" class="py-1">
      <img :src="jpegImgUrl" class="rounded" />
    </div>

    <div v-if="succesfulUpload" class="pb-2">
      <img :src="jpegImgUrl" class="rounded successful-upload" />
    </div>
  </div>

  <ion-footer class="ion-no-border form-footer">
    <ion-toolbar>
      <ion-grid>
        <ion-row v-if="!previewImage">
          <ion-col class="d-flex justify-content-center">
            <label class="cursor-pointer ml-3 mr-3" for="fileForm">
              <ion-icon size="small" :icon="cloudUpload"></ion-icon>
            </label>
          </ion-col>
          <ion-col class="d-flex justify-content-center" v-if="processButtons">
            <label class="cursor-pointer ml-3 mr-3" @click="rotateImg(90)">
              <ion-icon size="small" :icon="arrowRedo"></ion-icon>
            </label>
          </ion-col>
          <ion-col class="d-flex justify-content-center" v-if="processButtons">
            <label class="cursor-pointer ml-3 mr-3" @click="rotateImg(-90)">
              <ion-icon size="small" :icon="arrowUndo"></ion-icon>
            </label>
          </ion-col>
          <ion-col class="d-flex justify-content-center" v-if="processButtons">
            <label class="cursor-pointer ml-3 mr-3" @click="restartUpload">
              <ion-icon size="small" :icon="trash"></ion-icon>
            </label>
          </ion-col>
          <ion-col class="d-flex justify-content-center" v-if="processButtons">
            <label class="cursor-pointer" @click="previewImage = !previewImage">
              <ion-icon size="small" :icon="eye"></ion-icon>
            </label>
          </ion-col>
        </ion-row>
        <ion-row v-else>
          <ion-col class="d-flex justify-content-center" v-if="processButtons">
            <label class="cursor-pointer" @click="previewImage = !previewImage">
              <ion-icon size="small" :icon="createOutline"></ion-icon>
            </label>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import {
  IonHeader,
  IonTitle,
  IonFooter,
  IonToolbar,
  IonIcon,
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonButtons,
} from "@ionic/vue";
import {
  cloudUpload,
  arrowRedo,
  arrowUndo,
  trash,
  eye,
  createOutline,
  close,
} from "ionicons/icons";
import { ref } from "vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  components: {
    Cropper,
    IonHeader,
    IonTitle,
    IonFooter,
    IonToolbar,
    IonIcon,
    IonCol,
    IonGrid,
    IonRow,
    IonButton,
    IonButtons,
  },
  emits: ["emitUpdateImageUpload", "emitCloseImageUpload"],
  props: {
    ImgHeight: {
      type: Number,
      required: true,
    },
    ImgWidth: {
      type: Number,
      required: true,
    },
    canvasSize: {
      type: String,
      required: true,
      default: "max-rec-cropper",
    },
  },
  setup(_, { emit }) {
    const currentImg = ref("");
    const jpegImgUrl = ref(null);
    const succesfulUpload = ref(false);
    const processButtons = ref(false);

    const imgCoordinates = ref({
      width: 0,
      height: 0,
      left: 0,
      top: 0,
    });

    function uploadImage({ coordinates, canvas }) {
      imgCoordinates.value = coordinates;
      jpegImgUrl.value = canvas.toDataURL("image/jpeg");
    }

    function onFileChange(e) {
      const file = e.target.files[0];

      if (file) {
        const url = URL.createObjectURL(file);
        currentImg.value = url;
        processButtons.value = true;
      }
    }

    const cropper = ref(null);

    function rotateImg(angle) {
      cropper.value.rotate(angle);
    }

    function restartUpload() {
      currentImg.value = null;
      jpegImgUrl.value = null;
      processButtons.value = false;
    }

    const previewImage = ref(false);

    function confirmImageUpload() {
      emit("emitUpdateImageUpload", jpegImgUrl.value);
    }

    function closeImageUpload() {
      emit("emitCloseImageUpload");
    }

    return {
      cloudUpload,
      arrowRedo,
      arrowUndo,
      trash,
      eye,
      createOutline,
      close,
      currentImg,
      jpegImgUrl,
      uploadImage,
      succesfulUpload,
      processButtons,
      restartUpload,
      onFileChange,
      cropper,
      rotateImg,
      previewImage,
      confirmImageUpload,
      closeImageUpload,
    };
  },
};
</script>

<style scoped>
.max-rec-cropper {
  width: 100%;
  min-height: 20rem;
  background: #ddd;
}

.small-sqr-cropper {
  width: 350px;
  min-height: 350px;
  background: #ddd;
}

.successful-upload {
  width: 100%;
}

.form-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-height: 45px;
}
</style>
