/*
Category : Utility hooks
Description : Contains the list of various errors to be used site wide
*/

export default function geoLocation(){

    const countryDetails = [
            {
                "countryCode": "AD",
                "countryName": "Andorra",
                "currencyCode": "EUR",
                "population": "84000",
                "capital": "Andorra la Vella",
                "continentName": "Europe"
            },
            {
                "countryCode": "AE",
                "countryName": "United Arab Emirates",
                "currencyCode": "AED",
                "population": "4975593",
                "capital": "Abu Dhabi",
                "continentName": "Asia"
            },
            {
                "countryCode": "AF",
                "countryName": "Afghanistan",
                "currencyCode": "AFN",
                "population": "29121286",
                "capital": "Kabul",
                "continentName": "Asia"
            },
            {
                "countryCode": "AG",
                "countryName": "Antigua and Barbuda",
                "currencyCode": "XCD",
                "population": "86754",
                "capital": "St. John's",
                "continentName": "North America"
            },
            {
                "countryCode": "AI",
                "countryName": "Anguilla",
                "currencyCode": "XCD",
                "population": "13254",
                "capital": "The Valley",
                "continentName": "North America"
            },
            {
                "countryCode": "AL",
                "countryName": "Albania",
                "currencyCode": "ALL",
                "population": "2986952",
                "capital": "Tirana",
                "continentName": "Europe"
            },
            {
                "countryCode": "AM",
                "countryName": "Armenia",
                "currencyCode": "AMD",
                "population": "2968000",
                "capital": "Yerevan",
                "continentName": "Asia"
            },
            {
                "countryCode": "AO",
                "countryName": "Angola",
                "currencyCode": "AOA",
                "population": "13068161",
                "capital": "Luanda",
                "continentName": "Africa"
            },
            {
                "countryCode": "AQ",
                "countryName": "Antarctica",
                "currencyCode": "USD",
                "population": "0",
                "capital": "",
                "continentName": "Antarctica"
            },
            {
                "countryCode": "AR",
                "countryName": "Argentina",
                "currencyCode": "ARS",
                "population": "41343201",
                "capital": "Buenos Aires",
                "continentName": "South America"
            },
            {
                "countryCode": "AS",
                "countryName": "American Samoa",
                "currencyCode": "USD",
                "population": "57881",
                "capital": "Pago Pago",
                "continentName": "Oceania"
            },
            {
                "countryCode": "AT",
                "countryName": "Austria",
                "currencyCode": "EUR",
                "population": "8205000",
                "capital": "Vienna",
                "continentName": "Europe"
            },
            {
                "countryCode": "AU",
                "countryName": "Australia",
                "currencyCode": "AUD",
                "population": "21515754",
                "capital": "Canberra",
                "continentName": "Oceania"
            },
            {
                "countryCode": "AW",
                "countryName": "Aruba",
                "currencyCode": "AWG",
                "population": "71566",
                "capital": "Oranjestad",
                "continentName": "North America"
            },
            {
                "countryCode": "AX",
                "countryName": "Åland",
                "currencyCode": "EUR",
                "population": "26711",
                "capital": "Mariehamn",
                "continentName": "Europe"
            },
            {
                "countryCode": "AZ",
                "countryName": "Azerbaijan",
                "currencyCode": "AZN",
                "population": "8303512",
                "capital": "Baku",
                "continentName": "Asia"
            },
            {
                "countryCode": "BA",
                "countryName": "Bosnia and Herzegovina",
                "currencyCode": "BAM",
                "population": "4590000",
                "capital": "Sarajevo",
                "continentName": "Europe"
            },
            {
                "countryCode": "BB",
                "countryName": "Barbados",
                "currencyCode": "BBD",
                "population": "285653",
                "capital": "Bridgetown",
                "continentName": "North America"
            },
            {
                "countryCode": "BD",
                "countryName": "Bangladesh",
                "currencyCode": "BDT",
                "population": "156118464",
                "capital": "Dhaka",
                "continentName": "Asia"
            },
            {
                "countryCode": "BE",
                "countryName": "Belgium",
                "currencyCode": "EUR",
                "population": "10403000",
                "capital": "Brussels",
                "continentName": "Europe"
            },
            {
                "countryCode": "BF",
                "countryName": "Burkina Faso",
                "currencyCode": "XOF",
                "population": "16241811",
                "capital": "Ouagadougou",
                "continentName": "Africa"
            },
            {
                "countryCode": "BG",
                "countryName": "Bulgaria",
                "currencyCode": "BGN",
                "population": "7148785",
                "capital": "Sofia",
                "continentName": "Europe"
            },
            {
                "countryCode": "BH",
                "countryName": "Bahrain",
                "currencyCode": "BHD",
                "population": "738004",
                "capital": "Manama",
                "continentName": "Asia"
            },
            {
                "countryCode": "BI",
                "countryName": "Burundi",
                "currencyCode": "BIF",
                "population": "9863117",
                "capital": "Bujumbura",
                "continentName": "Africa"
            },
            {
                "countryCode": "BJ",
                "countryName": "Benin",
                "currencyCode": "XOF",
                "population": "9056010",
                "capital": "Porto-Novo",
                "continentName": "Africa"
            },
            {
                "countryCode": "BL",
                "countryName": "Saint Barthélemy",
                "currencyCode": "EUR",
                "population": "8450",
                "capital": "Gustavia",
                "continentName": "North America"
            },
            {
                "countryCode": "BM",
                "countryName": "Bermuda",
                "currencyCode": "BMD",
                "population": "65365",
                "capital": "Hamilton",
                "continentName": "North America"
            },
            {
                "countryCode": "BN",
                "countryName": "Brunei",
                "currencyCode": "BND",
                "population": "395027",
                "capital": "Bandar Seri Begawan",
                "continentName": "Asia"
            },
            {
                "countryCode": "BO",
                "countryName": "Bolivia",
                "currencyCode": "BOB",
                "population": "9947418",
                "capital": "Sucre",
                "continentName": "South America"
            },
            {
                "countryCode": "BQ",
                "countryName": "Bonaire",
                "currencyCode": "USD",
                "population": "18012",
                "capital": "Kralendijk",
                "continentName": "North America"
            },
            {
                "countryCode": "BR",
                "countryName": "Brazil",
                "currencyCode": "BRL",
                "population": "201103330",
                "capital": "Brasília",
                "continentName": "South America"
            },
            {
                "countryCode": "BS",
                "countryName": "Bahamas",
                "currencyCode": "BSD",
                "population": "301790",
                "capital": "Nassau",
                "continentName": "North America"
            },
            {
                "countryCode": "BT",
                "countryName": "Bhutan",
                "currencyCode": "BTN",
                "population": "699847",
                "capital": "Thimphu",
                "continentName": "Asia"
            },
            {
                "countryCode": "BV",
                "countryName": "Bouvet Island",
                "currencyCode": "NOK",
                "population": "0",
                "capital": "",
                "continentName": "Antarctica"
            },
            {
                "countryCode": "BW",
                "countryName": "Botswana",
                "currencyCode": "BWP",
                "population": "2029307",
                "capital": "Gaborone",
                "continentName": "Africa"
            },
            {
                "countryCode": "BY",
                "countryName": "Belarus",
                "currencyCode": "BYR",
                "population": "9685000",
                "capital": "Minsk",
                "continentName": "Europe"
            },
            {
                "countryCode": "BZ",
                "countryName": "Belize",
                "currencyCode": "BZD",
                "population": "314522",
                "capital": "Belmopan",
                "continentName": "North America"
            },
            {
                "countryCode": "CA",
                "countryName": "Canada",
                "currencyCode": "CAD",
                "population": "33679000",
                "capital": "Ottawa",
                "continentName": "North America"
            },
            {
                "countryCode": "CC",
                "countryName": "Cocos [Keeling] Islands",
                "currencyCode": "AUD",
                "population": "628",
                "capital": "West Island",
                "continentName": "Asia"
            },
            {
                "countryCode": "CD",
                "countryName": "Democratic Republic of the Congo",
                "currencyCode": "CDF",
                "population": "70916439",
                "capital": "Kinshasa",
                "continentName": "Africa"
            },
            {
                "countryCode": "CF",
                "countryName": "Central African Republic",
                "currencyCode": "XAF",
                "population": "4844927",
                "capital": "Bangui",
                "continentName": "Africa"
            },
            {
                "countryCode": "CG",
                "countryName": "Republic of the Congo",
                "currencyCode": "XAF",
                "population": "3039126",
                "capital": "Brazzaville",
                "continentName": "Africa"
            },
            {
                "countryCode": "CH",
                "countryName": "Switzerland",
                "currencyCode": "CHF",
                "population": "7581000",
                "capital": "Bern",
                "continentName": "Europe"
            },
            {
                "countryCode": "CI",
                "countryName": "Ivory Coast",
                "currencyCode": "XOF",
                "population": "21058798",
                "capital": "Yamoussoukro",
                "continentName": "Africa"
            },
            {
                "countryCode": "CK",
                "countryName": "Cook Islands",
                "currencyCode": "NZD",
                "population": "21388",
                "capital": "Avarua",
                "continentName": "Oceania"
            },
            {
                "countryCode": "CL",
                "countryName": "Chile",
                "currencyCode": "CLP",
                "population": "16746491",
                "capital": "Santiago",
                "continentName": "South America"
            },
            {
                "countryCode": "CM",
                "countryName": "Cameroon",
                "currencyCode": "XAF",
                "population": "19294149",
                "capital": "Yaoundé",
                "continentName": "Africa"
            },
            {
                "countryCode": "CN",
                "countryName": "China",
                "currencyCode": "CNY",
                "population": "1330044000",
                "capital": "Beijing",
                "continentName": "Asia"
            },
            {
                "countryCode": "CO",
                "countryName": "Colombia",
                "currencyCode": "COP",
                "population": "47790000",
                "capital": "Bogotá",
                "continentName": "South America"
            },
            {
                "countryCode": "CR",
                "countryName": "Costa Rica",
                "currencyCode": "CRC",
                "population": "4516220",
                "capital": "San José",
                "continentName": "North America"
            },
            {
                "countryCode": "CU",
                "countryName": "Cuba",
                "currencyCode": "CUP",
                "population": "11423000",
                "capital": "Havana",
                "continentName": "North America"
            },
            {
                "countryCode": "CV",
                "countryName": "Cape Verde",
                "currencyCode": "CVE",
                "population": "508659",
                "capital": "Praia",
                "continentName": "Africa"
            },
            {
                "countryCode": "CW",
                "countryName": "Curacao",
                "currencyCode": "ANG",
                "population": "141766",
                "capital": "Willemstad",
                "continentName": "North America"
            },
            {
                "countryCode": "CX",
                "countryName": "Christmas Island",
                "currencyCode": "AUD",
                "population": "1500",
                "capital": "Flying Fish Cove",
                "continentName": "Asia"
            },
            {
                "countryCode": "CY",
                "countryName": "Cyprus",
                "currencyCode": "EUR",
                "population": "1102677",
                "capital": "Nicosia",
                "continentName": "Europe"
            },
            {
                "countryCode": "CZ",
                "countryName": "Czechia",
                "currencyCode": "CZK",
                "population": "10476000",
                "capital": "Prague",
                "continentName": "Europe"
            },
            {
                "countryCode": "DE",
                "countryName": "Germany",
                "currencyCode": "EUR",
                "population": "81802257",
                "capital": "Berlin",
                "continentName": "Europe"
            },
            {
                "countryCode": "DJ",
                "countryName": "Djibouti",
                "currencyCode": "DJF",
                "population": "740528",
                "capital": "Djibouti",
                "continentName": "Africa"
            },
            {
                "countryCode": "DK",
                "countryName": "Denmark",
                "currencyCode": "DKK",
                "population": "5484000",
                "capital": "Copenhagen",
                "continentName": "Europe"
            },
            {
                "countryCode": "DM",
                "countryName": "Dominica",
                "currencyCode": "XCD",
                "population": "72813",
                "capital": "Roseau",
                "continentName": "North America"
            },
            {
                "countryCode": "DO",
                "countryName": "Dominican Republic",
                "currencyCode": "DOP",
                "population": "9823821",
                "capital": "Santo Domingo",
                "continentName": "North America"
            },
            {
                "countryCode": "DZ",
                "countryName": "Algeria",
                "currencyCode": "DZD",
                "population": "34586184",
                "capital": "Algiers",
                "continentName": "Africa"
            },
            {
                "countryCode": "EC",
                "countryName": "Ecuador",
                "currencyCode": "USD",
                "population": "14790608",
                "capital": "Quito",
                "continentName": "South America"
            },
            {
                "countryCode": "EE",
                "countryName": "Estonia",
                "currencyCode": "EUR",
                "population": "1291170",
                "capital": "Tallinn",
                "continentName": "Europe"
            },
            {
                "countryCode": "EG",
                "countryName": "Egypt",
                "currencyCode": "EGP",
                "population": "80471869",
                "capital": "Cairo",
                "continentName": "Africa"
            },
            {
                "countryCode": "EH",
                "countryName": "Western Sahara",
                "currencyCode": "MAD",
                "population": "273008",
                "capital": "Laâyoune / El Aaiún",
                "continentName": "Africa"
            },
            {
                "countryCode": "ER",
                "countryName": "Eritrea",
                "currencyCode": "ERN",
                "population": "5792984",
                "capital": "Asmara",
                "continentName": "Africa"
            },
            {
                "countryCode": "ES",
                "countryName": "Spain",
                "currencyCode": "EUR",
                "population": "46505963",
                "capital": "Madrid",
                "continentName": "Europe"
            },
            {
                "countryCode": "ET",
                "countryName": "Ethiopia",
                "currencyCode": "ETB",
                "population": "88013491",
                "capital": "Addis Ababa",
                "continentName": "Africa"
            },
            {
                "countryCode": "FI",
                "countryName": "Finland",
                "currencyCode": "EUR",
                "population": "5244000",
                "capital": "Helsinki",
                "continentName": "Europe"
            },
            {
                "countryCode": "FJ",
                "countryName": "Fiji",
                "currencyCode": "FJD",
                "population": "875983",
                "capital": "Suva",
                "continentName": "Oceania"
            },
            {
                "countryCode": "FK",
                "countryName": "Falkland Islands",
                "currencyCode": "FKP",
                "population": "2638",
                "capital": "Stanley",
                "continentName": "South America"
            },
            {
                "countryCode": "FM",
                "countryName": "Micronesia",
                "currencyCode": "USD",
                "population": "107708",
                "capital": "Palikir",
                "continentName": "Oceania"
            },
            {
                "countryCode": "FO",
                "countryName": "Faroe Islands",
                "currencyCode": "DKK",
                "population": "48228",
                "capital": "Tórshavn",
                "continentName": "Europe"
            },
            {
                "countryCode": "FR",
                "countryName": "France",
                "currencyCode": "EUR",
                "population": "64768389",
                "capital": "Paris",
                "continentName": "Europe"
            },
            {
                "countryCode": "GA",
                "countryName": "Gabon",
                "currencyCode": "XAF",
                "population": "1545255",
                "capital": "Libreville",
                "continentName": "Africa"
            },
            {
                "countryCode": "GB",
                "countryName": "United Kingdom",
                "currencyCode": "GBP",
                "population": "62348447",
                "capital": "London",
                "continentName": "Europe"
            },
            {
                "countryCode": "GD",
                "countryName": "Grenada",
                "currencyCode": "XCD",
                "population": "107818",
                "capital": "St. George's",
                "continentName": "North America"
            },
            {
                "countryCode": "GE",
                "countryName": "Georgia",
                "currencyCode": "GEL",
                "population": "4630000",
                "capital": "Tbilisi",
                "continentName": "Asia"
            },
            {
                "countryCode": "GF",
                "countryName": "French Guiana",
                "currencyCode": "EUR",
                "population": "195506",
                "capital": "Cayenne",
                "continentName": "South America"
            },
            {
                "countryCode": "GG",
                "countryName": "Guernsey",
                "currencyCode": "GBP",
                "population": "65228",
                "capital": "St Peter Port",
                "continentName": "Europe"
            },
            {
                "countryCode": "GH",
                "countryName": "Ghana",
                "currencyCode": "GHS",
                "population": "24339838",
                "capital": "Accra",
                "continentName": "Africa"
            },
            {
                "countryCode": "GI",
                "countryName": "Gibraltar",
                "currencyCode": "GIP",
                "population": "27884",
                "capital": "Gibraltar",
                "continentName": "Europe"
            },
            {
                "countryCode": "GL",
                "countryName": "Greenland",
                "currencyCode": "DKK",
                "population": "56375",
                "capital": "Nuuk",
                "continentName": "North America"
            },
            {
                "countryCode": "GM",
                "countryName": "Gambia",
                "currencyCode": "GMD",
                "population": "1593256",
                "capital": "Bathurst",
                "continentName": "Africa"
            },
            {
                "countryCode": "GN",
                "countryName": "Guinea",
                "currencyCode": "GNF",
                "population": "10324025",
                "capital": "Conakry",
                "continentName": "Africa"
            },
            {
                "countryCode": "GP",
                "countryName": "Guadeloupe",
                "currencyCode": "EUR",
                "population": "443000",
                "capital": "Basse-Terre",
                "continentName": "North America"
            },
            {
                "countryCode": "GQ",
                "countryName": "Equatorial Guinea",
                "currencyCode": "XAF",
                "population": "1014999",
                "capital": "Malabo",
                "continentName": "Africa"
            },
            {
                "countryCode": "GR",
                "countryName": "Greece",
                "currencyCode": "EUR",
                "population": "11000000",
                "capital": "Athens",
                "continentName": "Europe"
            },
            {
                "countryCode": "GS",
                "countryName": "South Georgia and the South Sandwich Islands",
                "currencyCode": "GBP",
                "population": "30",
                "capital": "Grytviken",
                "continentName": "Antarctica"
            },
            {
                "countryCode": "GT",
                "countryName": "Guatemala",
                "currencyCode": "GTQ",
                "population": "13550440",
                "capital": "Guatemala City",
                "continentName": "North America"
            },
            {
                "countryCode": "GU",
                "countryName": "Guam",
                "currencyCode": "USD",
                "population": "159358",
                "capital": "Hagåtña",
                "continentName": "Oceania"
            },
            {
                "countryCode": "GW",
                "countryName": "Guinea-Bissau",
                "currencyCode": "XOF",
                "population": "1565126",
                "capital": "Bissau",
                "continentName": "Africa"
            },
            {
                "countryCode": "GY",
                "countryName": "Guyana",
                "currencyCode": "GYD",
                "population": "748486",
                "capital": "Georgetown",
                "continentName": "South America"
            },
            {
                "countryCode": "HK",
                "countryName": "Hong Kong",
                "currencyCode": "HKD",
                "population": "6898686",
                "capital": "Hong Kong",
                "continentName": "Asia"
            },
            {
                "countryCode": "HM",
                "countryName": "Heard Island and McDonald Islands",
                "currencyCode": "AUD",
                "population": "0",
                "capital": "",
                "continentName": "Antarctica"
            },
            {
                "countryCode": "HN",
                "countryName": "Honduras",
                "currencyCode": "HNL",
                "population": "7989415",
                "capital": "Tegucigalpa",
                "continentName": "North America"
            },
            {
                "countryCode": "HR",
                "countryName": "Croatia",
                "currencyCode": "HRK",
                "population": "4284889",
                "capital": "Zagreb",
                "continentName": "Europe"
            },
            {
                "countryCode": "HT",
                "countryName": "Haiti",
                "currencyCode": "HTG",
                "population": "9648924",
                "capital": "Port-au-Prince",
                "continentName": "North America"
            },
            {
                "countryCode": "HU",
                "countryName": "Hungary",
                "currencyCode": "HUF",
                "population": "9982000",
                "capital": "Budapest",
                "continentName": "Europe"
            },
            {
                "countryCode": "ID",
                "countryName": "Indonesia",
                "currencyCode": "IDR",
                "population": "242968342",
                "capital": "Jakarta",
                "continentName": "Asia"
            },
            {
                "countryCode": "IE",
                "countryName": "Ireland",
                "currencyCode": "EUR",
                "population": "4622917",
                "capital": "Dublin",
                "continentName": "Europe"
            },
            {
                "countryCode": "IL",
                "countryName": "Israel",
                "currencyCode": "ILS",
                "population": "7353985",
                "capital": "",
                "continentName": "Asia"
            },
            {
                "countryCode": "IM",
                "countryName": "Isle of Man",
                "currencyCode": "GBP",
                "population": "75049",
                "capital": "Douglas",
                "continentName": "Europe"
            },
            {
                "countryCode": "IN",
                "countryName": "India",
                "currencyCode": "INR",
                "population": "1173108018",
                "capital": "New Delhi",
                "continentName": "Asia"
            },
            {
                "countryCode": "IO",
                "countryName": "British Indian Ocean Territory",
                "currencyCode": "USD",
                "population": "4000",
                "capital": "",
                "continentName": "Asia"
            },
            {
                "countryCode": "IQ",
                "countryName": "Iraq",
                "currencyCode": "IQD",
                "population": "29671605",
                "capital": "Baghdad",
                "continentName": "Asia"
            },
            {
                "countryCode": "IR",
                "countryName": "Iran",
                "currencyCode": "IRR",
                "population": "76923300",
                "capital": "Tehran",
                "continentName": "Asia"
            },
            {
                "countryCode": "IS",
                "countryName": "Iceland",
                "currencyCode": "ISK",
                "population": "308910",
                "capital": "Reykjavik",
                "continentName": "Europe"
            },
            {
                "countryCode": "IT",
                "countryName": "Italy",
                "currencyCode": "EUR",
                "population": "60340328",
                "capital": "Rome",
                "continentName": "Europe"
            },
            {
                "countryCode": "JE",
                "countryName": "Jersey",
                "currencyCode": "GBP",
                "population": "90812",
                "capital": "Saint Helier",
                "continentName": "Europe"
            },
            {
                "countryCode": "JM",
                "countryName": "Jamaica",
                "currencyCode": "JMD",
                "population": "2847232",
                "capital": "Kingston",
                "continentName": "North America"
            },
            {
                "countryCode": "JO",
                "countryName": "Jordan",
                "currencyCode": "JOD",
                "population": "6407085",
                "capital": "Amman",
                "continentName": "Asia"
            },
            {
                "countryCode": "JP",
                "countryName": "Japan",
                "currencyCode": "JPY",
                "population": "127288000",
                "capital": "Tokyo",
                "continentName": "Asia"
            },
            {
                "countryCode": "KE",
                "countryName": "Kenya",
                "currencyCode": "KES",
                "population": "40046566",
                "capital": "Nairobi",
                "continentName": "Africa"
            },
            {
                "countryCode": "KG",
                "countryName": "Kyrgyzstan",
                "currencyCode": "KGS",
                "population": "5776500",
                "capital": "Bishkek",
                "continentName": "Asia"
            },
            {
                "countryCode": "KH",
                "countryName": "Cambodia",
                "currencyCode": "KHR",
                "population": "14453680",
                "capital": "Phnom Penh",
                "continentName": "Asia"
            },
            {
                "countryCode": "KI",
                "countryName": "Kiribati",
                "currencyCode": "AUD",
                "population": "92533",
                "capital": "Tarawa",
                "continentName": "Oceania"
            },
            {
                "countryCode": "KM",
                "countryName": "Comoros",
                "currencyCode": "KMF",
                "population": "773407",
                "capital": "Moroni",
                "continentName": "Africa"
            },
            {
                "countryCode": "KN",
                "countryName": "Saint Kitts and Nevis",
                "currencyCode": "XCD",
                "population": "51134",
                "capital": "Basseterre",
                "continentName": "North America"
            },
            {
                "countryCode": "KP",
                "countryName": "North Korea",
                "currencyCode": "KPW",
                "population": "22912177",
                "capital": "Pyongyang",
                "continentName": "Asia"
            },
            {
                "countryCode": "KR",
                "countryName": "South Korea",
                "currencyCode": "KRW",
                "population": "48422644",
                "capital": "Seoul",
                "continentName": "Asia"
            },
            {
                "countryCode": "KW",
                "countryName": "Kuwait",
                "currencyCode": "KWD",
                "population": "2789132",
                "capital": "Kuwait City",
                "continentName": "Asia"
            },
            {
                "countryCode": "KY",
                "countryName": "Cayman Islands",
                "currencyCode": "KYD",
                "population": "44270",
                "capital": "George Town",
                "continentName": "North America"
            },
            {
                "countryCode": "KZ",
                "countryName": "Kazakhstan",
                "currencyCode": "KZT",
                "population": "15340000",
                "capital": "Astana",
                "continentName": "Asia"
            },
            {
                "countryCode": "LA",
                "countryName": "Laos",
                "currencyCode": "LAK",
                "population": "6368162",
                "capital": "Vientiane",
                "continentName": "Asia"
            },
            {
                "countryCode": "LB",
                "countryName": "Lebanon",
                "currencyCode": "LBP",
                "population": "4125247",
                "capital": "Beirut",
                "continentName": "Asia"
            },
            {
                "countryCode": "LC",
                "countryName": "Saint Lucia",
                "currencyCode": "XCD",
                "population": "160922",
                "capital": "Castries",
                "continentName": "North America"
            },
            {
                "countryCode": "LI",
                "countryName": "Liechtenstein",
                "currencyCode": "CHF",
                "population": "35000",
                "capital": "Vaduz",
                "continentName": "Europe"
            },
            {
                "countryCode": "LK",
                "countryName": "Sri Lanka",
                "currencyCode": "LKR",
                "population": "21513990",
                "capital": "Colombo",
                "continentName": "Asia"
            },
            {
                "countryCode": "LR",
                "countryName": "Liberia",
                "currencyCode": "LRD",
                "population": "3685076",
                "capital": "Monrovia",
                "continentName": "Africa"
            },
            {
                "countryCode": "LS",
                "countryName": "Lesotho",
                "currencyCode": "LSL",
                "population": "1919552",
                "capital": "Maseru",
                "continentName": "Africa"
            },
            {
                "countryCode": "LT",
                "countryName": "Lithuania",
                "currencyCode": "EUR",
                "population": "2944459",
                "capital": "Vilnius",
                "continentName": "Europe"
            },
            {
                "countryCode": "LU",
                "countryName": "Luxembourg",
                "currencyCode": "EUR",
                "population": "497538",
                "capital": "Luxembourg",
                "continentName": "Europe"
            },
            {
                "countryCode": "LV",
                "countryName": "Latvia",
                "currencyCode": "EUR",
                "population": "2217969",
                "capital": "Riga",
                "continentName": "Europe"
            },
            {
                "countryCode": "LY",
                "countryName": "Libya",
                "currencyCode": "LYD",
                "population": "6461454",
                "capital": "Tripoli",
                "continentName": "Africa"
            },
            {
                "countryCode": "MA",
                "countryName": "Morocco",
                "currencyCode": "MAD",
                "population": "33848242",
                "capital": "Rabat",
                "continentName": "Africa"
            },
            {
                "countryCode": "MC",
                "countryName": "Monaco",
                "currencyCode": "EUR",
                "population": "32965",
                "capital": "Monaco",
                "continentName": "Europe"
            },
            {
                "countryCode": "MD",
                "countryName": "Moldova",
                "currencyCode": "MDL",
                "population": "4324000",
                "capital": "Chişinău",
                "continentName": "Europe"
            },
            {
                "countryCode": "ME",
                "countryName": "Montenegro",
                "currencyCode": "EUR",
                "population": "666730",
                "capital": "Podgorica",
                "continentName": "Europe"
            },
            {
                "countryCode": "MF",
                "countryName": "Saint Martin",
                "currencyCode": "EUR",
                "population": "35925",
                "capital": "Marigot",
                "continentName": "North America"
            },
            {
                "countryCode": "MG",
                "countryName": "Madagascar",
                "currencyCode": "MGA",
                "population": "21281844",
                "capital": "Antananarivo",
                "continentName": "Africa"
            },
            {
                "countryCode": "MH",
                "countryName": "Marshall Islands",
                "currencyCode": "USD",
                "population": "65859",
                "capital": "Majuro",
                "continentName": "Oceania"
            },
            {
                "countryCode": "MK",
                "countryName": "Macedonia",
                "currencyCode": "MKD",
                "population": "2062294",
                "capital": "Skopje",
                "continentName": "Europe"
            },
            {
                "countryCode": "ML",
                "countryName": "Mali",
                "currencyCode": "XOF",
                "population": "13796354",
                "capital": "Bamako",
                "continentName": "Africa"
            },
            {
                "countryCode": "MM",
                "countryName": "Myanmar [Burma]",
                "currencyCode": "MMK",
                "population": "53414374",
                "capital": "Naypyitaw",
                "continentName": "Asia"
            },
            {
                "countryCode": "MN",
                "countryName": "Mongolia",
                "currencyCode": "MNT",
                "population": "3086918",
                "capital": "Ulan Bator",
                "continentName": "Asia"
            },
            {
                "countryCode": "MO",
                "countryName": "Macao",
                "currencyCode": "MOP",
                "population": "449198",
                "capital": "Macao",
                "continentName": "Asia"
            },
            {
                "countryCode": "MP",
                "countryName": "Northern Mariana Islands",
                "currencyCode": "USD",
                "population": "53883",
                "capital": "Saipan",
                "continentName": "Oceania"
            },
            {
                "countryCode": "MQ",
                "countryName": "Martinique",
                "currencyCode": "EUR",
                "population": "432900",
                "capital": "Fort-de-France",
                "continentName": "North America"
            },
            {
                "countryCode": "MR",
                "countryName": "Mauritania",
                "currencyCode": "MRO",
                "population": "3205060",
                "capital": "Nouakchott",
                "continentName": "Africa"
            },
            {
                "countryCode": "MS",
                "countryName": "Montserrat",
                "currencyCode": "XCD",
                "population": "9341",
                "capital": "Plymouth",
                "continentName": "North America"
            },
            {
                "countryCode": "MT",
                "countryName": "Malta",
                "currencyCode": "EUR",
                "population": "403000",
                "capital": "Valletta",
                "continentName": "Europe"
            },
            {
                "countryCode": "MU",
                "countryName": "Mauritius",
                "currencyCode": "MUR",
                "population": "1294104",
                "capital": "Port Louis",
                "continentName": "Africa"
            },
            {
                "countryCode": "MV",
                "countryName": "Maldives",
                "currencyCode": "MVR",
                "population": "395650",
                "capital": "Malé",
                "continentName": "Asia"
            },
            {
                "countryCode": "MW",
                "countryName": "Malawi",
                "currencyCode": "MWK",
                "population": "15447500",
                "capital": "Lilongwe",
                "continentName": "Africa"
            },
            {
                "countryCode": "MX",
                "countryName": "Mexico",
                "currencyCode": "MXN",
                "population": "112468855",
                "capital": "Mexico City",
                "continentName": "North America"
            },
            {
                "countryCode": "MY",
                "countryName": "Malaysia",
                "currencyCode": "MYR",
                "population": "28274729",
                "capital": "Kuala Lumpur",
                "continentName": "Asia"
            },
            {
                "countryCode": "MZ",
                "countryName": "Mozambique",
                "currencyCode": "MZN",
                "population": "22061451",
                "capital": "Maputo",
                "continentName": "Africa"
            },
            {
                "countryCode": "NA",
                "countryName": "Namibia",
                "currencyCode": "NAD",
                "population": "2128471",
                "capital": "Windhoek",
                "continentName": "Africa"
            },
            {
                "countryCode": "NC",
                "countryName": "New Caledonia",
                "currencyCode": "XPF",
                "population": "216494",
                "capital": "Noumea",
                "continentName": "Oceania"
            },
            {
                "countryCode": "NE",
                "countryName": "Niger",
                "currencyCode": "XOF",
                "population": "15878271",
                "capital": "Niamey",
                "continentName": "Africa"
            },
            {
                "countryCode": "NF",
                "countryName": "Norfolk Island",
                "currencyCode": "AUD",
                "population": "1828",
                "capital": "Kingston",
                "continentName": "Oceania"
            },
            {
                "countryCode": "NG",
                "countryName": "Nigeria",
                "currencyCode": "NGN",
                "population": "154000000",
                "capital": "Abuja",
                "continentName": "Africa"
            },
            {
                "countryCode": "NI",
                "countryName": "Nicaragua",
                "currencyCode": "NIO",
                "population": "5995928",
                "capital": "Managua",
                "continentName": "North America"
            },
            {
                "countryCode": "NL",
                "countryName": "Netherlands",
                "currencyCode": "EUR",
                "population": "16645000",
                "capital": "Amsterdam",
                "continentName": "Europe"
            },
            {
                "countryCode": "NO",
                "countryName": "Norway",
                "currencyCode": "NOK",
                "population": "5009150",
                "capital": "Oslo",
                "continentName": "Europe"
            },
            {
                "countryCode": "NP",
                "countryName": "Nepal",
                "currencyCode": "NPR",
                "population": "28951852",
                "capital": "Kathmandu",
                "continentName": "Asia"
            },
            {
                "countryCode": "NR",
                "countryName": "Nauru",
                "currencyCode": "AUD",
                "population": "10065",
                "capital": "Yaren",
                "continentName": "Oceania"
            },
            {
                "countryCode": "NU",
                "countryName": "Niue",
                "currencyCode": "NZD",
                "population": "2166",
                "capital": "Alofi",
                "continentName": "Oceania"
            },
            {
                "countryCode": "NZ",
                "countryName": "New Zealand",
                "currencyCode": "NZD",
                "population": "4252277",
                "capital": "Wellington",
                "continentName": "Oceania"
            },
            {
                "countryCode": "OM",
                "countryName": "Oman",
                "currencyCode": "OMR",
                "population": "2967717",
                "capital": "Muscat",
                "continentName": "Asia"
            },
            {
                "countryCode": "PA",
                "countryName": "Panama",
                "currencyCode": "PAB",
                "population": "3410676",
                "capital": "Panama City",
                "continentName": "North America"
            },
            {
                "countryCode": "PE",
                "countryName": "Peru",
                "currencyCode": "PEN",
                "population": "29907003",
                "capital": "Lima",
                "continentName": "South America"
            },
            {
                "countryCode": "PF",
                "countryName": "French Polynesia",
                "currencyCode": "XPF",
                "population": "270485",
                "capital": "Papeete",
                "continentName": "Oceania"
            },
            {
                "countryCode": "PG",
                "countryName": "Papua New Guinea",
                "currencyCode": "PGK",
                "population": "6064515",
                "capital": "Port Moresby",
                "continentName": "Oceania"
            },
            {
                "countryCode": "PH",
                "countryName": "Philippines",
                "currencyCode": "PHP",
                "population": "99900177",
                "capital": "Manila",
                "continentName": "Asia"
            },
            {
                "countryCode": "PK",
                "countryName": "Pakistan",
                "currencyCode": "PKR",
                "population": "184404791",
                "capital": "Islamabad",
                "continentName": "Asia"
            },
            {
                "countryCode": "PL",
                "countryName": "Poland",
                "currencyCode": "PLN",
                "population": "38500000",
                "capital": "Warsaw",
                "continentName": "Europe"
            },
            {
                "countryCode": "PM",
                "countryName": "Saint Pierre and Miquelon",
                "currencyCode": "EUR",
                "population": "7012",
                "capital": "Saint-Pierre",
                "continentName": "North America"
            },
            {
                "countryCode": "PN",
                "countryName": "Pitcairn Islands",
                "currencyCode": "NZD",
                "population": "46",
                "capital": "Adamstown",
                "continentName": "Oceania"
            },
            {
                "countryCode": "PR",
                "countryName": "Puerto Rico",
                "currencyCode": "USD",
                "population": "3916632",
                "capital": "San Juan",
                "continentName": "North America"
            },
            {
                "countryCode": "PS",
                "countryName": "Palestine",
                "currencyCode": "ILS",
                "population": "3800000",
                "capital": "",
                "continentName": "Asia"
            },
            {
                "countryCode": "PT",
                "countryName": "Portugal",
                "currencyCode": "EUR",
                "population": "10676000",
                "capital": "Lisbon",
                "continentName": "Europe"
            },
            {
                "countryCode": "PW",
                "countryName": "Palau",
                "currencyCode": "USD",
                "population": "19907",
                "capital": "Melekeok",
                "continentName": "Oceania"
            },
            {
                "countryCode": "PY",
                "countryName": "Paraguay",
                "currencyCode": "PYG",
                "population": "6375830",
                "capital": "Asunción",
                "continentName": "South America"
            },
            {
                "countryCode": "QA",
                "countryName": "Qatar",
                "currencyCode": "QAR",
                "population": "840926",
                "capital": "Doha",
                "continentName": "Asia"
            },
            {
                "countryCode": "RE",
                "countryName": "Réunion",
                "currencyCode": "EUR",
                "population": "776948",
                "capital": "Saint-Denis",
                "continentName": "Africa"
            },
            {
                "countryCode": "RO",
                "countryName": "Romania",
                "currencyCode": "RON",
                "population": "21959278",
                "capital": "Bucharest",
                "continentName": "Europe"
            },
            {
                "countryCode": "RS",
                "countryName": "Serbia",
                "currencyCode": "RSD",
                "population": "7344847",
                "capital": "Belgrade",
                "continentName": "Europe"
            },
            {
                "countryCode": "RU",
                "countryName": "Russia",
                "currencyCode": "RUB",
                "population": "140702000",
                "capital": "Moscow",
                "continentName": "Europe"
            },
            {
                "countryCode": "RW",
                "countryName": "Rwanda",
                "currencyCode": "RWF",
                "population": "11055976",
                "capital": "Kigali",
                "continentName": "Africa"
            },
            {
                "countryCode": "SA",
                "countryName": "Saudi Arabia",
                "currencyCode": "SAR",
                "population": "25731776",
                "capital": "Riyadh",
                "continentName": "Asia"
            },
            {
                "countryCode": "SB",
                "countryName": "Solomon Islands",
                "currencyCode": "SBD",
                "population": "559198",
                "capital": "Honiara",
                "continentName": "Oceania"
            },
            {
                "countryCode": "SC",
                "countryName": "Seychelles",
                "currencyCode": "SCR",
                "population": "88340",
                "capital": "Victoria",
                "continentName": "Africa"
            },
            {
                "countryCode": "SD",
                "countryName": "Sudan",
                "currencyCode": "SDG",
                "population": "35000000",
                "capital": "Khartoum",
                "continentName": "Africa"
            },
            {
                "countryCode": "SE",
                "countryName": "Sweden",
                "currencyCode": "SEK",
                "population": "9828655",
                "capital": "Stockholm",
                "continentName": "Europe"
            },
            {
                "countryCode": "SG",
                "countryName": "Singapore",
                "currencyCode": "SGD",
                "population": "4701069",
                "capital": "Singapore",
                "continentName": "Asia"
            },
            {
                "countryCode": "SH",
                "countryName": "Saint Helena",
                "currencyCode": "SHP",
                "population": "7460",
                "capital": "Jamestown",
                "continentName": "Africa"
            },
            {
                "countryCode": "SI",
                "countryName": "Slovenia",
                "currencyCode": "EUR",
                "population": "2007000",
                "capital": "Ljubljana",
                "continentName": "Europe"
            },
            {
                "countryCode": "SJ",
                "countryName": "Svalbard and Jan Mayen",
                "currencyCode": "NOK",
                "population": "2550",
                "capital": "Longyearbyen",
                "continentName": "Europe"
            },
            {
                "countryCode": "SK",
                "countryName": "Slovakia",
                "currencyCode": "EUR",
                "population": "5455000",
                "capital": "Bratislava",
                "continentName": "Europe"
            },
            {
                "countryCode": "SL",
                "countryName": "Sierra Leone",
                "currencyCode": "SLL",
                "population": "5245695",
                "capital": "Freetown",
                "continentName": "Africa"
            },
            {
                "countryCode": "SM",
                "countryName": "San Marino",
                "currencyCode": "EUR",
                "population": "31477",
                "capital": "San Marino",
                "continentName": "Europe"
            },
            {
                "countryCode": "SN",
                "countryName": "Senegal",
                "currencyCode": "XOF",
                "population": "12323252",
                "capital": "Dakar",
                "continentName": "Africa"
            },
            {
                "countryCode": "SO",
                "countryName": "Somalia",
                "currencyCode": "SOS",
                "population": "10112453",
                "capital": "Mogadishu",
                "continentName": "Africa"
            },
            {
                "countryCode": "SR",
                "countryName": "Suriname",
                "currencyCode": "SRD",
                "population": "492829",
                "capital": "Paramaribo",
                "continentName": "South America"
            },
            {
                "countryCode": "SS",
                "countryName": "South Sudan",
                "currencyCode": "SSP",
                "population": "8260490",
                "capital": "Juba",
                "continentName": "Africa"
            },
            {
                "countryCode": "ST",
                "countryName": "São Tomé and Príncipe",
                "currencyCode": "STD",
                "population": "175808",
                "capital": "São Tomé",
                "continentName": "Africa"
            },
            {
                "countryCode": "SV",
                "countryName": "El Salvador",
                "currencyCode": "USD",
                "population": "6052064",
                "capital": "San Salvador",
                "continentName": "North America"
            },
            {
                "countryCode": "SX",
                "countryName": "Sint Maarten",
                "currencyCode": "ANG",
                "population": "37429",
                "capital": "Philipsburg",
                "continentName": "North America"
            },
            {
                "countryCode": "SY",
                "countryName": "Syria",
                "currencyCode": "SYP",
                "population": "22198110",
                "capital": "Damascus",
                "continentName": "Asia"
            },
            {
                "countryCode": "SZ",
                "countryName": "Swaziland",
                "currencyCode": "SZL",
                "population": "1354051",
                "capital": "Mbabane",
                "continentName": "Africa"
            },
            {
                "countryCode": "TC",
                "countryName": "Turks and Caicos Islands",
                "currencyCode": "USD",
                "population": "20556",
                "capital": "Cockburn Town",
                "continentName": "North America"
            },
            {
                "countryCode": "TD",
                "countryName": "Chad",
                "currencyCode": "XAF",
                "population": "10543464",
                "capital": "N'Djamena",
                "continentName": "Africa"
            },
            {
                "countryCode": "TF",
                "countryName": "French Southern Territories",
                "currencyCode": "EUR",
                "population": "140",
                "capital": "Port-aux-Français",
                "continentName": "Antarctica"
            },
            {
                "countryCode": "TG",
                "countryName": "Togo",
                "currencyCode": "XOF",
                "population": "6587239",
                "capital": "Lomé",
                "continentName": "Africa"
            },
            {
                "countryCode": "TH",
                "countryName": "Thailand",
                "currencyCode": "THB",
                "population": "67089500",
                "capital": "Bangkok",
                "continentName": "Asia"
            },
            {
                "countryCode": "TJ",
                "countryName": "Tajikistan",
                "currencyCode": "TJS",
                "population": "7487489",
                "capital": "Dushanbe",
                "continentName": "Asia"
            },
            {
                "countryCode": "TK",
                "countryName": "Tokelau",
                "currencyCode": "NZD",
                "population": "1466",
                "capital": "",
                "continentName": "Oceania"
            },
            {
                "countryCode": "TL",
                "countryName": "East Timor",
                "currencyCode": "USD",
                "population": "1154625",
                "capital": "Dili",
                "continentName": "Oceania"
            },
            {
                "countryCode": "TM",
                "countryName": "Turkmenistan",
                "currencyCode": "TMT",
                "population": "4940916",
                "capital": "Ashgabat",
                "continentName": "Asia"
            },
            {
                "countryCode": "TN",
                "countryName": "Tunisia",
                "currencyCode": "TND",
                "population": "10589025",
                "capital": "Tunis",
                "continentName": "Africa"
            },
            {
                "countryCode": "TO",
                "countryName": "Tonga",
                "currencyCode": "TOP",
                "population": "122580",
                "capital": "Nuku'alofa",
                "continentName": "Oceania"
            },
            {
                "countryCode": "TR",
                "countryName": "Turkey",
                "currencyCode": "TRY",
                "population": "77804122",
                "capital": "Ankara",
                "continentName": "Asia"
            },
            {
                "countryCode": "TT",
                "countryName": "Trinidad and Tobago",
                "currencyCode": "TTD",
                "population": "1228691",
                "capital": "Port of Spain",
                "continentName": "North America"
            },
            {
                "countryCode": "TV",
                "countryName": "Tuvalu",
                "currencyCode": "AUD",
                "population": "10472",
                "capital": "Funafuti",
                "continentName": "Oceania"
            },
            {
                "countryCode": "TW",
                "countryName": "Taiwan",
                "currencyCode": "TWD",
                "population": "22894384",
                "capital": "Taipei",
                "continentName": "Asia"
            },
            {
                "countryCode": "TZ",
                "countryName": "Tanzania",
                "currencyCode": "TZS",
                "population": "41892895",
                "capital": "Dodoma",
                "continentName": "Africa"
            },
            {
                "countryCode": "UA",
                "countryName": "Ukraine",
                "currencyCode": "UAH",
                "population": "45415596",
                "capital": "Kiev",
                "continentName": "Europe"
            },
            {
                "countryCode": "UG",
                "countryName": "Uganda",
                "currencyCode": "UGX",
                "population": "33398682",
                "capital": "Kampala",
                "continentName": "Africa"
            },
            {
                "countryCode": "UM",
                "countryName": "U.S. Minor Outlying Islands",
                "currencyCode": "USD",
                "population": "0",
                "capital": "",
                "continentName": "Oceania"
            },
            {
                "countryCode": "US",
                "countryName": "United States of America (USA)",
                "currencyCode": "USD",
                "population": "310232863",
                "capital": "Washington",
                "continentName": "North America"
            },
            {
                "countryCode": "UY",
                "countryName": "Uruguay",
                "currencyCode": "UYU",
                "population": "3477000",
                "capital": "Montevideo",
                "continentName": "South America"
            },
            {
                "countryCode": "UZ",
                "countryName": "Uzbekistan",
                "currencyCode": "UZS",
                "population": "27865738",
                "capital": "Tashkent",
                "continentName": "Asia"
            },
            {
                "countryCode": "VA",
                "countryName": "Vatican City",
                "currencyCode": "EUR",
                "population": "921",
                "capital": "Vatican City",
                "continentName": "Europe"
            },
            {
                "countryCode": "VC",
                "countryName": "Saint Vincent and the Grenadines",
                "currencyCode": "XCD",
                "population": "104217",
                "capital": "Kingstown",
                "continentName": "North America"
            },
            {
                "countryCode": "VE",
                "countryName": "Venezuela",
                "currencyCode": "VEF",
                "population": "27223228",
                "capital": "Caracas",
                "continentName": "South America"
            },
            {
                "countryCode": "VG",
                "countryName": "British Virgin Islands",
                "currencyCode": "USD",
                "population": "21730",
                "capital": "Road Town",
                "continentName": "North America"
            },
            {
                "countryCode": "VI",
                "countryName": "U.S. Virgin Islands",
                "currencyCode": "USD",
                "population": "108708",
                "capital": "Charlotte Amalie",
                "continentName": "North America"
            },
            {
                "countryCode": "VN",
                "countryName": "Vietnam",
                "currencyCode": "VND",
                "population": "89571130",
                "capital": "Hanoi",
                "continentName": "Asia"
            },
            {
                "countryCode": "VU",
                "countryName": "Vanuatu",
                "currencyCode": "VUV",
                "population": "221552",
                "capital": "Port Vila",
                "continentName": "Oceania"
            },
            {
                "countryCode": "WF",
                "countryName": "Wallis and Futuna",
                "currencyCode": "XPF",
                "population": "16025",
                "capital": "Mata-Utu",
                "continentName": "Oceania"
            },
            {
                "countryCode": "WS",
                "countryName": "Samoa",
                "currencyCode": "WST",
                "population": "192001",
                "capital": "Apia",
                "continentName": "Oceania"
            },
            {
                "countryCode": "XK",
                "countryName": "Kosovo",
                "currencyCode": "EUR",
                "population": "1800000",
                "capital": "Pristina",
                "continentName": "Europe"
            },
            {
                "countryCode": "YE",
                "countryName": "Yemen",
                "currencyCode": "YER",
                "population": "23495361",
                "capital": "Sanaa",
                "continentName": "Asia"
            },
            {
                "countryCode": "YT",
                "countryName": "Mayotte",
                "currencyCode": "EUR",
                "population": "159042",
                "capital": "Mamoudzou",
                "continentName": "Africa"
            },
            {
                "countryCode": "ZA",
                "countryName": "South Africa",
                "currencyCode": "ZAR",
                "population": "49000000",
                "capital": "Pretoria",
                "continentName": "Africa"
            },
            {
                "countryCode": "ZM",
                "countryName": "Zambia",
                "currencyCode": "ZMW",
                "population": "13460305",
                "capital": "Lusaka",
                "continentName": "Africa"
            },
            {
                "countryCode": "ZW",
                "countryName": "Zimbabwe",
                "currencyCode": "ZWL",
                "population": "13061000",
                "capital": "Harare",
                "continentName": "Africa"
            }
        ]

     const statesDetails = {
       Afghanistan: [
         "Badakhshan",
         "Badghis",
         "Baghlan",
         "Balkh",
         "Bamian",
         "Daykondi",
         "Farah",
         "Faryab",
         "Ghazni",
         "Ghowr",
         "Helmand",
         "Herat",
         "Jowzjan",
         "Kabul",
         "Kandahar",
         "Kapisa",
         "Khost",
         "Konar",
         "Kondoz",
         "Laghman",
         "Lowgar",
         "Nangarhar",
         "Nimruz",
         "Nurestan",
         "Oruzgan",
         "Paktia",
         "Paktika",
         "Panjshir",
         "Parvan",
         "Samangan",
         "Sar-e Pol",
         "Takhar",
         "Vardak",
         "Zabol",
       ],
       Albania: [
         "Berat",
         "Dibres",
         "Durres",
         "Elbasan",
         "Fier",
         "Gjirokastre",
         "Korce",
         "Kukes",
         "Lezhe",
         "Shkoder",
         "Tirane",
         "Vlore",
       ],
       Algeria: [
         "Adrar",
         "Ain Defla",
         "Ain Temouchent",
         "Alger",
         "Annaba",
         "Batna",
         "Bechar",
         "Bejaia",
         "Biskra",
         "Blida",
         "Bordj Bou Arreridj",
         "Bouira",
         "Boumerdes",
         "Chlef",
         "Constantine",
         "Djelfa",
         "El Bayadh",
         "El Oued",
         "El Tarf",
         "Ghardaia",
         "Guelma",
         "Illizi",
         "Jijel",
         "Khenchela",
         "Laghouat",
         "Muaskar",
         "Medea",
         "Mila",
         "Mostaganem",
         "M'Sila",
         "Naama",
         "Oran",
         "Ouargla",
         "Oum el Bouaghi",
         "Relizane",
         "Saida",
         "Setif",
         "Sidi Bel Abbes",
         "Skikda",
         "Souk Ahras",
         "Tamanghasset",
         "Tebessa",
         "Tiaret",
         "Tindouf",
         "Tipaza",
         "Tissemsilt",
         "Tizi Ouzou",
         "Tlemcen",
       ],
       "American Samoa": null,
       Andorra: [
         "Andorra la Vella",
         "Canillo",
         "Encamp",
         "Escaldes-Engordany",
         "La Massana",
         "Ordino",
         "Sant Julia de Loria",
       ],
       Angola: [
         "Bengo",
         "Benguela",
         "Bie",
         "Cabinda",
         "Cuando Cubango",
         "Cuanza Norte",
         "Cuanza Sul",
         "Cunene",
         "Huambo",
         "Huila",
         "Luanda",
         "Lunda Norte",
         "Lunda Sul",
         "Malanje",
         "Moxico",
         "Namibe",
         "Uige",
         "Zaire",
       ],
       Anguilla: null,
       Antarctica: null,
       "Antigua and Barbuda": [
         "Barbuda",
         "Redonda",
         "Saint George",
         "Saint John",
         "Saint Mary",
         "Saint Paul",
         "Saint Peter",
         "Saint Philip",
       ],
       Argentina: [
         "Buenos Aires",
         "Buenos Aires Capital",
         "Catamarca",
         "Chaco",
         "Chubut",
         "Cordoba",
         "Corrientes",
         "Entre Rios",
         "Formosa",
         "Jujuy",
         "La Pampa",
         "La Rioja",
         "Mendoza",
         "Misiones",
         "Neuquen",
         "Rio Negro",
         "Salta",
         "San Juan",
         "San Luis",
         "Santa Cruz",
         "Santa Fe",
         "Santiago del Estero",
         "Tierra del Fuego",
         "Tucuman",
       ],
       Armenia: [
         "Aragatsotn",
         "Ararat",
         "Armavir",
         "Geghark'unik'",
         "Kotayk'",
         "Lorri",
         "Shirak",
         "Syunik'",
         "Tavush",
         "Vayots' Dzor",
         "Yerevan",
       ],
       Aruba: null,
       Australia: [
         "Australian Capital Territory",
         "New South Wales",
         "Northern Territory",
         "Queensland",
         "South Australia",
         "Tasmania",
         "Victoria",
         "Western Australia",
       ],
       Austria: [
         "Burgenland",
         "Kaernten",
         "Niederoesterreich",
         "Oberoesterreich",
         "Salzburg",
         "Steiermark",
         "Tirol",
         "Vorarlberg",
         "Wien",
       ],
       Azerbaijan: [
         "Abseron Rayonu",
         "Agcabadi Rayonu",
         "Agdam Rayonu",
         "Agdas Rayonu",
         "Agstafa Rayonu",
         "Agsu Rayonu",
         "Astara Rayonu",
         "Balakan Rayonu",
         "Barda Rayonu",
         "Beylaqan Rayonu",
         "Bilasuvar Rayonu",
         "Cabrayil Rayonu",
         "Calilabad Rayonu",
         "Daskasan Rayonu",
         "Davaci Rayonu",
         "Fuzuli Rayonu",
         "Gadabay Rayonu",
         "Goranboy Rayonu",
         "Goycay Rayonu",
         "Haciqabul Rayonu",
         "Imisli Rayonu",
         "Ismayilli Rayonu",
         "Kalbacar Rayonu",
         "Kurdamir Rayonu",
         "Lacin Rayonu",
         "Lankaran Rayonu",
         "Lerik Rayonu",
         "Masalli Rayonu",
         "Neftcala Rayonu",
         "Oguz Rayonu",
         "Qabala Rayonu",
         "Qax Rayonu",
         "Qazax Rayonu",
         "Qobustan Rayonu",
         "Quba Rayonu",
         "Qubadli Rayonu",
         "Qusar Rayonu",
         "Saatli Rayonu",
         "Sabirabad Rayonu",
         "Saki Rayonu",
         "Salyan Rayonu",
         "Samaxi Rayonu",
         "Samkir Rayonu",
         "Samux Rayonu",
         "Siyazan Rayonu",
         "Susa Rayonu",
         "Tartar Rayonu",
         "Tovuz Rayonu",
         "Ucar Rayonu",
         "Xacmaz Rayonu",
         "Xanlar Rayonu",
         "Xizi Rayonu",
         "Xocali Rayonu",
         "Xocavand Rayonu",
         "Yardimli Rayonu",
         "Yevlax Rayonu",
         "Zangilan Rayonu",
         "Zaqatala Rayonu",
         "Zardab Rayonu",
         "Ali Bayramli Sahari",
         "Baki Sahari",
         "Ganca Sahari",
         "Lankaran Sahari",
         "Mingacevir Sahari",
         "Naftalan Sahari",
         "Saki Sahari",
         "Sumqayit Sahari",
         "Susa Sahari",
         "Xankandi Sahari",
         "Yevlax Sahari",
         "Naxcivan Muxtar",
       ],
       "Bahamas (the)": [
         "Acklins and Crooked Islands",
         "Bimini",
         "Cat Island",
         "Exuma",
         "Freeport",
         "Fresh Creek",
         "Governor's Harbour",
         "Green Turtle Cay",
         "Harbour Island",
         "High Rock",
         "Inagua",
         "Kemps Bay",
         "Long Island",
         "Marsh Harbour",
         "Mayaguana",
         "New Providence",
         "Nichollstown and Berry Islands",
         "Ragged Island",
         "Rock Sound",
         "Sandy Point",
         "San Salvador and Rum Cay",
       ],
       Bahrain: [
         "Al Hadd",
         "Al Manamah",
         "Al Mintaqah al Gharbiyah",
         "Al Mintaqah al Wusta",
         "Al Mintaqah ash Shamaliyah",
         "Al Muharraq",
         "Ar Rifa' wa al Mintaqah al Janubiyah",
         "Jidd Hafs",
         "Madinat Hamad",
         "Madinat 'Isa",
         "Juzur Hawar",
         "Sitrah",
       ],
       Bangladesh: ["Barisal", "Chittagong", "Dhaka", "Khulna", "Rajshahi", "Sylhet"],
       Barbados: [
         "Christ Church",
         "Saint Andrew",
         "Saint George",
         "Saint James",
         "Saint John",
         "Saint Joseph",
         "Saint Lucy",
         "Saint Michael",
         "Saint Peter",
         "Saint Philip",
         "Saint Thomas",
       ],
       Belarus: [
         "Brest",
         "Homyel",
         "Horad Minsk",
         "Hrodna",
         "Mahilyow",
         "Minsk",
         "Vitsyebsk",
       ],
       Belgium: [
         "Antwerpen",
         "Brabant Wallon",
         "Brussels",
         "Flanders",
         "Hainaut",
         "Liege",
         "Limburg",
         "Luxembourg",
         "Namur",
         "Oost-Vlaanderen",
         "Vlaams-Brabant",
         "Wallonia",
         "West-Vlaanderen",
       ],
       Belize: ["Belize", "Cayo", "Corozal", "Orange Walk", "Stann Creek", "Toledo"],
       Benin: [
         "Alibori",
         "Atakora",
         "Atlantique",
         "Borgou",
         "Collines",
         "Donga",
         "Kouffo",
         "Littoral",
         "Mono",
         "Oueme",
         "Plateau",
         "Zou",
       ],
       Bermuda: [
         "Devonshire",
         "Hamilton",
         "Hamilton",
         "Paget",
         "Pembroke",
         "Saint George",
         "Saint George's",
         "Sandys",
         "Smith's",
         "Southampton",
         "Warwick",
       ],
       Bhutan: [
         "Bumthang",
         "Chukha",
         "Dagana",
         "Gasa",
         "Haa",
         "Lhuntse",
         "Mongar",
         "Paro",
         "Pemagatshel",
         "Punakha",
         "Samdrup Jongkhar",
         "Samtse",
         "Sarpang",
         "Thimphu",
         "Trashigang",
         "Trashiyangste",
         "Trongsa",
         "Tsirang",
         "Wangdue Phodrang",
         "Zhemgang",
       ],
       "Bolivia (Plurinational State of)": [
         "Chuquisaca",
         "Cochabamba",
         "Beni",
         "La Paz",
         "Oruro",
         "Pando",
         "Potosi",
         "Santa Cruz",
         "Tarija",
       ],
       "Bonaire, Sint Eustatius and Saba": null,
       "Bosnia and Herzegovina": [
         "Una-Sana [Federation]",
         "Posavina [Federation]",
         "Tuzla [Federation]",
         "Zenica-Doboj [Federation]",
         "Bosnian Podrinje [Federation]",
         "Central Bosnia [Federation]",
         "Herzegovina-Neretva [Federation]",
         "West Herzegovina [Federation]",
         "Sarajevo [Federation]",
         " West Bosnia [Federation]",
         "Banja Luka [RS]",
         "Bijeljina [RS]",
         "Doboj [RS]",
         "Fo?a [RS]",
         "Sarajevo-Romanija [RS]",
         "Trebinje [RS]",
         "Vlasenica [RS]",
       ],
       Botswana: [
         "Central",
         "Ghanzi",
         "Kgalagadi",
         "Kgatleng",
         "Kweneng",
         "North East",
         "North West",
         "South East",
         "Southern",
       ],
       "Bouvet Island": null,
       Brazil: [
         "Acre",
         "Alagoas",
         "Amapa",
         "Amazonas",
         "Bahia",
         "Ceara",
         "Distrito Federal",
         "Espirito Santo",
         "Goias",
         "Maranhao",
         "Mato Grosso",
         "Mato Grosso do Sul",
         "Minas Gerais",
         "Para",
         "Paraiba",
         "Parana",
         "Pernambuco",
         "Piaui",
         "Rio de Janeiro",
         "Rio Grande do Norte",
         "Rio Grande do Sul",
         "Rondonia",
         "Roraima",
         "Santa Catarina",
         "Sao Paulo",
         "Sergipe",
         "Tocantins",
       ],
       "British Indian Ocean Territory (the)": [
         "Belait",
         "Brunei and Muara",
         "Temburong",
         "Tutong",
       ],
       "Brunei Darussalam": null,
       Bulgaria: [
         "Blagoevgrad",
         "Burgas",
         "Dobrich",
         "Gabrovo",
         "Khaskovo",
         "Kurdzhali",
         "Kyustendil",
         "Lovech",
         "Montana",
         "Pazardzhik",
         "Pernik",
         "Pleven",
         "Plovdiv",
         "Razgrad",
         "Ruse",
         "Shumen",
         "Silistra",
         "Sliven",
         "Smolyan",
         "Sofiya",
         "Sofiya-Grad",
         "Stara Zagora",
         "Turgovishte",
         "Varna",
         "Veliko Turnovo",
         "Vidin",
         "Vratsa",
         "Yambol",
       ],
       "Burkina Faso": [
         "Bale",
         "Bam",
         "Banwa",
         "Bazega",
         "Bougouriba",
         "Boulgou",
         "Boulkiemde",
         "Comoe",
         "Ganzourgou",
         "Gnagna",
         "Gourma",
         "Houet",
         "Ioba",
         "Kadiogo",
         "Kenedougou",
         "Komondjari",
         "Kompienga",
         "Kossi",
         "Koulpelogo",
         "Kouritenga",
         "Kourweogo",
         "Leraba",
         "Loroum",
         "Mouhoun",
         "Namentenga",
         "Nahouri",
         "Nayala",
         "Noumbiel",
         "Oubritenga",
         "Oudalan",
         "Passore",
         "Poni",
         "Sanguie",
         "Sanmatenga",
         "Seno",
         "Sissili",
         "Soum",
         "Sourou",
         "Tapoa",
         "Tuy",
         "Yagha",
         "Yatenga",
         "Ziro",
         "Zondoma",
         "Zoundweogo",
       ],
       Burma: [
         "Ayeyarwady",
         "Bago",
         "Magway",
         "Mandalay",
         "Sagaing",
         "Tanintharyi",
         "Yangon",
         "Chin State",
         "Kachin State",
         "Kayin State",
         "Kayah State",
         "Mon State",
         "Rakhine State",
         "Shan State",
       ],
       Burundi: [
         "Bubanza",
         "Bujumbura Mairie",
         "Bujumbura Rural",
         "Bururi",
         "Cankuzo",
         "Cibitoke",
         "Gitega",
         "Karuzi",
         "Kayanza",
         "Kirundo",
         "Makamba",
         "Muramvya",
         "Muyinga",
         "Mwaro",
         "Ngozi",
         "Rutana",
         "Ruyigi",
       ],
       Cambodia: [
         "Banteay Mean Chey",
         "Batdambang",
         "Kampong Cham",
         "Kampong Chhnang",
         "Kampong Spoe",
         "Kampong Thum",
         "Kampot",
         "Kandal",
         "Koh Kong",
         "Kracheh",
         "Mondol Kiri",
         "Otdar Mean Chey",
         "Pouthisat",
         "Preah Vihear",
         "Prey Veng",
         "Rotanakir",
         "Siem Reab",
         "Stoeng Treng",
         "Svay Rieng",
         "Takao",
         "Keb",
         "Pailin",
         "Phnom Penh",
         "Preah Seihanu",
       ],
       Cameroon: [
         "Adamaoua",
         "Centre",
         "Est",
         "Extreme-Nord",
         "Littoral",
         "Nord",
         "Nord-Ouest",
         "Ouest",
         "Sud",
         "Sud-Ouest",
       ],
       Canada: [
         "Alberta",
         "British Columbia",
         "Manitoba",
         "New Brunswick",
         "Newfoundland and Labrador",
         "Northwest Territories",
         "Nova Scotia",
         "Nunavut",
         "Ontario",
         "Prince Edward Island",
         "Quebec",
         "Saskatchewan",
         "Yukon Territory",
       ],
       "Cape Verde": [
         "Santo Antão",
         "São Vicente",
         "Santa Luzia,",
         "São Nicolau",
         "Sal",
         "Boa Vista",
         "Maio",
         "Santiago",
         "Fogo",
         "Brava",
       ],
       "Cayman Islands (the)": null,
       "Central African Republic (the)": [
         "Bamingui-Bangoran",
         "Bangui",
         "Basse-Kotto",
         "Haute-Kotto",
         "Haut-Mbomou",
         "Kemo",
         "Lobaye",
         "Mambere-Kadei",
         "Mbomou",
         "Nana-Grebizi",
         "Nana-Mambere",
         "Ombella-Mpoko",
         "Ouaka",
         "Ouham",
         "Ouham-Pende",
         "Sangha-Mbaere",
         "Vakaga",
       ],
       Chad: [
         "Batha",
         "Biltine",
         "Borkou-Ennedi-Tibesti",
         "Chari-Baguirmi",
         "Guéra",
         "Kanem",
         "Lac",
         "Logone Occidental",
         "Logone Oriental",
         "Mayo-Kebbi",
         "Moyen-Chari",
         "Ouaddaï",
         "Salamat",
         "Tandjile",
       ],
       Chile: [
         "Aysen",
         "Antofagasta",
         "Araucania",
         "Atacama",
         "Bio-Bio",
         "Coquimbo",
         "O'Higgins",
         "Los Lagos",
         "Magallanes y la Antartica Chilena",
         "Maule",
         "Santiago Region Metropolitana",
         "Tarapaca",
         "Valparaiso",
       ],
       China: [
         "Anhui",
         "Fujian",
         "Gansu",
         "Guangdong",
         "Guizhou",
         "Hainan",
         "Hebei",
         "Heilongjiang",
         "Henan",
         "Hubei",
         "Hunan",
         "Jiangsu",
         "Jiangxi",
         "Jilin",
         "Liaoning",
         "Qinghai",
         "Shaanxi",
         "Shandong",
         "Shanxi",
         "Sichuan",
         "Yunnan",
         "Zhejiang",
         "Guangxi",
         "Nei Mongol",
         "Ningxia",
         "Xinjiang",
         "Xizang (Tibet)",
         "Beijing",
         "Chongqing",
         "Shanghai",
         "Tianjin",
       ],
       "Christmas Island": null,
       "Cocos (Keeling) Islands (the)": null,
       Colombia: [
         "Amazonas",
         "Antioquia",
         "Arauca",
         "Atlantico",
         "Bogota District Capital",
         "Bolivar",
         "Boyaca",
         "Caldas",
         "Caqueta",
         "Casanare",
         "Cauca",
         "Cesar",
         "Choco",
         "Cordoba",
         "Cundinamarca",
         "Guainia",
         "Guaviare",
         "Huila",
         "La Guajira",
         "Magdalena",
         "Meta",
         "Narino",
         "Norte de Santander",
         "Putumayo",
         "Quindio",
         "Risaralda",
         "San Andres & Providencia",
         "Santander",
         "Sucre",
         "Tolima",
         "Valle del Cauca",
         "Vaupes",
         "Vichada",
       ],
       "Comoros (the)": [
         "Grande Comore (Njazidja)",
         "Anjouan (Nzwani)",
         "Moheli (Mwali)",
       ],
       "Congo (the Democratic Republic)": [
         "Bandundu",
         "Bas-Congo",
         "Equateur",
         "Kasai-Occidental",
         "Kasai-Oriental",
         "Katanga",
         "Kinshasa",
         "Maniema",
         "Nord-Kivu",
         "Orientale",
         "Sud-Kivu",
       ],
       "Congo (the)": [
         "Bouenza",
         "Brazzaville",
         "Cuvette",
         "Cuvette-Ouest",
         "Kouilou",
         "Lekoumou",
         "Likouala",
         "Niari",
         "Plateaux",
         "Pool",
         "Sangha",
       ],
       "Cook Islands (the)": null,
       "Costa Rica": [
         "Alajuela",
         "Cartago",
         "Guanacaste",
         "Heredia",
         "Limon",
         "Puntarenas",
         "San Jose",
       ],
       Croatia: [
         "Bjelovarsko-Bilogorska",
         "Brodsko-Posavska",
         "Dubrovacko-Neretvanska",
         "Istarska",
         "Karlovacka",
         "Koprivnicko-Krizevacka",
         "Krapinsko-Zagorska",
         "Licko-Senjska",
         "Medimurska",
         "Osjecko-Baranjska",
         "Pozesko-Slavonska",
         "Primorsko-Goranska",
         "Sibensko-Kninska",
         "Sisacko-Moslavacka",
         "Splitsko-Dalmatinska",
         "Varazdinska",
         "Viroviticko-Podravska",
         "Vukovarsko-Srijemska",
         "Zadarska",
         "Zagreb",
         "Zagrebacka",
       ],
       Cuba: [
         "Camaguey",
         "Ciego de Avila",
         "Cienfuegos",
         "Ciudad de La Habana",
         "Granma",
         "Guantanamo",
         "Holguin",
         "Isla de la Juventud",
         "La Habana",
         "Las Tunas",
         "Matanzas",
         "Pinar del Rio",
         "Sancti Spiritus",
         "Santiago de Cuba",
         "Villa Clara",
       ],
       Curaçao: null,
       Cyprus: ["Famagusta", "Kyrenia", "Larnaca", "Limassol", "Nicosia", "Paphos"],
       Czechia: [
         "Jihocesky Kraj",
         "Jihomoravsky Kraj",
         "Karlovarsky Kraj",
         "Kralovehradecky Kraj",
         "Liberecky Kraj",
         "Moravskoslezsky Kraj",
         "Olomoucky Kraj",
         "Pardubicky Kraj",
         "Plzensky Kraj",
         "Praha",
         "Stredocesky Kraj",
         "Ustecky Kraj",
         "Vysocina",
         "Zlinsky Kraj",
       ],
       "Côte d'Ivoire": [
         "Abidjan",
         "Bas-Sassandra",
         "Comoé",
         "Denguélé",
         "Gôh-Djiboua",
         "Lacs",
         "Lagunes",
         "Montagnes",
         "Sassandra-Marahoué",
         "Savanes",
         "Vallée du Bandama",
         "Woroba",
         "Yamoussoukro",
         "Zanzan",
       ],
       Denmark: [
         "Arhus",
         "Bornholm",
         "Frederiksberg",
         "Frederiksborg",
         "Fyn",
         "Kobenhavn",
         "Kobenhavns",
         "Nordjylland",
         "Ribe",
         "Ringkobing",
         "Roskilde",
         "Sonderjylland",
         "Storstrom",
         "Vejle",
         "Vestsjalland",
         "Viborg",
       ],
       Djibouti: ["Ali Sabih", "Dikhil", "Djibouti", "Obock", "Tadjoura"],
       Dominica: [
         "Saint Andrew",
         "Saint David",
         "Saint George",
         "Saint John",
         "Saint Joseph",
         "Saint Luke",
         "Saint Mark",
         "Saint Patrick",
         "Saint Paul",
         "Saint Peter",
       ],
       "Dominican Republic (the)": [
         "Azua",
         "Baoruco",
         "Barahona",
         "Dajabon",
         "Distrito Nacional",
         "Duarte",
         "Elias Pina",
         "El Seibo",
         "Espaillat",
         "Hato Mayor",
         "Independencia",
         "La Altagracia",
         "La Romana",
         "La Vega",
         "Maria Trinidad Sanchez",
         "Monsenor Nouel",
         "Monte Cristi",
         "Monte Plata",
         "Pedernales",
         "Peravia",
         "Puerto Plata",
         "Salcedo",
         "Samana",
         "Sanchez Ramirez",
         "San Cristobal",
         "San Jose de Ocoa",
         "San Juan",
         "San Pedro de Macoris",
         "Santiago",
         "Santiago Rodriguez",
         "Santo Domingo",
         "Valverde",
       ],
       "East Timor": [
         "Aileu",
         "Ainaro",
         "Baucau",
         "Bobonaro",
         "Cova-Lima",
         "Dili",
         "Ermera",
         "Lautem",
         "Liquica",
         "Manatuto",
         "Manufahi",
         "Oecussi",
         "Viqueque",
       ],
       Ecuador: [
         "Azuay",
         "Bolivar",
         "Canar",
         "Carchi",
         "Chimborazo",
         "Cotopaxi",
         "El Oro",
         "Esmeraldas",
         "Galapagos",
         "Guayas",
         "Imbabura",
         "Loja",
         "Los Rios",
         "Manabi",
         "Morona-Santiago",
         "Napo",
         "Orellana",
         "Pastaza",
         "Pichincha",
         "Sucumbios",
         "Tungurahua",
         "Zamora-Chinchipe",
       ],
       Egypt: [
         "Ad Daqahliyah",
         "Al Bahr al Ahmar",
         "Al Buhayrah",
         "Al Fayyum",
         "Al Gharbiyah",
         "Al Iskandariyah",
         "Al Isma'iliyah",
         "Al Jizah",
         "Al Minufiyah",
         "Al Minya",
         "Al Qahirah",
         "Al Qalyubiyah",
         "Al Wadi al Jadid",
         "Ash Sharqiyah",
         "As Suways",
         "Aswan",
         "Asyut",
         "Bani Suwayf",
         "Bur Sa'id",
         "Dumyat",
         "Janub Sina'",
         "Kafr ash Shaykh",
         "Matruh",
         "Qina",
         "Shamal Sina'",
         "Suhaj",
       ],
       "El Salvador": [
         "Ahuachapan",
         "Cabanas",
         "Chalatenango",
         "Cuscatlan",
         "La Libertad",
         "La Paz",
         "La Union",
         "Morazan",
         "San Miguel",
         "San Salvador",
         "Santa Ana",
         "San Vicente",
         "Sonsonate",
         "Usulutan",
       ],
       "Equatorial Guinea": [
         "Annobon",
         "Bioko Norte",
         "Bioko Sur",
         "Centro Sur",
         "Kie-Ntem",
         "Litoral",
         "Wele-Nzas",
       ],
       Eritrea: [
         "Anseba",
         "Debub",
         "Debubawi K'eyih Bahri",
         "Gash Barka",
         "Ma'akel",
         "Semenawi Keyih Bahri",
       ],
       Estonia: [
         "Harjumaa (Tallinn)",
         "Hiiumaa (Kardla)",
         "Ida-Virumaa (Johvi)",
         "Jarvamaa (Paide)",
         "Jogevamaa (Jogeva)",
         "Laanemaa (Haapsalu)",
         "Laane-Virumaa (Rakvere)",
         "Parnumaa (Parnu)",
         "Polvamaa (Polva)",
         "Raplamaa (Rapla)",
         "Saaremaa (Kuressaare)",
         "Tartumaa (Tartu)",
         "Valgamaa (Valga)",
         "Viljandimaa (Viljandi)",
         "Vorumaa (Voru)",
       ],
       Eswatini: null,
       Ethiopia: [
         "Addis Ababa",
         "Afar",
         "Amhara",
         "Binshangul Gumuz",
         "Dire Dawa",
         "Gambela Hizboch",
         "Harari",
         "Oromia",
         "Somali",
         "Tigray",
         "Southern Nations, Nationalities, and Peoples Region",
       ],
       "Falkland Islands (the) [Malvinas]": null,
       "Faroe Islands (the)": null,
       Fiji: [
         "Central (Suva)",
         "Eastern (Levuka)",
         "Northern (Labasa)",
         "Rotuma",
         "Western (Lautoka)",
       ],
       Finland: [
         "Aland",
         "Etela-Suomen Laani",
         "Ita-Suomen Laani",
         "Lansi-Suomen Laani",
         "Lappi",
         "Oulun Laani",
       ],
       France: [
         "Alsace",
         "Aquitaine",
         "Auvergne",
         "Basse-Normandie",
         "Bourgogne",
         "Bretagne",
         "Centre",
         "Champagne-Ardenne",
         "Corse",
         "Franche-Comte",
         "Haute-Normandie",
         "Ile-de-France",
         "Languedoc-Roussillon",
         "Limousin",
         "Lorraine",
         "Midi-Pyrenees",
         "Nord-Pas-de-Calais",
         "Pays de la Loire",
         "Picardie",
         "Poitou-Charentes",
         "Provence-Alpes-Cote d'Azur",
         "Rhone-Alpes",
       ],
       "French Guiana": null,
       "French Polynesia": null,
       "French Southern Territories (the)": null,
       Gabon: [
         "Estuaire",
         "Haut-Ogooue",
         "Moyen-Ogooue",
         "Ngounie",
         "Nyanga",
         "Ogooue-Ivindo",
         "Ogooue-Lolo",
         "Ogooue-Maritime",
         "Woleu-Ntem",
       ],
       "Gambia (the)": [
         "Banjul",
         "Central River",
         "Lower River",
         "North Bank",
         "Upper River",
         "Western",
       ],
       Georgia: [
         "Abkhazia",
         "Adjara",
         "Guria",
         "Imereti",
         "Kakheti",
         "Kvemo Kartli",
         "Mtskheta-Mtianeti",
         "Racha-Lechkhumi and Kvemo Svaneti",
         "Samegrelo-Zemo Svaneti",
         "Samtskhe-Javakheti",
         "Shida Kartli",
         "Tbilisi",
       ],
       Germany: [
         "Baden-Wuerttemberg",
         "Bayern",
         "Berlin",
         "Brandenburg",
         "Bremen",
         "Hamburg",
         "Hessen",
         "Mecklenburg-Vorpommern",
         "Niedersachsen",
         "Nordrhein-Westfalen",
         "Rheinland-Pfalz",
         "Saarland",
         "Sachsen",
         "Sachsen-Anhalt",
         "Schleswig-Holstein",
         "Thueringen",
       ],
       Ghana: [
         "Ashanti",
         "Brong-Ahafo",
         "Central",
         "Eastern",
         "Greater Accra",
         "Northern",
         "Upper East",
         "Upper West",
         "Volta",
         "Western",
       ],
       Gibraltar: null,
       Greece: [
         "Agion Oros",
         "Achaia",
         "Aitolia kai Akarmania",
         "Argolis",
         "Arkadia",
         "Arta",
         "Attiki",
         "Chalkidiki",
         "Chanion",
         "Chios",
         "Dodekanisos",
         "Drama",
         "Evros",
         "Evrytania",
         "Evvoia",
         "Florina",
         "Fokidos",
         "Fthiotis",
         "Grevena",
         "Ileia",
         "Imathia",
         "Ioannina",
         "Irakleion",
         "Karditsa",
         "Kastoria",
         "Kavala",
         "Kefallinia",
         "Kerkyra",
         "Kilkis",
         "Korinthia",
         "Kozani",
         "Kyklades",
         "Lakonia",
         "Larisa",
         "Lasithi",
         "Lefkas",
         "Lesvos",
         "Magnisia",
         "Messinia",
         "Pella",
         "Pieria",
         "Preveza",
         "Rethynnis",
         "Rodopi",
         "Samos",
         "Serrai",
         "Thesprotia",
         "Thessaloniki",
         "Trikala",
         "Voiotia",
         "Xanthi",
         "Zakynthos",
       ],
       Greenland: [
         "Avannaa (Nordgronland)",
         "Tunu (Ostgronland)",
         "Kitaa (Vestgronland)",
       ],
       Grenada: [
         "Carriacou and Petit Martinique",
         "Saint Andrew",
         "Saint David",
         "Saint George",
         "Saint John",
         "Saint Mark",
         "Saint Patrick",
       ],
       Guadeloupe: null,
       Guam: null,
       Guatemala: [
         "Alta Verapaz",
         "Baja Verapaz",
         "Chimaltenango",
         "Chiquimula",
         "El Progreso",
         "Escuintla",
         "Guatemala",
         "Huehuetenango",
         "Izabal",
         "Jalapa",
         "Jutiapa",
         "Peten",
         "Quetzaltenango",
         "Quiche",
         "Retalhuleu",
         "Sacatepequez",
         "San Marcos",
         "Santa Rosa",
         "Solola",
         "Suchitepequez",
         "Totonicapan",
         "Zacapa",
       ],
       Guernsey: null,
       Guinea: [
         "Beyla",
         "Boffa",
         "Boke",
         "Conakry",
         "Coyah",
         "Dabola",
         "Dalaba",
         "Dinguiraye",
         "Dubreka",
         "Faranah",
         "Forecariah",
         "Fria",
         "Gaoual",
         "Gueckedou",
         "Kankan",
         "Kerouane",
         "Kindia",
         "Kissidougou",
         "Koubia",
         "Koundara",
         "Kouroussa",
         "Labe",
         "Lelouma",
         "Lola",
         "Macenta",
         "Mali",
         "Mamou",
         "Mandiana",
         "Nzerekore",
         "Pita",
         "Siguiri",
         "Telimele",
         "Tougue",
         "Yomou",
       ],
       "Guinea-Bissau": [
         "Bafata",
         "Biombo",
         "Bissau",
         "Bolama",
         "Cacheu",
         "Gabu",
         "Oio",
         "Quinara",
         "Tombali",
       ],
       Guyana: [
         "Barima-Waini",
         "Cuyuni-Mazaruni",
         "Demerara-Mahaica",
         "East Berbice-Corentyne",
         "Essequibo Islands-West Demerara",
         "Mahaica-Berbice",
         "Pomeroon-Supenaam",
         "Potaro-Siparuni",
         "Upper Demerara-Berbice",
         "Upper Takutu-Upper Essequibo",
       ],
       Haiti: [
         "Artibonite",
         "Centre",
         "Grand 'Anse",
         "Nord",
         "Nord-Est",
         "Nord-Ouest",
         "Ouest",
         "Sud",
         "Sud-Est",
       ],
       "Heard Island and McDonald Islands": null,
       "Holy See (the)": null,
       Honduras: [
         "Atlantida",
         "Choluteca",
         "Colon",
         "Comayagua",
         "Copan",
         "Cortes",
         "El Paraiso",
         "Francisco Morazan",
         "Gracias a Dios",
         "Intibuca",
         "Islas de la Bahia",
         "La Paz",
         "Lempira",
         "Ocotepeque",
         "Olancho",
         "Santa Barbara",
         "Valle",
         "Yoro",
       ],
       "Hong Kong": [
         "Yuen Long District",
         "Tsuen Wan District",
         "Tai Po District",
         "Sai Kung District",
         "Islands District",
         "Central and Western District",
         "Wan Chai",
         "Eastern",
         "Southern",
         "North",
         "Yau Tsim Mong",
         "Sham Shui Po",
         "Kowloon City",
         "Wong Tai Sin",
         "Kwun Tong",
         "Kwai Tsing",
         "Tuen Mun",
         "Sha Tin",
       ],
       Hungary: [
         "Bacs-Kiskun",
         "Baranya",
         "Bekes",
         "Borsod-Abauj-Zemplen",
         "Csongrad",
         "Fejer",
         "Gyor-Moson-Sopron",
         "Hajdu-Bihar",
         "Heves",
         "Jasz-Nagykun-Szolnok",
         "Komarom-Esztergom",
         "Nograd",
         "Pest",
         "Somogy",
         "Szabolcs-Szatmar-Bereg",
         "Tolna",
         "Vas",
         "Veszprem",
         "Zala",
         "Bekescsaba",
         "Debrecen",
         "Dunaujvaros",
         "Eger",
         "Gyor",
         "Hodmezovasarhely",
         "Kaposvar",
         "Kecskemet",
         "Miskolc",
         "Nagykanizsa",
         "Nyiregyhaza",
         "Pecs",
         "Sopron",
         "Szeged",
         "Szekesfehervar",
         "Szolnok",
         "Szombathely",
         "Tatabanya",
         "Veszprem",
         "Zalaegerszeg",
       ],
       Iceland: [
         "Austurland",
         "Hofudhborgarsvaedhi",
         "Nordhurland Eystra",
         "Nordhurland Vestra",
         "Sudhurland",
         "Sudhurnes",
         "Vestfirdhir",
         "Vesturland",
       ],
       India: [
         "Andaman and Nicobar Islands",
         "Andhra Pradesh",
         "Arunachal Pradesh",
         "Assam",
         "Bihar",
         "Chandigarh",
         "Chhattisgarh",
         "Dadra and Nagar Haveli",
         "Daman and Diu",
         "Delhi",
         "Goa",
         "Gujarat",
         "Haryana",
         "Himachal Pradesh",
         "Jammu and Kashmir",
         "Jharkhand",
         "Karnataka",
         "Kerala",
         "Ladakh",
         "Lakshadweep",
         "Madhya Pradesh",
         "Maharashtra",
         "Manipur",
         "Meghalaya",
         "Mizoram",
         "Nagaland",
         "Orissa",
         "Pondicherry",
         "Punjab",
         "Rajasthan",
         "Sikkim",
         "Tamil Nadu",
         "Telangana",
         "Tripura",
         "Uttaranchal",
         "Uttar Pradesh",
         "West Bengal",
       ],
       Indonesia: [
         "Aceh",
         "Bali",
         "Banten",
         "Bengkulu",
         "Gorontalo",
         "Irian Jaya Barat",
         "Jakarta Raya",
         "Jambi",
         "Jawa Barat",
         "Jawa Tengah",
         "Jawa Timur",
         "Kalimantan Barat",
         "Kalimantan Selatan",
         "Kalimantan Tengah",
         "Kalimantan Timur",
         "Kepulauan Bangka Belitung",
         "Kepulauan Riau",
         "Lampung",
         "Maluku",
         "Maluku Utara",
         "Nusa Tenggara Barat",
         "Nusa Tenggara Timur",
         "Papua",
         "Riau",
         "Sulawesi Barat",
         "Sulawesi Selatan",
         "Sulawesi Tengah",
         "Sulawesi Tenggara",
         "Sulawesi Utara",
         "Sumatera Barat",
         "Sumatera Selatan",
         "Sumatera Utara",
         "Yogyakarta",
       ],
       "Iran (Islamic Republic of)": [
         "Ardabil",
         "Azarbayjan-e Gharbi",
         "Azarbayjan-e Sharqi",
         "Bushehr",
         "Chahar Mahall va Bakhtiari",
         "Esfahan",
         "Fars",
         "Gilan",
         "Golestan",
         "Hamadan",
         "Hormozgan",
         "Ilam",
         "Kerman",
         "Kermanshah",
         "Khorasan-e Janubi",
         "Khorasan-e Razavi",
         "Khorasan-e Shemali",
         "Khuzestan",
         "Kohgiluyeh va Buyer Ahmad",
         "Kordestan",
         "Lorestan",
         "Markazi",
         "Mazandaran",
         "Qazvin",
         "Qom",
         "Semnan",
         "Sistan va Baluchestan",
         "Tehran",
         "Yazd",
         "Zanjan",
       ],
       Iraq: [
         "Al Anbar",
         "Al Basrah",
         "Al Muthanna",
         "Al Qadisiyah",
         "An Najaf",
         "Arbil",
         "As Sulaymaniyah",
         "At Ta'mim",
         "Babil",
         "Baghdad",
         "Dahuk",
         "Dhi Qar",
         "Diyala",
         "Karbala'",
         "Maysan",
         "Ninawa",
         "Salah ad Din",
         "Wasit",
       ],
       Ireland: [
         "Carlow",
         "Cavan",
         "Clare",
         "Cork",
         "Donegal",
         "Dublin",
         "Galway",
         "Kerry",
         "Kildare",
         "Kilkenny",
         "Laois",
         "Leitrim",
         "Limerick",
         "Longford",
         "Louth",
         "Mayo",
         "Meath",
         "Monaghan",
         "Offaly",
         "Roscommon",
         "Sligo",
         "Tipperary",
         "Waterford",
         "Westmeath",
         "Wexford",
         "Wicklow",
       ],
       "Isle of Man": null,
       Israel: ["Central", "Haifa", "Jerusalem", "Northern", "Southern", "Tel Aviv"],
       Italy: [
         "Abruzzo",
         "Basilicata",
         "Calabria",
         "Campania",
         "Emilia-Romagna",
         "Friuli-Venezia Giulia",
         "Lazio",
         "Liguria",
         "Lombardia",
         "Marche",
         "Molise",
         "Piemonte",
         "Puglia",
         "Sardegna",
         "Sicilia",
         "Toscana",
         "Trentino-Alto Adige",
         "Umbria",
         "Valle d'Aosta",
         "Veneto",
       ],
       Jamaica: [
         "Clarendon",
         "Hanover",
         "Kingston",
         "Manchester",
         "Portland",
         "Saint Andrew",
         "Saint Ann",
         "Saint Catherine",
         "Saint Elizabeth",
         "Saint James",
         "Saint Mary",
         "Saint Thomas",
         "Trelawny",
         "Westmoreland",
       ],
       Japan: [
         "Aichi",
         "Akita",
         "Aomori",
         "Chiba",
         "Ehime",
         "Fukui",
         "Fukuoka",
         "Fukushima",
         "Gifu",
         "Gumma",
         "Hiroshima",
         "Hokkaido",
         "Hyogo",
         "Ibaraki",
         "Ishikawa",
         "Iwate",
         "Kagawa",
         "Kagoshima",
         "Kanagawa",
         "Kochi",
         "Kumamoto",
         "Kyoto",
         "Mie",
         "Miyagi",
         "Miyazaki",
         "Nagano",
         "Nagasaki",
         "Nara",
         "Niigata",
         "Oita",
         "Okayama",
         "Okinawa",
         "Osaka",
         "Saga",
         "Saitama",
         "Shiga",
         "Shimane",
         "Shizuoka",
         "Tochigi",
         "Tokushima",
         "Tokyo",
         "Tottori",
         "Toyama",
         "Wakayama",
         "Yamagata",
         "Yamaguchi",
         "Yamanashi",
       ],
       Jersey: null,
       Jordan: [
         "Ajlun",
         "Al 'Aqabah",
         "Al Balqa'",
         "Al Karak",
         "Al Mafraq",
         "'Amman",
         "At Tafilah",
         "Az Zarqa'",
         "Irbid",
         "Jarash",
         "Ma'an",
         "Madaba",
       ],
       Kazakhstan: [
         "Almaty Oblysy",
         "Almaty Qalasy",
         "Aqmola Oblysy",
         "Aqtobe Oblysy",
         "Astana Qalasy",
         "Atyrau Oblysy",
         "Batys Qazaqstan Oblysy",
         "Bayqongyr Qalasy",
         "Mangghystau Oblysy",
         "Ongtustik Qazaqstan Oblysy",
         "Pavlodar Oblysy",
         "Qaraghandy Oblysy",
         "Qostanay Oblysy",
         "Qyzylorda Oblysy",
         "Shyghys Qazaqstan Oblysy",
         "Soltustik Qazaqstan Oblysy",
         "Zhambyl Oblysy",
       ],
       Kenya: [
         "Central",
         "Coast",
         "Eastern",
         "Nairobi Area",
         "North Eastern",
         "Nyanza",
         "Rift Valley",
         "Western",
       ],
       Kiribati: [
         "Banaba",
         "Tarawa",
         "Northern Gilbert Islands",
         "Central Gilbert Island",
         "Southern Gilbert Islands",
         "Line Islands",
       ],
       "South-Korea": [
         "Chagang",
         "North Hamgyong",
         "South Hamgyong",
         "North Hwanghae",
         "South Hwanghae",
         "Kangwon",
         "North P'yongan",
         "South P'yongan",
         "Yanggang",
         "Kaesong",
         "Najin",
         "Namp'o",
         "Pyongyang",
       ],
       "North-Korea": [
         "Seoul",
         "Busan City",
         "Daegu City",
         "Incheon City",
         "Gwangju City",
         "Daejeon City",
         "Ulsan",
         "Gyeonggi Province",
         "Gangwon Province",
         "North Chungcheong Province",
         "South Chungcheong Province",
         "North Jeolla Province",
         "South Jeolla Province",
         "North Gyeongsang Province",
         "South Gyeongsang Province",
         "Jeju",
       ],
       Kosovo: [
         "Ferizaj",
         "Gjakova",
         "Gjilan",
         "Mitrovica",
         "Peja",
         "Pristina",
         "Prizren",
       ],
       Kuwait: [
         "Al Ahmadi",
         "Al Farwaniyah",
         "Al Asimah",
         "Al Jahra",
         "Hawalli",
         "Mubarak Al-Kabeer",
       ],
       Kyrgyzstan: [
         "Batken Oblasty",
         "Bishkek Shaary",
         "Chuy Oblasty",
         "Jalal-Abad Oblasty",
         "Naryn Oblasty",
         "Osh Oblasty",
         "Talas Oblasty",
         "Ysyk-Kol Oblasty",
       ],
       "Lao People's Democratic Republic (the)": [
         "Attapu",
         "Bokeo",
         "Bolikhamxai",
         "Champasak",
         "Houaphan",
         "Khammouan",
         "Louangnamtha",
         "Louangphrabang",
         "Oudomxai",
         "Phongsali",
         "Salavan",
         "Savannakhet",
         "Viangchan",
         "Viangchan",
         "Xaignabouli",
         "Xaisomboun",
         "Xekong",
         "Xiangkhoang",
       ],
       Latvia: [
         "Aizkraukles Rajons",
         "Aluksnes Rajons",
         "Balvu Rajons",
         "Bauskas Rajons",
         "Cesu Rajons",
         "Daugavpils",
         "Daugavpils Rajons",
         "Dobeles Rajons",
         "Gulbenes Rajons",
         "Jekabpils Rajons",
         "Jelgava",
         "Jelgavas Rajons",
         "Jurmala",
         "Kraslavas Rajons",
         "Kuldigas Rajons",
         "Liepaja",
         "Liepajas Rajons",
         "Limbazu Rajons",
         "Ludzas Rajons",
         "Madonas Rajons",
         "Ogres Rajons",
         "Preilu Rajons",
         "Rezekne",
         "Rezeknes Rajons",
         "Riga",
         "Rigas Rajons",
         "Saldus Rajons",
         "Talsu Rajons",
         "Tukuma Rajons",
         "Valkas Rajons",
         "Valmieras Rajons",
         "Ventspils",
         "Ventspils Rajons",
       ],
       Lebanon: [
         "Beyrouth",
         "Beqaa",
         "Liban-Nord",
         "Liban-Sud",
         "Mont-Liban",
         "Nabatiye",
       ],
       Lesotho: [
         "Berea",
         "Butha-Buthe",
         "Leribe",
         "Mafeteng",
         "Maseru",
         "Mohale's Hoek",
         "Mokhotlong",
         "Qacha's Nek",
         "Quthing",
         "Thaba-Tseka",
       ],
       Liberia: [
         "Bomi",
         "Bong",
         "Gbarpolu",
         "Grand Bassa",
         "Grand Cape Mount",
         "Grand Gedeh",
         "Grand Kru",
         "Lofa",
         "Margibi",
         "Maryland",
         "Montserrado",
         "Nimba",
         "River Cess",
         "River Gee",
         "Sinoe",
       ],
       Libya: [
         "Ajdabiya",
         "Al 'Aziziyah",
         "Al Fatih",
         "Al Jabal al Akhdar",
         "Al Jufrah",
         "Al Khums",
         "Al Kufrah",
         "An Nuqat al Khams",
         "Ash Shati'",
         "Awbari",
         "Az Zawiyah",
         "Banghazi",
         "Darnah",
         "Ghadamis",
         "Gharyan",
         "Misratah",
         "Murzuq",
         "Sabha",
         "Sawfajjin",
         "Surt",
         "Tarabulus",
         "Tarhunah",
         "Tubruq",
         "Yafran",
         "Zlitan",
       ],
       Liechtenstein: [
         "Balzers",
         "Eschen",
         "Gamprin",
         "Mauren",
         "Planken",
         "Ruggell",
         "Schaan",
         "Schellenberg",
         "Triesen",
         "Triesenberg",
         "Vaduz",
       ],
       Lithuania: [
         "Alytaus",
         "Kauno",
         "Klaipedos",
         "Marijampoles",
         "Panevezio",
         "Siauliu",
         "Taurages",
         "Telsiu",
         "Utenos",
         "Vilniaus",
       ],
       Luxembourg: ["Diekirch", "Grevenmacher", "Luxembourg"],
       Macao: null,
       Macedonia: [
         "Aerodrom",
         "Aracinovo",
         "Berovo",
         "Bitola",
         "Bogdanci",
         "Bogovinje",
         "Bosilovo",
         "Brvenica",
         "Butel",
         "Cair",
         "Caska",
         "Centar",
         "Centar Zupa",
         "Cesinovo",
         "Cucer-Sandevo",
         "Debar",
         "Debartsa",
         "Delcevo",
         "Demir Hisar",
         "Demir Kapija",
         "Dojran",
         "Dolneni",
         "Drugovo",
         "Gazi Baba",
         "Gevgelija",
         "Gjorce Petrov",
         "Gostivar",
         "Gradsko",
         "Ilinden",
         "Jegunovce",
         "Karbinci",
         "Karpos",
         "Kavadarci",
         "Kicevo",
         "Kisela Voda",
         "Kocani",
         "Konce",
         "Kratovo",
         "Kriva Palanka",
         "Krivogastani",
         "Krusevo",
         "Kumanovo",
         "Lipkovo",
         "Lozovo",
         "Makedonska Kamenica",
         "Makedonski Brod",
         "Mavrovo i Rastusa",
         "Mogila",
         "Negotino",
         "Novaci",
         "Novo Selo",
         "Ohrid",
         "Oslomej",
         "Pehcevo",
         "Petrovec",
         "Plasnica",
         "Prilep",
         "Probistip",
         "Radovis",
         "Rankovce",
         "Resen",
         "Rosoman",
         "Saraj",
         "Skopje",
         "Sopiste",
         "Staro Nagoricane",
         "Stip",
         "Struga",
         "Strumica",
         "Studenicani",
         "Suto Orizari",
         "Sveti Nikole",
         "Tearce",
         "Tetovo",
         "Valandovo",
         "Vasilevo",
         "Veles",
         "Vevcani",
         "Vinica",
         "Vranestica",
         "Vrapciste",
         "Zajas",
         "Zelenikovo",
         "Zelino",
         "Zrnovci",
       ],
       Madagascar: [
         "Antananarivo",
         "Antsiranana",
         "Fianarantsoa",
         "Mahajanga",
         "Toamasina",
         "Toliara",
       ],
       Malawi: [
         "Balaka",
         "Blantyre",
         "Chikwawa",
         "Chiradzulu",
         "Chitipa",
         "Dedza",
         "Dowa",
         "Karonga",
         "Kasungu",
         "Likoma",
         "Lilongwe",
         "Machinga",
         "Mangochi",
         "Mchinji",
         "Mulanje",
         "Mwanza",
         "Mzimba",
         "Ntcheu",
         "Nkhata Bay",
         "Nkhotakota",
         "Nsanje",
         "Ntchisi",
         "Phalombe",
         "Rumphi",
         "Salima",
         "Thyolo",
         "Zomba",
       ],
       Malaysia: [
         "Johor",
         "Kedah",
         "Kelantan",
         "Kuala Lumpur",
         "Labuan",
         "Malacca",
         "Negeri Sembilan",
         "Pahang",
         "Perak",
         "Perlis",
         "Penang",
         "Sabah",
         "Sarawak",
         "Selangor",
         "Terengganu",
       ],
       Maldives: [
         "Alifu",
         "Baa",
         "Dhaalu",
         "Faafu",
         "Gaafu Alifu",
         "Gaafu Dhaalu",
         "Gnaviyani",
         "Haa Alifu",
         "Haa Dhaalu",
         "Kaafu",
         "Laamu",
         "Lhaviyani",
         "Maale",
         "Meemu",
         "Noonu",
         "Raa",
         "Seenu",
         "Shaviyani",
         "Thaa",
         "Vaavu",
       ],
       Mali: [
         "Bamako (Capital)",
         "Gao",
         "Kayes",
         "Kidal",
         "Koulikoro",
         "Mopti",
         "Segou",
         "Sikasso",
         "Tombouctou",
       ],
       Malta: [
         "Southern Harbour",
         "Northern Harbour",
         "Western District",
         "Northern District",
         "Gozo and Comino",
       ],
       "Marshall Islands (the)": [
         "Ailuk",
         "Ailinglaplap",
         "Arno",
         "Aur",
         "Ebon",
         "Enewetak",
         "Jabat",
         "Jaluit",
         "Kili",
         "Kwajalein",
         "Lae",
         "Lib",
         "Likiep",
         "Majuro",
         "Maloelap",
         "Mejit",
         "Mili",
         "Namorik",
         "Namu",
         "Rongelap",
         "Ujae",
         "Utirik",
         "Wotho",
         "Wotje",
         "Ailinginae",
         "Bikar",
         "Bikini",
         "Bokak",
         "Erikub",
         "Jemo",
         "Rongrik",
         "Toke",
         "Ujelang",
       ],
       Martinique: null,
       Mauritania: [
         "Adrar",
         "Assaba",
         "Brakna",
         "Dakhlet Nouadhibou",
         "Gorgol",
         "Guidimaka",
         "Hodh Ech Chargui",
         "Hodh El Gharbi",
         "Inchiri",
         "Nouakchott",
         "Tagant",
         "Tiris Zemmour",
         "Trarza",
       ],
       Mauritius: [
         "Agalega Islands",
         "Black River",
         "Cargados Carajos Shoals",
         "Flacq",
         "Grand Port",
         "Moka",
         "Pamplemousses",
         "Plaines Wilhems",
         "Port Louis",
         "Riviere du Rempart",
         "Rodrigues",
         "Savanne",
       ],
       Mayotte: null,
       Mexico: [
         "Aguascalientes",
         "Baja California",
         "Baja California Sur",
         "Campeche",
         "Chiapas",
         "Chihuahua",
         "Coahuila de Zaragoza",
         "Colima",
         "Distrito Federal",
         "Durango",
         "Guanajuato",
         "Guerrero",
         "Hidalgo",
         "Jalisco",
         "Mexico",
         "Michoacan de Ocampo",
         "Morelos",
         "Nayarit",
         "Nuevo Leon",
         "Oaxaca",
         "Puebla",
         "Queretaro de Arteaga",
         "Quintana Roo",
         "San Luis Potosi",
         "Sinaloa",
         "Sonora",
         "Tabasco",
         "Tamaulipas",
         "Tlaxcala",
         "Veracruz-Llave",
         "Yucatan",
         "Zacatecas",
       ],
       "Micronesia (Federated States of)": null,
       "Moldova": [
         "Anenii Noi",
         "Basarabeasca",
         "Briceni",
         "Cahul",
         "Cantemir",
         "Calarasi",
         "Causeni",
         "Cimislia",
         "Criuleni",
         "Donduseni",
         "Drochia",
         "Dubasari",
         "Edinet",
         "Falesti",
         "Floresti",
         "Glodeni",
         "Hincesti",
         "Ialoveni",
         "Leova",
         "Nisporeni",
         "Ocnita",
         "Orhei",
         "Rezina",
         "Riscani",
         "Singerei",
         "Soldanesti",
         "Soroca",
         "Stefan-Voda",
         "Straseni",
         "Taraclia",
         "Telenesti",
         "Ungheni",
         "Balti",
         "Bender",
         "Chisinau",
         "Gagauzia",
         "Stinga Nistrului",
       ],
       Monaco: ["Monaco-Ville", "La Condamine", "Monte Carlo", "Fontvieille"],
       Mongolia: [
         "Arhangay",
         "Bayanhongor",
         "Bayan-Olgiy",
         "Bulgan",
         "Darhan Uul",
         "Dornod",
         "Dornogovi",
         "Dundgovi",
         "Dzavhan",
         "Govi-Altay",
         "Govi-Sumber",
         "Hentiy",
         "Hovd",
         "Hovsgol",
         "Omnogovi",
         "Orhon",
         "Ovorhangay",
         "Selenge",
         "Suhbaatar",
         "Tov",
         "Ulaanbaatar",
         "Uvs",
       ],
       Montenegro: [
         "Andrijevica",
         "Bar",
         "Berane",
         "Berane",
         "Bijelo Polje",
         "Budva",
         "Cetinje",
         "Danilovgrad",
         "Gusinje",
         "Herceg Novi",
         "Kolašin",
         "Kotor",
         "Mojkovac",
         "Nikšić",
         "Petnjica",
         "Plav",
         "Pljevlja",
         "Plužine",
         "Podgorica",
         "Rožaje",
         "Šavnik",
         "Tivat",
         "Tuzi",
         "Ulcinj",
         "Žabljak",
       ],
       Montserrat: null,
       Morocco: [
         "Agadir",
         "Al Hoceima",
         "Azilal",
         "Beni Mellal",
         "Ben Slimane",
         "Boulemane",
         "Casablanca",
         "Chaouen",
         "El Jadida",
         "El Kelaa des Sraghna",
         "Er Rachidia",
         "Essaouira",
         "Fes",
         "Figuig",
         "Guelmim",
         "Ifrane",
         "Kenitra",
         "Khemisset",
         "Khenifra",
         "Khouribga",
         "Laayoune",
         "Larache",
         "Marrakech",
         "Meknes",
         "Nador",
         "Ouarzazate",
         "Oujda",
         "Rabat-Sale",
         "Safi",
         "Settat",
         "Sidi Kacem",
         "Tangier",
         "Tan-Tan",
         "Taounate",
         "Taroudannt",
         "Tata",
         "Taza",
         "Tetouan",
         "Tiznit",
       ],
       Mozambique: [
         "Cabo Delgado",
         "Gaza",
         "Inhambane",
         "Manica",
         "Maputo",
         "Cidade de Maputo",
         "Nampula",
         "Niassa",
         "Sofala",
         "Tete",
         "Zambezia",
       ],
       Myanmar: null,
       Namibia: [
         "Caprivi",
         "Erongo",
         "Hardap",
         "Karas",
         "Khomas",
         "Kunene",
         "Ohangwena",
         "Okavango",
         "Omaheke",
         "Omusati",
         "Oshana",
         "Oshikoto",
         "Otjozondjupa",
       ],
       Nauru: ["Aiwo", "Anabar", "Anetan", "Anibare"],
       Nepal: [
         "Bagmati",
         "Bheri",
         "Dhawalagiri",
         "Gandaki",
         "Janakpur",
         "Karnali",
         "Kosi",
         "Lumbini",
         "Mahakali",
         "Mechi",
         "Narayani",
         "Rapti",
         "Sagarmatha",
         "Seti",
       ],
       "Netherlands": [
         "Drenthe",
         "Flevoland",
         "Friesland",
         "Gelderland",
         "Groningen",
         "Limburg",
         "Noord-Brabant",
         "Noord-Holland",
         "Overijssel",
         "Utrecht",
         "Zeeland",
         "Zuid-Holland",
       ],
       "New Caledonia": null,
       "New Zealand": [
         "Auckland",
         "Bay of Plenty",
         "Canterbury",
         "Chatham Islands",
         "Gisborne",
         "Hawke's Bay",
         "Manawatu-Wanganui",
         "Marlborough",
         "Nelson",
         "Northland",
         "Otago",
         "Southland",
         "Taranaki",
         "Tasman",
         "Waikato",
         "Wellington",
         "West Coast",
       ],
       Nicaragua: [
         "Atlantico Norte",
         "Atlantico Sur",
         "Boaco",
         "Carazo",
         "Chinandega",
         "Chontales",
         "Esteli",
         "Granada",
         "Jinotega",
         "Leon",
         "Madriz",
         "Managua",
         "Masaya",
         "Matagalpa",
         "Nueva Segovia",
         "Rio San Juan",
         "Rivas",
       ],
       "Niger (the)": [
         "Agadez",
         "Diffa",
         "Dosso",
         "Maradi",
         "Niamey",
         "Tahoua",
         "Tillaberi",
         "Zinder",
       ],
       Nigeria: [
         "Abia",
         "Abuja (FCT)",
         "Adamawa",
         "Akwa Ibom",
         "Anambra",
         "Bauchi",
         "Bayelsa",
         "Benue",
         "Borno",
         "Cross River",
         "Delta",
         "Ebonyi",
         "Edo",
         "Ekiti",
         "Enugu",
         "Gombe",
         "Imo",
         "Jigawa",
         "Kaduna",
         "Kano",
         "Katsina",
         "Kebbi",
         "Kogi",
         "Kwara",
         "Lagos",
         "Nassarawa",
         "Niger",
         "Ogun",
         "Ondo",
         "Osun",
         "Oyo",
         "Plateau",
         "Rivers",
         "Sokoto",
         "Taraba",
         "Yobe",
         "Zamfara",
       ],
       Niue: null,
       "Norfolk Island": null,
       "Northern Mariana Islands (the)": null,
       Norway: [
         "Akershus",
         "Aust-Agder",
         "Buskerud",
         "Finnmark",
         "Hedmark",
         "Hordaland",
         "More og Romsdal",
         "Nordland",
         "Nord-Trondelag",
         "Oppland",
         "Oslo",
         "Ostfold",
         "Rogaland",
         "Sogn og Fjordane",
         "Sor-Trondelag",
         "Telemark",
         "Troms",
         "Vest-Agder",
         "Vestfold",
       ],
       Oman: [
         "Ad Dakhiliyah",
         "Al Batinah",
         "Al Wusta",
         "Ash Sharqiyah",
         "Az Zahirah",
         "Masqat",
         "Musandam",
         "Dhofar",
       ],
       Pakistan: [
         "Balochistan",
         "North-West Frontier Province",
         "Punjab",
         "Sindh",
         "Islamabad Capital Territory",
         "Federally Administered Tribal Areas",
       ],
       Palau: null,
       "Palestine, State of": null,
       Panama: [
         "Bocas del Toro",
         "Chiriqui",
         "Cocle",
         "Colon",
         "Darien",
         "Herrera",
         "Los Santos",
         "Panama",
         "San Blas",
         "Veraguas",
       ],
       "Papua New Guinea": [
         "Bougainville",
         "Central",
         "Chimbu",
         "Eastern Highlands",
         "East New Britain",
         "East Sepik",
         "Enga",
         "Gulf",
         "Madang",
         "Manus",
         "Milne Bay",
         "Morobe",
         "National Capital",
         "New Ireland",
         "Northern",
         "Sandaun",
         "Southern Highlands",
         "Western",
         "Western Highlands",
         "West New Britain",
       ],
       Paraguay: [
         "Alto Paraguay",
         "Alto Parana",
         "Amambay",
         "Asuncion",
         "Boqueron",
         "Caaguazu",
         "Caazapa",
         "Canindeyu",
         "Central",
         "Concepcion",
         "Cordillera",
         "Guaira",
         "Itapua",
         "Misiones",
         "Neembucu",
         "Paraguari",
         "Presidente Hayes",
         "San Pedro",
       ],
       Peru: [
         "Amazonas",
         "Ancash",
         "Apurimac",
         "Arequipa",
         "Ayacucho",
         "Cajamarca",
         "Callao",
         "Cusco",
         "Huancavelica",
         "Huanuco",
         "Ica",
         "Junin",
         "La Libertad",
         "Lambayeque",
         "Lima",
         "Loreto",
         "Madre de Dios",
         "Moquegua",
         "Pasco",
         "Piura",
         "Puno",
         "San Martin",
         "Tacna",
         "Tumbes",
         "Ucayali",
       ],
       "Philippines (the)": [
         "Abra",
         "Agusan del Norte",
         "Agusan del Sur",
         "Aklan",
         "Albay",
         "Antique",
         "Apayao",
         "Aurora",
         "Basilan",
         "Bataan",
         "Batanes",
         "Batangas",
         "Biliran",
         "Benguet",
         "Bohol",
         "Bukidnon",
         "Bulacan",
         "Cagayan",
         "Camarines Norte",
         "Camarines Sur",
         "Camiguin",
         "Capiz",
         "Catanduanes",
         "Cavite",
         "Cebu",
         "Compostela",
         "Davao del Norte",
         "Davao del Sur",
         "Davao Oriental",
         "Eastern Samar",
         "Guimaras",
         "Ifugao",
         "Ilocos Norte",
         "Ilocos Sur",
         "Iloilo",
         "Isabela",
         "Kalinga",
         "Laguna",
         "Lanao del Norte",
         "Lanao del Sur",
         "La Union",
         "Leyte",
         "Maguindanao",
         "Marinduque",
         "Masbate",
         "Mindoro Occidental",
         "Mindoro Oriental",
         "Misamis Occidental",
         "Misamis Oriental",
         "Mountain Province",
         "Negros Occidental",
         "Negros Oriental",
         "North Cotabato",
         "Northern Samar",
         "Nueva Ecija",
         "Nueva Vizcaya",
         "Palawan",
         "Pampanga",
         "Pangasinan",
         "Quezon",
         "Quirino",
         "Rizal",
         "Romblon",
         "Samar",
         "Sarangani",
         "Siquijor",
         "Sorsogon",
         "South Cotabato",
         "Southern Leyte",
         "Sultan Kudarat",
         "Sulu",
         "Surigao del Norte",
         "Surigao del Sur",
         "Tarlac",
         "Tawi-Tawi",
         "Zambales",
         "Zamboanga del Norte",
         "Zamboanga del Sur",
         "Zamboanga Sibugay",
       ],
       Pitcairn: null,
       Poland: [
         "Greater Poland (Wielkopolskie)",
         "Kuyavian-Pomeranian (Kujawsko-Pomorskie)",
         "Lesser Poland (Malopolskie)",
         "Lodz (Lodzkie)",
         "Lower Silesian (Dolnoslaskie)",
         "Lublin (Lubelskie)",
         "Lubusz (Lubuskie)",
         "Masovian (Mazowieckie)",
         "Opole (Opolskie)",
         "Podlasie (Podlaskie)",
         "Pomeranian (Pomorskie)",
         "Silesian (Slaskie)",
         "Subcarpathian (Podkarpackie)",
         "Swietokrzyskie (Swietokrzyskie)",
         "Warmian-Masurian (Warminsko-Mazurskie)",
         "West Pomeranian (Zachodniopomorskie)",
       ],
       Portugal: [
         "Aveiro",
         "Acores",
         "Beja",
         "Braga",
         "Braganca",
         "Castelo Branco",
         "Coimbra",
         "Evora",
         "Faro",
         "Guarda",
         "Leiria",
         "Lisboa",
         "Madeira",
         "Portalegre",
         "Porto",
         "Santarem",
         "Setubal",
         "Viana do Castelo",
         "Vila Real",
         "Viseu",
       ],
       "Puerto Rico": null,
       Qatar: [
         "Ad Dawhah",
         "Al Ghuwayriyah",
         "Al Jumayliyah",
         "Al Khawr",
         "Al Wakrah",
         "Ar Rayyan",
         "Jarayan al Batinah",
         "Madinat ash Shamal",
         "Umm Sa'id",
         "Umm Salal",
       ],
       "Republic of North Macedonia": null,
       Romania: [
         "Alba",
         "Arad",
         "Arges",
         "Bacau",
         "Bihor",
         "Bistrita-Nasaud",
         "Botosani",
         "Braila",
         "Brasov",
         "Bucuresti",
         "Buzau",
         "Calarasi",
         "Caras-Severin",
         "Cluj",
         "Constanta",
         "Covasna",
         "Dimbovita",
         "Dolj",
         "Galati",
         "Gorj",
         "Giurgiu",
         "Harghita",
         "Hunedoara",
         "Ialomita",
         "Iasi",
         "Ilfov",
         "Maramures",
         "Mehedinti",
         "Mures",
         "Neamt",
         "Olt",
         "Prahova",
         "Salaj",
         "Satu Mare",
         "Sibiu",
         "Suceava",
         "Teleorman",
         "Timis",
         "Tulcea",
         "Vaslui",
         "Vilcea",
         "Vrancea",
       ],
       "Russia": [
         "Amur",
         "Arkhangel'sk",
         "Astrakhan'",
         "Belgorod",
         "Bryansk",
         "Chelyabinsk",
         "Chita",
         "Irkutsk",
         "Ivanovo",
         "Kaliningrad",
         "Kaluga",
         "Kamchatka",
         "Kemerovo",
         "Kirov",
         "Kostroma",
         "Kurgan",
         "Kursk",
         "Leningrad",
         "Lipetsk",
         "Magadan",
         "Moscow",
         "Murmansk",
         "Nizhniy Novgorod",
         "Novgorod",
         "Novosibirsk",
         "Omsk",
         "Orenburg",
         "Orel",
         "Penza",
         "Perm'",
         "Pskov",
         "Rostov",
         "Ryazan'",
         "Sakhalin",
         "Samara",
         "Saratov",
         "Smolensk",
         "Sverdlovsk",
         "Tambov",
         "Tomsk",
         "Tula",
         "Tver'",
         "Tyumen'",
         "Ul'yanovsk",
         "Vladimir",
         "Volgograd",
         "Vologda",
         "Voronezh",
         "Yaroslavl'",
         "Adygeya",
         "Altay",
         "Bashkortostan",
         "Buryatiya",
         "Chechnya",
         "Chuvashiya",
         "Dagestan",
         "Ingushetiya",
         "Kabardino-Balkariya",
         "Kalmykiya",
         "Karachayevo-Cherkesiya",
         "Kareliya",
         "Khakasiya",
         "Komi",
         "Mariy-El",
         "Mordoviya",
         "Sakha",
         "North Ossetia",
         "Tatarstan",
         "Tyva",
         "Udmurtiya",
         "Aga Buryat",
         "Chukotka",
         "Evenk",
         "Khanty-Mansi",
         "Komi-Permyak",
         "Koryak",
         "Nenets",
         "Taymyr",
         "Ust'-Orda Buryat",
         "Yamalo-Nenets",
         "Altay",
         "Khabarovsk",
         "Krasnodar",
         "Krasnoyarsk",
         "Primorskiy",
         "Stavropol'",
         "Moscow",
         "St. Petersburg",
         "Yevrey",
       ],
       Rwanda: [
         "Butare",
         "Byumba",
         "Cyangugu",
         "Gikongoro",
         "Gisenyi",
         "Gitarama",
         "Kibungo",
         "Kibuye",
         "Kigali Rurale",
         "Kigali-ville",
         "Umutara",
         "Ruhengeri",
       ],
       Réunion: null,
       "Saint Barthélemy": null,
       "Saint Helena, Ascension and Tristan da Cunha": null,
       "Saint Kitts and Nevis": null,
       "Saint Lucia": null,
       "Saint Martin (French part)": null,
       "Saint Pierre and Miquelon": null,
       "Saint Vincent and the Grenadines": null,
       Samoa: [
         "A'ana",
         "Aiga-i-le-Tai",
         "Atua",
         "Fa'asaleleaga",
         "Gaga'emauga",
         "Gagaifomauga",
         "Palauli",
         "Satupa'itea",
         "Tuamasaga",
         "Va'a-o-Fonoti",
         "Vaisigano",
       ],
       "San Marino": [
         "Acquaviva",
         "Borgo Maggiore",
         "Chiesanuova",
         "Domagnano",
         "Faetano",
         "Fiorentino",
         "Montegiardino",
         "San Marino Citta",
         "Serravalle",
       ],
       "Sao Tome and Principe": [
         "Água Grande",
         "Cantagalo",
         "Caué",
         "Lembá",
         "Lobata",
         "Mé-Zóchi",
         "Autonomous Region of Príncipe",
       ],
       "Saudi Arabia": [
         "Al Bahah",
         "Al Hudud ash Shamaliyah",
         "Al Jawf",
         "Al Madinah",
         "Al Qasim",
         "Ar Riyad",
         "Ash Sharqiyah",
         "'Asir",
         "Ha'il",
         "Jizan",
         "Makkah",
         "Najran",
         "Tabuk",
       ],
       Senegal: [
         "Dakar",
         "Diourbel",
         "Fatick",
         "Kaolack",
         "Kolda",
         "Louga",
         "Matam",
         "Saint-Louis",
         "Tambacounda",
         "Thies",
         "Ziguinchor",
       ],
       Serbia: [
         "Valjevo",
         "Šabac",
         "Čačak",
         "Jagodina",
         "Kruševac",
         "Kraljevo",
         "Kragujevac",
         "Užice",
         "Bor",
         "Požarevac",
         "Leskovac",
         "Niš",
         "Vranje",
         "Pirot",
         "Smederevo",
         "Prokuplje",
         "Zaječar",
         "Zrenjanin",
         "Subotica",
         "Kikinda",
         "Novi Sad",
         "Pančevo",
         "Sremska Mitrovica",
         "Sombor",
       ],
       Seychelles: [
         "Anse aux Pins",
         "Anse Boileau",
         "Anse Etoile",
         "Anse Louis",
         "Anse Royale",
         "Baie Lazare",
         "Baie Sainte Anne",
         "Beau Vallon",
         "Bel Air",
         "Bel Ombre",
         "Cascade",
         "Glacis",
         "Grand' Anse",
         "Grand' Anse",
         "La Digue",
         "La Riviere Anglaise",
         "Mont Buxton",
         "Mont Fleuri",
         "Plaisance",
         "Pointe La Rue",
         "Port Glaud",
         "Saint Louis",
         "Takamaka",
       ],
       "Sierra Leone": [
         "Eastern Province",
         "Northern Province",
         "Southern Province",
         "North West Province",
         "Western Area",
       ],
       Singapore: [
         "Aljunied Group Representation Constituency",
         "Ang Mo Kio Group Representation Constituency",
         "Bishan–Toa Payoh Group Representation Constituency",
         "Chua Chu Kang Group Representation Constituency",
         "East Coast Group Representation Constituency",
         "Holland–Bukit Timah Group Representation Constituency",
         "Jalan Besar Group Representation Constituency",
         "Jurong Group Representation Constituency",
         "Marine Parade Group Representation Constituency",
         "Marsiling–Yew Tee Group Representation Constituency",
         "Nee Soon Group Representation Constituency",
         "Pasir Ris–Punggol Group Representation Constituency",
         "Sengkang Group Representation Constituency",
         "Tampines Group Representation Constituency",
         "Tanjong Pagar Group Representation Constituency",
         "West Coast Group Representation Constituency",
       ],
       "Sint Maarten (Dutch part)": null,
       Slovakia: [
         "Banskobystricky",
         "Bratislavsky",
         "Kosicky",
         "Nitriansky",
         "Presovsky",
         "Trenciansky",
         "Trnavsky",
         "Zilinsky",
       ],
       Slovenia: [
         "Ajdovscina",
         "Beltinci",
         "Benedikt",
         "Bistrica ob Sotli",
         "Bled",
         "Bloke",
         "Bohinj",
         "Borovnica",
         "Bovec",
         "Braslovce",
         "Brda",
         "Brezice",
         "Brezovica",
         "Cankova",
         "Celje",
         "Cerklje na Gorenjskem",
         "Cerknica",
         "Cerkno",
         "Cerkvenjak",
         "Crensovci",
         "Crna na Koroskem",
         "Crnomelj",
         "Destrnik",
         "Divaca",
         "Dobje",
         "Dobrepolje",
         "Dobrna",
         "Dobrova-Horjul-Polhov Gradec",
         "Dobrovnik-Dobronak",
         "Dolenjske Toplice",
         "Dol pri Ljubljani",
         "Domzale",
         "Dornava",
         "Dravograd",
         "Duplek",
         "Gorenja Vas-Poljane",
         "Gorisnica",
         "Gornja Radgona",
         "Gornji Grad",
         "Gornji Petrovci",
         "Grad",
         "Grosuplje",
         "Hajdina",
         "Hoce-Slivnica",
         "Hodos-Hodos",
         "Horjul",
         "Hrastnik",
         "Hrpelje-Kozina",
         "Idrija",
         "Ig",
         "Ilirska Bistrica",
         "Ivancna Gorica",
         "Izola-Isola",
         "Jesenice",
         "Jezersko",
         "Jursinci",
         "Kamnik",
         "Kanal",
         "Kidricevo",
         "Kobarid",
         "Kobilje",
         "Kocevje",
         "Komen",
         "Komenda",
         "Koper-Capodistria",
         "Kostel",
         "Kozje",
         "Kranj",
         "Kranjska Gora",
         "Krizevci",
         "Krsko",
         "Kungota",
         "Kuzma",
         "Lasko",
         "Lenart",
         "Lendava-Lendva",
         "Litija",
         "Ljubljana",
         "Ljubno",
         "Ljutomer",
         "Logatec",
         "Loska Dolina",
         "Loski Potok",
         "Lovrenc na Pohorju",
         "Luce",
         "Lukovica",
         "Majsperk",
         "Maribor",
         "Markovci",
         "Medvode",
         "Menges",
         "Metlika",
         "Mezica",
         "Miklavz na Dravskem Polju",
         "Miren-Kostanjevica",
         "Mirna Pec",
         "Mislinja",
         "Moravce",
         "Moravske Toplice",
         "Mozirje",
         "Murska Sobota",
         "Muta",
         "Naklo",
         "Nazarje",
         "Nova Gorica",
         "Novo Mesto",
         "Odranci",
         "Oplotnica",
         "Ormoz",
         "Osilnica",
         "Pesnica",
         "Piran-Pirano",
         "Pivka",
         "Podcetrtek",
         "Podlehnik",
         "Podvelka",
         "Polzela",
         "Postojna",
         "Prebold",
         "Preddvor",
         "Prevalje",
         "Ptuj",
         "Puconci",
         "Race-Fram",
         "Radece",
         "Radenci",
         "Radlje ob Dravi",
         "Radovljica",
         "Ravne na Koroskem",
         "Razkrizje",
         "Ribnica",
         "Ribnica na Pohorju",
         "Rogasovci",
         "Rogaska Slatina",
         "Rogatec",
         "Ruse",
         "Salovci",
         "Selnica ob Dravi",
         "Semic",
         "Sempeter-Vrtojba",
         "Sencur",
         "Sentilj",
         "Sentjernej",
         "Sentjur pri Celju",
         "Sevnica",
         "Sezana",
         "Skocjan",
         "Skofja Loka",
         "Skofljica",
         "Slovenj Gradec",
         "Slovenska Bistrica",
         "Slovenske Konjice",
         "Smarje pri Jelsah",
         "Smartno ob Paki",
         "Smartno pri Litiji",
         "Sodrazica",
         "Solcava",
         "Sostanj",
         "Starse",
         "Store",
         "Sveta Ana",
         "Sveti Andraz v Slovenskih Goricah",
         "Sveti Jurij",
         "Tabor",
         "Tisina",
         "Tolmin",
         "Trbovlje",
         "Trebnje",
         "Trnovska Vas",
         "Trzic",
         "Trzin",
         "Turnisce",
         "Velenje",
         "Velika Polana",
         "Velike Lasce",
         "Verzej",
         "Videm",
         "Vipava",
         "Vitanje",
         "Vodice",
         "Vojnik",
         "Vransko",
         "Vrhnika",
         "Vuzenica",
         "Zagorje ob Savi",
         "Zalec",
         "Zavrc",
         "Zelezniki",
         "Zetale",
         "Ziri",
         "Zirovnica",
         "Zuzemberk",
         "Zrece",
       ],
       "Solomon Islands": [
         "Central",
         "Choiseul",
         "Guadalcanal",
         "Honiara",
         "Isabel",
         "Makira",
         "Malaita",
         "Rennell and Bellona",
         "Temotu",
         "Western",
       ],
       Somalia: [
         "Awdal",
         "Bakool",
         "Banaadir",
         "Bari",
         "Bay",
         "Galguduud",
         "Gedo",
         "Hiiraan",
         "Jubbada Dhexe",
         "Jubbada Hoose",
         "Mudug",
         "Nugaal",
         "Sanaag",
         "Shabeellaha Dhexe",
         "Shabeellaha Hoose",
         "Sool",
         "Togdheer",
         "Woqooyi Galbeed",
       ],
       "South Africa": [
         "Eastern Cape",
         "Free State",
         "Gauteng",
         "KwaZulu-Natal",
         "Limpopo",
         "Mpumalanga",
         "North-West",
         "Northern Cape",
         "Western Cape",
       ],
       "South Georgia and the South Sandwich Islands": null,
       "South Sudan": null,
       Spain: [
         "Andalucia",
         "Aragon",
         "Asturias",
         "Baleares",
         "Ceuta",
         "Canarias",
         "Cantabria",
         "Castilla-La Mancha",
         "Castilla y Leon",
         "Cataluna",
         "Comunidad Valenciana",
         "Extremadura",
         "Galicia",
         "La Rioja",
         "Madrid",
         "Melilla",
         "Murcia",
         "Navarra",
         "Pais Vasco",
       ],
       "Sri Lanka": [
         "Central",
         "North Central",
         "North Eastern",
         "North Western",
         "Sabaragamuwa",
         "Southern",
         "Uva",
         "Western",
       ],
       "Sudan (the)": [
         "A'ali an Nil",
         "Al Bahr al Ahmar",
         "Al Buhayrat",
         "Al Jazirah",
         "Al Khartum",
         "Al Qadarif",
         "Al Wahdah",
         "An Nil al Abyad",
         "An Nil al Azraq",
         "Ash Shamaliyah",
         "Bahr al Jabal",
         "Gharb al Istiwa'iyah",
         "Gharb Bahr al Ghazal",
         "Gharb Darfur",
         "Gharb Kurdufan",
         "Janub Darfur",
         "Janub Kurdufan",
         "Junqali",
         "Kassala",
         "Nahr an Nil",
         "Shamal Bahr al Ghazal",
         "Shamal Darfur",
         "Shamal Kurdufan",
         "Sharq al Istiwa'iyah",
         "Sinnar",
         "Warab",
       ],
       Suriname: [
         "Brokopondo",
         "Commewijne",
         "Coronie",
         "Marowijne",
         "Nickerie",
         "Para",
         "Paramaribo",
         "Saramacca",
         "Sipaliwini",
         "Wanica",
       ],
       "Svalbard and Jan Mayen": null,
       Swaziland: ["Hhohho", "Lubombo", "Manzini", "Shiselweni"],
       Sweden: [
         "Blekinge",
         "Dalarna",
         "Gävleborg",
         "Gotland",
         "Halland",
         "Jämtland",
         "Jönköping",
         "Kalmar",
         "Kronoberg",
         "Norrbotten",
         "Örebro",
         "Östergötland",
         "Skåne",
         "Södermanland",
         "Stockholm",
         "Uppsala",
         "Värmland",
         "Västerbotten",
         "Västernorrland",
         "Västmanland",
         "Västra Götaland",
       ],
       Switzerland: [
         "Aargau",
         "Appenzell Ausser-Rhoden",
         "Appenzell Inner-Rhoden",
         "Basel-Landschaft",
         "Basel-Stadt",
         "Bern",
         "Fribourg",
         "Geneve",
         "Glarus",
         "Graubunden",
         "Jura",
         "Luzern",
         "Neuchatel",
         "Nidwalden",
         "Obwalden",
         "Sankt Gallen",
         "Schaffhausen",
         "Schwyz",
         "Solothurn",
         "Thurgau",
         "Ticino",
         "Uri",
         "Valais",
         "Vaud",
         "Zug",
         "Zurich",
       ],
       "Syrian Arab Republic": [
         "Al Hasakah",
         "Al Ladhiqiyah",
         "Al Qunaytirah",
         "Ar Raqqah",
         "As Suwayda'",
         "Dar'a",
         "Dayr az Zawr",
         "Dimashq",
         "Halab",
         "Hamah",
         "Hims",
         "Idlib",
         "Rif Dimashq",
         "Tartus",
       ],
       Taiwan: [
         "Chang-hua",
         "Chia-i",
         "Hsin-chu",
         "Hua-lien",
         "I-lan",
         "Kao-hsiung",
         "Kin-men",
         "Lien-chiang",
         "Miao-li",
         "Nan-t'ou",
         "P'eng-hu",
         "P'ing-tung",
         "T'ai-chung",
         "T'ai-nan",
         "T'ai-pei",
         "T'ai-tung",
         "T'ao-yuan",
         "Yun-lin",
         "Chia-i",
         "Chi-lung",
         "Hsin-chu",
         "T'ai-chung",
         "T'ai-nan",
         "Kao-hsiung city",
         "T'ai-pei city",
       ],
       Tajikistan: null,
       "Tanzania": [
         "Arusha",
         "Dar es Salaam",
         "Dodoma",
         "Districts of Republican Subordination",
         "Dushanbe",
         "Gorno-Badakhshan Autonomous Region",
         "Iringa",
         "Kagera",
         "Khatlon Region",
         "Kigoma",
         "Kilimanjaro",
         "Lindi",
         "Manyara",
         "Mara",
         "Mbeya",
         "Morogoro",
         "Mtwara",
         "Mwanza",
         "Pemba North",
         "Pemba South",
         "Pwani",
         "Rukwa",
         "Ruvuma",
         "Shinyanga",
         "Singida",
         "Sughd Region",
         "Tabora",
         "Tanga",
         "Zanzibar Central/South",
         "Zanzibar North",
         "Zanzibar Urban/West",
       ],
       Thailand: [
         "Amnat Charoen",
         "Ang Thong",
         "Buriram",
         "Chachoengsao",
         "Chai Nat",
         "Chaiyaphum",
         "Chanthaburi",
         "Chiang Mai",
         "Chiang Rai",
         "Chon Buri",
         "Chumphon",
         "Kalasin",
         "Kamphaeng Phet",
         "Kanchanaburi",
         "Khon Kaen",
         "Krabi",
         "Krung Thep Mahanakhon",
         "Lampang",
         "Lamphun",
         "Loei",
         "Lop Buri",
         "Mae Hong Son",
         "Maha Sarakham",
         "Mukdahan",
         "Nakhon Nayok",
         "Nakhon Pathom",
         "Nakhon Phanom",
         "Nakhon Ratchasima",
         "Nakhon Sawan",
         "Nakhon Si Thammarat",
         "Nan",
         "Narathiwat",
         "Nong Bua Lamphu",
         "Nong Khai",
         "Nonthaburi",
         "Pathum Thani",
         "Pattani",
         "Phangnga",
         "Phatthalung",
         "Phayao",
         "Phetchabun",
         "Phetchaburi",
         "Phichit",
         "Phitsanulok",
         "Phra Nakhon Si Ayutthaya",
         "Phrae",
         "Phuket",
         "Prachin Buri",
         "Prachuap Khiri Khan",
         "Ranong",
         "Ratchaburi",
         "Rayong",
         "Roi Et",
         "Sa Kaeo",
         "Sakon Nakhon",
         "Samut Prakan",
         "Samut Sakhon",
         "Samut Songkhram",
         "Sara Buri",
         "Satun",
         "Sing Buri",
         "Sisaket",
         "Songkhla",
         "Sukhothai",
         "Suphan Buri",
         "Surat Thani",
         "Surin",
         "Tak",
         "Trang",
         "Trat",
         "Ubon Ratchathani",
         "Udon Thani",
         "Uthai Thani",
         "Uttaradit",
         "Yala",
         "Yasothon",
       ],
       "Timor-Leste": null,
       Togo: ["Kara", "Plateaux", "Savanes", "Centrale", "Maritime"],
       Tokelau: null,
       Tonga: ["Tongatapu", "Vavaʻu", "Haʻapai", "ʻEua", "Ongo Niua", "Tonga"],
       "Trinidad and Tobago": [
         "Couva",
         "Diego Martin",
         "Mayaro",
         "Penal",
         "Princes Town",
         "Sangre Grande",
         "San Juan",
         "Siparia",
         "Tunapuna",
         "Port-of-Spain",
         "San Fernando",
         "Arima",
         "Point Fortin",
         "Chaguanas",
         "Tobago",
       ],
       Tunisia: [
         "Ariana (Aryanah)",
         "Beja (Bajah)",
         "Ben Arous (Bin 'Arus)",
         "Bizerte (Banzart)",
         "Gabes (Qabis)",
         "Gafsa (Qafsah)",
         "Jendouba (Jundubah)",
         "Kairouan (Al Qayrawan)",
         "Kasserine (Al Qasrayn)",
         "Kebili (Qibili)",
         "Kef (Al Kaf)",
         "Mahdia (Al Mahdiyah)",
         "Manouba (Manubah)",
         "Medenine (Madanin)",
         "Monastir (Al Munastir)",
         "Nabeul (Nabul)",
         "Sfax (Safaqis)",
         "Sidi Bou Zid (Sidi Bu Zayd)",
         "Siliana (Silyanah)",
         "Sousse (Susah)",
         "Tataouine (Tatawin)",
         "Tozeur (Tawzar)",
         "Tunis",
         "Zaghouan (Zaghwan)",
       ],
       Turkey: [
         "Adana",
         "Adiyaman",
         "Afyonkarahisar",
         "Agri",
         "Aksaray",
         "Amasya",
         "Ankara",
         "Antalya",
         "Ardahan",
         "Artvin",
         "Aydin",
         "Balikesir",
         "Bartin",
         "Batman",
         "Bayburt",
         "Bilecik",
         "Bingol",
         "Bitlis",
         "Bolu",
         "Burdur",
         "Bursa",
         "Canakkale",
         "Cankiri",
         "Corum",
         "Denizli",
         "Diyarbakir",
         "Duzce",
         "Edirne",
         "Elazig",
         "Erzincan",
         "Erzurum",
         "Eskisehir",
         "Gaziantep",
         "Giresun",
         "Gumushane",
         "Hakkari",
         "Hatay",
         "Igdir",
         "Isparta",
         "Istanbul",
         "Izmir",
         "Kahramanmaras",
         "Karabuk",
         "Karaman",
         "Kars",
         "Kastamonu",
         "Kayseri",
         "Kilis",
         "Kirikkale",
         "Kirklareli",
         "Kirsehir",
         "Kocaeli",
         "Konya",
         "Kutahya",
         "Malatya",
         "Manisa",
         "Mardin",
         "Mersin",
         "Mugla",
         "Mus",
         "Nevsehir",
         "Nigde",
         "Ordu",
         "Osmaniye",
         "Rize",
         "Sakarya",
         "Samsun",
         "Sanliurfa",
         "Siirt",
         "Sinop",
         "Sirnak",
         "Sivas",
         "Tekirdag",
         "Tokat",
         "Trabzon",
         "Tunceli",
         "Usak",
         "Van",
         "Yalova",
         "Yozgat",
         "Zonguldak",
       ],
       Turkmenistan: [
         "Ahal Welayaty (Ashgabat)",
         "Balkan Welayaty (Balkanabat)",
         "Dashoguz Welayaty",
         "Lebap Welayaty (Turkmenabat)",
         "Mary Welayaty",
       ],
       "Turks and Caicos Islands (the)": null,
       Tuvalu: null,
       Uganda: [
         "Adjumani",
         "Apac",
         "Arua",
         "Bugiri",
         "Bundibugyo",
         "Bushenyi",
         "Busia",
         "Gulu",
         "Hoima",
         "Iganga",
         "Jinja",
         "Kabale",
         "Kabarole",
         "Kaberamaido",
         "Kalangala",
         "Kampala",
         "Kamuli",
         "Kamwenge",
         "Kanungu",
         "Kapchorwa",
         "Kasese",
         "Katakwi",
         "Kayunga",
         "Kibale",
         "Kiboga",
         "Kisoro",
         "Kitgum",
         "Kotido",
         "Kumi",
         "Kyenjojo",
         "Lira",
         "Luwero",
         "Masaka",
         "Masindi",
         "Mayuge",
         "Mbale",
         "Mbarara",
         "Moroto",
         "Moyo",
         "Mpigi",
         "Mubende",
         "Mukono",
         "Nakapiripirit",
         "Nakasongola",
         "Nebbi",
         "Ntungamo",
         "Pader",
         "Pallisa",
         "Rakai",
         "Rukungiri",
         "Sembabule",
         "Sironko",
         "Soroti",
         "Tororo",
         "Wakiso",
         "Yumbe",
       ],
       Ukraine: [
         "Cherkasy",
         "Chernihiv",
         "Chernivtsi",
         "Crimea",
         "Dnipropetrovs'k",
         "Donets'k",
         "Ivano-Frankivs'k",
         "Kharkiv",
         "Kherson",
         "Khmel'nyts'kyy",
         "Kirovohrad",
         "Kiev",
         "Kyyiv",
         "Luhans'k",
         "L'viv",
         "Mykolayiv",
         "Odesa",
         "Poltava",
         "Rivne",
         "Sevastopol'",
         "Sumy",
         "Ternopil'",
         "Vinnytsya",
         "Volyn'",
         "Zakarpattya",
         "Zaporizhzhya",
         "Zhytomyr",
       ],
       "United Arab Emirates (UAE)": [
         "Abu Dhabi",
         "'Ajman",
         "Al Fujayrah",
         "Sharjah",
         "Dubai",
         "Ra's al Khaymah",
         "Umm al Qaywayn",
       ],
       "United Kingdom of Great Britain and Northern Ireland (UK)": [
         "Aberconwy and Colwyn",
         "Aberdeen City",
         "Aberdeenshire",
         "Anglesey",
         "Angus",
         "Antrim",
         "Argyll and Bute",
         "Armagh",
         "Avon",
         "Ayrshire",
         "Bath and NE Somerset",
         "Bedfordshire",
         "Belfast",
         "Berkshire",
         "Berwickshire",
         "BFPO",
         "Blaenau Gwent",
         "Buckinghamshire",
         "Caernarfonshire",
         "Caerphilly",
         "Caithness",
         "Cambridgeshire",
         "Cardiff",
         "Cardiganshire",
         "Carmarthenshire",
         "Ceredigion",
         "Channel Islands",
         "Cheshire",
         "City of Bristol",
         "Clackmannanshire",
         "Clwyd",
         "Conwy",
         "Cornwall/Scilly",
         "Cumbria",
         "Denbighshire",
         "Derbyshire",
         "Derry/Londonderry",
         "Devon",
         "Dorset",
         "Down",
         "Dumfries and Galloway",
         "Dunbartonshire",
         "Dundee",
         "Durham",
         "Dyfed",
         "East Ayrshire",
         "East Dunbartonshire",
         "East Lothian",
         "East Renfrewshire",
         "East Riding Yorkshire",
         "East Sussex",
         "Edinburgh",
         "England",
         "Essex",
         "Falkirk",
         "Fermanagh",
         "Fife",
         "Flintshire",
         "Glasgow",
         "Gloucestershire",
         "Greater London",
         "Greater Manchester",
         "Gwent",
         "Gwynedd",
         "Hampshire",
         "Hartlepool",
         "Hereford and Worcester",
         "Hertfordshire",
         "Highlands",
         "Inverclyde",
         "Inverness-Shire",
         "Isle of Man",
         "Isle of Wight",
         "Kent",
         "Kincardinshire",
         "Kingston Upon Hull",
         "Kinross-Shire",
         "Kirklees",
         "Lanarkshire",
         "Lancashire",
         "Leicestershire",
         "Lincolnshire",
         "Londonderry",
         "Merseyside",
         "Merthyr Tydfil",
         "Mid Glamorgan",
         "Mid Lothian",
         "Middlesex",
         "Monmouthshire",
         "Moray",
         "Neath & Port Talbot",
         "Newport",
         "Norfolk",
         "North Ayrshire",
         "North East Lincolnshire",
         "North Lanarkshire",
         "North Lincolnshire",
         "North Somerset",
         "North Yorkshire",
         "Northamptonshire",
         "Northern Ireland",
         "Northumberland",
         "Nottinghamshire",
         "Orkney and Shetland Isles",
         "Oxfordshire",
         "Pembrokeshire",
         "Perth and Kinross",
         "Powys",
         "Redcar and Cleveland",
         "Renfrewshire",
         "Rhonda Cynon Taff",
         "Rutland",
         "Scottish Borders",
         "Shetland",
         "Shropshire",
         "Somerset",
         "South Ayrshire",
         "South Glamorgan",
         "South Gloucesteshire",
         "South Lanarkshire",
         "South Yorkshire",
         "Staffordshire",
         "Stirling",
         "Stockton On Tees",
         "Suffolk",
         "Surrey",
         "Swansea",
         "Torfaen",
         "Tyne and Wear",
         "Tyrone",
         "Vale Of Glamorgan",
         "Wales",
         "Warwickshire",
         "West Berkshire",
         "West Dunbartonshire",
         "West Glamorgan",
         "West Lothian",
         "West Midlands",
         "West Sussex",
         "West Yorkshire",
         "Western Isles",
         "Wiltshire",
         "Wirral",
         "Worcestershire",
         "Wrexham",
         "York",
       ],
       "United States Minor Outlying Islands (the)": null,
       "United States of America (USA)": [
         "Alabama",
         "Alaska",
         "Arizona",
         "Arkansas",
         "California",
         "Colorado",
         "Connecticut",
         "Delaware",
         "District of Columbia",
         "Florida",
         "Georgia",
         "Hawaii",
         "Idaho",
         "Illinois",
         "Indiana",
         "Iowa",
         "Kansas",
         "Kentucky",
         "Louisiana",
         "Maine",
         "Maryland",
         "Massachusetts",
         "Michigan",
         "Minnesota",
         "Mississippi",
         "Missouri",
         "Montana",
         "Nebraska",
         "Nevada",
         "New Hampshire",
         "New Jersey",
         "New Mexico",
         "New York",
         "North Carolina",
         "North Dakota",
         "Ohio",
         "Oklahoma",
         "Oregon",
         "Pennsylvania",
         "Rhode Island",
         "South Carolina",
         "South Dakota",
         "Tennessee",
         "Texas",
         "Utah",
         "Vermont",
         "Virginia",
         "Washington",
         "West Virginia",
         "Wisconsin",
         "Wyoming",
       ],
       Uruguay: [
         "Artigas",
         "Canelones",
         "Cerro Largo",
         "Colonia",
         "Durazno",
         "Flores",
         "Florida",
         "Lavalleja",
         "Maldonado",
         "Montevideo",
         "Paysandu",
         "Rio Negro",
         "Rivera",
         "Rocha",
         "Salto",
         "San Jose",
         "Soriano",
         "Tacuarembo",
         "Treinta y Tres",
       ],
       Uzbekistan: [
         "Andijon Viloyati",
         "Buxoro Viloyati",
         "Farg'ona Viloyati",
         "Jizzax Viloyati",
         "Namangan Viloyati",
         "Navoiy Viloyati",
         "Qashqadaryo Viloyati",
         "Qaraqalpog'iston Respublikasi",
         "Samarqand Viloyati",
         "Sirdaryo Viloyati",
         "Surxondaryo Viloyati",
         "Toshkent Shahri",
         "Toshkent Viloyati",
         "Xorazm Viloyati",
       ],
       Vanuatu: ["Malampa", "Penama", "Sanma", "Shefa", "Tafea", "Torba"],
       "Venezuela (Bolivarian Republic of)": [
         "Amazonas",
         "Anzoategui",
         "Apure",
         "Aragua",
         "Barinas",
         "Bolivar",
         "Carabobo",
         "Cojedes",
         "Delta Amacuro",
         "Dependencias Federales",
         "Distrito Federal",
         "Falcon",
         "Guarico",
         "Lara",
         "Merida",
         "Miranda",
         "Monagas",
         "Nueva Esparta",
         "Portuguesa",
         "Sucre",
         "Tachira",
         "Trujillo",
         "Vargas",
         "Yaracuy",
         "Zulia",
       ],
       "Viet Nam": [
         "An Giang",
         "Bac Giang",
         "Bac Kan",
         "Bac Lieu",
         "Bac Ninh",
         "Ba Ria-Vung Tau",
         "Ben Tre",
         "Binh Dinh",
         "Binh Duong",
         "Binh Phuoc",
         "Binh Thuan",
         "Ca Mau",
         "Cao Bang",
         "Dac Lak",
         "Dac Nong",
         "Dien Bien",
         "Dong Nai",
         "Dong Thap",
         "Gia Lai",
         "Ha Giang",
         "Hai Duong",
         "Ha Nam",
         "Ha Tay",
         "Ha Tinh",
         "Hau Giang",
         "Hoa Binh",
         "Hung Yen",
         "Khanh Hoa",
         "Kien Giang",
         "Kon Tum",
         "Lai Chau",
         "Lam Dong",
         "Lang Son",
         "Lao Cai",
         "Long An",
         "Nam Dinh",
         "Nghe An",
         "Ninh Binh",
         "Ninh Thuan",
         "Phu Tho",
         "Phu Yen",
         "Quang Binh",
         "Quang Nam",
         "Quang Ngai",
         "Quang Ninh",
         "Quang Tri",
         "Soc Trang",
         "Son La",
         "Tay Ninh",
         "Thai Binh",
         "Thai Nguyen",
         "Thanh Hoa",
         "Thua Thien-Hue",
         "Tien Giang",
         "Tra Vinh",
         "Tuyen Quang",
         "Vinh Long",
         "Vinh Phuc",
         "Yen Bai",
         "Can Tho",
         "Da Nang",
         "Hai Phong",
         "Hanoi",
         "Ho Chi Minh",
       ],
       "Virgin Islands (British)": null,
       "Virgin Islands (U.S.)": null,
       "Wallis and Futuna": null,
       "Western Sahara": null,
       Yemen: [
         "Abyan",
         "'Adan",
         "Ad Dali'",
         "Al Bayda'",
         "Al Hudaydah",
         "Al Jawf",
         "Al Mahrah",
         "Al Mahwit",
         "'Amran",
         "Dhamar",
         "Hadramawt",
         "Hajjah",
         "Ibb",
         "Lahij",
         "Ma'rib",
         "Sa'dah",
         "San'a'",
         "Shabwah",
         "Ta'izz",
       ],
       Zambia: [
         "Central",
         "Copperbelt",
         "Eastern",
         "Luapula",
         "Lusaka",
         "Northern",
         "North-Western",
         "Southern",
         "Western",
       ],
       Zimbabwe: [
         "Bulawayo",
         "Harare",
         "Manicaland",
         "Mashonaland Central",
         "Mashonaland East",
         "Mashonaland West",
         "Masvingo",
         "Matabeleland North",
         "Matabeleland South",
         "Midlands",
       ],
       "Aland Islands": null,
     }


    return {
        countryDetails,
        statesDetails
    }

}
