<template>
  <feed-fab v-if="!isWebBigScreen && showFloatingTab"></feed-fab>

  <ion-footer class="ion-no-border" v-if="showMenuTab">
    <ion-toolbar v-if="!isWebBigScreen">
      <!-- <ion-tabs @ionTabsWillChange="beforeTabChange" @ionTabsDidChange="afterTabChange"> -->
      <ion-tabs>
        <ion-router-outlet></ion-router-outlet>
        <ion-tab-bar slot="bottom">
          <ion-tab-button tab="menu" @click="openMenuOptions">
            <ion-icon :icon="menu"></ion-icon>
          </ion-tab-button>
          <ion-tab-button tab="search" href="/search">
            <ion-icon :icon="searchOutline"></ion-icon>
          </ion-tab-button>
          <ion-tab-button tab="Feed" href="/feed">
            <ion-icon :icon="home"></ion-icon>
          </ion-tab-button>
          <ion-tab-button tab="calendar" href="/calendar">
            <ion-icon :icon="calendar"></ion-icon>
            <!-- <ion-badge>6</ion-badge> -->
          </ion-tab-button>
          <!-- <ion-tab-button tab="message" href="/message" >
            <ion-icon :icon="mail"></ion-icon>
            <ion-label>Messages</ion-label>
          </ion-tab-button> -->
          <ion-tab-button tab="my_account" href="/my_account">
            <ion-icon :icon="person"></ion-icon>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </ion-toolbar>
  </ion-footer>
</template>

<script lang="ts">
import {
  IonToolbar,
  IonFooter,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonIcon,
  IonRouterOutlet,
  modalController,
} from "@ionic/vue";
import { defineComponent, inject, ref, computed } from "vue";
import {
  home,
  menu,
  calendar,
  notifications,
  mail,
  searchOutline,
  person,
} from "ionicons/icons";
import { useStore } from "vuex";

import FeedFab from "@/components/feed/FeedFab.vue";
import MainMenuOptions from "@/components/main/MainMenuOptions.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "MainTab",
  components: {
    IonToolbar,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonIcon,
    IonRouterOutlet,
    FeedFab,
    IonFooter,
  },
  props: {
    showFloatingTab: {
      type: Boolean,
      default: false,
    },
    showMenuTab: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();

    // get the global vuex store objects
    const vueStore = useStore();

    const isWebBigScreen = ref(inject("isWebBigScreen"));

    async function openMenuOptions() {
      const modal = await modalController.create({
        component: MainMenuOptions,
        breakpoints: [0, 0.6],
        initialBreakpoint: 0.6,
        handle: true,
        mode: "ios",
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if (role === "confirm") {
        router.push(data);
      }
    }

    const compAlertsList = computed(function () {
      const alertsData = vueStore.getters["alerts/getAlertsList"];

      const activeAlerts = alertsData.filter(
        (item) => item.status === "active"
      );

      return activeAlerts;
    });

    return {
      home,
      menu,
      calendar,
      notifications,
      mail,
      searchOutline,
      person,
      openMenuOptions,
      isWebBigScreen,
      compAlertsList,
    };
  },
});
</script>

<style scoped>
ion-modal::part(content) {
  display: flex;
  flex-direction: column;
  height: 100%;
}

ion-action-sheet.action-sheet-class {
  --background: #eee;
  --backdrop-opacity: 0.8;
  --button-background-selected: #e97223;
  --button-color: #333;
  --color: #000;
  /* role: "destructive" button iOS styling override */
  --ion-color-danger: #000000;
}

ion-toolbar {
  --background: #fff;
}

ion-tab-button {
  --color: #555;
}
</style>
