<template>
  <div class="container">
    <div
      class="row justify-content-center align-items-center"
      :class="actionType === 'onboarding' ? 'vh-100' : 'mt-2'"
    >
      <div
        class="mx-auto"
        :class="
          actionType === 'onboarding' && isWebBigScreen ? 'col-4' : 'col-12'
        "
      >
        <div
          class="text-center osahan-login pt-4"
          v-if="actionType === 'onboarding'"
        >
          <router-link to="/account"
            ><img src="../../../images/main/logo.png" alt=""
          /></router-link>
          <h5 class="font-weight-bold mt-3" v-if="reqFormStatus">
            Create brand/business name
          </h5>
          <p class="text-muted mt-2">Step <b>1</b> of <b>2</b></p>
        </div>

        <div
          class="osahan-login"
          :class="actionType === 'onboarding' ? 'mt-4 py-4' : 'mt-2'"
          v-if="reqFormStatus"
        >
          <div class="form-group">
            <ion-label class="mb-1">
              Brand/Business name
              <ion-note
                ><span v-if="fv.username.msg">
                  | {{ fv.username.msg }}</span
                ></ion-note
              >
            </ion-label>
            <div class="position-relative icon-form-control">
              <i class="position-absolute">
                <ion-icon :icon="person"></ion-icon>
              </i>
              <input
                type="text"
                class="form-control"
                required
                v-model.trim="fv.username.val"
                @keyup="validate.validateFormData(fv, 'username', true)"
              />
            </div>
          </div>

          <ion-note>{{ reqFeedback }}</ion-note>
          <div v-if="reqStatus === 'ready'">
            <button
              class="btn btn-success btn-block"
              @click="runChangeUsername"
              type="button"
            >
              Update Brand/Business name
            </button>
          </div>
          <ion-spinner
            name="circles"
            class="mb-3 ml-2"
            v-if="reqStatus === 'processing'"
          ></ion-spinner>
        </div>
        <div class="container" v-else>
          <img
            class="img-fluid"
            src="../../../images/main/username.svg"
            alt=""
          />
          <div class="text-center pt-2">
            <h5 class="font-weight-bold mt-3">
              Brand/Business name update successful !!!
            </h5>
            <p class="text-muted">
              Almost there, proceed to the final step to complete your
              onboarding.
            </p>

            <ion-button
              @click="goToLink('onboard_service_provider')"
              class="mt-2"
              expand="block"
              color="primary"
              fill="outline"
              style="text-transform: none"
              >Create basic profile information</ion-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { IonIcon, IonNote, IonLabel, IonSpinner, IonButton } from "@ionic/vue";
import { person } from "ionicons/icons";
import { defineComponent, reactive, inject, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import storeKeeper from "@/hooks/RendezStorage";
import { CapacitorHttp } from "@capacitor/core";
import urls from "@/hooks/urlConfig";
import validators from "@/hooks/validation";

export default defineComponent({
  name: "CreateAuthChangeUsername",
  components: {
    IonIcon,
    IonNote,
    IonLabel,
    IonSpinner,
    IonButton,
  },
  props: {
    actionType: {
      required: true,
      default: "settings",
    },
  },
  setup(props) {
    const sk = storeKeeper();

    //get the urls
    const coreUrl = urls.coreUrl;

    // get the global vuex store objects
    const vueStore = useStore();

    const router = useRouter();

    // get the validators
    const validate = validators();

    // get feedback toast function
    const feedbackToast: any = inject("feedbackToast");

    const isWebBigScreen = ref(inject("isWebBigScreen"));

    // reference form variables validation in main doc
    const fv: any = reactive({
      username: {
        status: false,
        val: "",
        msg: false,
        validate: [
          "required",
          "safeSpaceInput",
          { option: "textMin", val: 3 },
          { option: "textMax", val: 40 },
        ],
      },
      showSubmit: { status: false, val: true },
    });

    // reference request state and response in main doc
    const reqFeedback = ref("");
    const reqStatus = ref("ready");
    const reqFormStatus = ref(true);

    // initiate reactive sessionData variable
    const sessionData: any = ref(null);

    // got to link for rerouting link
    function goToLink(link) {
      router.replace(link);
    }

    async function getCurrentUsername() {
      const newSessionData = await sk.getStoreIon("session_data");
      sessionData.value = await JSON.parse(newSessionData);
      fv.username.val =
        props.actionType === "settings" ? sessionData.value.username : "";
    }

    getCurrentUsername();

    // function to actually change the username
    const changeUsername = async () => {
      // get token to allow yo to access the backend api
      const tokenId = await sk.getStoreIon("access_token");

      // start action
      reqStatus.value = "processing";

      // set the data required to access and run the backend
      const options = {
        url: coreUrl.backendUrl + "update_username",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenId,
        },
        data: {
          iv_username: fv.username.val.replace(/ /g, "_"),
        },
      };

      // send the request to the server to be processed
      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      // execute storage to update frontend state data
      async function runStorage() {
        // save data in vue x for faster state management
        const payload = {
          loadAuth: true,
          data: response.data.data.sessionData,
        };
        await vueStore.dispatch("auth/executeSetSessionData", payload);

        // save data in the ionic storage db
        await sk.setStoreIon(
          "session_data",
          JSON.stringify(response.data.data.sessionData)
        );
      }

      // if action is succesful update state, and reset form
      if (response.data.status) {
        // main action success message
        reqFeedback.value = response.data.message.main_msg;

        // update state
        runStorage();

        // reset form and request Status depending on actiontype , settings or onboarding
        reqFormStatus.value = props.actionType === "settings" ? true : false;
        reqStatus.value = "complete";
      } else if (response.status === 401) {
        // if auth token has expired, delete all local storage data with regards to this page and redirect to sign in page
        await sk.delAllStoreIon();

        window.location.href = "/sign_in";
      } else {
        // get the general error message and
        // form specific error
        const iv_msg: any = response.data.message.iv_msg;
        reqFeedback.value = response.data.message.main_msg;

        // loop through form errors and match the faulty forms
        if (iv_msg) {
          for (const key in iv_msg) {
            if (fv[key]) {
              fv[key]["status"] = false;
              fv[key]["msg"] = iv_msg[key];
            }
          }
        }

        // reset action to ready
        reqStatus.value = "ready";
      }
    };

    const runChangeUsername = async () => {
      changeUsername()
        .then()
        .catch((err) => {
          const response = err
            ? "Temporary server issue"
            : "Temporary server issue";
          feedbackToast(response);
        });
    };

    return {
      person,
      reqFeedback,
      runChangeUsername,
      validate,
      fv,
      isWebBigScreen,
      reqStatus,
      reqFormStatus,
      goToLink,
    };
  },
});
</script>

<style scoped>
ion-list {
  --background: #fff;
}

ion-item {
  --background: #fff;
  --color: #111;
  --padding: 0px;

  /* --border-style: dashed; */
  /* --border-width: 2px; */

  /* --border-radius: 20px;

   --ripple-color: purple;

   --detail-icon-color: white;
   --detail-icon-opacity: 1;
   --detail-icon-font-size: 20px; */
}

.homepage-search-block {
  background: #f5f5f5;
}
</style>
