import { createStore } from "vuex";

import auth from "./modules/auth/index";
import account from "./modules/account/index";
import feed from "./modules/feed/index";
import search from "./modules/search/index";
import fav from "./modules/fav/index";
import alerts from "./modules/alerts/index";
import bookings from "./modules/bookings/index";

const vueStore = createStore({
  modules: {
    auth: auth,
    account: account,
    feed: feed,
    search: search,
    fav: fav,
    alerts: alerts,
    bookings: bookings,
  },
});

export default vueStore;
