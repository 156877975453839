<template>
  <div v-if="currentBookingView === 'schedulePreview'">
    <div id="center-spinner" v-if="!loadRequiredData">
      <ion-spinner name="bubbles"></ion-spinner>
    </div>
    <div
      v-if="accountDataServices.length === 0 && activeDeliveryModeFilter"
      class="text-center my-5 py-3 mx-3 no-services"
    >
      This provider does not offer {{ activeDeliveryModeFilter }} services.
    </div>

    <div
      v-if="accountDataServices.length === 0 && !activeDeliveryModeFilter"
      class="text-center my-5 py-5 mx-3 no-services"
    >
      Select a service delivery mode to proceed with your request.
      <div class="row mt-5 py-1">
        <div class="col-lg-3 col-md-3 col-1"></div>
        <div class="col-lg-3 col-md-3 col-5 px-1">
          <ion-button
            fill="outline"
            color="dark"
            expand="block"
            @click="$emit('emitUpdateActiveDeliveryModeFilter', 'remote')"
          >
            <i class="fa fa-wifi pr-2"></i>
            Remote
          </ion-button>
        </div>
        <div class="col-lg-3 col-md-3 col-5 px-1">
          <ion-button
            fill="outline"
            color="dark"
            expand="block"
            @click="$emit('emitUpdateActiveDeliveryModeFilter', 'in-person')"
          >
            <i class="fa fa-address-book-o pr-2"></i>
            In-person
          </ion-button>
        </div>
        <div class="col-lg-3 col-md-3 col-1"></div>
      </div>
    </div>
    <div v-if="accountDataServices.length > 0">
      <ion-list class="p-0 m-0">
        <ion-item
          lines="full"
          class="mt-2 pt-2 pb-2"
          v-for="(serve, index) in currAccountDataServices"
          :key="serve + index"
        >
          <ion-label class="ion-text-wrap">
            {{ serve.service }}
            <div class="small text-gray-500" style="font-size: 9px">
              {{ tk.timeHourMin(serve.duration) }} per session
              <b>
                (
                {{
                  serve.delivery_mode
                    ? serve.delivery_mode == "hybrid"
                      ? "remote & in-person"
                      : serve.delivery_mode
                    : currentProviderData["delivery_mode"] === "hybrid"
                    ? "remote & in-person"
                    : currentProviderData["delivery_mode"]
                }}
                )
              </b>
            </div>
            <div class="small text-gray-500" style="font-size: 9px">
              <b>
                {{
                  cash["currencyDetails"][totalServiceCurrency.split("-")[0]][
                    "symbol_native"
                  ]
                }}
                {{ parseInt(serve.cost).toLocaleString() }}
              </b>
            </div>
          </ion-label>
          <ion-checkbox
            color="primary"
            slot="end"
            :value="index"
            @click="selectCustomService(index)"
            v-if="serve.status === 'selected'"
            checked
          >
          </ion-checkbox>
          <ion-checkbox
            color="primary"
            slot="end"
            :value="index"
            @click="selectCustomService(index)"
            v-else
          >
          </ion-checkbox>
        </ion-item>
      </ion-list>
    </div>
  </div>

  <div v-if="currentBookingView === 'bookingPreview'">
    <ion-toolbar v-if="loadProviderInfo">
      <ion-buttons
        slot="start"
        @click="changeDays('prev')"
        class="pointer-cursor"
        v-if="!isDateToday"
      >
        <ion-icon :icon="arrowBackCircle" size="small" slot="start"></ion-icon>
        prev day
      </ion-buttons>
      <ion-title>
        <ion-datetime-button
          datetime="datetime"
          v-if="appointmentProviderInfo"
        ></ion-datetime-button>
      </ion-title>
      <ion-buttons
        slot="end"
        @click="changeDays('next')"
        class="pointer-cursor"
      >
        next day
        <ion-icon :icon="arrowForwardCircle" size="small" slot="end"></ion-icon>
      </ion-buttons>
    </ion-toolbar>

    <ion-list v-if="compValidApptSchedules.length > 0">
      <div class="mt-3 text-center">
        <div class="container">
          <div class="row">
            <div
              :class="isWebBigScreen ? 'col-2' : 'col-6 px-1'"
              v-for="(appt, index) in compValidApptSchedules"
              :key="appt + index"
            >
              <ion-button
                size="large"
                expand="block"
                :color="selectedSessions.includes(index) ? 'primary' : 'dark'"
                :fill="selectedSessions.includes(index) ? 'solid' : 'outline'"
                :class="isWebBigScreen ? 'my-3 my-1' : 'my-2 '"
                @click="selectSessionTime(index)"
              >
                <ion-label class="text-small">
                  <i class="fa fa-clock-o pr-2 pl-0"></i>
                  {{ showCorrectTimeZone(index) }}</ion-label
                >
              </ion-button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mt-3 text-center"
        :class="
          compSessionInsufficient < totalServiceDuration ? 'text-danger' : ''
        "
        v-if="
          compSessionInsufficient &&
          compSessionInsufficient !== totalServiceDuration
        "
      >
        <div>
          <i class="fa fa-warning"></i>
          {{
            compSessionInsufficient < totalServiceDuration
              ? `${tk.timeHourMin(
                  compSessionInsufficient
                )} is not sufficient time for`
              : `You have selected ${tk.timeHourMin(
                  compSessionInsufficient
                )} , but only ${tk.timeHourMin(
                  totalServiceDuration
                )} is required for `
          }}
          your {{ totalService.length }}
          {{
            totalService.length > 1 ? " selected services." : "selected service"
          }}
        </div>
      </div>
    </ion-list>

    <div class="container text-center" v-if="loadProviderInfo">
      <div
        class="container text-center"
        v-if="compValidApptSchedules.length === 0"
      >
        <div class="row pt-4">
          <div :class="isMobile ? 'col-3' : 'col-4'"></div>
          <div :class="isMobile ? 'col-6' : 'col-4'">
            <img
              class="img-fluid"
              src="../../../images/main/no_appointments.svg"
              alt=""
            />
          </div>
          <div :class="isMobile ? 'col-3' : 'col-4'"></div>
        </div>

        <div class="text-center">
          <h5 class="font-weight-bold mt-3">
            No available appointments {{ isMobile ? "" : compCleanCurrentDate }}
          </h5>
        </div>
      </div>
    </div>
    <ion-spinner name="circles" class="mb-3 ml-2" v-else></ion-spinner>
  </div>

  <div
    class="container text-center"
    v-if="
      currentBookingView === 'completePreview' ||
      currentBookingView === 'successfulPreview'
    "
  >
    <div
      class="bg-white mb-1 box shadow-sm rounded text-left mt-5 summary-text"
      v-if="currentBookingView !== 'successfulPreview'"
    >
      <div class="box-title border-bottom p-3">
        <h6 class="m-0">
          Request summary
          <span
            class="float-right pointer-cursor"
            style="color: #333"
            @click="editRequest"
          >
            <i class="fa fa-undo"></i>
            <span class="text-small"> Change </span>
          </span>
        </h6>
      </div>
      <div class="box-body p-3">
        <div class="row my-3 py-2 text-left">
          <span class="col-11 pr-0 mr-0 fw-bold">
            <span
              class="material-symbols-rounded pointer-cursor summary-text-icon fw-bold pr-2"
            >
              payments
            </span>
            {{
              totalService.length > 1
                ? totalService.length + " services"
                : totalService.length + " service"
            }}
            <span v-if="totalServiceCost > 0">
              at a cost of
              {{
                cash["currencyDetails"][totalServiceCurrency.split("-")[0]][
                  "symbol_native"
                ]
              }}

              {{ totalServiceCost }} </span
            ><span class="fw-bold" v-else>for free</span>
          </span>
          <span
            class="col-1 px-0 pointer-cursor text-secondary"
            @click="showServicesSummary = !showServicesSummary"
          >
            <i
              class="fa fa-chevron-down float-right pr-3"
              v-if="showServicesSummary"
            >
            </i>
            <i class="fa fa-chevron-right float-right pr-3" v-else> </i>
          </span>

          <div class="col-12 mx-2" v-if="showServicesSummary">
            <ion-list lines="full">
              <ion-item
                class="mt-2"
                style="font-size: 12px"
                v-for="(serve, index) in totalService"
                :key="serve + index"
              >
                <i>
                  <ion-label class="ion-text-wrap">
                    {{ serve.service }}
                  </ion-label>
                  <ion-label slot="end" class="fw-bold">
                    {{
                      cash["currencyDetails"][
                        totalServiceCurrency.split("-")[0]
                      ]["symbol_native"]
                    }}
                    {{ serve.cost }}
                  </ion-label>
                </i>
              </ion-item>
            </ion-list>
          </div>
        </div>

        <div class="row my-3 py-2 text-left">
          <span class="col-11 pr-0 mr-0 fw-bold">
            <span
              class="material-symbols-rounded pointer-cursor summary-text-icon fw-bold pr-2"
            >
              public
            </span>
            You opted for
            {{ activeDeliveryModeFilter === "remote" ? "a" : "an" }}
            {{ activeDeliveryModeFilter }} appointment</span
          >
          <span
            class="col-1 px-0 pointer-cursor text-secondary"
            @click="editRequest"
          >
            <i class="fa fa-chevron-right float-right pr-3"> </i>
          </span>
        </div>

        <div
          class="row my-3 py-2 text-left"
          v-if="activeDeliveryModeFilter === 'remote'"
        >
          <span class="col-11 pr-0 mr-0 fw-bold" @click="editRequest">
            <span
              class="material-symbols-rounded pointer-cursor summary-text-icon fw-bold pr-2"
            >
              location_on
            </span>
            Meeting platform & link to be shared on acceptance.</span
          >
          <span
            class="col-1 px-0 pointer-cursor text-secondary"
            @click="editRequest"
          >
            <i class="fa fa-chevron-right float-right pr-3"> </i>
          </span>
        </div>

        <div
          class="row my-3 py-2 text-left"
          v-if="activeDeliveryModeFilter === 'in-person'"
        >
          <span class="col-11 pr-0 mr-0 fw-bold">
            <span
              class="material-symbols-rounded pointer-cursor summary-text-icon fw-bold pr-3"
            >
              location_on
            </span>
            <span>
              {{ currentProviderData["location_address"] }}.
              {{ currentProviderData["city"] }},
              {{ currentProviderData["country"] }}</span
            >
          </span>
          <span
            class="col-1 px-0 pointer-cursor text-secondary"
            @click="editRequest"
          >
            <i class="fa fa-chevron-right float-right pr-3"> </i>
          </span>
        </div>

        <div class="row my-3 py-2 text-left">
          <span class="col-11 pr-0 mr-0 fw-bold">
            <span
              class="material-symbols-rounded pointer-cursor summary-text-icon fw-bold pr-2"
            >
              event
            </span>
            Scheduled for {{ showCorrectDateZone(currentDate) }}, for
            {{ tk.timeHourMin(totalServiceDuration) }}
          </span>
          <span
            class="col-1 px-0 pointer-cursor text-secondary"
            @click="showScheduleSummary = !showScheduleSummary"
          >
            <i
              class="fa fa-chevron-down float-right pr-3"
              v-if="showScheduleSummary"
            >
            </i>
            <i class="fa fa-chevron-right float-right pr-3" v-else> </i>
          </span>
        </div>

        <div class="row" v-if="showScheduleSummary">
          <div
            :class="
              isWebBigScreen
                ? 'col-2'
                : selectedSessions.length > 1
                ? 'col-6 px-2'
                : 'col-12 px-2'
            "
            v-for="(selected, index) in selectedSessions"
            :key="selected + index"
          >
            <ion-button
              size="small"
              color="light"
              expand="block"
              fill="solid"
              :class="isWebBigScreen ? 'my-3 my-1' : 'my-2 '"
            >
              <i class="fa fa-clock-o pr-2 pl-0 text-left"></i>
              <ion-label class="text-small">
                {{ showCorrectTimeZone(selected) }}
              </ion-label>
            </ion-button>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-2">
      <div :class="isMobile ? 'col-12' : 'col-3'"></div>
      <div class="p-0 m-0" :class="isMobile ? 'col-12' : 'col-6'">
        <div
          v-if="
            !isAuthenticated &&
            guestBookingPhase == 'finalize_booking' &&
            reqStatus === 'ready'
          "
          class="form-group text-left px-3 mt-4"
        >
          <div class="position-relative icon-form-control">
            <i class="position-absolute">
              <ion-icon :icon="lockClosed"></ion-icon>
            </i>
            <input
              placeholder="Enter the token that was sent your mail"
              type="text"
              class="form-control"
              required
              v-model.trim="guestToken"
            />
          </div>
        </div>

        <ion-spinner
          name="circles"
          class="mb-3 ml-2"
          v-if="reqStatus === 'processing'"
        ></ion-spinner>

        <div v-if="reqStatus === 'ready'" class="pt-2">
          <ion-note>{{ reqFeedback }}</ion-note>

          <!-- Start confirm booking for authenticated users -->
          <div v-if="isAuthenticated">
            <ion-button
              @click="executeCreateAppointmentOrder('active')"
              class="mt-1 mb-5 mx-2"
              expand="block"
            >
              Confirm booking
            </ion-button>
          </div>
          <!-- End confirm booking for authenticated users -->

          <!-- Start final guest booking confirmation with token-->

          <ion-button
            v-if="!isAuthenticated && guestBookingPhase == 'finalize_booking'"
            @click="executeCreateAppointmentOrder('guest')"
            class="mt-1 mb-5 px-3"
            expand="block"
          >
            Authenticate and confirm booking
          </ion-button>
        </div>

        <!-- Start validate  unauthenticated users -->
        <div
          v-if="!isAuthenticated && guestBookingPhase == 'verify_credentials'"
          class="px-3 mt-2"
        >
          <div class="form-group text-left">
            <ion-label class="mb-1">
              Full name
              <ion-note
                ><span v-if="fv.name.msg"> | {{ fv.name.msg }}</span></ion-note
              >
            </ion-label>
            <div class="position-relative icon-form-control">
              <i class="position-absolute">
                <ion-icon :icon="person"></ion-icon>
              </i>
              <input
                type="text"
                class="form-control"
                required
                v-model.trim="fv.name.val"
                @blur="validate.validateFormData(fv, 'name', true)"
              />
            </div>
          </div>

          <div class="form-group text-left">
            <ion-label class="mb-1">
              Email
              <ion-note
                ><span v-if="fv.email.msg">
                  | {{ fv.email.msg }}</span
                ></ion-note
              >
            </ion-label>
            <div class="position-relative icon-form-control">
              <i class="position-absolute">
                <ion-icon :icon="mail"></ion-icon>
              </i>
              <input
                type="email"
                class="form-control"
                required
                v-model.trim="fv.email.val"
                @blur="validate.validateFormData(fv, 'email', true)"
              />
            </div>
          </div>

          <ion-note>{{ reqFeedbackVerify }}</ion-note>

          <ion-button
            @click="verifyGuestCredentials"
            class="mt-1 mb-2"
            expand="block"
            v-if="reqStatusVerify === 'ready'"
          >
            Continue as guest
          </ion-button>
          <ion-spinner
            name="circles"
            class="mb-3 ml-2"
            v-if="reqStatusVerify === 'processing'"
          ></ion-spinner>
          <ion-note v-if="reqStatusVerify === 'ready'">
            If you already have an account.
            <router-link to="/sign_in" @click="closeModal">Sign up</router-link>
          </ion-note>
        </div>
        <!--End final guest booking confirmation with token-->

        <div class="text-center mt-5" v-if="reqStatus === 'succesful'">
          <img
            class="img-fluid"
            src="../../../images/main/event_request.svg"
            style="width: 50%"
            alt=""
          />
          <h5 class="font-weight-bold mt-3">Booking request successful!!</h5>
          <ion-note class="ml-3 m4-3 mb-3 pt-2 pb-3">
            Your booking request has been sent to the service provider. The
            appointment will become official and be made available on your
            calendar schedule only when they accept.
          </ion-note>

          <div class="mt-2 pt-2" v-if="!isAuthenticated">
            Try rendez for your services & scheduling needs. It takes less than
            a minute to setup.
            <router-link to="/sign_up" @click="closeModal">Sign up</router-link>
          </div>
        </div>
      </div>
      <div :class="isMobile ? 'col-12' : 'col-3'"></div>
    </div>
  </div>

  <ion-modal :keep-contents-mounted="true" class="modal-wrappers">
    <ion-datetime
      v-model="currentDate"
      :min="minDay"
      :show-default-buttons="true"
      :is-date-enabled="isActiveWeekDay"
      presentation="date"
      max="2030"
      id="datetime"
      v-if="appointmentProviderInfo"
    >
    </ion-datetime>
  </ion-modal>
</template>

<script lang="ts">
import {
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonCheckbox,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonButton,
  IonNote,
  IonSpinner,
} from "@ionic/vue";
import {
  defineComponent,
  ref,
  reactive,
  toRef,
  computed,
  inject,
  watch,
} from "vue";
import {
  lockClosed,
  person,
  mail,
  alarmOutline,
  calendarOutline,
  informationCircle,
  arrowBackCircle,
  arrowForwardCircle,
} from "ionicons/icons";
import storeKeeper from "@/hooks/RendezStorage";
import timeKeeper from "@/hooks/Timer";
import moneyInfo from "@/hooks/Money";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { CapacitorHttp } from "@capacitor/core";
import urls from "@/hooks/urlConfig";
import { formatInTimeZone } from "date-fns-tz";
import validators from "@/hooks/validation";
import coreSockets from "@/hooks/sockEvents";

export default defineComponent({
  name: "CreateFeedPostServiceBooking",
  emits: [
    "emitUpdateCurrentProviderServices",
    "emitUpdateCurrentServicesSchedule",
    "emitUpdateCurrentBookingView",
    "emitUpdateActiveDeliveryModeFilter",
    "emitCloseModal",
  ],
  components: {
    IonToolbar,
    IonTitle,
    IonButtons,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonCheckbox,
    IonDatetime,
    IonDatetimeButton,
    IonModal,
    IonButton,
    IonNote,
    IonSpinner,
  },
  props: {
    loadRequiredData: {
      type: Boolean,
      required: true,
      default: false,
    },
    currentProviderData: {
      type: Object,
      required: true,
    },
    accountDataServices: {
      required: true,
      default: [],
    },
    activeDeliveryModeFilter: {
      type: String,
      required: true,
      default: "remote",
    },
    currentBookingView: {
      required: true,
      default: "schedulePreview",
    },
    totalService: {
      required: true,
      default: () => [],
    },
    totalServiceDuration: {
      required: true,
      default: 0,
    },
    totalServiceCost: {
      required: true,
      default: 0,
    },
    totalServiceCurrency: {
      required: true,
      default: "",
    },
  },
  setup(props, { emit }) {
    const sk = storeKeeper();

    const tk = timeKeeper();

    const cash = moneyInfo();

    // get the validators
    const validate = validators();

    //get the urls
    const coreUrl = urls.coreUrl;

    const vueStore = useStore();

    // get the global route object
    const router = useRouter();

    const isWebBigScreen = ref(inject("isWebBigScreen"));

    // real time alerts

    const sock = coreSockets();

    const isMobile = ref(inject("isMobile"));

    const weekDaysList = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    const todayDate = new Date();

    const currAccountDataServices: any = toRef(props, "accountDataServices");

    const minDay = ref(todayDate.toISOString());
    const currentDate = ref(todayDate.toISOString());

    // Get the time zone set on the user's device
    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const clientTimezone = "America/New_York";

    const compCleanCurrentDate = computed(function () {
      const currDate = currentDate.value;
      return (
        "for " + formatInTimeZone(currDate, clientTimezone, "dd, MMM yyyy")
      );
    });

    const loadProviderInfo: any = ref(false);
    const appointmentProviderInfo: any = ref(false);
    const bookingInfo: any = ref(false);

    const provider_data: any = ref(props.currentProviderData);

    const providerTimezone: any = ref("Europe/Berlin");
    const serverTimezone: any = ref("Europe/Berlin");

    const sessionData = ref(vueStore.getters["auth/getSessionData"]);
    const isAuthenticated = ref(
      sessionData.value["isAuthenticated"] ? true : false
    );

    // Example of a GET request
    const getProviderAppointmentSettings = async () => {
      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url:
          coreUrl.backendUrl +
          "get_provider_appointment_settings_and_booking_history",
        headers: { Authorization: "Bearer " + tokenId },
        params: {
          provider_id: provider_data.value.owner_id,
          owner_id: isAuthenticated.value
            ? sessionData.value["owner_id"]
            : "none",
        },
      };

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "GET",
      });

      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.push("home");
      }

      if (response.data.status) {
        appointmentProviderInfo.value = await response.data.data
          .appointment_info;
        bookingInfo.value = await response.data.data.booking_info;
        providerTimezone.value = appointmentProviderInfo.value["timezone"];
        loadProviderInfo.value = true;
      }
    };

    getProviderAppointmentSettings();

    const existingBooking: any = ref([]);

    const compValidApptSchedules = computed(function () {
      const providerInfo = appointmentProviderInfo.value;
      const tempBookingInfo = bookingInfo.value;
      const tempExistingBooking = existingBooking.value;
      const dayDate = currentDate.value;
      const dayIndex = new Date(dayDate).getUTCDay();
      const activeDay = weekDaysList[dayIndex];

      if (tempBookingInfo) {
        let x = 0;
        const bookingArrayLength = tempBookingInfo ? tempBookingInfo.length : 0;

        let general_booking_list: any = [];

        while (x < bookingArrayLength) {
          if (tempBookingInfo[x]["session_time"]) {
            const new_existing_booking = JSON.parse(
              tempBookingInfo[x]["session_time"]
            );
            general_booking_list =
              general_booking_list.concat(new_existing_booking);
          }

          x++;
        }

        let y = 0;
        const currActiveDate = dayDate.split("T")[0];
        while (y < general_booking_list.length) {
          const tempActiveDate =
            general_booking_list[y]["provider_start_time"].split(" ")[0];

          if (tempActiveDate === currActiveDate) {
            tempExistingBooking.push(
              general_booking_list[y]["appointment_slot_id"]
            ); // appointment_slot_id
          }

          y++;
        }
      }

      let activeDayQuotaList = [];

      if (providerInfo) {
        const providerInfoList = providerInfo;
        activeDayQuotaList = JSON.parse(
          providerInfoList[`${activeDay}_custom_slots`]
        );
        activeDayQuotaList = activeDayQuotaList.filter(function (item) {
          const isExistingBooking = tempExistingBooking.includes(item["id"])
            ? false
            : true;

          const checkTime = updateTimeToDate(
            currentDate.value,
            item["startTime"],
            false
          );
          const checkTimeSlot = new Date(checkTime + "Z");
          const currentTimeSlot = todayDate;

          const isTimeSlotValid =
            currentTimeSlot > checkTimeSlot ? false : true;

          return isExistingBooking && isTimeSlotValid;
        });
      }

      return activeDayQuotaList;
    });

    const showCorrectTimeZone = function (index) {
      let alias: any = compValidApptSchedules.value[index]["alias"];

      if (providerTimezone.value !== clientTimezone) {
        const startTime = compValidApptSchedules.value[index]["startTime"];
        const endTime = compValidApptSchedules.value[index]["endTime"];
        const newStartTime = formatInTimeZone(
          startTime,
          clientTimezone,
          "yyyy-MM-dd HH:mm:ss"
        );
        const newEndTime = formatInTimeZone(
          endTime,
          clientTimezone,
          "yyyy-MM-dd HH:mm:ss"
        );

        alias = tk.getTimeAlias(newStartTime, newEndTime);
      }

      return alias;
    };

    const showCorrectDateZone = function (currentDate) {
      let alias: any = formatInTimeZone(
        currentDate,
        providerTimezone.value,
        "dd-MMM-yyy"
      );

      if (providerTimezone.value !== clientTimezone) {
        alias = formatInTimeZone(currentDate, clientTimezone, "dd-MMM-yyy");
      }

      return alias;
    };

    const changeDays = function (action) {
      selectedSessions.value = [];
      const newCurrentDate = new Date(currentDate.value);
      if (action === "next") {
        let goNext = true;

        while (goNext) {
          newCurrentDate.setDate(newCurrentDate.getDate() + 1);
          const utcDay = newCurrentDate.getUTCDay();
          compActiveWeekDaysList.value = true;
          goNext = compActiveWeekDaysList.value.includes(weekDaysList[utcDay])
            ? false
            : true;
        }

        currentDate.value = newCurrentDate.toISOString();
      }

      if (action === "prev") {
        let goPrev = true;

        while (goPrev) {
          newCurrentDate.setDate(newCurrentDate.getDate() - 1);
          const utcDay = newCurrentDate.getUTCDay();
          compActiveWeekDaysList.value = true;
          goPrev = compActiveWeekDaysList.value.includes(weekDaysList[utcDay])
            ? false
            : true;
        }

        currentDate.value = newCurrentDate.toISOString();
      }

      existingBooking.value = [];
    };

    const isDateToday = computed(function () {
      const otherDate = new Date(currentDate.value);

      if (
        otherDate.getDate() === todayDate.getDate() &&
        otherDate.getMonth() === todayDate.getMonth() &&
        otherDate.getFullYear() === todayDate.getFullYear()
      ) {
        return true;
      } else {
        return false;
      }
    });

    const activeWeekDaysList: any = ref([]);

    const compActiveWeekDaysList = computed({
      get() {
        return activeWeekDaysList.value;
      },
      set(val) {
        activeWeekDaysList.value = [];
        if (appointmentProviderInfo.value && val) {
          appointmentProviderInfo.value.sunday_window_status
            ? activeWeekDaysList.value.push("sunday")
            : null;
          appointmentProviderInfo.value.monday_window_status
            ? activeWeekDaysList.value.push("monday")
            : null;
          appointmentProviderInfo.value.tuesday_window_status
            ? activeWeekDaysList.value.push("tuesday")
            : null;
          appointmentProviderInfo.value.wednesday_window_status
            ? activeWeekDaysList.value.push("wednesday")
            : null;
          appointmentProviderInfo.value.thursday_window_status
            ? activeWeekDaysList.value.push("thursday")
            : null;
          appointmentProviderInfo.value.friday_window_status
            ? activeWeekDaysList.value.push("friday")
            : null;
          appointmentProviderInfo.value.saturday_window_status
            ? activeWeekDaysList.value.push("saturday")
            : null;
        }
      },
    });

    const isActiveWeekDay = (dateString: string) => {
      const date = new Date(dateString);
      const utcDay = date.getUTCDay();

      /**
       * Date will be enabled if it is not
       * Sunday or Saturday
       */
      compActiveWeekDaysList.value = true;
      return compActiveWeekDaysList.value.includes(weekDaysList[utcDay]);
    };

    const selectedSessions: any = ref([]);

    function selectSessionTime(index) {
      const existingSessions = selectedSessions.value.filter(
        (item) => item === index
      );
      if (existingSessions.length === 0) {
        selectedSessions.value.push(parseInt(index));
        selectedSessions.value.sort(function (a, b) {
          return a - b;
        });
      } else {
        const selectedIndex = selectedSessions.value.indexOf(index);

        if (index !== -1) {
          selectedSessions.value.splice(selectedIndex, 1);
        }
      }
    }

    const compSessionInsufficient = computed(function () {
      const selected = selectedSessions.value;
      let totalSessionDuration: any = 0;

      for (let i = 0; i < selected.length; i++) {
        const index = parseInt(selected[i]);
        totalSessionDuration =
          parseInt(totalSessionDuration) +
          parseInt(compValidApptSchedules.value[index]["duration"]);
      }

      return totalSessionDuration;
    });

    watch(
      () => compSessionInsufficient.value,
      (currentValue, _oldValue) => {
        emit("emitUpdateCurrentServicesSchedule", currentValue);
      }
    );

    const selectedApptSchedule = ref(-1);

    const compSelectedAppointment = computed(function () {
      const validApptSchedules = compValidApptSchedules.value;
      const selectedSchedule = selectedApptSchedule.value;

      return providerTimezone.value !== clientTimezone && selectedSchedule > -1
        ? "Service provider timezone: " +
            validApptSchedules[selectedSchedule]["alias"]
        : "";
    });

    const ownerId: any = ref(null);
    const clientName: any = ref(null);
    const clientEmail: any = ref(null);

    const getSessionData = async () => {
      const sessionData = await sk.getStoreIon("session_data");

      ownerId.value = sessionData ? JSON.parse(sessionData)["ownerId"] : false;
      clientName.value = sessionData ? JSON.parse(sessionData)["name"] : false;
      clientEmail.value = sessionData
        ? JSON.parse(sessionData)["email"]
        : false;
    };

    getSessionData();

    const guestToken = ref("");

    const showServicesSummary = ref(false);
    const showScheduleSummary = ref(true);

    const reqFeedback = ref("");
    const reqFormStatus = ref(true);
    const reqStatus = ref("ready");
    const showPreviewButton = ref(false);

    function allowPostBooking() {
      showPreviewButton.value = true;
    }

    function updateTimeToDate(actualDate, selectedTime, iso) {
      const newDate = actualDate.split("T")[0];
      const newTime = selectedTime.split("T")[1];

      return iso ? newDate + "T" + newTime : newDate + " " + newTime;
    }

    const sessionList: any = [];

    const executeCreateAppointmentOrder = async (client_type) => {
      // sessionList = []

      const selected = selectedSessions.value;

      const selectedServices = await currAccountDataServices.value.filter(
        (item) => item.status === "selected"
      );

      for (let i = 0; i < selected.length; i++) {
        const index = parseInt(selected[i]);

        const providerStartTime = updateTimeToDate(
          currentDate.value,
          compValidApptSchedules.value[index]["startTime"],
          false
        );
        const providerEndTime = updateTimeToDate(
          currentDate.value,
          compValidApptSchedules.value[index]["endTime"],
          false
        );

        const clientStartTime =
          providerTimezone.value === clientTimezone
            ? providerStartTime
            : formatInTimeZone(
                providerStartTime,
                clientTimezone,
                "yyyy-MM-dd HH:mm:ss"
              );
        const clientEndTime =
          providerTimezone.value === clientTimezone
            ? providerEndTime
            : formatInTimeZone(
                providerEndTime,
                clientTimezone,
                "yyyy-MM-dd HH:mm:ss"
              );

        const serverStartTime = formatInTimeZone(
          providerStartTime,
          serverTimezone.value,
          "yyyy-MM-dd HH:mm:ss"
        );
        const serverEndTime = formatInTimeZone(
          providerEndTime,
          serverTimezone.value,
          "yyyy-MM-dd HH:mm:ss"
        );

        const data = {
          provider_start_time: providerStartTime,
          provider_end_time: providerEndTime,
          client_start_time: clientStartTime,
          client_end_time: clientEndTime,
          server_start_time: serverStartTime,
          server_end_time: serverEndTime,
          duration: compValidApptSchedules.value[index]["duration"],
          appointment_slot_id: compValidApptSchedules.value[index]["id"],
        };

        sessionList.push(data);
      }

      reqStatus.value = "processing";
      const tokenId = await sk.getStoreIon("access_token");

      const mainAccessToken =
        client_type === "active" ? tokenId : guestAccessToken.value;

      const in_person_location = `${props.currentProviderData["location_address"]}, ${props.currentProviderData["city"]}, ${props.currentProviderData["country"]}`;
      const remote_location = "remote_location";

      const options = {
        url: coreUrl.backendUrl + "create_appointment_order",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + mainAccessToken,
        },
        data: {
          iv_provider_id: provider_data.value.owner_id,
          iv_brief: "I would like to book an appointment with you.",
          iv_guest_token:
            client_type === "active" ? "no_token" : guestToken.value,
          iv_service_list: selectedServices,
          iv_total_cost: props.totalServiceCost
            ? props.totalServiceCost
            : "zero",
          iv_total_services: props.totalService.length,
          iv_total_duration: props.totalServiceDuration,
          iv_total_currency: props.totalServiceCurrency,
          iv_delivery_mode: props.activeDeliveryModeFilter,
          iv_delivery_location:
            props.activeDeliveryModeFilter === "remote"
              ? remote_location
              : in_person_location,
          iv_user_category: client_type === "active" ? "active" : "guest",
          iv_allow_multiple_clients: "false",
          iv_provider_pay_status: "unpaid",
          iv_client_pay_status: "unpaid",
          iv_session_list: sessionList,
          iv_provider_start_time: sessionList[0]["provider_start_time"],
          iv_provider_end_time:
            sessionList[sessionList.length - 1]["provider_end_time"],
          iv_client_start_time: sessionList[0]["client_start_time"],
          iv_client_end_time:
            sessionList[sessionList.length - 1]["client_end_time"],
          iv_server_start_time: sessionList[0]["server_start_time"],
          iv_server_end_time:
            sessionList[sessionList.length - 1]["server_end_time"],
          iv_client_name:
            client_type === "active" ? clientName.value : fv.name.val,
          iv_provider_name: provider_data.value.username,
          iv_client_email:
            client_type === "active" ? clientEmail.value : fv.email.val,
          iv_provider_email: provider_data.value.email,
        },
      };

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.push("home");
      }

      if (response.data.status) {
        reqStatus.value = "succesful";
        reqFeedback.value = response.data.message.main_msg;
        selectedSessions.value = [];

        const payload = {
          data: response.data.data.booking_data,
          action: "unshift",
        };

        vueStore.dispatch("bookings/executeSetBookingRequests", payload);

        // // Emit real time notification
        sock.socket.emit("set_notification", response.data.data);

        emit("emitUpdateCurrentBookingView", "successfulPreview");
      } else {
        reqFeedback.value = response.data.message.main_msg
          ? response.data.message.main_msg
          : "Error";
        reqStatus.value = "ready";
      }
    };

    // start service preview functions
    function selectCustomService(index) {
      currAccountDataServices.value[index]["status"] =
        currAccountDataServices.value[index]["status"] === "selected"
          ? "active"
          : "selected";

      emit(
        "emitUpdateCurrentProviderServices",
        currAccountDataServices.value,
        "custom_services"
      );
    }

    function editRequest() {
      emit("emitUpdateCurrentBookingView", "schedulePreview");
    }

    function closeModal() {
      emit("emitCloseModal");
    }

    // guest user

    const fv: any = reactive({
      name: {
        status: false,
        val: "",
        msg: false,
        validate: [
          "required",
          "safeSpaceInput",
          { option: "textMin", val: 3 },
          { option: "textMax", val: 120 },
        ],
      },
      email: {
        status: false,
        val: "",
        msg: false,
        validate: ["required", "email"],
      },
      showSubmit: { status: false, val: true },
    });

    const guestBookingPhase = ref("verify_credentials");
    const guestAccessToken = ref("guest_token");

    const reqFeedbackVerify = ref("");
    const reqStatusVerify = ref("ready");

    const verifyGuestCredentials = async () => {
      reqStatusVerify.value = "processing";

      const options = {
        url: coreUrl.backendUrl + "verify_guest_credentials",
        headers: { "Content-Type": "application/json" },
        data: {
          iv_name: fv.name.val,
          iv_email: fv.email.val,
        },
      };

      // const response: HttpResponse = await CapacitorHttp.post(options);

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      if (response.data.status) {
        guestAccessToken.value = response.data.data.access_token;
        reqFeedbackVerify.value = response.data.message.main_msg;
        reqStatusVerify.value = "complete";
        guestBookingPhase.value = "finalize_booking";
      } else {
        const iv_msg: any = response.data.message.iv_msg;
        reqFeedbackVerify.value = response.data.message.main_msg
          ? response.data.message.main_msg
          : "error with message";

        if (iv_msg) {
          for (const key in iv_msg) {
            if (fv[key]) {
              fv[key]["status"] = false;
              fv[key]["msg"] = iv_msg[key];
            }
          }
        }

        reqStatusVerify.value = "ready";
      }
    };

    return {
      tk,
      cash,
      lockClosed,
      person,
      mail,
      informationCircle,
      alarmOutline,
      calendarOutline,
      arrowBackCircle,
      arrowForwardCircle,
      isAuthenticated,
      isMobile,
      appointmentProviderInfo,
      loadProviderInfo,
      compValidApptSchedules,
      showCorrectTimeZone,
      showCorrectDateZone,
      fv,
      validate,
      showServicesSummary,
      showScheduleSummary,
      reqFeedback,
      reqStatus,
      reqFormStatus,
      reqFeedbackVerify,
      reqStatusVerify,
      guestBookingPhase,
      verifyGuestCredentials,
      guestToken,
      changeDays,
      minDay,
      currentDate,
      compCleanCurrentDate,
      compSelectedAppointment,
      isDateToday,
      isActiveWeekDay,
      selectedSessions,
      selectSessionTime,
      compSessionInsufficient,
      showPreviewButton,
      allowPostBooking,
      executeCreateAppointmentOrder,
      compActiveWeekDaysList,
      currAccountDataServices,
      selectCustomService,
      editRequest,
      closeModal,
      isWebBigScreen,
    };
  },
});
</script>
<style scoped>
ion-toolbar {
  --background: #fff;
}

ion-icon {
  color: #000;
}

ion-datetime {
  --background: #fff;
}

ion-datetime-button {
  --background: #fff;
}

ion-radio {
  --size: 8px;
  --background-checked: #7e00e9;
}

ion-checkbox::part(container) {
  /* border-radius: 5px; */
  border: 2px solid #7e00e9;
}

ion-datetime-button::part(native) {
  background: #f5f5f5;
}

.summary-text {
  font-size: 15px;
  color: #555;
}

.fw-bold {
  font-weight: bolder;
}

.no-services {
  font-size: 25px;
  color: #555;
}
</style>
