export default {
  executeSetAvatarSrc(context, payload) {
    context.commit("setAvataSrc", payload);
  },
  executeSetAccountAllData(context, payload) {
    context.commit("setAccountData", payload.data);
    context.commit("setLoadAccountState", payload.loadAccount);
    context.commit("setAccountServices", payload.services);
    context.commit("setAccountRatingSummary", payload.rating_summary);
  },
  executeSetAccountData(context, payload) {
    context.commit("setAccountData", payload.data);
    context.commit("setLoadAccountState", payload.loadAccount);
  },
  executeSetAccountServices(context, payload) {
    context.commit("setAccountServices", payload.services);
    context.commit("setLoadAccountState", payload.loadAccount);
  },
  executeSetDefaultAccountData(context) {
    context.commit("setDefaultAccountData");
  },
};
