import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6467d520"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "center-spinner" }
const _hoisted_2 = {
  key: 1,
  class: "container rendez-wide"
}
const _hoisted_3 = {
  key: 2,
  class: "container rendez-wide pb-5"
}
const _hoisted_4 = {
  class: "row",
  style: {"min-height":"100vh"}
}
const _hoisted_5 = {
  key: 0,
  class: "col-lg-3 left mr-5 d-none d-lg-block d-xl-block d-md-block"
}
const _hoisted_6 = {
  key: 0,
  class: "my-5 py-5 text-center text-secondary"
}
const _hoisted_7 = { class: "material-symbols-rounded action-user-icon mx-1 pointer-cursor large-text-caption" }
const _hoisted_8 = ["alt", "src"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "text-small" }
const _hoisted_12 = { class: "text-small" }
const _hoisted_13 = {
  key: 0,
  class: "text-small"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_calendar_view_f_c = _resolveComponent("calendar-view-f-c")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.showBookingOrderList)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["container", _ctx.viewType === 'page' ? 'pt-4`  ' : ''])
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_spinner, { name: "circles" })
          ])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.bookingInfoTab === 'calendar' && _ctx.showBookingOrderList)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_calendar_view_f_c, {
            compBookingList: _ctx.compBookingList,
            scheduleSearchValue: _ctx.scheduleSearchValue
          }, null, 8, ["compBookingList", "scheduleSearchValue"])
        ]))
      : _createCommentVNode("", true),
    (
      _ctx.showBookingOrderList &&
      (_ctx.bookingInfoTab === 'order' ||
        _ctx.bookingInfoTab === 'request' ||
        _ctx.bookingInfoTab === 'order_and_request')
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.isWebBigScreen && _ctx.viewType === 'page')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(["bg-white ml-1", 
          _ctx.viewType === 'page'
            ? _ctx.isWebBigScreen
              ? 'col-lg-8 col-sm-12'
              : 'col-lg-8 col-sm-12 mx-0 px-0'
            : 'col-12 px-0'
        ])
            }, [
              (_ctx.compBookingList.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.navIcons[_ctx.appointmentInfo]), 1),
                    _createElementVNode("h2", null, " You have no " + _toDisplayString(_ctx.appointmentInfo.replace("_", " ")) + " with " + _toDisplayString(_ctx.bookingInfoTab === "order" ? "any client." : "") + " " + _toDisplayString(_ctx.bookingInfoTab === "request" ? "any provider." : ""), 1)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.compBookingList, (order, index) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      lines: "full",
                      class: "pt-3",
                      key: index + order.name
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_avatar, {
                          slot: "start",
                          class: "mb-3 pointer-cursor",
                          onClick: ($event: any) => (
                _ctx.goToLink(
                  order.onboardProfile
                    ? 'p/' + order.username
                    : 'p/' + order.name
                )
              )
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("img", {
                              alt: 
                  order.onboardProfile
                    ? 'p/' + order.username
                    : 'p/' + order.name
                ,
                              src: 
                  _ctx.imgBaseAvatarUrl + 'tr:di-default_avatar.png/' + order.avatar
                
                            }, null, 8, _hoisted_8)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createVNode(_component_ion_label, { class: "pl-3 ion-text-wrap pb-3" }, {
                          default: _withCtx(() => [
                            _createElementVNode("h3", null, [
                              _createElementVNode("b", null, [
                                (order.provider_id === _ctx.compOwnerId)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.filterAppointmentInfo === "ongoing"
                        ? "Ongoing session with "
                        : "Appointment request from"), 1))
                                  : _createCommentVNode("", true),
                                (order.owner_id === _ctx.compOwnerId)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.filterAppointmentInfo === "ongoing"
                        ? "Ongoing session with "
                        : "Appointment request to"), 1))
                                  : _createCommentVNode("", true),
                                _createTextVNode(" " + _toDisplayString(order.user_category === "active"
                      ? order.name
                      : order.guest_name), 1)
                              ])
                            ]),
                            _createElementVNode("p", _hoisted_11, [
                              _createElementVNode("strong", null, " [ Time : " + _toDisplayString(_ctx.tk.getTimeAliasUnrange(order.provider_start_time) +
                    " / " +
                    _ctx.tk.formatCleanFullDate(order.provider_start_time)) + " ]", 1)
                            ]),
                            _createElementVNode("p", null, [
                              _createElementVNode("i", _hoisted_12, _toDisplayString(order.user_category === "active"
                      ? " Requested "
                      : " [Guest client] Request posted ") + " " + _toDisplayString(_ctx.tk.fromNow(order.created_date)) + ". ", 1),
                              (_ctx.viewType === 'modal')
                                ? (_openBlock(), _createElementBlock("i", _hoisted_13, [
                                    (order.provider_id === _ctx.compOwnerId)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, " You are the provider "))
                                      : _createCommentVNode("", true),
                                    (order.owner_id === _ctx.compOwnerId)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, " You are the client "))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        (
                order.request_status === 'completed' &&
                order.complete_status === 'completed'
              )
                          ? (_openBlock(), _createBlock(_component_ion_label, {
                              key: 0,
                              slot: "end"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.rating ? parseInt(order.rating) : 0, (star) => {
                                  return (_openBlock(), _createElementBlock("i", {
                                    class: "fa fa-star pl-1",
                                    key: star
                                  }))
                                }), 128))
                              ]),
                              _: 2
                            }, 1024))
                          : (_openBlock(), _createBlock(_component_ion_buttons, {
                              key: 1,
                              slot: "end"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_button, {
                                  "text-captitalize": "false",
                                  onClick: ($event: any) => (_ctx.openBookingForm(index)),
                                  fill: "outline",
                                  class: "mb-3",
                                  shape: "round",
                                  color: "tertiary"
                                }, {
                                  default: _withCtx(() => [
                                    (
                    order.request_status === 'completed' &&
                    order.complete_status !== 'completed' &&
                    _ctx.bookingInfoTab === 'order'
                  )
                                      ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Request rating ")
                                          ]),
                                          _: 1
                                        }))
                                      : (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
                                          default: _withCtx(() => [
                                            (_ctx.viewType !== 'modal')
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.filterAppointmentInfo === "accepted" &&
                      _ctx.bookingInfoTab === "order"
                        ? "Start session"
                        : _ctx.compActionType.replace("_", " ")), 1))
                                              : _createCommentVNode("", true)
                                          ]),
                                          _: 1
                                        })),
                                    _createVNode(_component_ion_icon, {
                                      icon: _ctx.ellipsisVertical,
                                      color: "tertiary"
                                    }, null, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1024))
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}