/*
Category : Utility hooks
Description : Contains the list of various errors to be used site wide
*/
import * as CordovaSQLiteDriver from "localforage-cordovasqlitedriver";
import { Storage, Drivers } from "@ionic/storage";

export default function storeKeeper() {
  const storeIon = new Storage({
    name: "rendez_db",
    driverOrder: [
      CordovaSQLiteDriver._driver,
      Drivers.IndexedDB,
      Drivers.LocalStorage,
    ],
  });

  storeIon.create();

  async function setStoreIon(key: string, data: any) {
    await storeIon.set(key, data);
  }

  async function getStoreIon(key: string) {
    const reqData = await storeIon.get(key);
    return reqData;
  }

  async function delStoreIon(key: string) {
    await storeIon.remove(key);
    // const quick = await storeIon.get(key)
  }

  async function delAllStoreIon() {
    await storeIon.clear();
    // const quick = await storeIon.get("session_data")
  }

  // async function delVueX(){
  //     const defaultSession = {
  //         accessTokenExpiry: null,
  //         accessToken: null,
  //         ownerId: null,
  //         email: null,
  //         avatar: null,
  //         name: null,
  //         username: null,
  //         accountType: null,
  //         isProfileCreated: false,
  //         autoVerification: "unverified",
  //         manualVerification: "unverified",
  //         permissions: {}
  //     }
  //
  //     const payload = { loadAuth:false, data:defaultSession}
  //     await vueStore.dispatch("auth/executeSetSessionData", payload);
  // }

  return {
    setStoreIon,
    getStoreIon,
    delStoreIon,
    delAllStoreIon,
  };
}
