export default {
  getAvatarSrc(state) {
    return state.avatarSrc;
  },
  getAccountData(state) {
    return state.accountData;
  },
  getAccountServices(state) {
    return state.accountServices;
  },
  getAccountRatingSummary(state) {
    return state.accountRatingSummary;
  },
  getLoadAccountState(state) {
    return state.loadAccountState;
  },
};
