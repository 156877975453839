<template>
  <ion-header :translucent="true" mode="ios" class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="cancelModal">
          <ion-icon :icon="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title mode="ios">{{ reqPostOptions.title }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="confirmModal">Done</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content v-if="reqPostOptions.action === 'preferences'">
    <ion-list>
      <ion-radio-group v-model.trim="isPostType" value="now">
        <ion-item lines="full">
          <ion-icon
            :icon="megaphoneOutline"
            size="small"
            slot="start"
          ></ion-icon>
          <ion-label class="pl-3">Post now</ion-label>
          <ion-radio slot="end" value="now"></ion-radio>
        </ion-item>

        <ion-item :lines="isPostType === 'schedule' ? 'none' : 'full'">
          <ion-icon :icon="alarmOutline" size="small" slot="start"></ion-icon>
          <ion-label class="pl-3"> Schedule post </ion-label>
          <ion-radio slot="end" value="schedule"></ion-radio>
        </ion-item>

        <ion-item lines="full" v-if="isPostType === 'schedule'">
          <div class="pl-4">
            <ion-datetime-button datetime="datetime"></ion-datetime-button>
          </div>
        </ion-item>

        <ion-item lines="full">
          <ion-icon
            :icon="folderOpenOutline"
            size="small"
            slot="start"
          ></ion-icon>
          <ion-label class="pl-3">Save as draft</ion-label>
          <ion-radio slot="end" value="draft"></ion-radio>
        </ion-item>
      </ion-radio-group>

      <ion-item lines="full">
        <ion-icon :icon="eyeOutline" size="small" slot="start"></ion-icon>
        <ion-label class="pl-3">Visibility</ion-label>
        <ion-select placeholder="Select" slot="end" v-model="isPostAudience">
          <ion-select-option
            v-for="(aud, index) in postAudience"
            :key="aud.value + index"
            :value="aud.value"
            >{{ aud.alias }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item lines="full">
        <ion-icon
          :icon="stopCircleOutline"
          size="small"
          slot="start"
        ></ion-icon>
        <ion-label class="pl-3">Mature content</ion-label>
        <ion-select
          interface="popover"
          placeholder="Select"
          slot="end"
          v-model="isPostMature"
        >
          <ion-select-option
            v-for="(mat, index) in matureContent"
            :key="mat.value + index"
            :value="mat.value"
            >{{ mat.alias }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>
  </ion-content>

  <ion-content v-if="reqPostOptions.action === 'tags'" :fullscreen="true">
    <ion-segment
      color="dark"
      :value="currentTab"
      mode="md"
      @ionChange="switchTab($event)"
    >
      <ion-segment-button value="services">
        <ion-label color="dark">Tags from services</ion-label>
      </ion-segment-button>
      <ion-segment-button value="custom">
        <ion-label color="dark">Create custom tags</ion-label>
      </ion-segment-button>
    </ion-segment>

    <div
      class="position-relative mx-3 my-3 py-3"
      v-if="currentTab === 'services'"
    >
      <select
        class="form-control"
        v-model="selectTag"
        @change="addTagBySelect"
        v-if="compCurrentProviderServices.length > 0"
      >
        <option value="">Select service</option>
        <option
          v-for="(serve, index) in compCurrentProviderServices"
          :key="serve + index"
          :value="serve.service"
        >
          {{ serve.service }}
        </option>
      </select>
      <div v-else>You have already included all your services as tags.</div>
    </div>

    <ion-item
      ref="item"
      lines="none"
      class="my-3 py-3"
      v-if="currentTab === 'custom'"
    >
      <ion-label>#</ion-label>
      <ion-input
        type="text"
        v-model.trim="currTag"
        placeholder="Enter a tag you like, and click ',' or hit enter to add. "
        @keyup="addTagByComma"
        @keyup.enter="addTagByClick"
      >
      </ion-input>
    </ion-item>
    <ion-chip
      class="p-2 mt-1 mr-1 ml-1"
      v-for="(tag, index) in postTags"
      :key="tag + index"
      @click="removeTag(index)"
      color="primary"
    >
      <ion-label>{{ tag }}</ion-label>
      <ion-icon size="small" :icon="close"></ion-icon>
    </ion-chip>
  </ion-content>

  <ion-modal :keep-contents-mounted="true">
    <ion-datetime
      v-model="isPostSchedule"
      :show-default-buttons="true"
      id="datetime"
    ></ion-datetime>
  </ion-modal>
</template>

<script lang="ts">
import {
  IonIcon,
  IonInput,
  IonChip,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonSegment,
  IonSegmentButton,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref, computed } from "vue";
import {
  close,
  megaphoneOutline,
  alarmOutline,
  folderOpenOutline,
  eyeOutline,
  stopCircleOutline,
} from "ionicons/icons";
import storeKeeper from "@/hooks/RendezStorage";

export default defineComponent({
  name: "CreateFeedPostOptions",
  components: {
    IonIcon,
    IonInput,
    IonChip,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonItem,
    IonLabel,
    IonList,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonDatetime,
    IonDatetimeButton,
    IonModal,
    IonSelectOption,
    IonSegment,
    IonSegmentButton,
  },
  props: {
    reqPostOptions: {
      required: true,
    },
    currentProviderServices: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    const sk = storeKeeper();

    const new_date = new Date();

    const isPostType = ref("now");
    const isPostSchedule = ref(new_date.toISOString());
    const isPostAudience = ref("all");
    const isPostMature = ref("no");
    const postTags: any = ref([]);

    async function getPostOptionData() {
      const newData = await sk.getStoreIon("post_option_data");
      const currData = JSON.parse(newData);

      if (currData) {
        isPostType.value = currData.postType;
        isPostSchedule.value = currData.postSchedule;
        isPostAudience.value = currData.postAudience;
        isPostMature.value = currData.postMature;
        postTags.value = currData.postTags;
      }
    }

    getPostOptionData();

    const postAudience = [
      { value: "all", alias: "All" },
      { value: "interests", alias: "Interests" },
      { value: "trusted", alias: "Trusted" },
    ];

    const matureContent = [
      { value: "no", alias: "No" },
      { value: "yes", alias: "Yes" },
    ];

    // variable for switching between tabs
    const currentTab = ref("services");

    // function for switching between tabs
    function switchTab(ev: any) {
      currentTab.value = ev.detail.value;
    }

    const compCurrentProviderServices = computed(function () {
      let activeServices = props.currentProviderServices;
      activeServices = activeServices.filter((item: any) => {
        return postTags.value.includes(item["service"]) ? false : true;
      });

      return activeServices;
    });

    const currTag = ref("");

    function addTagByClick() {
      if (currTag.value) {
        postTags.value.push(currTag.value);
        currTag.value = "";
      }
    }

    function addTagByComma() {
      if (currTag.value.search(",") > -1) {
        currTag.value = currTag.value.replace(/,/g, "");
        if (currTag.value) {
          const tempTag = currTag.value;
          currTag.value = "";
          postTags.value.push(tempTag.replace(",", ""));
        }
      }
    }

    const selectTag = ref("");
    function addTagBySelect() {
      if (selectTag.value) {
        postTags.value.push(selectTag.value);
        selectTag.value = "";
      }
    }

    function removeTag(index) {
      if (index > -1 && index < postTags.value.length) {
        postTags.value.splice(index, 1);
      }
    }

    function cancelModal() {
      return modalController.dismiss(null, "cancel");
    }

    async function confirmModal() {
      const postOptionData = {
        postType: isPostType.value,
        postSchedule: isPostSchedule.value,
        postAudience: isPostAudience.value,
        postMature: isPostMature.value,
        postTags: postTags.value,
      };

      await sk.setStoreIon("post_option_data", JSON.stringify(postOptionData));

      return modalController.dismiss(postOptionData, "confirm");
    }

    return {
      name,
      close,
      megaphoneOutline,
      alarmOutline,
      folderOpenOutline,
      eyeOutline,
      stopCircleOutline,
      cancelModal,
      confirmModal,
      isPostType,
      isPostSchedule,
      isPostAudience,
      postAudience,
      isPostMature,
      matureContent,
      currentTab,
      switchTab,
      postTags,
      currTag,
      compCurrentProviderServices,
      addTagByClick,
      addTagByComma,
      removeTag,
      selectTag,
      addTagBySelect,
    };
  },
});
</script>
<style scoped>
ion-toolbar {
  --background: #fff;
}

ion-icon {
  color: #000;
}

ion-datetime {
  --background: #fff;
}

ion-datetime-button {
  --background: #fff;
}
</style>
