<template>
  <ion-header
    :translucent="true"
    mode="ios"
    class="ion-no-border"
    v-if="imageBase"
  >
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="cancelModal">
          <ion-icon :icon="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title> Save avatar </ion-title>
      <ion-buttons slot="end">
        <ion-button @click="clearImage">
          <ion-icon :icon="cameraReverse"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <utility-image-upload
    v-if="!imageBase"
    :ImgHeight="300"
    :ImgWidth="300"
    canvasSize="small-sqr-cropper "
    @emit-update-image-upload="updateImageUpload"
    @emit-close-image-upload="cancelModal"
  >
  </utility-image-upload>

  <div v-if="imageBase" class="mt-3 pt-3 col-6 mx-auto">
    <ion-icon
      :icon="closeCircle"
      color="light"
      @click="clearImage"
      size="large"
      class="clear-image cursor-pointer"
    ></ion-icon>
    <ion-img :src="imageBase" style="width: 100%" class="rounded"></ion-img>
    <ion-button
      @click="createNewAvatar"
      class="mb-4 mt-4"
      expand="block"
      color="primary"
      v-if="reqStatus"
      >Update Avatar</ion-button
    >
    <ion-spinner name="circles" class="mb-3 ml-2" v-else></ion-spinner>
  </div>
</template>

<script lang="ts">
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonImg,
  IonSpinner,
  modalController,
} from "@ionic/vue";
import { defineComponent, inject, ref, computed } from "vue";
import { close, closeCircle, cameraReverse } from "ionicons/icons";
import storeKeeper from "@/hooks/RendezStorage";
import { useStore } from "vuex";
import urls from "@/hooks/urlConfig";
import { CapacitorHttp } from "@capacitor/core";
import UtilityImageUpload from "@/components/utility/UtilityImageUpload.vue";

export default defineComponent({
  name: "AccountProfileCard",
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonImg,
    IonSpinner,
    UtilityImageUpload,
  },
  setup() {
    const sk = storeKeeper();

    //get the urls
    const coreUrl = urls.coreUrl;

    const imgBaseAvatarUrl = ref(coreUrl.imgAvatarHost);

    // get the global vuex store objects
    const vueStore = useStore();

    // get feedback toast function
    const feedbackToast: any = inject("feedbackToast");

    const imageBase: any = ref(false);

    function cancelModal() {
      return modalController.dismiss(null, "cancel");
    }

    function updateImageUpload(imageSource: string) {
      imageBase.value = imageSource;
      // showImageModal.value = !showImageModal.value;
    }

    function clearImage() {
      imageBase.value = false;
    }

    const avatarName = ref(vueStore.getters["auth/getSessionData"]["avatar"]);

    const imageBaseSrc: any = ref(vueStore.getters["account/getAvatarSrc"]);

    const compAvatarSrc = computed(function () {
      const imageSrc = imageBaseSrc.value;

      return imageSrc
        ? imageSrc
        : imgBaseAvatarUrl.value +
            "tr:di-default_avatar.png/" +
            avatarName.value +
            "?" +
            new Date().getTime();
    });

    const reqStatus = ref(true);

    // Example of a POST request. Note: data
    // can be passed as a raw JS Object (must be JSON serializable)
    const createNewAvatar = async () => {
      reqStatus.value = false;

      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url: coreUrl.backendUrl + "update_avatar",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenId,
        },
        data: {
          iv_media_base: imageBase.value ? imageBase.value : "none",
        },
      };

      // const response: HttpResponse = await CapacitorHttp.post(options);

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      if (response.data.status) {
        const reqFeedback = response.data.message.main_msg;

        feedbackToast(reqFeedback);

        reqStatus.value = true;

        imageBaseSrc.value = imageBase.value;

        await vueStore.dispatch("account/executeSetAvatarSrc", imageBase.value);

        imageBase.value = false;

        return modalController.dismiss(null, "cancel");
      } else if (response.status === 401) {
        await sk.delAllStoreIon();
        // await sk.delVueX()

        window.location.href = "/home";
      } else {
        // const iv_msg: any = response.data.message.iv_msg;
        const reqFeedback = response.data.message.main_msg;

        feedbackToast(reqFeedback);

        reqStatus.value = true;
      }
    };

    return {
      close,
      closeCircle,
      cameraReverse,
      imgBaseAvatarUrl,
      imageBase,
      cancelModal,
      updateImageUpload,
      clearImage,
      reqStatus,
      createNewAvatar,
      compAvatarSrc,
    };
  },
});
</script>

<style scoped>
.clear-image {
  float: right;
  margin-bottom: -33px;
  position: relative;
  z-index: 9999;
}

.profile_info_mobile .seller-card {
  margin: 0px;
  text-align: center;
  padding: 0px;
  position: relative;
  background-color: #ffffff;
}

ion-toolbar {
  --background: #f5f5f5;
  --border-color: #ccc;
  --border-width: 0px 0px 1px 0px;
}

ion-segment {
  --background: #f8f8ff;
}

ion-segment-button {
  --background: #fff;
  --indicator-color: #f8f8ff;
}

.sidebar-icon {
  font-size: 36px;
}

.sidebar-link {
  font-size: 16px;
}
</style>
