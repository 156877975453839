const coreLocalUrl = {
  frontendUrl: "http://127.0.0.1:8100/",
  backendUrl: "http://127.0.0.1:5000/api/",
  socketUrl: "http://127.0.0.1:5000",
  imgFeedHost: "https://ik.imagekit.io/bytepixels/rendez_feed/",
  imgAvatarHost: "https://ik.imagekit.io/bytepixels/rendez_avatar/",
};

const coreGLocalUrl = {
  frontendUrl: "http://127.0.0.1:8100/",
  backendUrl: "http://127.0.0.1:8000/api/",
  socketUrl: "http://127.0.0.1:8000",
  imgFeedHost: "https://ik.imagekit.io/bytepixels/rendez_feed/",
  imgAvatarHost: "https://ik.imagekit.io/bytepixels/rendez_avatar/",
};

const coreRemoteUrl = {
  frontendUrl: "https://web.rendezapp.com/",
  backendUrl: "https://api.rendezapp.com/api/",
  socketUrl: "https://api.rendezapp.com",
  imgFeedHost: "https://ik.imagekit.io/bytepixels/rendez_feed/",
  imgAvatarHost: "https://ik.imagekit.io/bytepixels/rendez_avatar/",
};

const urlOptions = {
  coreLocalUrl,
  coreGLocalUrl,
  coreRemoteUrl,
};

const coreUrl = urlOptions.coreRemoteUrl;

// const coreUrl = urlOptions.coreGLocalUrl;

export default { coreUrl };
