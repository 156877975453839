import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      avatarSrc: false,
      loadAccountState: false,
      accountData: {
        expertise: "",
        contact_phone: "",
        country: "",
        city: "",
        currency: "",
        location_address: "",
        category: "",
        delivery_mode: "",
        languages: "[]",
        skills: "[]",
        policy_perks: "[]",
        services: "[]",
        bio: "",
      },
      defaultAccountData: {
        expertise: "",
        contact_phone: "",
        country: "",
        city: "",
        currency: "",
        location_address: "",
        category: "",
        delivery_mode: "",
        languages: "[]",
        skills: "[]",
        policy_perks: "[]",
        services: "[]",
        bio: "",
      },
      accountServices: [],
      defaultAccountServices: [],
      accountRatingSummary: { reviews: 0, rating: 0 },
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
