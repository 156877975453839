<template>
  <ion-header :translucent="true" mode="ios" class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button @click="cancelModal">
          <ion-icon :icon="arrowBack"></ion-icon>
        </ion-button>
      </ion-buttons>
      <!-- <ion-title class="text-left pl-5" color="primary" style="color:#000">Account name</ion-title> -->
      <div class="text-left pl-2">
        <div class=" ">
          <ion-avatar class="pt-2 float-left">
            <img
              :src="
                imgBaseAvatarUrl +
                'tr:di-default_avatar.png/' +
                currentProvider.avatar
              "
              class="img-fluid user-pict-img"
              alt="Rendez"
            />
          </ion-avatar>
          <div class="user-profile-label pt-2 pl-5 ml-2">
            <div class="username-line">
              <a href="#" class="seller-link">
                {{ currentProvider.username }}
                <i
                  class="fa fa-user-plus pr-1"
                  title="provider is in your circle"
                  v-if="compIsProviderAnInterest"
                ></i>
                <i
                  class="fa fa-handshake-o pr-1"
                  title="trusted provider"
                  v-if="compIsProviderTrusted"
                ></i>
              </a>
            </div>
            <div class="oneliner-wrapper">
              <small class="oneliner">{{ currentProvider.expertise }} </small>
              <small class="oneliner text-primary pl-2">
                <strong>
                  <ion-icon
                    :icon="
                      currentProvider.manual_verification === 'unverified'
                        ? starOutline
                        : star
                    "
                  >
                  </ion-icon>
                </strong>
                {{ currentProvider.manual_verification }}
              </small>
              <div class="ratings-wrapper">
                <p class="rating-text">
                  <i class="fa fa-star pr-1"></i>
                  <strong>{{ currentProvider.rating_all_avg }}</strong>
                  ({{ currentProvider.rating_client_size }} reviews)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ion-buttons slot="end">
        <ion-button
          @click="removeFromBlock"
          fill="solid"
          color="danger"
          style="font-size: 12px"
          v-if="compIsUserBlocked && reqStatus === 'ready'"
        >
          Unblock this account
        </ion-button>
        <ion-button
          @click="addToBlock"
          fill="outline"
          color="danger"
          style="font-size: 12px"
          v-if="!compIsUserBlocked && reqStatus === 'ready' && isAuthenticated"
        >
          Block this account
        </ion-button>
        <ion-spinner
          name="bubbles"
          v-if="reqStatus === 'processing'"
        ></ion-spinner>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="" :fullscreen="true" mode="ios">
    <!-- Start report issue  -->

    <ion-list v-if="tabStep === 1 && reqReportStatus !== 'complete'">
      <h2 class="p-3">Report an issue</h2>
      <ion-radio-group v-model.trim="selectedReport" value="">
        <ion-item
          class="py-3"
          lines="full"
          v-for="(opt, index) in reportOptions"
          :key="opt + index"
        >
          <ion-label class="ion-text-wrap">
            <h2>{{ opt.title }}</h2>
            <span class="small-details pr-2">{{ opt.details }}</span>
          </ion-label>
          <ion-radio color="primary" :value="index" slot="end"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>

    <!-- End report issue  -->

    <!-- Start additional details  -->

    <div
      class="form-group p-3"
      v-if="tabStep === 2 && reqReportStatus !== 'complete'"
    >
      <h2 class="py-3">Share any additional details</h2>
      <ion-label color="primary">
        <span v-if="fv.report_additional.val && fv.report_additional.msg">
          {{ fv.report_additional.msg }}</span
        >
      </ion-label>
      <div class="position-relative">
        <textarea
          type="text"
          class="form-control"
          rows="8"
          required
          v-model.trim="fv.report_additional.val"
          @keyup="validate.validateFormData(fv, 'report_additional', true)"
        ></textarea>
      </div>
      <span><i> (500 words max) </i></span>
    </div>

    <!-- End additional details  -->

    <!-- start report summary  -->

    <ion-list
      v-if="
        tabStep === 3 && selectedReport > -1 && reqReportStatus !== 'complete'
      "
    >
      <h2 class="p-3">Report Summary</h2>
      <ion-item class="py-3">
        <ion-label class="ion-text-wrap">
          <h2>
            {{
              selectedReport > -1 ? reportOptions[selectedReport]["title"] : ""
            }}
          </h2>
          <span class="small-details pr-2">
            {{
              selectedReport > -1
                ? reportOptions[selectedReport]["details"]
                : ""
            }}
          </span>
        </ion-label>
      </ion-item>

      <ion-item class="py-3">
        <ion-label class="ion-text-wrap">
          <h2>Additional details</h2>
          <span class="small-details pr-2">
            {{
              fv.report_additional.val
                ? fv.report_additional.val
                : "No additional details was provided"
            }}
          </span>
        </ion-label>
      </ion-item>

      <div class="my-3 px-3">
        <div class="my-2 px-2" v-if="reqReportStatus === 'ready'">
          {{ reqFeedback }}
        </div>
        <ion-button
          expand="block"
          @click="submitReport"
          v-if="reqReportStatus === 'ready'"
        >
          Submit Report
        </ion-button>
      </div>
      <ion-spinner
        name="circles"
        class="mb-3 ml-2"
        v-if="reqReportStatus === 'processing'"
      ></ion-spinner>
    </ion-list>

    <div class="container" v-if="reqReportStatus === 'complete'">
      <div class="text-center pt-5">
        <h5 class="font-weight-bold mt-5">
          Your report was successfuly submitted !!!
        </h5>
        <p class="text-muted">
          We will get back to you with any feedback. If necessary.
        </p>
      </div>
    </div>

    <!-- End report summary  -->
  </ion-content>
  <ion-footer class="ion-no-border">
    <ion-toolbar>
      <ion-buttons
        slot="secondary"
        v-if="tabStep > 1 && reqReportStatus !== 'complete'"
      >
        <ion-button fill="flat" @click="moveTabStep('back')">
          <ion-icon slot="start" :icon="arrowBackCircleOutline"></ion-icon>
          Go back
        </ion-button>
      </ion-buttons>
      <ion-buttons
        slot="primary"
        v-if="
          tabStep < 3 && selectedReport > -1 && reqReportStatus !== 'complete'
        "
      >
        <ion-button fill="flat" @click="moveTabStep('front')">
          Continue
          <ion-icon slot="end" :icon="arrowForwardCircleOutline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title
        class="pointer-cursor"
        v-if="
          tabStep === 2 &&
          !fv.report_additional.val &&
          reqReportStatus !== 'complete'
        "
        @click="moveTabStep('front')"
        >Skip</ion-title
      >
    </ion-toolbar>
  </ion-footer>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonFooter,
  IonAvatar,
  IonButtons,
  IonButton,
  IonIcon,
  IonSpinner,
  IonItem,
  IonTitle,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  toastController,
  modalController,
} from "@ionic/vue";
import { defineComponent, reactive, ref, computed } from "vue";
import {
  arrowBack,
  arrowBackCircleOutline,
  arrowForwardCircleOutline,
  mail,
  personAdd,
  star,
  starOutline,
  calendarOutline,
} from "ionicons/icons";
import urls from "@/hooks/urlConfig";
import timeKeeper from "@/hooks/Timer";
import moneyInfo from "@/hooks/Money";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import storeKeeper from "@/hooks/RendezStorage";
import { CapacitorHttp } from "@capacitor/core";
import validators from "@/hooks/validation";

export default defineComponent({
  name: "FeedBooking",
  components: {
    IonContent,
    IonHeader,
    IonToolbar,
    IonFooter,
    IonAvatar,
    IonButtons,
    IonButton,
    IonIcon,
    IonSpinner,
    IonTitle,
    IonItem,
    IonLabel,
    IonList,
    IonRadio,
    IonRadioGroup,
  },
  props: {
    currentProvider: {
      required: true,
      default: { owner_id: "none" },
    },
  },
  setup(props) {
    const sk = storeKeeper();

    const tk = timeKeeper();

    const cash = moneyInfo();

    // get the validators
    const validate = validators();

    //get the urls
    const coreUrl = urls.coreUrl;

    // get the global vuex store objects
    const vueStore = useStore();

    const router = useRouter();

    const imgBaseAvatarUrl = ref(coreUrl.imgAvatarHost);

    const currentTab = ref("book");

    const isAuthenticated = ref(vueStore.getters["auth/getSessionData"]);

    const compGetInterestData = computed(function () {
      const interests = vueStore.getters["fav/getFavInterests"];
      return interests;
    });

    const compIsProviderAnInterest = computed(function () {
      const interestList = compGetInterestData.value.filter(
        (item) => item.provider_id === props.currentProvider["owner_id"]
      );

      return interestList.length > 0 ? true : false;
    });

    const compIsProviderTrusted = computed(function () {
      const interestList = compGetInterestData.value.filter(
        (item) =>
          item.provider_id === props.currentProvider["owner_id"] &&
          item.trusted === true
      );

      return interestList.length > 0 ? true : false;
    });

    const compGetBlocksData = computed(function () {
      const blocks = vueStore.getters["fav/getFavBlocks"];
      return blocks;
    });

    const compIsUserBlocked = computed(function () {
      const blockList = compGetBlocksData.value.filter(
        (item) => item.user_id === props.currentProvider["owner_id"]
      );
      return blockList.length > 0 ? true : false;
    });

    const providerId = ref(props.currentProvider["owner_id"]);

    const reqStatus = ref("ready");

    const addToBlock = async () => {
      try {
        reqStatus.value = "processing";

        const tokenId = await sk.getStoreIon("access_token");

        const options = {
          url: coreUrl.backendUrl + "add_to_block",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenId,
          },
          data: {
            iv_user_id: providerId.value,
          },
        };

        // or...
        const response = await CapacitorHttp.request({
          ...options,
          method: "POST",
        });

        if (response.status === 401 || response.status === 422) {
          // delete all data in the ionic storage db
          await sk.delAllStoreIon();

          router.replace("home");
        }

        if (response.data.status) {
          reqStatus.value = "ready";
          const block_payload = {
            loadFav: true,
            data: { storage: "append", info: response.data.data.block_info },
          };
          await vueStore.dispatch("fav/executeSetFavBlocks", block_payload);
          feedbackToast("Account was successfully blocked");
        } else {
          reqStatus.value = "ready";
        }
      } catch (err) {
        console.log(err);
      }
    };

    const removeFromBlock = async () => {
      try {
        reqStatus.value = "processing";

        const tokenId = await sk.getStoreIon("access_token");

        const options = {
          url: coreUrl.backendUrl + "remove_from_block",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenId,
          },
          data: {
            iv_user_id: providerId.value,
          },
        };

        // or...
        const response = await CapacitorHttp.request({
          ...options,
          method: "POST",
        });

        if (response.status === 401 || response.status === 422) {
          // delete all data in the ionic storage db
          await sk.delAllStoreIon();

          router.replace("home");
        }

        if (response.data.status) {
          const existingBlocks = compGetBlocksData.value;

          for (let i = 0; i <= existingBlocks.length; i++) {
            if (existingBlocks[i]["user_id"] === providerId.value) {
              existingBlocks.splice(i, 1);

              const block_payload = {
                loadFav: true,
                data: { storage: "replace", info: existingBlocks },
              };
              await vueStore.dispatch("fav/executeSetFavBlocks", block_payload);

              break;
            }
          }

          reqStatus.value = "ready";

          feedbackToast("Account was successfully unblocked");
        } else {
          reqStatus.value = "ready";
        }
      } catch (err) {
        console.log(err);
      }
    };

    // feedback toast controller
    async function feedbackToast(msg) {
      const toast = await toastController.create({
        message: msg,
        duration: 2500,
        position: "bottom",
      });

      await toast.present();
    }

    // start make report

    const selectedReport = ref(-1);

    const tabStep = ref(1);

    function moveTabStep(step) {
      if (step === "front") {
        tabStep.value++;
      }

      if (step === "back") {
        tabStep.value--;
      }
    }

    const reportOptions = [
      {
        title: "Hate",
        details:
          "Slurs, Racist or sexist stereotypes, Dehumanization, Incitement of fear or discrimination, Hateful references, Hateful symbols & logos",
      },
      {
        title: "Abuse & Harassment",
        details:
          "Insults, Unwanted Sexual Content & Graphic Objectification, Unwanted NSFW & Graphic Content, Violent Event Denial, Targeted Harassment and Inciting Harassment",
      },
      {
        title: "Violence",
        details:
          "Violent Threats, Wish of Harm, Glorification of Violence, Incitement of Violence, Coded Incitement of Violence",
      },
      {
        title: "Fraud",
        details: "Fraudulaent business conduct",
      },
      {
        title: "Child Safety",
        details:
          "Child sexual exploitation, grooming, physical child abuse, underage user",
      },
      {
        title: "Privacy",
        details:
          "Sharing private information, threatening to share/expose private information, sharing non-consensual intimate images, sharing images of me that I don’t want on the platform",
      },
      {
        title: "Spam",
        details:
          "Financial scams, posting malicious links, misusing hashtags, fake engagement, repetitive replies, Retweets, or Direct Messages",
      },
      {
        title: "Deceptive Identities",
        details: "Impersonation, non-compliant parody/fan accounts",
      },
    ];

    const fv: any = reactive({
      report_title: {
        status: false,
        val: "",
        msg: false,
        validate: ["required"],
      },
      report_details: {
        status: false,
        val: "",
        msg: false,
        validate: ["required"],
      },
      report_additional: {
        status: false,
        val: "",
        msg: false,
        validate: [
          { option: "textMin", val: 3 },
          { option: "textMax", val: 500 },
        ],
      },
      showSubmit: { status: false, val: true },
    });

    const reqFeedback = ref("");
    const reqReportStatus = ref("ready");
    const reqFormStatus = ref(true);

    // Example of a POST request. Note: data
    // can be passed as a raw JS Object (must be JSON serializable)
    const submitReport = async () => {
      const tokenId = await sk.getStoreIon("access_token");

      reqReportStatus.value = "processing";

      const options = {
        url: coreUrl.backendUrl + "report_user_account",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenId,
        },
        data: {
          iv_user_id: providerId.value,
          iv_report_title:
            selectedReport.value > -1
              ? reportOptions[selectedReport.value]["title"]
              : "",
          iv_report_details:
            selectedReport.value > -1
              ? reportOptions[selectedReport.value]["details"]
              : "",
          iv_report_additional: fv.report_additional.val
            ? fv.report_additional.val
            : "No additional details was provided",
        },
      };

      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      if (response.data.status) {
        reqFeedback.value = response.data.message.main_msg;

        reqFormStatus.value = true;
        reqReportStatus.value = "complete";
      } else if (response.status === 401) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        window.location.href = "/signin";
      } else {
        const iv_msg: any = response.data.message.iv_msg;
        reqFeedback.value = response.data.message.main_msg;

        if (iv_msg) {
          for (const key in iv_msg) {
            if (fv[key]) {
              fv[key]["status"] = false;
              fv[key]["msg"] = iv_msg[key];
            }
          }
        }

        reqReportStatus.value = "ready";
      }
    };

    function cancelModal() {
      return modalController.dismiss(null, "cancel");
    }

    return {
      tk,
      cash,
      isAuthenticated,
      currentTab,
      cancelModal,
      arrowBack,
      arrowBackCircleOutline,
      arrowForwardCircleOutline,
      star,
      starOutline,
      calendarOutline,
      imgBaseAvatarUrl,
      mail,
      personAdd,
      compIsProviderAnInterest,
      compIsProviderTrusted,
      addToBlock,
      removeFromBlock,
      compIsUserBlocked,
      reportOptions,
      selectedReport,
      tabStep,
      moveTabStep,
      reqStatus,
      submitReport,
      reqReportStatus,
      reqFeedback,
      fv,
      validate,
    };
  },
});
</script>

<style scoped>
/* ion-modal::part(content) {
     display: flex;
    flex-direction: column;
    height: 100%;
} */

ion-action-sheet.my-custom-class {
  --background: #eee;
  --backdrop-opacity: 0.6;
  --button-background-selected: #e97223;
  --button-color: #333;
  --color: #000;
  /* role: "destructive" button iOS styling override */
  --ion-color-danger: #000000;
}

ion-toolbar {
  --background: #fff;
}

ion-datetime {
  --background: #f5f5f5;
}

ion-radio {
  --size: 18px;
  --background-checked: #7e00e9;
}

ion-radio::part(container) {
  border-radius: 36px;
  border: 2px solid #333;
}
</style>
