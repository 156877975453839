import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!

  return (_openBlock(), _createBlock(_component_ion_fab, {
    style: {"bottom":"15%","right":"12%"},
    slot: "fixed",
    horizontal: "end",
    vertical: "bottom"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_fab_button, { onClick: _ctx.openPostContent }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}