export default {
    setSearchHistoryData(state: any, payload: any){

        if(payload.searchStorage === "replace"){
            state.searchHistoryData = payload.data;
        }

        if(payload.searchStorage === "append"){
            state.searchHistoryData.push(payload.data);
        }

    }
};
