<template>
  <!--       footer -->
  <footer class="bg-white">
    <div class="container">
      <div class="copyright">
        <p>&copy; {{ currentYear }} Rendez. All Rights Reserved</p>
        <ul class="social">
          <p>Download app on</p>
          <li>
            <a href="#"><i class="fa fa-apple" aria-hidden="true"></i></a>
          </li>
          <li>
            <a href="#"><i class="fa fa-google" aria-hidden="true"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
  <!--       footer-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "MainFooter",
  setup() {
    const today = new Date();
    const currentYear = today.getFullYear();

    return {
      currentYear,
    };
  },
});
</script>

<style scoped></style>
