<template>
  <ion-list class="py-0">
    <ion-item lines="none" color="light" v-if="isWebBigScreen">
      <ion-label>
        <h3><strong>Booking summary</strong></h3>
      </ion-label>
    </ion-item>

    <ion-item lines="full" class="pt-3">
      <ion-label class="ion-text-wrap">
        <h3>
          <span
            class="material-symbols-rounded pointer-cursor summary-text-icon-admin fw-bold pr-2"
          >
            {{ bookingInfoType === "order" ? "person" : "record_voice_over" }}
          </span>
          {{ bookingInfoType === "order" ? "Client " : "Service provider " }}
        </h3>
      </ion-label>
      <ion-label slot="end">
        <h3>
          {{
            bookingInfoType === "order"
              ? bookingInfo.user_category === "active"
                ? bookingInfo["name"]
                : bookingInfo["guest_name"]
              : bookingInfo["username"]
          }}
        </h3>
      </ion-label>
    </ion-item>

    <ion-item lines="full" class="pt-3">
      <ion-label class="ion-text-wrap">
        <h3>
          <span
            class="material-symbols-rounded pointer-cursor summary-text-icon-admin fw-bold pr-2"
          >
            payments
          </span>
          Total cost of {{ serviceList.length }}
          {{ serviceList.length > 1 ? " services" : "service" }}
          [<span @click="showServices = !showServices" class="secondary-color">
            <u class="text-small">
              {{ showServices ? "hide services" : "show services" }}</u
            > </span
          >]
        </h3>
      </ion-label>
      <ion-label slot="end">
        <h3 v-if="bookingInfo.total_cost > 0">
          {{
            cash["currencyDetails"][
              bookingInfo["total_currency"].split("-")[0]
            ]["symbol_native"]
          }}
          {{ parseInt(bookingInfo.total_cost).toLocaleString() }}
        </h3>
        <h3 v-else>Free</h3>
      </ion-label>
    </ion-item>

    <div v-if="showServices">
      <ion-item
        lines="full"
        class="pt-3 ml-2"
        v-for="(serve, index) in serviceList"
        :key="serve.service_id + index"
      >
        <ion-label class="text-italics text-small" style="font-size: 12px">
          {{ serve.service }}
        </ion-label>
        <ion-label
          slot="end"
          class="text-italics text-small mr-2"
          style="font-size: 12px"
        >
          <span v-if="bookingInfo.total_cost > 0">
            {{
              cash["currencyDetails"][serve.currency.split("-")[0]][
                "symbol_native"
              ]
            }}
            {{ parseInt(serve.cost).toLocaleString() }}
          </span>
          <span
            class="text-italics text-small mr-2"
            style="font-size: 12px"
            v-else
            >free</span
          >
        </ion-label>
      </ion-item>
    </div>

    <ion-item lines="full" class="pt-3">
      <ion-label class="ion-text-wrap">
        <h3>
          <span
            class="material-symbols-rounded pointer-cursor summary-text-icon-admin fw-bold pr-2"
          >
            location_on
          </span>
          <span v-if="bookingInfo.delivery_mode === 'remote'">
            Appointment
            {{ isPastEvent ? "was held remotely " : "to hold remotely " }}
          </span>
          <span v-if="bookingInfo.delivery_mode === 'in-person'">
            Meeting {{ isPastEvent ? "was held at " : "to hold at " }}
            {{ bookingInfo["delivery_location"] }}
          </span>
        </h3>
      </ion-label>
      <ion-label slot="end">
        <h3>
          {{ bookingInfo.delivery_mode }}
        </h3>
      </ion-label>
    </ion-item>

    <ion-item lines="full" class="pt-3">
      <ion-label>
        <h3>
          <span
            class="material-symbols-rounded pointer-cursor summary-text-icon-admin fw-bold pr-2"
          >
            event
          </span>
          Appointment {{ isPastEvent ? "was held on " : "is scheduled for " }}
        </h3>
      </ion-label>
      <ion-label slot="end">
        <h3>
          {{ tk.formatCleanFullDate(bookingInfo.provider_start_time) }}
        </h3>
      </ion-label>
    </ion-item>

    <ion-item :lines="showTimeSlots ? 'none' : 'full'" class="pt-3">
      <ion-label>
        <h3>
          <span
            class="material-symbols-rounded pointer-cursor summary-text-icon-admin fw-bold pr-2"
          >
            schedule
          </span>
          Appointment
          {{ isPastEvent ? "duration was for " : "duration is for " }}
          {{ tk.formatMinToHr(bookingInfo.duration) }}
        </h3>
      </ion-label>
      <ion-label slot="end">
        <span
          @click="showTimeSlots = !showTimeSlots"
          class="secondary-color pointer-cursor"
        >
          <u class="text-small">
            {{ showTimeSlots ? "hide time slots" : "show time slots" }}</u
          >
        </span>
      </ion-label>
    </ion-item>
    <ion-item lines="full" class="pt-3" v-if="showTimeSlots">
      <div class="row" :class="isWebBigScreen ? 'full-flex' : ''">
        <div
          v-for="(session, index) in JSON.parse(bookingInfo['session_time'])"
          :key="session + index"
          :class="
            isWebBigScreen
              ? JSON.parse(bookingInfo['session_time']).length > 1
                ? 'col-6 pr-0'
                : 'col-12 ml-4 mb-4'
              : JSON.parse(bookingInfo['session_time']).length > 1
              ? 'col-6 px-2'
              : 'col-12 px-2'
          "
        >
          <ion-button
            :size="isWebBigScreen ? 'large' : 'small'"
            color="light"
            fill="solid"
            expand="block"
            :class="isWebBigScreen ? 'my-2 mx-1' : 'my-1 mx-1'"
          >
            <i class="fa fa-clock-o pr-2 pl-0 text-left"></i>
            <ion-label class="text-small">
              {{
                tk.getTimeAlias(
                  session.provider_start_time,
                  session.provider_end_time
                )
              }}</ion-label
            >
          </ion-button>
        </div>
      </div>
    </ion-item>
  </ion-list>
</template>

<script lang="ts">
import { IonItem, IonList, IonButton, IonLabel } from "@ionic/vue";
import { defineComponent, ref, inject } from "vue";
import timeKeeper from "@/hooks/Timer";
import moneyInfo from "@/hooks/Money";

export default defineComponent({
  components: {
    IonItem,
    IonLabel,
    IonList,
    IonButton,
  },
  props: {
    bookingInfo: {
      required: true,
      type: Object,
      default: () => {
        [];
      },
    },
    bookingInfoType: {
      required: true,
    },
    isPastEvent: {
      required: true,
      default: false,
    },
    isGuestReviewer: {
      default: false,
    },
  },
  setup(props) {
    const tk = timeKeeper();

    const currStartTime = ref("2022-01-01T12:00");
    const currEndTime = ref("2022-01-01T13:00");

    const isWebBigScreen = ref(inject("isWebBigScreen"));

    const serviceList = ref(JSON.parse(props.bookingInfo["services"]));

    const cash = moneyInfo();

    const showServices = ref(false);
    const showTimeSlots = ref(false);

    return {
      tk,
      cash,
      currStartTime,
      currEndTime,
      showServices,
      showTimeSlots,
      serviceList,
      isWebBigScreen,
    };
  },
});
</script>

<style scoped>
.summary-text-icon-admin {
  font-size: 20px;
  color: #555;
  vertical-align: bottom;
}

.full-flex {
  flex: 0 0 100%;
}
</style>
