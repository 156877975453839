export default {
  getFavInterests(state) {
    return state.favInterests;
  },
  getFavBookmarks(state) {
    return state.favBookmarks;
  },
  getFavBlocks(state) {
    return state.favBlocks;
  },
  getLoadFavState(state) {
    return state.loadFavState;
  },
};
