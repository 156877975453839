import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-88aba338"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container rendez-wide" }
const _hoisted_2 = {
  class: "row",
  style: {"min-height":"100vh"}
}
const _hoisted_3 = {
  key: 0,
  class: "col-lg-3 left mr-5 d-none d-lg-block d-xl-block d-md-block"
}
const _hoisted_4 = {
  key: 0,
  class: "mt-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FullCalendar = _resolveComponent("FullCalendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isWebBigScreen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["col-lg-8 col-sm-12 bg-white ml-1 pt-3", _ctx.isWebBigScreen ? '' : 'mx-0 px-0 '])
      }, [
        (!_ctx.isWebBigScreen)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_4, _toDisplayString(_ctx.currentCalendarDateTitle), 1))
          : _createCommentVNode("", true),
        (_ctx.showCalendar)
          ? (_openBlock(), _createBlock(_component_FullCalendar, {
              key: 1,
              options: _ctx.calendarOptions
            }, null, 8, ["options"]))
          : _createCommentVNode("", true)
      ], 2)
    ])
  ]))
}