<template>
  <div class="container">
    <div
      class="row justify-content-center align-items-center"
      :class="actionType === 'onboarding' ? 'vh-100' : 'mt-1'"
    >
      <div
        class="mx-auto"
        :class="
          actionType === 'onboarding' && isWebBigScreen ? 'col-4' : 'col-12'
        "
      >
        <div
          class="text-center osahan-login pt-4"
          v-if="actionType === 'onboarding'"
        >
          <a href="/account"
            ><img src="../../../images/main/logo.png" alt=""
          /></a>
          <h5 class="font-weight-bold mt-3" v-if="reqFormStatus">
            Create basic information
          </h5>
          <p class="text-muted mt-2">Step <b>2</b> of <b>2</b></p>
        </div>

        <div class="osahan-login mt-1 py-4" v-if="reqFormStatus">
          <div class="form-group">
            <ion-label class="mb-1">
              <b>Industry ( business category )</b>
              <ion-note
                ><span v-if="fv.category.msg">
                  | {{ fv.category.msg }}</span
                ></ion-note
              >
            </ion-label>
            <div class="position-relative icon-form-control">
              <i class="position-absolute fa fa-institution fa-fw"> </i>
              <select
                class="form-control"
                @change="validate.validateFormData(fv, 'category', true)"
                required
                v-model.trim="fv.category.val"
              >
                <option value="">Select Industry</option>
                <option
                  v-for="(cat, index) in categoryList"
                  :key="cat.id + index"
                  :value="cat.value"
                >
                  {{ cat.alias }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <ion-label class="mb-1">
              <b>Expertise</b>
              <ion-note
                ><span v-if="fv.expertise.msg">
                  | {{ fv.expertise.msg }}</span
                ></ion-note
              >
            </ion-label>
            <div class="position-relative icon-form-control">
              <i class="position-absolute fa fa-star fa-fw"> </i>
              <input
                type="text"
                class="form-control"
                required
                v-model.trim="fv.expertise.val"
                @keyup="validate.validateFormData(fv, 'expertise', true)"
              />
            </div>
          </div>

          <div class="form-group">
            <ion-label class="mb-1">
              <b>Contact phone</b>
              <ion-note
                ><span v-if="fv.contact_phone.msg">
                  | {{ fv.contact_phone.msg }}</span
                ></ion-note
              >
            </ion-label>
            <div class="position-relative icon-form-control">
              <i class="position-absolute fa fa-phone fa-fw"> </i>
              <input
                type="text"
                class="form-control"
                required
                v-model.trim="fv.contact_phone.val"
                @keyup="validate.validateFormData(fv, 'contact_phone', true)"
              />
            </div>
          </div>

          <div class="form-group">
            <ion-label class="mb-1">
              <b>Country</b>
              <ion-note
                ><span v-if="fv.country.msg">
                  | {{ fv.country.msg }}</span
                ></ion-note
              >
            </ion-label>
            <div class="position-relative icon-form-control">
              <i class="position-absolute fa fa-globe fa-fw"> </i>
              <select
                class="form-control"
                @change="suggestCurrency"
                required
                v-model.trim="fv.country.val"
              >
                <option value="">Select country</option>
                <option
                  v-for="(country, index) in countryList"
                  :key="country.countryName + index"
                  :value="country.countryName"
                >
                  {{ country.countryName }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group" v-if="fv.country.val">
            <ion-label class="mb-1">
              <b>City</b>
              <ion-note
                ><span v-if="fv.city.msg"> | {{ fv.city.msg }}</span></ion-note
              >
            </ion-label>
            <div class="position-relative icon-form-control">
              <i class="position-absolute fa fa-map-marker fa-fw"> </i>
              <select
                class="form-control"
                @change="validate.validateFormData(fv, 'city', true)"
                required
                v-model.trim="fv.city.val"
              >
                <option value="">Select city</option>
                <option
                  v-for="(city, index) in compStateList"
                  :key="city + index"
                  :value="city"
                >
                  {{ city }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <ion-label class="mb-1">
              <b>Preferred currency</b>
              <ion-note
                ><span v-if="fv.currency.msg">
                  | {{ fv.currency.msg }}</span
                ></ion-note
              >
            </ion-label>
            <div class="position-relative icon-form-control">
              <i class="position-absolute fa fa-money fa-fw"> </i>
              <select
                class="form-control"
                @change="validate.validateFormData(fv, 'currency', true)"
                required
                v-model.trim="fv.currency.val"
              >
                <option value="">Select currency</option>
                <option
                  v-for="(country, index) in countryList"
                  :key="country.currencyCode + index"
                  :value="country.currencyCode + '-' + country.countryCode"
                >
                  {{ country.countryName }} ({{ country.currencyCode }})
                </option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <ion-label>
              <!-- <i class="fa fa-map-signs fa-fw " ></i> -->
              <b>Location Address</b>
              <ion-note
                ><span v-if="fv.location_address.msg">
                  | {{ fv.location_address.msg }}</span
                ></ion-note
              >
            </ion-label>
            <div class="position-relative icon-form-control">
              <i class="position-absolute fa fa-map-signs fa-fw"> </i>
              <input
                type="text"
                class="form-control"
                required
                v-model.trim="fv.location_address.val"
                @keyup="validate.validateFormData(fv, 'location_address', true)"
              />
            </div>
          </div>

          <div class="form-group">
            <ion-label>
              <b> Default delivery mode </b>
              <ion-note
                ><span v-if="fv.delivery_mode.msg">
                  | {{ fv.delivery_mode.msg }}</span
                ></ion-note
              >
            </ion-label>
            <div class="position-relative icon-form-control">
              <i class="position-absolute fa fa-send fa-fw"> </i>
              <select
                class="form-control"
                @change="validate.validateFormData(fv, 'delivery_mode', true)"
                required
                v-model.trim="fv.delivery_mode.val"
              >
                <option
                  v-for="(serve, index) in serviceDeliveryMode"
                  :key="serve.value + index"
                  :value="serve.value"
                >
                  {{ serve.alias }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <ion-label>
              <b> Brief business summary </b>
              <ion-note
                ><span v-if="fv.bio.msg"> | {{ fv.bio.msg }}</span></ion-note
              >
            </ion-label>
            <div class="position-relative">
              <textarea
                type="text"
                class="form-control"
                rows="8"
                required
                v-model.trim="fv.bio.val"
                @keyup="validate.validateFormData(fv, 'bio', true)"
              ></textarea>
            </div>
          </div>

          <ion-note>{{ reqFeedback }}</ion-note>
          <div v-if="reqStatus === 'ready'">
            <button
              class="btn btn-success btn-block text-uppercase"
              @click="runChangeProfileInfo"
              type="button"
            >
              Become a service provider
            </button>
          </div>
          <ion-spinner
            name="circles"
            class="mb-3 ml-2"
            v-if="reqStatus === 'processing'"
          ></ion-spinner>
        </div>

        <div
          class="container"
          v-if="reqStatus === 'complete' && actionType !== 'settings'"
        >
          <img
            class="img-fluid"
            src="../../../images/main/profile.svg"
            alt=""
          />
          <div class="text-center pt-2">
            <h5 class="font-weight-bold mt-3">
              Basic info successfully updated !!!
            </h5>
            <p class="text-muted">
              Well done, you are all set to start accepting clients.
            </p>

            <!-- <p class="text-muted">
                                <b> Tip: </b> You can boost your chances of landing clients , by updating your skill sets and list of services
                            </p> -->
            <ion-button
              @click="routeBasicInfo"
              class="mt-4"
              expand="block"
              color="primary"
              fill="outline"
              style="text-transform: none"
            >
              Got to dashboard
            </ion-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { IonNote, IonLabel, IonSpinner, IonButton } from "@ionic/vue";
import {
  star,
  send,
  call,
  earth,
  location,
  pin,
  briefcase,
  informationCircle,
} from "ionicons/icons";
import { defineComponent, reactive, ref, inject, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import storeKeeper from "@/hooks/RendezStorage";
import geoLocation from "@/hooks/Location";
import constDataList from "@/hooks/simpleData";
import { CapacitorHttp } from "@capacitor/core";
import urls from "@/hooks/urlConfig";
import validators from "@/hooks/validation";

export default defineComponent({
  name: "CreateOnboardServiceProvider",
  components: {
    IonNote,
    IonLabel,
    IonSpinner,
    IonButton,
  },
  props: {
    actionType: {
      required: true,
      default: "settings",
    },
  },
  setup(props) {
    const sk = storeKeeper();

    const geo = geoLocation();

    const dataList = constDataList();

    //get the urls
    const coreUrl = urls.coreUrl;

    // get feedback toast function
    const feedbackToast: any = inject("feedbackToast");

    // get the data for checking if user device is a big screen or not
    const isWebBigScreen = ref(inject("isWebBigScreen"));

    // get the global vuex store objects
    const vueStore = useStore();

    // get the router handle
    const router = useRouter();

    // get the validators
    const validate = validators();

    // get the current account data (if it exits) from the vue storage
    const accountDataInfo = ref(vueStore.getters["account/getAccountData"]);

    // compute check action to know if feching accountData was successful
    const compCheckAction = computed(function () {
      const accountData = accountDataInfo.value;

      return accountData && props.actionType == "settings" ? true : false;
    });

    function routeBasicInfo() {
      router.replace("my_account");
    }

    // reference form variables validation in main doc
    const fv: any = reactive({
      category: {
        status: false,
        val: compCheckAction.value ? accountDataInfo.value["category"] : "",
        msg: false,
        validate: ["required"],
      },
      expertise: {
        status: false,
        val: compCheckAction.value ? accountDataInfo.value["expertise"] : "",
        msg: false,
        validate: [
          "required",
          "safeSpaceInput",
          { option: "textMin", val: 3 },
          { option: "textMax", val: 60 },
        ],
      },
      contact_phone: {
        status: false,
        val: compCheckAction.value
          ? accountDataInfo.value["contact_phone"]
          : "",
        msg: false,
        validate: [
          "required",
          "numeric",
          { option: "textMin", val: 6 },
          { option: "textMax", val: 16 },
        ],
      },
      country: {
        status: false,
        val: compCheckAction.value ? accountDataInfo.value["country"] : "",
        msg: false,
        validate: [
          "required",
          "safeSpaceInput",
          { option: "textMin", val: 6 },
          { option: "textMax", val: 60 },
        ],
      },
      city: {
        status: false,
        val: compCheckAction.value ? accountDataInfo.value["city"] : "",
        msg: false,
        validate: [
          "required",
          "safeSpaceInput",
          { option: "textMin", val: 3 },
          { option: "textMax", val: 60 },
        ],
      },
      currency: {
        status: false,
        val: compCheckAction.value ? accountDataInfo.value["currency"] : "",
        msg: false,
        validate: [
          "required",
          "safeSpaceInput",
          { option: "textMin", val: 2 },
          { option: "textMax", val: 6 },
        ],
      },
      location_address: {
        status: false,
        val: compCheckAction.value
          ? accountDataInfo.value["location_address"]
          : "",
        msg: false,
        validate: [
          "required",
          { option: "textMin", val: 3 },
          { option: "textMax", val: 150 },
        ],
      },
      delivery_mode: {
        status: false,
        val: compCheckAction.value
          ? accountDataInfo.value["delivery_mode"]
          : "",
        msg: false,
        validate: [
          "required",
          "safeSpaceInput",
          { option: "textMin", val: 3 },
          { option: "textMax", val: 12 },
        ],
      },
      bio: {
        status: false,
        val: compCheckAction.value ? accountDataInfo.value["bio"] : "",
        msg: false,
        validate: [
          "required",
          { option: "textMin", val: 3 },
          { option: "textMax", val: 500 },
        ],
      },
      showSubmit: { status: false, val: true },
    });

    // reference request state and response in main doc
    const reqFeedback = ref("");
    const reqStatus = ref("ready");
    const reqFormStatus = ref(true);

    // initiate reactive sessionData variable
    const sessionData: any = ref(null);

    async function getSessionData() {
      const newSessionData = await sk.getStoreIon("session_data");
      sessionData.value = await JSON.parse(newSessionData);
    }

    getSessionData();

    const serviceDeliveryMode = ref([
      { value: "", alias: "Select option" },
      { value: "in-person", alias: "In-person" },
      { value: "remote", alias: "Remote" },
      { value: "hybrid", alias: "Hybrid (In-person & remote)" },
    ]);

    const countryList = ref(geo.countryDetails);
    const categoryList = ref(dataList.categoryList);

    const compStateList = computed(function () {
      const selectedCountry = fv.country.val;
      let stateList: any = [selectedCountry];

      if (selectedCountry) {
        const tempStateList = geo.statesDetails[selectedCountry];
        stateList = tempStateList ? tempStateList : stateList;
      }

      return stateList;
    });

    function suggestCurrency() {
      const activeCountry = countryList.value.filter(
        (item) => item["countryName"] === fv.country.val
      );
      fv.currency.val =
        activeCountry.length === 1
          ? activeCountry[0]["currencyCode"] +
            "-" +
            activeCountry[0]["countryCode"]
          : "";
    }

    // Example of a POST request. Note: data
    // can be passed as a raw JS Object (must be JSON serializable)
    const changeProfileInfo = async () => {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const tokenId = await sk.getStoreIon("access_token");

      reqStatus.value = "processing";

      const options = {
        url: coreUrl.backendUrl + "update_profile_info",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenId,
        },
        data: {
          iv_category: fv.category.val,
          iv_expertise: fv.expertise.val,
          iv_contact_phone: fv.contact_phone.val,
          iv_country: fv.country.val,
          iv_city: fv.city.val,
          iv_currency: fv.currency.val,
          iv_location_address: fv.location_address.val,
          iv_delivery_mode: fv.delivery_mode.val,
          iv_bio: fv.bio.val,
          iv_timezone: userTimeZone,
        },
      };

      // send the request to the backend
      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      async function runStorage() {
        try {
          accountDataInfo.value["category"] = fv.category.val;
          accountDataInfo.value["expertise"] = fv.expertise.val;
          accountDataInfo.value["contact_phone"] = fv.contact_phone.val;
          accountDataInfo.value["country"] = fv.country.val;
          accountDataInfo.value["city"] = fv.city.val;
          accountDataInfo.value["currency"] = fv.currency.val;
          accountDataInfo.value["location_address"] = fv.location_address.val;
          accountDataInfo.value["delivery_mode"] = fv.delivery_mode.val;
          accountDataInfo.value["bio"] = fv.bio.val;

          // save data in vue x for faster state management
          const payload = { loadAuth: true, data: accountDataInfo.value };
          await vueStore.dispatch("account/executeSetAccountData", payload);

          await sk.setStoreIon(
            "session_data",
            JSON.stringify(response.data.data.session_data)
          );
          const payload_session = {
            loadAuth: true,
            data: response.data.data.session_data,
          };
          await vueStore.dispatch(
            "auth/executeSetSessionData",
            payload_session
          );
        } catch (e) {
          console.log(e);
        }
      }

      if (response.data.status) {
        reqFeedback.value = response.data.message.main_msg;
        runStorage();

        reqFormStatus.value = props.actionType === "settings" ? true : false;
        reqStatus.value = "complete";
      } else if (response.status === 401) {
        // const payload = { loadAuth:false, data:response.data.data.sessionData}
        // await vueStore.dispatch("auth/executeSetSessionData", payload);

        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        window.location.href = "/signin";
      } else {
        const iv_msg: any = response.data.message.iv_msg;
        reqFeedback.value = response.data.message.main_msg;

        if (iv_msg) {
          for (const key in iv_msg) {
            if (fv[key]) {
              fv[key]["status"] = false;
              fv[key]["msg"] = iv_msg[key];
            }
          }
        }

        reqStatus.value = "ready";
      }
    };

    const runChangeProfileInfo = async () => {
      changeProfileInfo()
        .then()
        .catch((err) => {
          const response = err
            ? "Temporary server issue"
            : "Temporary server issue";
          feedbackToast(response);
        });
    };

    return {
      briefcase,
      star,
      send,
      call,
      earth,
      location,
      pin,
      informationCircle,
      serviceDeliveryMode,
      categoryList,
      countryList,
      compStateList,
      suggestCurrency,
      reqFeedback,
      runChangeProfileInfo,
      validate,
      fv,
      isWebBigScreen,
      reqStatus,
      reqFormStatus,
      routeBasicInfo,
    };
  },
});
</script>

<style scoped>
ion-list {
  --background: #fff;
}

ion-item {
  --background: #fff;
  --color: #111;
  --padding: 0px;

  /* --border-style: dashed; */
  /* --border-width: 2px; */

  /* --border-radius: 20px;

   --ripple-color: purple;

   --detail-icon-color: white;
   --detail-icon-opacity: 1;
   --detail-icon-font-size: 20px; */
}
</style>
