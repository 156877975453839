import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  namespaced: true,
  state() {
    return {
      loadAuthState: false,
      sessionData: {
        accessTokenExpiry: null,
        accessToken: null,
        ownerId: null,
        authId: null,
        email: null,
        phone: null,
        avatar: null,
        name: null,
        username: null,
        accountType: null,
        isAuthenticated: false,
        onboardUsername: false,
        onboardProfile: false,
        requestAppointments: false,
        serviceProvider: false,
        autoVerification: "unverified",
        manualVerification: "unverified",
        fav_category: ["all"],
        fav_services: ["all"],
      },
      defaultSessionData: {
        accessTokenExpiry: null,
        accessToken: null,
        ownerId: null,
        authId: null,
        email: null,
        phone: null,
        avatar: null,
        name: null,
        username: null,
        accountType: null,
        isAuthenticated: false,
        onboardUsername: false,
        onboardProfile: false,
        requestAppointments: false,
        serviceProvider: false,
        autoVerification: "unverified",
        manualVerification: "unverified",
        fav_category: ["all"],
        fav_services: ["all"],
      },
    };
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
};
