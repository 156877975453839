import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_feed_fab = _resolveComponent("feed-fab")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isWebBigScreen && _ctx.showFloatingTab)
      ? (_openBlock(), _createBlock(_component_feed_fab, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.showMenuTab)
      ? (_openBlock(), _createBlock(_component_ion_footer, {
          key: 1,
          class: "ion-no-border"
        }, {
          default: _withCtx(() => [
            (!_ctx.isWebBigScreen)
              ? (_openBlock(), _createBlock(_component_ion_toolbar, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_tabs, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_router_outlet),
                        _createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_tab_button, {
                              tab: "menu",
                              onClick: _ctx.openMenuOptions
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.menu }, null, 8, ["icon"])
                              ]),
                              _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_ion_tab_button, {
                              tab: "search",
                              href: "/search"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.searchOutline }, null, 8, ["icon"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_tab_button, {
                              tab: "Feed",
                              href: "/feed"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.home }, null, 8, ["icon"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_tab_button, {
                              tab: "calendar",
                              href: "/calendar"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.calendar }, null, 8, ["icon"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_tab_button, {
                              tab: "my_account",
                              href: "/my_account"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.person }, null, 8, ["icon"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}