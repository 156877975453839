<template>
  <div class="container rendez-wide">
    <div class="row" style="min-height: 100vh">
      <div
        class="col-lg-3 left mr-5 d-none d-lg-block d-xl-block d-md-block"
        v-if="isWebBigScreen"
      ></div>
      <div
        class="col-lg-8 col-sm-12 bg-white ml-1 pt-3"
        :class="isWebBigScreen ? '' : 'mx-0 px-0 '"
      >
        <h4 class="mt-0" v-if="!isWebBigScreen">
          {{ currentCalendarDateTitle }}
        </h4>
        <FullCalendar :options="calendarOptions" v-if="showCalendar" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, inject } from "vue";
import { modalController } from "@ionic/vue";

import "@fullcalendar/core"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";

import { useStore } from "vuex";

import CreateAppointmentBookingReview from "@/components/forms/appointment/CreateAppointmentBookingReview.vue";

export default defineComponent({
  name: "CalendarData",
  components: {
    FullCalendar,
  },
  props: {
    compBookingList: {
      type: Array,
      required: true,
      default: () => [],
    },
    scheduleSearchValue: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup(props) {
    // get the global vuex store objects
    const vueStore = useStore();

    const isWebBigScreen = ref(inject("isWebBigScreen"));

    const compOwnerId = computed(function () {
      const sessionData = vueStore.getters["auth/getSessionData"];
      return sessionData ? sessionData["ownerId"] : "";
    });

    const currentCalendarDateTitle: any = ref("");

    const handleEventClick = (arg) => {
      const order_id = arg.event.id;
      const title = arg.event.title;

      openBookingForm(order_id, title);
    };

    const handleDateChange = (arg) => {
      currentCalendarDateTitle.value = arg.view.title;
    };

    const calendarOptions = ref({
      contentHeight: 650,
      headerToolbar: {
        titleFormat: "MMM D YYYY",
        start: "dayGridMonth,timeGridWeek,timeGridDay,listWeek", // will normally be on the left. if RTL, will be on the right
        center: isWebBigScreen.value ? "title" : "",
        end: "prev,next,today", // will normally be on the right. if RTL, will be on the left
      },
      plugins: [dayGridPlugin, listPlugin, interactionPlugin, timeGridPlugin],
      initialView: "dayGridMonth",
      navLinks: true,
      events: [],
      eventClick: handleEventClick,
      datesSet: handleDateChange,
    });

    const bookingStatus = {
      pending: "#292b2c",
      accepted: "#0275d8",
      ongoing: "#5cb85c",
      completed: "#7e00e9",
      overdue: "#f0ad4e",
      rejected: "#d9534f",
      cancelled: "#000000",
    };

    const activeBookingList = async (unifiedBookingLists: any) => {
      // const bookingList: any = props.compBookingList;
      const finalEventList: any = [];

      // start get list of booking sessions

      let general_booking_list: any = [];

      if (unifiedBookingLists.length > 0) {
        let x = 0;
        const bookingArrayLength = unifiedBookingLists.length;

        while (x < bookingArrayLength) {
          if (unifiedBookingLists[x]["session_time"]) {
            const new_existing_booking = JSON.parse(
              unifiedBookingLists[x]["session_time"]
            );
            let y = 0;

            while (y < new_existing_booking.length) {
              new_existing_booking[y]["name"] = unifiedBookingLists[x][
                "onboardProfile"
              ]
                ? unifiedBookingLists[x]["username"]
                : unifiedBookingLists[x]["user_category"] === "active"
                ? unifiedBookingLists[x]["name"]
                : unifiedBookingLists[x]["guest_name"];
              new_existing_booking[y]["username"] =
                unifiedBookingLists[x]["username"];
              new_existing_booking[y]["owner_id"] =
                unifiedBookingLists[x]["owner_id"];
              new_existing_booking[y]["request_status"] =
                unifiedBookingLists[x]["request_status"];
              new_existing_booking[y]["order_id"] =
                unifiedBookingLists[x]["order_id"];
              new_existing_booking[y]["provider_id"] =
                unifiedBookingLists[x]["provider_id"];

              y++;
            }

            general_booking_list =
              general_booking_list.concat(new_existing_booking);
          }

          x++;
        }
      }

      // end get list of booking sessions

      if (general_booking_list.length > 0 && compOwnerId.value) {
        let i = 0;

        while (i < general_booking_list.length) {
          const bookingInfoType: any =
            general_booking_list[i]["provider_id"] === compOwnerId.value
              ? "order"
              : "request";
          const newBooking = {
            id: general_booking_list[i]["order_id"],
            classNames: "pointer-cursor",
            title: `Appointment with ${general_booking_list[i]["name"]} ${
              bookingInfoType === "order" ? " as provider " : " as client "
            } `,
            start:
              compOwnerId.value === general_booking_list[i]["owner_id"]
                ? general_booking_list[i]["client_start_time"]
                : general_booking_list[i]["provider_start_time"],
            end:
              compOwnerId.value === general_booking_list[i]["owner_id"]
                ? general_booking_list[i]["client_end_time"]
                : general_booking_list[i]["provider_end_time"],
            backgroundColor:
              bookingStatus[general_booking_list[i]["request_status"]],
            borderColor:
              bookingStatus[general_booking_list[i]["request_status"]],
          };

          finalEventList.push(newBooking);

          i++;
        }
      }

      return finalEventList;
    };

    const showCalendar = ref(false);
    async function getCalendarData() {
      calendarOptions.value.events = await activeBookingList(
        props.compBookingList
      );
      showCalendar.value = true;
    }

    getCalendarData();

    watch(
      () => props.scheduleSearchValue,
      async (_currentData, _oldData) => {
        showCalendar.value = false;
        calendarOptions.value.events = await activeBookingList(
          props.compBookingList
        );
        showCalendar.value = true;
      }
    );

    watch(
      () => props.compBookingList,
      async (_currentData, _oldData) => {
        showCalendar.value = false;
        calendarOptions.value.events = await activeBookingList(
          props.compBookingList
        );
        showCalendar.value = true;
      }
    );

    async function openBookingForm(order_id: string, eventTitle: string) {
      const unifiedBookingLists: any = props.compBookingList;
      const unifiedBookingInfoIndex = unifiedBookingLists.findIndex(
        (item) => item.order_id === order_id
      );

      const bookingInfoType: any =
        unifiedBookingLists[unifiedBookingInfoIndex]["provider_id"] ===
        compOwnerId.value
          ? "order"
          : "request";

      const componentValues: any = {
        filterStatus: "accepted",
        reviewTitle: eventTitle,
        bookingInfoType: bookingInfoType,
        bookingInfo: unifiedBookingLists[unifiedBookingInfoIndex],
        allowReview: true,
        actionType: "Review",
        isRequestRating: false,
        isPastEvent: false,
        isGuestReviewer: false,
      };

      const modal = await modalController.create({
        component: CreateAppointmentBookingReview,
        mode: "ios",
        cssClass: !isWebBigScreen.value ? "" : "large-xlg",
        backdropDismiss: false,
        componentProps: componentValues,
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if (role === "confirm") {
        if (data.type === "review") {
          if (bookingInfoType === "order") {
            const bookingLists: any =
              vueStore.getters["bookings/getBookingOrders"];

            const bookingInfoIndex = bookingLists.findIndex(
              (item) => item.order_id === order_id
            );

            bookingLists[bookingInfoIndex]["request_status"] =
              data["selected_status"];

            const payload = {
              data: bookingLists,
              action: "replace",
            };

            vueStore.dispatch("bookings/executeSetBookingOrders", payload);
          }

          if (bookingInfoType === "request") {
            const bookingLists: any =
              vueStore.getters["bookings/getBookingRequests"];

            const bookingInfoIndex = bookingLists.findIndex(
              (item) => item.order_id === order_id
            );

            bookingLists[bookingInfoIndex]["request_status"] =
              data["selected_status"];

            const payload = {
              data: bookingLists,
              action: "replace",
            };

            vueStore.dispatch("bookings/executeSetBookingRequests", payload);
          }
        }
      }
    }

    // setTimeout(() => {
    //     showCalendar.value=true
    // }, 3000);

    return {
      isWebBigScreen,
      activeBookingList,
      calendarOptions,
      openBookingForm,
      showCalendar,
      currentCalendarDateTitle,
    };
  },
});
</script>

<style scoped>
ion-avatar {
  width: 40px;
  height: 40px;
}

.fc-event-time,
.fc-event-title {
  padding: 0 1px;
  white-space: normal;
  color: #333333;
}

.fc-daygrid-dot-event {
  color: #333333;
}
</style>

<!--

extendedProps: {
      description: 'whatever',
      madeupProperty: 'banana'
  }

businessHours: {
  // days of week. an array of zero-based day of week integers (0=Sunday)
  daysOfWeek: [ 1, 2, 3, 4 ], // Monday - Thursday
  startTime: '10:00', // a start time (10am in this example)
  endTime: '18:00', // an end time (6pm in this example)
} -->
