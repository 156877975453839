export default {
    setFeedData(state: any, payload: any){

        if(payload.action === "replace"){
            state.feedData = payload.data;
        }

        if(payload.action === "unshift"){
            state.feedData.unshift(payload.data);
            console.log(payload.data)
        }

        if(payload.action === "push"){
            state.feedData.push(payload.data);
        }

    }
};
