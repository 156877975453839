<template>
  <div
    class="container"
    :class="viewType === 'page' ? 'pt-4`  ' : ''"
    v-if="!showBookingOrderList"
  >
    <div id="center-spinner">
      <ion-spinner name="circles"></ion-spinner>
    </div>
  </div>

  <div
    class="container rendez-wide"
    v-if="bookingInfoTab === 'calendar' && showBookingOrderList"
  >
    <calendar-view-f-c
      :compBookingList="compBookingList"
      :scheduleSearchValue="scheduleSearchValue"
    ></calendar-view-f-c>
  </div>

  <div
    class="container rendez-wide pb-5"
    v-if="
      showBookingOrderList &&
      (bookingInfoTab === 'order' ||
        bookingInfoTab === 'request' ||
        bookingInfoTab === 'order_and_request')
    "
  >
    <div class="row" style="min-height: 100vh">
      <div
        class="col-lg-3 left mr-5 d-none d-lg-block d-xl-block d-md-block"
        v-if="isWebBigScreen && viewType === 'page'"
      ></div>
      <div
        class="bg-white ml-1"
        :class="
          viewType === 'page'
            ? isWebBigScreen
              ? 'col-lg-8 col-sm-12'
              : 'col-lg-8 col-sm-12 mx-0 px-0'
            : 'col-12 px-0'
        "
      >
        <div
          class="my-5 py-5 text-center text-secondary"
          v-if="compBookingList.length === 0"
        >
          <span
            class="material-symbols-rounded action-user-icon mx-1 pointer-cursor large-text-caption"
          >
            {{ navIcons[appointmentInfo] }}
          </span>
          <h2>
            You have no
            {{ appointmentInfo.replace("_", " ") }} with
            {{ bookingInfoTab === "order" ? "any client." : "" }}
            {{ bookingInfoTab === "request" ? "any provider." : "" }}
          </h2>
        </div>

        <ion-list>
          <ion-item
            lines="full"
            class="pt-3"
            v-for="(order, index) in compBookingList"
            :key="index + order.name"
          >
            <!-- <ion-icon :icon="calendarOutline" size="small" slot="start"></ion-icon> -->
            <ion-avatar
              slot="start"
              class="mb-3 pointer-cursor"
              @click="
                goToLink(
                  order.onboardProfile
                    ? 'p/' + order.username
                    : 'p/' + order.name
                )
              "
            >
              <img
                :alt="
                  order.onboardProfile
                    ? 'p/' + order.username
                    : 'p/' + order.name
                "
                :src="
                  imgBaseAvatarUrl + 'tr:di-default_avatar.png/' + order.avatar
                "
              />
            </ion-avatar>
            <ion-label class="pl-3 ion-text-wrap pb-3">
              <h3>
                <b>
                  <span v-if="order.provider_id === compOwnerId">
                    {{
                      filterAppointmentInfo === "ongoing"
                        ? "Ongoing session with "
                        : "Appointment request from"
                    }}
                  </span>
                  <span v-if="order.owner_id === compOwnerId">
                    {{
                      filterAppointmentInfo === "ongoing"
                        ? "Ongoing session with "
                        : "Appointment request to"
                    }}
                  </span>
                  {{
                    order.user_category === "active"
                      ? order.name
                      : order.guest_name
                  }}
                </b>
              </h3>
              <p class="text-small">
                <strong>
                  [ Time :
                  {{
                    tk.getTimeAliasUnrange(order.provider_start_time) +
                    " / " +
                    tk.formatCleanFullDate(order.provider_start_time)
                  }}
                  ]</strong
                >
              </p>
              <p>
                <i class="text-small">
                  {{
                    order.user_category === "active"
                      ? " Requested "
                      : " [Guest client] Request posted "
                  }}
                  {{ tk.fromNow(order.created_date) }}.
                </i>
                <i class="text-small" v-if="viewType === 'modal'">
                  <div v-if="order.provider_id === compOwnerId">
                    You are the provider
                  </div>
                  <div v-if="order.owner_id === compOwnerId">
                    You are the client
                  </div>
                </i>
              </p>
            </ion-label>

            <ion-label
              slot="end"
              v-if="
                order.request_status === 'completed' &&
                order.complete_status === 'completed'
              "
            >
              <i
                class="fa fa-star pl-1"
                v-for="star in order.rating ? parseInt(order.rating) : 0"
                :key="star"
              ></i>
            </ion-label>
            <ion-buttons slot="end" v-else>
              <ion-button
                text-captitalize="false"
                @click="openBookingForm(index)"
                fill="outline"
                class="mb-3"
                shape="round"
                color="tertiary"
              >
                <ion-label
                  v-if="
                    order.request_status === 'completed' &&
                    order.complete_status !== 'completed' &&
                    bookingInfoTab === 'order'
                  "
                >
                  Request rating
                </ion-label>
                <ion-label v-else>
                  <span v-if="viewType !== 'modal'">
                    {{
                      filterAppointmentInfo === "accepted" &&
                      bookingInfoTab === "order"
                        ? "Start session"
                        : compActionType.replace("_", " ")
                    }}
                  </span>
                </ion-label>
                <ion-icon :icon="ellipsisVertical" color="tertiary"> </ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
        </ion-list>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  IonItem,
  IonList,
  IonLabel,
  IonIcon,
  IonButtons,
  IonButton,
  IonSpinner,
  IonAvatar,
  modalController,
} from "@ionic/vue";
import {
  calendarOutline,
  checkmark,
  close,
  ellipsisVertical,
} from "ionicons/icons";
import { defineComponent, ref, inject, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import storeKeeper from "@/hooks/RendezStorage";
import timeKeeper from "@/hooks/Timer";
import { CapacitorHttp } from "@capacitor/core";
import urls from "@/hooks/urlConfig";

import CalendarViewFC from "@/components/calendar/CalendarViewFC.vue";
import CreateAppointmentBookingReview from "@/components/forms/appointment/CreateAppointmentBookingReview.vue";
import CreateAppointmentBookingRating from "@/components/forms/appointment/CreateAppointmentBookingRating.vue";

export default defineComponent({
  name: "CompAppointmentRequests",
  components: {
    IonItem,
    IonLabel,
    IonList,
    IonIcon,
    IonButtons,
    IonButton,
    IonSpinner,
    IonAvatar,
    CalendarViewFC,
  },
  props: {
    appointmentInfo: {
      type: String,
      required: true,
      default: "upcoming_schedule",
    },
    filterAppointmentInfo: {
      type: String,
      required: true,
      default: "pending",
    },
    bookingInfoTab: {
      type: String,
      required: true,
      default: "request",
    },
    scheduleSearchValue: {
      type: String,
      required: true,
      default: "",
    },
    viewType: {
      type: String,
      default: "page",
    },
  },
  setup(props) {
    const sk = storeKeeper();

    const tk = timeKeeper();

    // get the global vuex store objects
    const vueStore = useStore();

    const isWebBigScreen = ref(inject("isWebBigScreen"));

    //get the urls
    const coreUrl = urls.coreUrl;

    const imgBaseAvatarUrl = ref(coreUrl.imgAvatarHost);

    // get the global route object
    const router = useRouter();

    const sessionData: any = ref(vueStore.getters["auth/getSessionData"]);

    const compIsServiceProvider = computed(function () {
      const authenticate = sessionData.value
        ? sessionData.value["serviceProvider"]
        : false;
      return authenticate;
    });

    const compOwnerId = computed(function () {
      const data = sessionData.value ? sessionData.value["ownerId"] : "none";
      return data;
    });

    const showBookingOrderList = ref(false);

    const filterStatus: any = ref(props.filterAppointmentInfo);

    const compBookingInfoType = computed(function () {
      return props.bookingInfoTab;
    });

    function goToLink(link) {
      router.push(link);
    }

    // Example of a GET request
    const getBookingOrder = async () => {
      const tokenId = await sk.getStoreIon("access_token");

      const options = {
        url: coreUrl.backendUrl + `get_booking_info`,
        headers: { Authorization: "Bearer " + tokenId },
      };

      // or...
      const response = await CapacitorHttp.request({
        ...options,
        method: "GET",
      });

      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.push("home");
      }

      if (
        response.data.status &&
        response.data.data.bookings_list.order &&
        response.data.data.bookings_list.request
      ) {
        showBookingOrderList.value = true;

        const payload_order = {
          data: response.data.data.bookings_list.order
            ? response.data.data.bookings_list.order
            : [],
          action: "replace",
        };

        const payload_request = {
          data: response.data.data.bookings_list.request
            ? response.data.data.bookings_list.request
            : [],
          action: "replace",
        };

        vueStore.dispatch("bookings/executeSetBookingOrders", payload_order);
        vueStore.dispatch(
          "bookings/executeSetBookingRequests",
          payload_request
        );
      }
    };

    getBookingOrder();

    const navOptions = [
      { filterStatus: "pending", alias: "Pending" },
      { filterStatus: "ongoing", alias: "Ongoing" },
      { filterStatus: "accepted", alias: "Accepted" },
      { filterStatus: "completed", alias: "Completed" },
      { filterStatus: "rejected", alias: "Rejected" },
      { filterStatus: "cancelled", alias: "Cancelled" },
    ];

    const navIcons = {
      ongoing_appointments: "perm_contact_calendar",
      upcoming_schedule: "event",
      overdue_appointments: "calendar_clock",
      pending_requests: "feedback",
      pending_review: "rate_review",
      completed_requests: "event_available",
      cancelled_requests: "free_cancellation",
      rejected_requests: "event_busy",
    };

    const compNavOptions = computed(function () {
      const tempNavOptions: any = navOptions;

      return tempNavOptions;
    });

    const compBookingList: any = computed(function () {
      let bookingLists: any = [];

      let finalOrderList: any = [];

      const search = props.scheduleSearchValue.toLowerCase();

      if (compBookingInfoType.value === "order") {
        bookingLists = vueStore.getters["bookings/getBookingOrders"];
        finalOrderList = bookingLists
          ? bookingLists.filter((item) =>
              item["request_status"] === filterStatus.value ? true : false
            )
          : [];
      }

      if (compBookingInfoType.value === "request") {
        bookingLists = vueStore.getters["bookings/getBookingRequests"];
        finalOrderList = bookingLists
          ? bookingLists.filter((item) =>
              item["request_status"] === filterStatus.value ? true : false
            )
          : [];
      }

      if (compBookingInfoType.value === "calendar") {
        const bookingOrders = vueStore.getters["bookings/getBookingOrders"];
        const bookingRequests = vueStore.getters["bookings/getBookingRequests"];

        bookingLists = bookingOrders.concat(bookingRequests);

        finalOrderList = bookingLists
          ? bookingLists.filter((item) =>
              item["request_status"] === "accepted" ? true : false
            )
          : [];
      }

      if (compBookingInfoType.value === "order_and_request") {
        const bookingOrders = vueStore.getters["bookings/getBookingOrders"];
        const bookingRequests = vueStore.getters["bookings/getBookingRequests"];

        bookingLists = bookingRequests.concat(bookingOrders);

        if (filterStatus.value === "pending") {
          finalOrderList = bookingOrders
            ? bookingOrders.filter((item) =>
                item["request_status"] === filterStatus.value ? true : false
              )
            : [];
        } else {
          finalOrderList = bookingLists
            ? bookingLists.filter((item) =>
                item["request_status"] === filterStatus.value ? true : false
              )
            : [];
        }
      }

      // check for full accepted requests or accepted requests that are overdue
      if (
        filterStatus.value === "accepted" &&
        props.appointmentInfo === "overdue_appointments"
      ) {
        if (finalOrderList) {
          finalOrderList = finalOrderList.filter(function (item) {
            const currDate = new Date();
            const existingDate = new Date(item["provider_start_time"]);
            if (currDate > existingDate) {
              return true;
            }
            return false;
          });
        } else {
          finalOrderList = [];
        }
      }

      // check for full completed requests or completed requests pending review
      if (filterStatus.value === "completed") {
        if (props.appointmentInfo === "pending_review") {
          finalOrderList = finalOrderList
            ? finalOrderList.filter((item) =>
                item["complete_status"] !== "completed" ? true : false
              )
            : [];
        }

        if (props.appointmentInfo === "completed_requests") {
          finalOrderList = finalOrderList
            ? finalOrderList.filter((item) =>
                item["complete_status"] === "completed" ? true : false
              )
            : [];
        }

        finalOrderList = finalOrderList.sort((b, a) =>
          a.complete_status.localeCompare(b.complete_status)
        );
      }

      if (search) {
        finalOrderList = finalOrderList.filter(function (item) {
          const username = item["username"]
            ? item["username"].toLowerCase()
            : "";
          const name = item["name"] ? item["name"].toLowerCase() : "";
          const guest_name = item["guest_name"]
            ? item["guest_name"].toLowerCase()
            : "";
          if (
            username.indexOf(search) > -1 ||
            name.indexOf(search) > -1 ||
            guest_name.indexOf(search) > -1
          ) {
            return true;
          }
          return false;
        });
      }

      return finalOrderList;
    });

    const compAllowReview = computed(function () {
      const exempt: any = ["rejected", "cancelled", "completed"];
      return exempt.includes(filterStatus.value) ? false : true;
    });

    const compIsPastEvent = computed(function () {
      const status = ["rejected", "cancelled", "completed"].includes(
        filterStatus.value
      )
        ? true
        : false;
      return status;
    });

    const compActionType = computed(function () {
      let review: any = "";

      if (props.bookingInfoTab === "order") {
        if (
          ["rejected", "cancelled", "completed"].includes(filterStatus.value)
        ) {
          review = "Details";
        } else if (
          ["pending", "accepted", "ongoing"].includes(filterStatus.value)
        ) {
          review = "Review";
        }
      }

      if (props.bookingInfoTab === "request") {
        if (filterStatus.value === "completed") {
          review = "Rate_session";
        } else if (["rejected", "cancelled"].includes(filterStatus.value)) {
          review = "Details";
        } else if (
          ["pending", "accepted", "ongoing"].includes(filterStatus.value)
        ) {
          review = "Review";
        }
      }

      return review;
    });

    async function openBookingForm(index: any) {
      let reviewTitle = "";

      const isRequestRating: any =
        compBookingList.value[index]["request_status"] === "completed" &&
        compBookingList.value[index]["complete_status"] !== "completed" &&
        props.bookingInfoTab === "order"
          ? true
          : false;

      const currName: any =
        compBookingList.value[index]["user_category"] === "active"
          ? compBookingList.value[index]["name"].split(" ")[0]
          : compBookingList.value[index]["guest_name"].split(" ")[0];

      const requestRatingTitle = `Request review & rating from ${currName}`;

      if (compActionType.value === "Review") {
        reviewTitle =
          props.bookingInfoTab === "order"
            ? `Review ${currName}'s order`
            : `Review your request to ${currName}'s`;

        if (filterStatus.value === "accepted") {
          reviewTitle = reviewTitle =
            props.bookingInfoTab === "order"
              ? `Start your session with ${currName}`
              : `Review your scheduled session with ${currName}'s`;
        }
      }

      if (compActionType.value === "Details") {
        reviewTitle = `${compBookingList.value[index]["name"]}'s ${props.bookingInfoTab} details`;
      }

      if (compActionType.value === "Rate_session") {
        reviewTitle = `Rate your session with ${currName}`;
      }

      if (props.bookingInfoTab === "order_and_request") {
        reviewTitle =
          compBookingList.value[index]["provider_id"] === compOwnerId.value
            ? `Review ${currName}'s order`
            : `Review your request to ${currName}'s`;

        if (filterStatus.value === "accepted") {
          reviewTitle = reviewTitle =
            compBookingList.value[index]["provider_id"] === compOwnerId.value
              ? `Start your session with ${currName}`
              : `Review your scheduled session with ${currName}'s`;
        }
      }

      let currBookingInfoTab = "";

      if (compBookingList.value[index]["provider_id"] === compOwnerId.value) {
        currBookingInfoTab = "order";
      }

      if (compBookingList.value[index]["owner_id"] === compOwnerId.value) {
        currBookingInfoTab = "request";
      }

      const componentValues: any = {
        filterStatus: filterStatus.value,
        reviewTitle: isRequestRating ? requestRatingTitle : reviewTitle,
        bookingInfoType: currBookingInfoTab,
        bookingInfo: compBookingList.value[index],
        allowReview: compAllowReview.value,
        actionType: isRequestRating ? "Review" : compActionType.value,
        isRequestRating: isRequestRating,
        isPastEvent: compIsPastEvent.value,
        isGuestReviewer: false,
      };

      if (compActionType.value === "Rate_session") {
        componentValues["reviewRatingInfo"] = false;
      }

      const modal = await modalController.create({
        component:
          compActionType.value === "Rate_session" || isRequestRating
            ? CreateAppointmentBookingRating
            : CreateAppointmentBookingReview,
        mode: "ios",
        // cssClass: "",
        cssClass: !isWebBigScreen.value ? "" : "large-xlg",
        backdropDismiss: false,
        componentProps: componentValues,
      });
      modal.present();

      const { data, role } = await modal.onWillDismiss();

      const orderId: any = compBookingList.value[index]["order_id"];

      if (role === "confirm") {
        if (data.type === "review") {
          if (
            props.bookingInfoTab === "order" ||
            props.bookingInfoTab === "order_and_request"
          ) {
            const bookingLists: any =
              vueStore.getters["bookings/getBookingOrders"];

            const bookingInfoIndex = bookingLists.findIndex(
              (item) => item.order_id === orderId
            );

            if (bookingInfoIndex >= 0) {
              bookingLists[bookingInfoIndex]["request_status"] =
                data["selected_status"];

              const payload = {
                data: bookingLists,
                action: "replace",
              };
              vueStore.dispatch("bookings/executeSetBookingOrders", payload);
            }
          }

          if (
            props.bookingInfoTab === "request" ||
            props.bookingInfoTab === "order_and_request"
          ) {
            const bookingLists: any =
              vueStore.getters["bookings/getBookingRequests"];

            const bookingInfoIndex = bookingLists.findIndex(
              (item) => item.order_id === orderId
            );

            if (bookingInfoIndex >= 0) {
              bookingLists[bookingInfoIndex]["request_status"] =
                data["selected_status"];

              const payload = {
                data: bookingLists,
                action: "replace",
              };
              vueStore.dispatch("bookings/executeSetBookingRequests", payload);
            }
          }
        }

        if (data.type === "rating") {
          const bookingLists: any =
            vueStore.getters["bookings/getBookingRequests"];

          const bookingInfoIndex = bookingLists.findIndex(
            (item) => item.order_id === orderId
          );

          bookingLists[bookingInfoIndex]["rating"] = data["selected_rating"];
          bookingLists[bookingInfoIndex]["feedback"] = data["feedback"];
          bookingLists[bookingInfoIndex]["complete_status"] = "completed";
          bookingLists[bookingInfoIndex]["rating_status"] = "completed";

          const payload = {
            data: bookingLists,
            action: "replace",
          };
          vueStore.dispatch("bookings/executeSetBookingRequests", payload);
        }
      }
    }

    return {
      calendarOutline,
      checkmark,
      close,
      tk,
      ellipsisVertical,
      isWebBigScreen,
      goToLink,
      filterStatus,
      compNavOptions,
      navIcons,
      compIsServiceProvider,
      compAllowReview,
      compActionType,
      compBookingList,
      imgBaseAvatarUrl,
      showBookingOrderList,
      openBookingForm,
      compOwnerId,
    };
  },
});
</script>

<style scoped>
ion-icon {
  color: #000;
}

ion-button {
  text-transform: none;
}
</style>
