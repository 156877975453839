<template>
  <ion-header :translucent="true" mode="ios" class="ion-no-border">
    <ion-toolbar v-if="reqStatus === 'ready'">
      <ion-buttons slot="start">
        <ion-button @click="cancelModal">
          <ion-icon :icon="arrowBack"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title class="text-left pl-5" color="danger">Delete Post</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="" :fullscreen="true" mode="ios">
    <ion-list v-if="reqStatus !== 'complete'">
      <ion-item class="py-3">
        <ion-label>What will you like to do?</ion-label>
        <ion-select
          label="What will you like to do with this post?"
          label-placement="fixed"
          interface="popover"
          placeholder="Select action"
          slot="end"
          class="px-3"
          @ionChange="handleDeleteAction($event)"
        >
          <ion-select-option value="delete">Delete</ion-select-option>
          <ion-select-option value="archive">Archive</ion-select-option>
        </ion-select>
        <div slot="helper" class="text-danger float-right" v-if="fv.action.msg">
          {{ fv.action.msg }} | Select an action from the drop down.
        </div>
      </ion-item>
    </ion-list>

    <div class="my-3 px-3">
      <ion-button
        color="danger"
        expand="block"
        @click="deletePost"
        v-if="reqStatus === 'ready'"
      >
        Delete post
      </ion-button>
    </div>

    <ion-spinner
      name="circles"
      class="mb-3 ml-2"
      v-if="reqStatus === 'processing'"
    ></ion-spinner>

    <div class="container" v-if="reqStatus === 'complete'">
      <div class="text-center pt-5">
        <h5 class="font-weight-bold mt-5">
          Your post was successfuly deleted !!!
        </h5>
      </div>
    </div>

    <!-- End report summary  -->
  </ion-content>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonSpinner,
  IonItem,
  IonTitle,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  modalController,
} from "@ionic/vue";
import { defineComponent, reactive, ref } from "vue";
import { arrowBack } from "ionicons/icons";
import urls from "@/hooks/urlConfig";
import timeKeeper from "@/hooks/Timer";
import moneyInfo from "@/hooks/Money";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import storeKeeper from "@/hooks/RendezStorage";
import { CapacitorHttp } from "@capacitor/core";
import validators from "@/hooks/validation";

export default defineComponent({
  name: "FeedBooking",
  components: {
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonSpinner,
    IonTitle,
    IonLabel,
    IonItem,
    IonList,
    IonSelect,
    IonSelectOption,
  },
  props: {
    currentProvider: {
      required: true,
      default: { owner_id: "none" },
    },
  },
  setup(props) {
    const sk = storeKeeper();

    const tk = timeKeeper();

    const cash = moneyInfo();

    // get the validators
    const validate = validators();

    //get the urls
    const coreUrl = urls.coreUrl;

    // get the global vuex store objects
    const vueStore = useStore();

    const router = useRouter();

    const isAuthenticated = ref(vueStore.getters["auth/getSessionData"]);

    const postId = ref(props.currentProvider["post_id"]);

    const fv: any = reactive({
      action: {
        status: false,
        val: "",
        msg: false,
        validate: ["required"],
      },
      showSubmit: { status: false, val: true },
    });

    function handleDeleteAction(ev) {
      fv.action.val = ev.detail.value;
    }

    const reqFeedback = ref("");
    const reqStatus = ref("ready");
    const reqFormStatus = ref(true);

    // Example of a POST request. Note: data
    // can be passed as a raw JS Object (must be JSON serializable)
    const deletePost = async () => {
      const tokenId = await sk.getStoreIon("access_token");

      reqStatus.value = "processing";

      const options = {
        url: coreUrl.backendUrl + "feed_post_delete",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + tokenId,
        },
        data: {
          iv_post_id: postId.value,
          iv_action: fv.action.val,
        },
      };

      const response = await CapacitorHttp.request({
        ...options,
        method: "POST",
      });

      if (response.status === 401 || response.status === 422) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        router.replace("home");
      }

      if (response.data.status) {
        reqFeedback.value = response.data.message.main_msg;

        reqFormStatus.value = true;
        reqStatus.value = "complete";

        setTimeout(function () {
          return modalController.dismiss(fv.action.val, "confirm");
        }, 2000);
      } else if (response.status === 401) {
        // delete all data in the ionic storage db
        await sk.delAllStoreIon();

        window.location.href = "/signin";
      } else {
        const iv_msg: any = response.data.message.iv_msg;
        reqFeedback.value = response.data.message.main_msg;

        if (iv_msg) {
          for (const key in iv_msg) {
            if (fv[key]) {
              fv[key]["status"] = false;
              fv[key]["msg"] = iv_msg[key];
            }
          }
        }

        reqStatus.value = "ready";
      }
    };

    function cancelModal() {
      return modalController.dismiss(null, "cancel");
    }

    function confirmModal() {
      return modalController.dismiss(fv.action.val, "confirm");
    }

    return {
      tk,
      cash,
      isAuthenticated,
      handleDeleteAction,
      cancelModal,
      confirmModal,
      arrowBack,
      reqStatus,
      deletePost,
      reqFeedback,
      fv,
      validate,
    };
  },
});
</script>

<style scoped>
ion-toolbar {
  --background: #fff;
}

ion-datetime {
  --background: #f5f5f5;
}
</style>
